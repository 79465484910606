import {ChangeDetectorRef, Component, NgZone, OnInit, ViewChild} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Okta} from '../../services/okta/okta.service';
import {LoginService} from '../../services/login/login.service';
import {environment} from '../../../../environments/environment';
import { HttpClient } from "@angular/common/http";
import { Constants } from '../../../shared/constants/constants';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']


})
export class NavigationComponent implements OnInit {

  @ViewChild('sidenav') public sidenav;

  opened: boolean;
  currentRoute: any;
  isSuperAdmin: string;
  showlogin: boolean;
  oktaSignIn;
  role: string;
  productsRoles:any = [];
  public cloudType = environment.CLOUD_TYPE;

  constructor(private router: Router,
              private okta: Okta,
              private changeDetectorRef: ChangeDetectorRef,
              private zone: NgZone,
              private loginService: LoginService,
              private httpClient: HttpClient) {
    this.oktaSignIn = this.okta.getWidget();
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
        this.httpClient.get("assets/json/userPermissions.json")
        .subscribe(userpermission =>{
          this.role = localStorage.getItem('role');
          this.productsRoles = userpermission[this.role]
          console.log(this.productsRoles);
        });
        if (this.currentRoute === undefined || this.currentRoute === '/' || this.currentRoute === '/ui-service' ||
          this.currentRoute === '/ui-service/login' || this.currentRoute === '/ui-service/logout'
          || this.currentRoute === '/ui-service/forgotPassword') {
          const loginUser = localStorage.getItem('isSuperAdmin');
          const isUserLoginedin = loginUser === '' || loginUser === undefined || loginUser === null ? false : true;

           if (isUserLoginedin) {
            const urlToRedirect = loginUser === 'no' ? 'ui-service/community' : 'ui-service/clients';
            this.router.navigate([urlToRedirect]);
          } else {
            this.showlogin = true;
            this.router.navigate(['ui-service/login']);
          }
          this.showlogin = true;
        } else {
          this.isSuperAdmin = localStorage.getItem('isSuperAdmin');
          if (this.isSuperAdmin !== undefined && this.isSuperAdmin !== null && this.isSuperAdmin !== '') {
            this.showlogin = false;
            this.opened = true;
          } else {
            this.opened = false;
            this.showlogin = true;

          }
        }
      }
    });
  }

  logout() {
    this.loginService.logoutFromMicrosoft()
      .subscribe(res => {
        if (res.response.code === 200 && res.response.status === 'success') {
          localStorage.clear();
          if (sessionStorage.getItem('authToken') !== null) {
            sessionStorage.removeItem('authToken');
            }
            if (this.cloudType === 'gcp') {
                window.location.href = Constants.GOOGLE_CLOUD_LOGOUT_URL;
              } else {
                window.location.href = res.response.data.url;
              }
        }
      });
  }

  logoutFromChild() {
    this.logout();
  }

  toggleSideNavbar() {
    this.sidenav.toggle();
  }
}
