import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Customer } from "../../../shared/models/customer";
import { ClientsService } from "../../../core/services/customer/clients.service";
import { ModalComponent } from "../../../shared/components/modal/pages/modal.component";
import { Domain } from "../../../shared/models/domain";
import { Constants } from "../../../shared/constants/constants";
import { Authenticationprovider } from "../../../shared/models/Authenticationprovider";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";

@Component({
  selector: "app-manage-clients",
  templateUrl: "./clients.component.html",
  styleUrls: ["./clients.component.css"],
})
export class ClientsComponent implements OnInit {
  @ViewChild(ModalComponent, { static: true }) modalComponent;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  dataSource: MatTableDataSource<any>;

  customers: Customer[] = [];
  clientForm: FormGroup;
  submitButton: string;
  messageToDisplay: any;
  isDelete = false;
  customerId: number;
  showSpinner = false;
  searchString: string = "";
  targetColumn = "";
  loginTypes: Authenticationprovider[] = [];
  isAADEnabled = false;
  isValidated = false;
  domains: Domain[] = [];
  isValid: boolean = false;
  config = new MatSnackBarConfig();
  pageSize = 5;
  total: number;
  currentPage = 0;
  activeSort: String = "customerId";
  sortOrder: string = "asc";
  constructor(
    private customerService: ClientsService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar
  ) {
    this.createForm();
  }
  tableColumns: string[] = [
    "customerName",
    "address",
    "email",
    "contact",
    "action",
    "status",
  ];
  ngOnInit() {
    this.config.verticalPosition = "top";
    this.config.horizontalPosition = "right";
    this.config.duration = 5000;
    this.config.panelClass = ["blue-snackbar"];
    this.submitButton = "Save";
    this.getCustomers(
      this.pageSize,
      this.currentPage,
      this.activeSort,
      this.sortOrder,
      this.searchString
    );
    this.getAuthenticationproviders();
  }

  createForm() {
    this.clientForm = this.fb.group({
      customer_name: new FormControl("", [
        Validators.required,
        Validators.pattern("^[a-zA-Z]+(\\s[a-zA-Z]+)?$"),
      ]),
      address: new FormControl("", [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9\\s,'-]*$"),
      ]),
      email: new FormControl("", [Validators.required, Validators.email]),
      contact: new FormControl(null, [
        Validators.required,
        Validators.pattern("^((\\\\+91-?)|0)?[0-9]{10}$"),
        Validators.maxLength(10),
        Validators.minLength(10),
      ]),
      active: new FormControl(true),
      customer_id: new FormControl(null),
      domain_names: new FormControl("", [Validators.required]),
      login_type: new FormControl("", [Validators.required]),
      aad_client_id: new FormControl("", [Validators.required]),
      aad_client_secrect: new FormControl("", [Validators.required]),
      aad_tenant_id: new FormControl("", [Validators.required]),
    });
  }

  getCustomers(pageSize, currentPage, activeSort, sortOrder, searchString) {
    if (searchString == "") {
      this.showSpinner = true;
    }
    this.customerService
      .getCustomers(
        localStorage.getItem("loginUserId"),
        pageSize,
        currentPage,
        activeSort,
        sortOrder,
        searchString
      )
      .subscribe(
        (data) => {
          this.showSpinner = false;
          this.customers = data.response.data;
          this.total = data.response.totalRecordsCount;
          this.dataSource = new MatTableDataSource(this.customers);
          if (typeof this.paginator.length === "undefined") {
            this.paginator.length = currentPage;
          }
          this.paginator.pageIndex = currentPage;
          this.paginator.pageSize = pageSize;
          // this.dataSource.sort = this.sort;
          // this.paginator.pageIndex = currentPage;
          setTimeout(() => {
            this.paginator.length = this.total;
          });
        },
        (error) => {
          this.showSpinner = false;
          if (
            error.status === 401 &&
            error.error.response.status === "error" &&
            error.error.response.message === "UnAuthorized Access Token Expired"
          ) {
            return this.snackBar.open(
              Constants.SESSION_EXPIRED,
              "Close",
              this.config
            );
          } else {
            return this.snackBar.open(
              error.error.response.message,
              "Close",
              this.config
            );
          }
        }
      );
  }

  submit() {
    if (this.clientForm.value.email.indexOf(".com") === -1) {
      this.clientForm.value.email += ".com";
    }
    this.validateCustomer(this.clientForm.value);
    if (this.isValid) {
      if (this.submitButton === "Save") {
        this.showSpinner = true;
        this.customerService.save(this.clientForm.value).subscribe(
          (res) => {
            this.showSpinner = false;
            this.reset();
            this.getCustomers(
              this.pageSize,
              this.currentPage,
              this.activeSort,
              this.sortOrder,
              this.searchString
            );
            this.modalComponent.populateModal(res);
          },
          (error) => {
            this.showSpinner = false;
            if (
              error.status === 401 &&
              error.error.response.status === "error" &&
              error.error.response.message ===
                "UnAuthorized Access Token Expired"
            ) {
              return this.snackBar.open(
                Constants.SESSION_EXPIRED,
                "Close",
                this.config
              );
            } else {
              return this.snackBar.open(
                error.error.response.message,
                "Close",
                this.config
              );
            }
          }
        );
      } else {
        this.showSpinner = true;
        this.customerService.update(this.clientForm.value).subscribe(
          (res) => {
            this.showSpinner = false;
            this.reset();
            this.submitButton = "Save";
            this.getCustomers(
              this.pageSize,
              this.currentPage,
              this.activeSort,
              this.sortOrder,
              this.searchString
            );
            this.modalComponent.populateModal(res);
          },
          (error) => {
            this.showSpinner = false;
            if (
              error.status === 401 &&
              error.error.response.status === "error" &&
              error.error.response.message ===
                "UnAuthorized Access Token Expired"
            ) {
              return this.snackBar.open(
                Constants.SESSION_EXPIRED,
                "Close",
                this.config
              );
            } else {
              return this.snackBar.open(
                error.error.response.message,
                "Close",
                this.config
              );
            }
          }
        );
      }
    }
  }

  reset() {
    this.submitButton = "Save";
    this.clientForm.reset();
    this.isAADEnabled = false;
    this.clientForm.controls["login_type"].setValue("");
    this.clientForm.controls["login_type"].enable();
    this.clientForm.controls["aad_client_id"].setValue("");
    this.clientForm.controls["aad_client_secrect"].setValue("");
    this.clientForm.controls["aad_tenant_id"].setValue("");
    this.isValidated = false;
    this.clientForm.enable();
  }

  editCustomer(customer) {
    this.isValidated = false;
    this.clientForm.enable();
    this.submitButton = "Update";
    this.clientForm.controls["customer_name"].setValue(customer.customerName);
    this.clientForm.controls["address"].setValue(customer.address);
    this.clientForm.controls["email"].setValue(customer.email);
    this.clientForm.controls["contact"].setValue(customer.contact);
    this.clientForm.controls["active"].setValue(customer.active);
    this.clientForm.controls["customer_id"].setValue(customer.customerId);
    this.clientForm.controls["login_type"].setValue(
      customer.authenticationProvider.authProviderId
    );
    // this.clientForm.controls['login_type'].disable();
    if (
      customer.authenticationProvider.authProviderName === Constants.TYPE_AAD
    ) {
      this.isAADEnabled = true;
      this.clientForm.controls["aad_client_id"].setValue(
        customer.clientaccessdetail.clientId
      );
      this.clientForm.controls["aad_client_secrect"].setValue(
        customer.clientaccessdetail.clientSecret
      );
      this.clientForm.controls["aad_tenant_id"].setValue(
        customer.clientaccessdetail.applicationId
      );
    } else {
      this.isAADEnabled = false;
    }

    if (customer.domains.length > 0) {
      let domainNames = "";
      customer.domains.map((domain: Domain) => {
        domainNames += domain.domainName + ",";
      });
      const data = domainNames.substr(0, domainNames.length - 1);
      this.clientForm.controls["domain_names"].setValue(data);
    }
    document.getElementById("address_focus").focus();
  }

  delete(customer) {
    this.isDelete = true;
    this.customerId = customer.customer_id;
    this.modalComponent.populateModal({
      response: { message: Constants.DELETE_CONFIRMATION_MESSAGE },
    });
  }

  // ngAfterViewInit() {
  //   this.paginator.page.subscribe((event) => {
  //     console.log("Paginator event:", event);
  //     this.getCustomers(event.pageSize, event.pageIndex);
  //   });
  // }

  confirmDelete(confirmMsg: any) {
    this.modalComponent.closeModal();
    if (confirmMsg) {
      this.showSpinner = true;
      this.customerService.delete(this.customerId).subscribe(
        (res) => {
          this.showSpinner = false;
          this.getCustomers(
            this.pageSize,
            this.currentPage,
            this.activeSort,
            this.sortOrder,
            this.searchString
          );
          this.modalComponent.populateModal(res);
        },
        (error) => {
          this.showSpinner = false;
          if (
            error.status === 401 &&
            error.error.response.status === "error" &&
            error.error.response.message === "UnAuthorized Access Token Expired"
          ) {
            return this.snackBar.open(
              Constants.SESSION_EXPIRED,
              "Close",
              this.config
            );
          } else {
            return this.snackBar.open(
              error.error.response.message,
              "Close",
              this.config
            );
          }
        }
      );
    } else {
      this.modalComponent.closeModal();
    }
    this.isDelete = false;
  }

  onSortChange(event) {
    this.sortOrder =
      event.direction == ""
        ? this.sortOrder == "asc"
          ? "desc"
          : "asc"
        : this.sortOrder == event.direction
        ? this.sortOrder == "asc"
          ? "desc"
          : "asc"
        : event.direction;
    this.getCustomers(
      this.pageSize,
      0,
      event.active,
      this.sortOrder,
      this.searchString
    );
  }
  updateStatus(event, customer: Customer) {
    this.showSpinner = true;
    this.customerService
      .updateStatus(customer.customerId, event.checked)
      .subscribe(
        (res) => {
          this.showSpinner = false;
          this.getCustomers(
            this.pageSize,
            this.currentPage,
            this.activeSort,
            this.sortOrder,
            this.searchString
          );
          if (event.checked) {
            res.response = {
              ...res.response,
              message: res.response.message.concat(
                Constants.ENABLE_COMMUNITIES_COMMUNITY_MAPPINGS
              ),
            };
          }
          this.modalComponent.populateModal(res);
        },
        (error) => {
          this.showSpinner = false;
          if (
            error.status === 401 &&
            error.error.response.status === "error" &&
            error.error.response.message === "UnAuthorized Access Token Expired"
          ) {
            return this.snackBar.open(
              Constants.SESSION_EXPIRED,
              "Close",
              this.config
            );
          } else {
            return this.snackBar.open(
              error.error.response.message,
              "Close",
              this.config
            );
          }
        }
      );
  }

  checkLoginType(providerId) {
    if (providerId === "") {
      this.isAADEnabled = false;
    } else {
      const provider: Authenticationprovider = this.loginTypes.filter(
        (authprovider: Authenticationprovider) => {
          return authprovider.authProviderId === +providerId;
        }
      )[0];
      this.isAADEnabled =
        provider.authProviderName === Constants.TYPE_AAD ? true : false;
    }
  }

  public getAuthenticationproviders() {
    this.showSpinner = true;
    this.customerService.authemticationProviers().subscribe(
      (res) => {
        this.showSpinner = false;
        if (res.response.code === 200 && res.response.status === "success") {
          this.loginTypes = res.response.data;
        } else {
          this.modalComponent.populateModal({
            response: { message: res.response.message },
          });
        }
      },
      (error) => {
        this.showSpinner = false;
        if (
          error.status === 401 &&
          error.error.response.status === "error" &&
          error.error.response.message === "UnAuthorized Access Token Expired"
        ) {
          return this.snackBar.open(
            Constants.SESSION_EXPIRED,
            "Close",
            this.config
          );
        } else {
          return this.snackBar.open(
            error.error.response.message,
            "Close",
            this.config
          );
        }
      }
    );
  }

  applyFilter(filterValue: string) {
    this.searchString = filterValue.trim().toLowerCase();
    this.getCustomers(
      this.pageSize,
      0,
      this.activeSort,
      this.sortOrder,
      this.searchString
    );
  }
  onChangePage(event) {
    if (this.pageSize !== event.pageSize) {
      this.currentPage = 0; // Reset to first page if page size changes
      this.pageSize = event.pageSize;
    } else {
      this.currentPage = event.pageIndex;
    }
    this.pageSize = +event.pageSize;
    this.getCustomers(
      this.pageSize,
      this.currentPage,
      this.activeSort,
      this.sortOrder,
      this.searchString
    );
  }
  public validateCustomer(customerData) {
    this.isValid = true;
    for (let customer of this.customers) {
      if (
        customer.customerName === customerData.customer_name &&
        this.submitButton === "Save"
      ) {
        this.isValid = false;
        return this.snackBar.open(
          Constants.CUSTOMER_EXISTS,
          "Close",
          this.config
        );
      }
      if (
        customer.customerName === customerData.customer_name &&
        this.submitButton === "Update" &&
        customer.customerId !== customerData.customer_id
      ) {
        this.isValid = false;
        return this.snackBar.open(
          Constants.CUSTOMER_EXISTS,
          "Close",
          this.config
        );
      }
    }

    let selectedDomains: any[] = customerData.domain_names.split(",");
    selectedDomains = selectedDomains.map((x) => x.trim());
    if (selectedDomains.length > 0) {
      let domains = new Set(selectedDomains);
      if (selectedDomains.length > domains.size) {
        this.isValid = false;
        return this.modalComponent.populateModal({
          response: { message: Constants.DOMAIN_DUPLICATE },
        });
      }
    }

    for (let customer of this.customers) {
      let customerDomains = customer.domains;
      for (let customerDomain of customerDomains) {
        customerDomain.domainName = customerDomain.domainName.trim();
        this.domains.push(customerDomain);
      }
    }

    /*   for (let enteredDomain of selectedDomains) {
      let d = {} as Domain 
      d.domainName = enteredDomain;
      d.domainId = -1;
      this.domains.push(d);
    } */
    for (let domain of this.domains) {
      if (
        selectedDomains.includes(domain.domainName) &&
        domain.domainCustomerId !== customerData.customer_id
      ) {
        this.isValid = false;
        return this.modalComponent.populateModal({
          response: { message: Constants.DOMAIN_EXISTS },
        });
      }
    }
  }

  validate() {
    let customer = this.clientForm.value;
    let message = "";
    this.validateCustomer(customer);
    if (this.isValid) {
      const payload = {
        address: customer.address.trim(),
        contact: +customer.contact,
        customerName: customer.customer_name.trim(),
        email: customer.email.trim(),
        clientaccessdetail: {
          clientId: customer.aad_client_id,
          clientSecret: customer.aad_client_secrect,
          applicationId: customer.aad_tenant_id,
        },
        authenticationProvider: { authProviderId: +customer.login_type },
      };
      this.showSpinner = true;
      this.customerService.validateCustomerCredentials(payload).subscribe(
        (res) => {
          this.showSpinner = false;
          if (res === true) {
            this.isValidated = true;
            message = Constants.CUSTOMER_DETAILS_VERIFIED_SUCCESSFULLY;
            this.clientForm.disable();
            return this.modalComponent.populateModal({
              response: { message: message },
            });
          } else {
            message = Constants.INVALID_CUSTOMER_CREDENTIALS;
            return this.modalComponent.populateModal({
              response: { message: message },
            });
          }
        },
        (error) => {
          this.showSpinner = false;
          if (
            error.status === 401 &&
            error.error.response.status === "error" &&
            error.error.response.message === "UnAuthorized Access Token Expired"
          ) {
            return this.snackBar.open(
              Constants.SESSION_EXPIRED,
              "Close",
              this.config
            );
          } else {
            return this.snackBar.open(
              error.error.response.message,
              "Close",
              this.config
            );
          }
        }
      );
    }
  }
}
