import {Injectable, NgZone} from '@angular/core';
//import * as OktaSignIn from '@okta/okta-signin-widget';
import {environment} from '../../../../environments/environment';
import {Router} from '@angular/router';

@Injectable({providedIn: 'root'})

export class Okta {
  widget;

  constructor(
    private zone: NgZone,
    private router: Router
  ) {
   /* this.widget = new OktaSignIn({
      logo: `${environment.OKTA_LOGO}`,
      baseUrl: `${environment.OKTA_BASE_URL}`,
      clientId: `${environment.OKTA_CLIENT_ID}`,
      redirectUri: `${environment.redirectUrl_OktaLogin}`,
      authParams: {
        issuer: 'default'
      },
      headers: {
        'Access-Control-Allow-Origin': `${environment.OKTA_BASE_URL}`,
        'Access-Control-Allow-Methods': 'HEAD, GET, POST, PUT, PATCH, DELETE',
        'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
      }
    }); */
  }

  getWidget() {
    return this.widget;
  }

  // logout() {
  //   this.getWidget().signOut(() => {
  //     localStorage.clear();
  //     localStorage.removeItem('isSuperAdmin');
  //     this.zone.run(() => {
  //       this.router.navigate(['logout']);
  //     });
  //   });
  // }
}
