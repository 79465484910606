import {ChangeDetectionStrategy, Component, Input, OnInit, ViewChild} from '@angular/core';
import {MappingsService} from '../../../core/services/Mappings/mappings.service';
import {Count} from '../../../shared/models/count';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {ModalComponent} from '../../../shared/components/modal/pages/modal.component';
import {Constants} from '../../../shared/constants/constants';
import {of} from 'rxjs/internal/observable/of';

@Component({
  selector: 'app-power-bi',
  templateUrl: './power-bi.component.html',
  styleUrls: ['./power-bi.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PowerBiComponent implements OnInit {

  @Input()
  communityId: number;

  @Input()
  categoryId: string;

  @Input()
  communityName: string;

  @ViewChild(ModalComponent, {static: true}) modal;


  spinner = false;
  data$: Observable<Count>;

  constructor(private mappingService: MappingsService) {
  }

  ngOnInit(): void {
    this.categoryId = this.categoryId === undefined ? '' : this.categoryId;
    this.spinner = true;
    this.data$ = this.mappingService.getPotentialData(this.communityId, this.communityName, this.categoryId)
      .pipe(
        map((httpResponse: any) => {
          this.spinner = false;
          return httpResponse.response.data;
        }),
        catchError((error) => {
          this.spinner = false;
          this.modal.populateModal({response: {message: Constants.SERVER_ERROR}});
          return of(null);
        })
      );
  }
}
