<li>
  <span>
    <i (click)="toggleorNot()" class="hand custom-icon" [ngClass]="!isToggle ? 'fa fa-caret-right' : 'fa fa-caret-down'"
       aria-hidden="true"></i>
     <label class="chkBoxContainer">
                  <input id="mig-category-{{category.name}}" [checked]="canSelect()" class="ml-1 mr-1" type="checkbox" (change)="onChange($event)">
                  <span class="checkmark"></span>
                </label>
    <i class="hand ml-1 mr-2 fa fa-folder" aria-hidden="true"></i>
  </span>
  {{category.name}}
  <div *ngIf="isToggle">
    <ul *ngFor="let subcat of category.categoryList">
      <app-category [data]="data" [parent]="category" [category]="subcat"></app-category>
    </ul>
    <ul *ngFor="let board of category.boardList">
      <app-board (boardEvent)="onBoardChange($event)" [parent]="category" [board]="board"></app-board>
    </ul>
  </div>
</li>

