import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.css']
})

/**
 * Used as middlewre for all other modules
 * to be maintained as TabView
 * used in the community creation page
 */
export class ApplicationComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }
}
