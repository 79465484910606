import {Pipe} from '@angular/core';
import {PipeTransform} from '@angular/core';

@Pipe({
  name: 'searchBy'
})
export class SearchByColumn implements PipeTransform {
  resultArray: Array<any> = [];

  transform(array: Array<any>, args?: any): any {
    if (args === undefined || args === '') {
      return array;
    } else {
      this.resultArray = [];
      array.forEach((obj) => {
        if (obj.hasOwnProperty('customerName')) {
          if (obj.customerName.toLowerCase().indexOf(args.toLowerCase()) > -1) {
            this.resultArray.push(obj);
          }
        } else if (obj.hasOwnProperty('firstName') || obj.hasOwnProperty('lastName')) {
          if (obj.firstName.toLowerCase().indexOf(args.toLowerCase()) > -1
            || obj.lastName.toLowerCase().indexOf(args.toLowerCase()) > -1) {
            this.resultArray.push(obj);
          }
        } else if (obj.hasOwnProperty('communityName')) {
          if (obj.communityName.toLowerCase().indexOf(args.toLowerCase()) > -1) {
            this.resultArray.push(obj);
          }
        } else if (obj.hasOwnProperty('subject')) {
          if (obj.subject.toLowerCase().indexOf(args.toLowerCase()) > -1) {
            this.resultArray.push(obj);
          }
        }
      });
      return this.resultArray;
    }
  }
}
