import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ActivateAccountComponent} from './pages/activate-account.component';

const routes: Routes = [
  {path: 'active-account', component: ActivateAccountComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ActivateAccountRoutingModule { }
