import {Injectable, ViewChild} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {ModalComponent} from '../../../shared/components/modal/pages/modal.component';

@Injectable()
export class CanActivateCustomerService implements CanActivate {
  userRole: string;

  constructor(private router: Router) {
  }

  canActivate(): boolean {
    let canNavigate = false;
    this.userRole = localStorage.getItem('isSuperAdmin');
    if (this.userRole !== null) {
      if (this.userRole === 'yes') {
        canNavigate = true;
      } else {
        this.router.navigate(['ui-service/community']);
      }
    } else {
      this.router.navigate(['ui-service/login']);
    }
    return canNavigate;
  }
}
