import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BoardMappingRoutingModule } from './board-mapping-routing.module';
import {MappingsComponent} from './pages/mappings.component';
import {ClientsService} from '../../core/services/customer/clients.service';
import {CommunityService} from '../../core/services/community/community.service';
import {MappingsService} from '../../core/services/Mappings/mappings.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTooltipModule} from '@angular/material/tooltip';
import { MatSelectModule} from '@angular/material/select';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {PopulateModalModule} from '../../shared/components/modal/populate-modal.module';
import {MatSelectInfiniteScrollModule} from 'ng-mat-select-infinite-scroll';
import { MatDividerModule } from '@angular/material/divider';
// import { BoardtoboardMappingComponent } from './boardtoboard-mapping/boardtoboard-mapping.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { MatSelectFilterModule } from 'mat-select-filter';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatDividerModule,
    MatSelectInfiniteScrollModule,
    BrowserAnimationsModule,
    BoardMappingRoutingModule,
    PopulateModalModule,
    MatTableModule,
    MatPaginatorModule,
    MatIconModule,
    MatInputModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatSelectFilterModule
  ],
  declarations: [MappingsComponent],
  exports: [
    MappingsComponent
  ],
  providers: [
    ClientsService,
    CommunityService,
    MappingsService
  ]
})
export class BoardMappingModule { }
