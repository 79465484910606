import { Component, Inject, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ModalComponent } from '../../../shared/components/modal/pages/modal.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConnectorService } from '../../../core/services/connector/connector.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { error } from '@angular/compiler/src/util';
import { Constants } from 'src/app/shared/constants/constants';
import { SkilljarEvent } from 'src/app/shared/models/skilljar-event.model';
import { MatTooltipModule } from '@angular/material/tooltip';
export interface ScheduleStatus {
    scheduleStatus: any;
    selectedConnector: any;
    entityType: any;
    skillJarEventsList: any;
}

@Component({
    selector: 'schedule-status.component',
    templateUrl: 'schedule-status.component.html',
    styleUrls: ['./schedule-status.component.css']
})
export class ScheduleStatusDialog implements OnInit, AfterViewInit {
    @ViewChild(ModalComponent, { static: true }) modalComponent;
    @ViewChild('viewSchedulesPaginator',  {read: MatPaginator, static: false}) viewSchedulesPaginator: MatPaginator;
    @ViewChild('viewSchedulesSort', {static: false}) viewSchedulesSort: MatSort;
    dataSource: MatTableDataSource<any>;
    selectedConnector: any;
    skillJarEventsList: SkilljarEvent[];
    entityType: any;
    spinner = false;
    config = new MatSnackBarConfig();
    scheduleListColumns = [];

    constructor(
        public dialogRef: MatDialogRef<ScheduleStatusDialog>,
        @Inject(MAT_DIALOG_DATA) public data: ScheduleStatus, private connectorService: ConnectorService, private snackBar: MatSnackBar) {
          if (data.entityType === 'skill-jar-events') {
            this.scheduleListColumns = [
              'eventDate',
              'userEmail',
              'courseTitle',
              'message',
              'status',
              'retryButton'
            ];
            this.skillJarEventsList = data.skillJarEventsList;
            this.entityType = data.entityType;
            this.dataSource = new MatTableDataSource(data.skillJarEventsList);
            this.dataSource.sortingDataAccessor = (item, property) => {
              if (property.includes('.')) return property.split('.').reduce((o,i)=>o[i], item);
              return item[property];
            };
            this.dataSource.sort = this.viewSchedulesSort;
          }
       else{
        this.scheduleListColumns=['scheduledFrom', 'completedAt', 'type', 'sourceStatus', 'targetStatus', 'scheduleStatus', 'totalCount', 'successCount', 'droppedCount','failedCount', 'retrySchedule'];
            this.selectedConnector = data.selectedConnector;
            this.entityType=data.entityType;
            this.dataSource = new MatTableDataSource(data.scheduleStatus);
            this.dataSource.paginator = this.viewSchedulesPaginator;
            this.dataSource.sortingDataAccessor = (item, property) => {
              if (property.includes('.')) return property.split('.').reduce((o,i)=>o[i], item)
              return item[property];
           };
           this.dataSource.sort = this.viewSchedulesSort;
          }
          
    }

    ngOnInit() {
        this.config.verticalPosition = 'top';
        this.config.horizontalPosition = 'end';
        this.config.duration = 5000;
        this.config.panelClass = ['blue-snackbar'];
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.viewSchedulesPaginator;
        this.dataSource.sortingDataAccessor = (item, property) => {
          if (property.includes('.')) return property.split('.').reduce((o,i)=>o[i], item)
          return item[property];
       };
        this.dataSource.sort = this.viewSchedulesSort;
    }
    close(): void {
        this.dialogRef.close();
    }

  reloadData() {
    this.spinner = true;
    this.connectorService.getSchedules(this.selectedConnector.id).subscribe(res => {
      this.dataSource = new MatTableDataSource(res);
      this.dataSource.paginator = this.viewSchedulesPaginator;
      this.dataSource.sortingDataAccessor = (item, property) => {
        if (property.includes('.')) return property.split('.').reduce((o, i) => o[i], item)
        return item[property];
      };
      this.dataSource.sort = this.viewSchedulesSort;
      this.spinner = false;
    }, error => {
      this.spinner = false;
      return this.snackBar.open(error.error.response.message, 'Close', this.config);
    });
  }

    retrySchedule(row: any) {
      this.connectorService.retryFailedSchedules(row.id)
        .subscribe(res => {
          console.log(res);
          if (res.response.code === 200 && res.response.status === 'success') {
            this.dialogRef.close(res.response.status);
            return this.snackBar.open(Constants.SCHEDULE_CONFIGURED, 'Close', this.config);
        }
        }, error => {
          console.log("Error occured when retring the schedule");
      });
    }
    reprocessSkillJarEvents(row: SkilljarEvent) {
      this.connectorService.reprocessSkillJarEvents(row).subscribe(
          res => {
              if (res.response.code === 200 && res.response.status === 'success') {
                  this.dialogRef.close(res.response.status);
                  return this.snackBar.open(Constants.SCHEDULE_CONFIGURED, 'Close', this.config);
              }
          },
          error => {
              this.snackBar.open('Error occurred while retrying SkillJar Events', 'Close', this.config);
          }
      );
  }
}
