import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {UserRoutingModule} from './user-routing.module';
import {UserService} from '../../core/services/user/user.service';
import {UsersComponent} from './pages/users.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { MatTooltipModule} from '@angular/material/tooltip';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ClientsService} from '../../core/services/customer/clients.service';
import {PipesModule} from '../../shared/pipes/pipes.module';
import {PopulateModalModule} from '../../shared/components/modal/populate-modal.module';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import {MatTableModule} from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { MatDividerModule } from '@angular/material/divider';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatSortModule, 
    MatDividerModule,   
    BrowserAnimationsModule,
    UserRoutingModule,
    PipesModule,
    PopulateModalModule,
    Ng2SearchPipeModule
  ],
  declarations: [
    UsersComponent
  ],
  providers: [
    UserService,
    ClientsService
  ]
})
export class UserModule {
}
