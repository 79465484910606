import { Component, Input, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Customer } from "../../../shared/models/customer";
import { ClientsService } from "../../../core/services/customer/clients.service";
import { Constants } from "../../../shared/constants/constants";
import { Community } from "../../../shared/models/community";
import { MappingsService } from "../../../core/services/Mappings/mappings.service";
import { ModalComponent } from "../../../shared/components/modal/pages/modal.component";
import { CustomFieldsService } from "../../../core/services/custom-field/custom-fields.service";
import { CustomField } from "../../../shared/models/custom-field";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";

@Component({
  selector: "app-custom-field",
  templateUrl: "./custom-field.component.html",
  styleUrls: ["./custom-field.component.css"],
})

/**
 * Custom field component
 * component used to save or update custom fields of a community
 */
export class CustomFieldComponent implements OnInit {
  @ViewChild(ModalComponent, { static: true }) modalComponent;

  public showSpinner = false;
  public customFieldForm: FormGroup;
  public customers: Customer[] = [];
  public communities: Community[] = [];
  public loginUserId = localStorage.getItem("loginUserId");
  public saveOrUpdate = "Save";
  public customFields: CustomField[] = [];
  public messageToDisplay: any;
  public isDelete = false;
  config = new MatSnackBarConfig();
  public index;
  customerObj = JSON.parse(localStorage.getItem("customerObj"));
  superAdmin = localStorage.getItem("isSuperAdmin") === "yes" ? true : false;
  selectedCommunity : Community;
  MetaDataFields :any[] =[];
  customFieldslength: number;
  showMetaDataFieldform :boolean = false;
  metadataLength: number = 0;
  khorosMetaData: any[] =[];

  constructor(
    private formBuilder: FormBuilder,
    private customerService: ClientsService,
    private mappingService: MappingsService,
    private customFieldService: CustomFieldsService,
    private snackBar: MatSnackBar
  ) {
    this.createForm();
  }

  /**
   * onLoading method
   * Fetch customers or communities based on Login user
   * if SUPER ADMIN logins it will populate Customers
   * or populate communities based on customer id
   */
  ngOnInit() {
    this.config.verticalPosition = "top";
    this.config.horizontalPosition = "end";
    this.config.duration = 5000;
    this.config.panelClass = ["blue-snackbar"];
    this.resetCustomFields();
    if (this.superAdmin) {
      this.fetchCustomers();
    } else {
      this.customerChange(this.customerObj.id);
    }
    this.MetaDataFields =[
      {
        name : 'transFirstQueue',
        id: 'lingotek.trans_first_queue'
      },
      {
        name:'isUpdate',
        id:'lingotek.is_update'
      },
      {
        name:'sourceTkb',
        id:'lingotek.source_tkb'
      }
    ]
  }

  /**
   * create for fields
   * validate form y wriritng rules
   */
  public createForm() {
    this.customFieldForm = this.formBuilder.group({
      customerId: new FormControl("", [Validators.required]),
      communityId: new FormControl("", [Validators.required]),
    });
  }

  /**
   * Fetch list of customer
   * rendering as a selection
   */
  public fetchCustomers() {
    this.showSpinner = true;
    this.customerService.getActiveCustomers(this.loginUserId).subscribe(
      (res) => {
        this.showSpinner = false;
        this.customers = res.response.data;
      },
      (error) => {
        this.showSpinner = false;
        if (
          error.status === 401 &&
          error.error.response.status === "error" &&
          error.error.response.message === "UnAuthorized Access Token Expired"
        ) {
          return this.snackBar.open(
            Constants.SESSION_EXPIRED,
            "Close",
            this.config
          );
        } else {
          return this.snackBar.open(
            error.error.response.message,
            "Close",
            this.config
          );
        }
      }
    );
  }

  /**
   * Fetch list of communities
   * By customer ID
   * @param customerId
   */
  public fetchCommunities(customerId: number) {
    this.showSpinner = true;
    this.mappingService.getCommunitybyCustomerId(customerId).subscribe(
      (res) => {
        this.showSpinner = false;
        this.communities = res.response.data;
      },
      (error) => {
        this.showSpinner = false;
        if (
          error.status === 401 &&
          error.error.response.status === "error" &&
          error.error.response.message === "UnAuthorized Access Token Expired"
        ) {
          return this.snackBar.open(
            Constants.SESSION_EXPIRED,
            "Close",
            this.config
          );
        } else {
          return this.snackBar.open(
            error.error.response.message,
            "Close",
            this.config
          );
        }
      }
    );
  }

  /**
   * Fetch Communities by customer ID
   * @param customerId
   */
  public customerChange(customerId: string) {
    if (customerId !== "") {
      this.fetchCommunities(+customerId);
    } else {
      this.communities = [];
      this.resetCustomFields();
      this.saveOrUpdate = "Save";
    }
  }

  /**
   * Fetch existing custome field mapping of selected community
   * if mapping exist, Update mapping
   * if not exist save custo fields
   * @param communityId
   */
  public communityChange(communityId: string) {
    this.selectedCommunity = this.communities.find(community => community.communityId === +communityId);
    if (communityId === "") {
      this.resetCustomFields();
      this.saveOrUpdate = "Save";
    }
    else {
      this.showSpinner = true;
      if (this.selectedCommunity.communityType.communityTypeName !== 'KHOROS-CLASSIC')
      {
        this.customFieldService.customFieldsByCommunityId(+communityId).subscribe(
          (res) => {
            this.showSpinner = false;
            if (res.response.code === 200 && res.response.status === "success") {
              if (res.response.data.length) {
                this.saveOrUpdate = "Update";
                this.customFields = res.response.data;
                this.customFieldslength = this.customFields.length ;
                this.khorosMetaData =res.response.data;
              } else {
                this.resetCustomFields();
                this.saveOrUpdate = "Save";
              }
            }
          },
          (error) => {
            this.showSpinner = false;
            if (
              error.status === 401 &&
              error.error.response.status === "error" &&
              error.error.response.message === "UnAuthorized Access Token Expired"
            ) {
              return this.snackBar.open(
                Constants.SESSION_EXPIRED,
                "Close",
                this.config
              );
            } else {
              return this.snackBar.open(
                error.error.response.message,
                "Close",
                this.config
              );
            }
          }
        );
      }
    }
    if (this.selectedCommunity.communityType.communityTypeName === 'KHOROS-CLASSIC') {
      this.showMetaDataFieldform =true ;
      this.showSpinner = true;
      this.customFieldService.getKhorosMetaDataFields([], +communityId).subscribe(
        (res) => {
          this.showSpinner = false;
           this.metadataLength = res.response.data.length ;
          if (res.response.code === 200 && res.response.status === "success") {
            if (this.metadataLength > 0 && this.metadataLength <= 3) {
              this.saveOrUpdate = 'Update';
              this.MetaDataFields.map(field => {
                const metadata = res.response.data.find(data => data.metadataName === field.name);
                if (metadata) {
                  field.value = metadata.metadataValue;
                  field.khorosClassicMetadataId = metadata.khorosClassicMetadataId;
                }
              });
            } else {
              this.saveOrUpdate ='Save';
              this.MetaDataFields.map(field => field.value = '');
            }
          }
        },
        (error) => {
          this.showSpinner = false;
          if (
            error.status === 401 &&
            error.error.response.status === "error" &&
            error.error.response.message === "UnAuthorized Access Token Expired"
          ) {
            return this.snackBar.open(
              Constants.SESSION_EXPIRED,
              "Close",
              this.config
            );
          } else {
            return this.snackBar.open(
              error.error.response.message,
              "Close",
              this.config
            );
          }
        }
      );
    }
  }

  /**
   * Reset form fields
   */
  public reset() {
    this.saveOrUpdate = "Save";
    this.createForm();
    this.resetCustomFields();
    this.showMetaDataFieldform =false;
    if (!this.superAdmin) {
      this.customerChange(this.customerObj.id);
    } else {
      this.communities = [];
    }
  }

  /**
   * Based on SaveOrUpdate button value
   * perform save or update functionality
   * @param CustomeFIeldForm
   */
  public submit() {
    const customFieldForm = this.customFieldForm.value;
    const payLoad = this.createPayLoad(customFieldForm);
    const emptyFields: CustomField[] = payLoad.filter((obj: CustomField) => {
        return obj.communityKey === "";
    });

    if (this.selectedCommunity.communityType.communityTypeName === 'KHOROS-CLASSIC') {
        const metadataPayload = [];
        const id = this.selectedCommunity.communityId;
        for (const field of this.MetaDataFields) {
            const existingMetadata = this.MetaDataFields.find(meta => meta.name === field.name);
            let khorosClassicMetadataId = 0;
            if (existingMetadata) {
                khorosClassicMetadataId = existingMetadata.khorosClassicMetadataId;
            } else {
              const metadataFromAPI = this.khorosMetaData.find(data => data.metadataName === field.name);
              if (metadataFromAPI) {
                  khorosClassicMetadataId = metadataFromAPI.khorosClassicMetadataId;
              }
            }
            metadataPayload.push({
                metadataValue: existingMetadata ? existingMetadata.value as string : field.value as string,
                metadataName: field.name as string,
                communityId: customFieldForm.communityId as number,
                khorosClassicMetadataId: khorosClassicMetadataId,
            });
        }
        if (this.metadataLength > 0) {
            this.saveOrUpdate = 'Update';
            this.showSpinner = true;
            this.customFieldService.KhorosMetaDataUpdate(metadataPayload, id)
                .subscribe((res) => {
                    this.handleApiResponse(res, true);
                    this.reset();
                });
        } else {
            this.saveOrUpdate = 'Save';
            this.showSpinner = true;
            this.customFieldService.KhorosMetaDataSave(metadataPayload, id)
                .subscribe((res) => {
                    this.handleApiResponse(res, true);
                    this.reset();
                });
        }
    } else {
        if (this.saveOrUpdate === "Save") {
            if (payLoad.length === 0 || emptyFields.length !== 0) {
                this.modalComponent.populateModal({
                    response: { message: "keys should not be empty" },
                });
                return;
            }

            this.showSpinner = true;
            this.customFieldService.save(payLoad, +customFieldForm.communityId)
                .subscribe((res) => {
                    this.handleApiResponse(res, false);
                    this.reset();
                });
        } else {
            this.showSpinner = true;
            this.customFieldService
                .update(payLoad, +customFieldForm.communityId)
                .subscribe((res) => {
                    this.handleApiResponse(res, false);
                    this.reset();
                });
        }
    }
}



  private handleApiResponse(res: any, closeForm: boolean = false) {
    this.showSpinner = false;
    if (res.response.code === 200 || res.response.status === "success") {
      this.modalComponent.populateModal({
        response: { message: res.response.message },
      });
      if (closeForm) {
        this.reset();
        this.showMetaDataFieldform = false;
      }
    }
  }

  /**
   * Method to add custome field
   * each time when end user clicks on ADD button
   */
  public add() {
    if (this.customFields.length < 50) {
      this.customFields.push({
        customFieldMappingsId: 0,
        communityKey: "",
      });
    } else {
      this.modalComponent.populateModal({
        response: { message: Constants.MAX_CUSTOM_FIELS_TO_BE_15_ONLY },
      });
    }
  }

  /**
   * PayLoad for Save or Update
   * @param formObject
   */
  public createPayLoad(formObject: any): Array<CustomField> {
    const payLoad: Array<CustomField> = [];
    this.customFields.map((obj) => {
      payLoad.push({
        customFieldMappingsId:
          this.saveOrUpdate === "Save" ? 0 : obj.customFieldMappingsId,
        communityKey: obj.communityKey,
      });
    });
    return payLoad;
  }

  public resetCustomFields() {
    this.customFields = [
      {
        customFieldMappingsId: 0,
        communityKey: "",
      },
    ];
  }
  /**
   * Getting confirmation of user action
   * Fetch the index of deleting custom field
   * @param index
   */
  public confirm(index) {
    this.index = index;
    this.isDelete = true;
    this.modalComponent.populateModal({
      response: { message: Constants.DELETE_CONFIRMATION_MESSAGE },
    });
    // this.customFields.splice(index, 1);
  }

  /**
   * Delete custom field
   * @param isDelte
   */
  public delete(isDelte: boolean) {
    this.modalComponent.closeModal();
    if (isDelte) {
      this.customFields.splice(this.index, 1);
      this.isDelete = false;
    }
  }
}
