<li class=" board-icons">
  <span>
    <label class="chkBoxContainer">
                 <input id="mig-board-{{board.name}}" style="margin-left: 12px;" [checked]="board.isChecked || parent.isChecked" type="checkbox"
                        (change)="onChange($event)">
                  <span class="checkmark"></span>
    </label>
    <i class="mr-1 ml-2"
       [ngClass]="board.interactionStyleName == 'tkb'?'fa fa-book fa-fw' :board.interactionStyleName == 'blog'?'fa fa-pencil fa-fw' :board.interactionStyleName == 'forum'?'fa fa-comments':board.interactionStyleName == 'group'?'fa fa-group':board.interactionStyleName == 'idea'?'fa fa-lightbulb-o':board.interactionStyleName == 'contest'?'fa fa-trophy':'fa fa-question' "
       aria-hidden="true">
    </i>
  </span>
  {{board.name}}
</li>
