import { GenerateAppComponent } from './generate-app.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SearchModule} from '../../shared/components/search/search.module';
import {MappingsService} from '../../core/services/Mappings/mappings.service';
import {PipesModule} from '../../shared/pipes/pipes.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {PopulateModalModule} from '../../shared/components/modal/populate-modal.module';
import { MatDividerModule } from '@angular/material/divider';

@NgModule({
  declarations: [
    GenerateAppComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    PopulateModalModule,
    MatDividerModule,
    SearchModule
  ],
  providers: [
    MappingsService
  ]
})
export class GenerateAppModule { }
