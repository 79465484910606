import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {CommunityRoutingModule} from './community-routing.module';
import {CommunitiesComponent} from './pages/communities.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatExpansionModule} from '@angular/material/expansion';
import { MatSlideToggleModule} from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule} from '@angular/material/tooltip';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CommunityService} from '../../core/services/community/community.service';
import {ClientsService} from '../../core/services/customer/clients.service';
import {PipesModule} from '../../shared/pipes/pipes.module';
import {PopulateModalModule} from '../../shared/components/modal/populate-modal.module';
import {SearchModule} from '../../shared/components/search/search.module';
import {ApplicationComponent} from './application/application.component';
import {CustomFieldModule} from '../custom-field/custom-field.module';
import {CommunityMappingModule} from '../community-mapping/community-maaping.module';
import {BoardMappingModule} from '../board-mapping/board-mapping.module';
import {CommunityListComponent} from './community-list/community-list.component';
import {InteractionStylesComponent} from './interaction-styles/interaction-styles.component';
import { AccessDetailsComponent } from './access-details/access-details.component';
import { CommunityInfoComponent } from './community-info/community-info.component';
import { TranslationComponent } from './translation/translation.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import {MatTableModule} from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { MatDividerModule } from '@angular/material/divider';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { MatSelectFilterModule } from 'mat-select-filter';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatTabsModule,
    MatTableModule,
    MatPaginatorModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatSortModule,
    BrowserAnimationsModule,
    CommunityRoutingModule,
    PipesModule,
    PopulateModalModule,
    SearchModule,
    CustomFieldModule,
    BoardMappingModule,
    CommunityMappingModule,
    Ng2SearchPipeModule,
    MatAutocompleteModule,
    MatSelectFilterModule
  ],
  declarations: [
    CommunitiesComponent,
    ApplicationComponent,
    CommunityListComponent,
    InteractionStylesComponent,
    AccessDetailsComponent,
    CommunityInfoComponent,
    TranslationComponent
  ],
  providers: [
    ClientsService,
    CommunityService
  ]
})
export class CommunityModule {
}
