import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CategoryListObj} from '../../../../shared/models/category-list-obj';
import {BoardListObject} from '../../../../shared/models/board-list-object';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {
  @Input() category: CategoryListObj;
  @Input() parent: CategoryListObj;
  @Input() data: CategoryListObj[];
  @Output() categoryChange = new EventEmitter();

  public isToggle = false;

  constructor() {
  }

  ngOnInit() {
  }

  onChange(event) {
    /*
    * If the category was the first category */
    const parent = Array.isArray(this.parent) ? this.parent[0] : this.parent;
    if (event.target.checked) {
      this.category.isChecked = true;
      this.category.boardList.forEach((board) => board.isChecked = true);
      const selectedParentCategories = parent.categoryList.filter((category) => category.isChecked);
      const selectedParentBoards = parent.boardList.filter((board) => board.isChecked);
      if (parent.categoryList.length === selectedParentCategories.length
        && parent.boardList.length === selectedParentBoards.length) {
        parent.isChecked = true;
      }
    } else {
      this.category.boardList.forEach((board) => board.isChecked = false);
      this.category.isChecked = false;
      if (parent) {
        parent.isChecked = false;
      }
    }
    if (this.category.categoryList) {
      this.updateSubCategoryState(this.category.categoryList, this.category.isChecked);
    }
    this.updateParentCategoryState(parent);
  }

  updateSubCategoryState(subCategories: CategoryListObj[], status: boolean) {
    subCategories.forEach((subCategory) => {
      if (status) {
        subCategory.isChecked = true;
        subCategory.boardList.forEach((board) => board.isChecked = true);
      } else {
        subCategory.isChecked = false;
        subCategory.boardList.forEach((board) => board.isChecked = false);
      }
      if (subCategory.categoryList) {
        this.updateSubCategoryState(subCategory.categoryList, status);
      }
    });
  }

  updateParentCategoryState(category: CategoryListObj) {
    const parentCategory: CategoryListObj = this.searchParentCategory(this.data, category.parentCategoryId);
    if (parentCategory && parentCategory.categoryList && parentCategory.boardList) {
      if (this.checkAllBoardsInCategoryIsCheckedOrNot(parentCategory) && category.isChecked) {
        parentCategory.isChecked = true;
      } else {
        parentCategory.isChecked = false;
      }
      this.updateParentCategoryState(parentCategory);
    }
  }

  checkAllBoardsInCategoryIsCheckedOrNot(parentCategory: CategoryListObj): boolean {
    const checkedBoardsFromParentCategory = parentCategory.boardList.filter(board => board.isChecked);
    const checkedCategoriesInParentcategory = parentCategory.categoryList.filter(subCategory => subCategory.isChecked);
    if (checkedBoardsFromParentCategory.length === parentCategory.boardList.length
      && checkedCategoriesInParentcategory.length === parentCategory.categoryList.length) {
      return true;
    } else {
      return false;
    }
  }

  onBoardChange(data) {
    const immediateParent: CategoryListObj = data.parent;
    const board: BoardListObject = data.board;
    if (immediateParent != null) {
      if (board.isChecked) {
        const selectedParentBoards = immediateParent.boardList.filter((parentBoard) => parentBoard.isChecked);
        const selectedParentCategories = immediateParent.categoryList.filter((category) => category.isChecked);
        if (immediateParent.boardList.length === selectedParentBoards.length
          && selectedParentCategories.length === immediateParent.categoryList.length) {
          immediateParent.isChecked = true;
        }
      } else {
        immediateParent.isChecked = false;
      }

      this.updateParentCategoryState(immediateParent);
    }
  }

  searchParentCategory(categoryList: CategoryListObj[], id: string): CategoryListObj {
    let matchedCategory;
    for (let i = 0; i < categoryList.length; i++) {
      const category = categoryList[i];
      if (category.id === id) {
        matchedCategory = category;
      } else if (category.categoryList) {
        matchedCategory = this.searchParentCategory(category.categoryList, id);
      }
      if (matchedCategory) {
        return matchedCategory;
      }
    }
  }

  getSelectedBoardsFromCategoryList(categoryList: CategoryListObj[], selectedBoards: BoardListObject[]): BoardListObject[] {
    categoryList.forEach((category) => {
      const selectedCategoryBoards: BoardListObject[] = this.getSelectedBoards(category.boardList);
      selectedBoards.push(...selectedCategoryBoards);
      if (category.categoryList) {
        this.getSelectedBoardsFromCategoryList(category.categoryList, selectedBoards);
      }
    });
    return selectedBoards;
  }

  getSelectedBoards(boards: BoardListObject[]) {
    return boards.filter((board) => board.isChecked);
  }

  toggleorNot() {
    this.isToggle = !this.isToggle;
  }

  canSelect(): boolean {
    return this.category.isChecked;
  }
}
