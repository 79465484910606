// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // Okta
  redirectUrl_MTL: 'https://dev-syndicate.eastus2.cloudapp.azure.com/ui-service/community',
  redirectUrl_OktaLogin: 'https://dev-syndicate.eastus2.cloudapp.azure.com',
  OKTA_BASE_URL: 'https://dev-602500.okta.com',
  OKTA_CLIENT_ID: '0oabnnkkxVsuZhUgJ356',
  OKTA_LOGO: 'http://www.wrmsdc.org/gala/wp-content/themes/twentyfifteen/images/supporters_italent.png',

  // Syndicate backend URLs
  BASE_URL: 'https://smartconxuat.azurefd.net/xcommunityui',
  AAD_BASE_URL: 'https://smartconxuat.azurefd.net',
  // BASE_URL: 'http://localhost:9192',
  // AAD_BASE_URL: 'http://localhost:9194',
  CLOUD_TYPE: 'cloud_type',
  IDLE_TIMEOUT_IN_SECS: 1500,
  PING_IN_SECONDS: 120

};
/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
