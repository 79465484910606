import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Response} from '../../../shared/models/response';
import {Community} from '../../../shared/models/community';
import {environment} from '../../../../environments/environment';
import {InteractionStyles} from '../../../shared/models/interaction-styles';
import {User} from '../../../shared/models/user';
import {CommunityTypeDef} from '../../../shared/models/community-type-def';
import {EnvironmentType} from '../../../shared/models/environment_type';

@Injectable()
export class CsvImportService {

  constructor(private http: HttpClient) { }

  importData(community: Community, file: File) {
   // const headers = new HttpHeaders().set('content-type', 'application/json');
    const url = `${environment.BASE_URL}/uploadfile?communityId=${community}`;
    const formdata: FormData = new FormData();  
    formdata.append('file', file);
   // formdata.append('communityid', JSON.stringify(community));
    return this.http.post <Response<any>>(url, formdata);
  }
}
