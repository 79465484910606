import {ClientRouting} from './client-routing';
import {NgModule} from '@angular/core';
import {ClientsService} from '../../core/services/customer/clients.service';
import {ClientsComponent} from './pages/clients.component';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { MatTooltipModule} from '@angular/material/tooltip';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {PipesModule} from '../../shared/pipes/pipes.module';
import {PopulateModalModule} from '../../shared/components/modal/populate-modal.module';
import {SearchModule} from '../../shared/components/search/search.module';
import {MatTableModule} from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { MatDividerModule } from '@angular/material/divider';

@NgModule({
  declarations: [
    ClientsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatSortModule,
    MatDividerModule,
    BrowserAnimationsModule,
    ClientRouting,
    PipesModule,
    PopulateModalModule,
    SearchModule
  ],
  providers: [ClientsService]
})
export class ClientModule {
}
