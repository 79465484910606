import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PotentialDataRoutingModule } from './potential-data-routing.module';
import { PotentialDataComponent } from './potential-data/potential-data.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SearchModule} from '../../shared/components/search/search.module';
import {MappingsService} from '../../core/services/Mappings/mappings.service';
import {ClientsService} from '../../core/services/customer/clients.service';
import {PipesModule} from '../../shared/pipes/pipes.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {PopulateModalModule} from '../../shared/components/modal/populate-modal.module';
import { PotentialTableComponent } from './potential-table/potential-table.component';
import { PowerBiComponent } from './power-bi/power-bi.component';
import { PowerAppsComponent } from './power-apps/power-apps.component';
import { MicrosoftFlowComponent } from './microsoft-flow/microsoft-flow.component';

@NgModule({
  declarations: [
    PotentialDataComponent,
    PotentialTableComponent,
    PowerBiComponent,
    PowerAppsComponent,
    MicrosoftFlowComponent
  ],
  imports: [
    CommonModule,
    PotentialDataRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    PopulateModalModule,
    SearchModule
  ],
  providers: [
    MappingsService,
    ClientsService
  ]
})
export class PotentialDataModule { }
