<app-header *ngIf="!showlogin" (logoutEvent)="logoutFromChild()" (toggleEvent)="toggleSideNavbar()"></app-header>
<mat-sidenav-container class="example-container" autosize>
  <mat-sidenav #sidenav mode="side" [(opened)]="opened" *ngIf="!showlogin">
    <aside class="main-sidebar">
      <section class="sidebar">
        <ul class="sidebar-menu" *ngFor="let tab of productsRoles; let i = index;">
          <li class="treeview active">
            <a id=""  class="cust_cursor" routerLinkActive="active"
               [routerLink]="[tab.link]"> <i  [ngClass]="[tab.name === 'Audit Logs' ? 'fa fa-group' :tab.name === 'Customers' ? 'fa fa-database' :tab.name === 'Users' ? 'fa fa-user' :tab.name === 'Applications' ? 'fa fa-group' : tab.name === 'Migration' ? 'fa fa-arrows' : tab.name === 'HealthCheck' ? 'fa fa-medkit' : tab.name === 'Import CSV' ? 'fa fa-cloud-upload':tab.name === 'Connector' ? 'fa fa-link' : tab.name === 'Generate App'? 'fa fa-cloud-download': 'na']"></i> {{tab.name}}</a>
          </li>
        </ul>
      </section>
    </aside>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
<app-footer *ngIf="!showlogin"></app-footer>
