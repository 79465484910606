import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ApplicationComponent} from './application/application.component';
import {CustomFieldComponent} from '../custom-field/pages/custom-field.component';
import {CommunitiesComponent} from './pages/communities.component';
import {CommunityMappingComponent} from '../community-mapping/pages/community-mapping.component';
import {MappingsComponent} from '../board-mapping/pages/mappings.component';
import {CanActivatecommunityService} from '../../core/guards/community/can-activatecommunity.service';
import {TranslationComponent} from './translation/translation.component';
//import { BoardtoboardMappingComponent } from '../board-mapping/boardtoboard-mapping/boardtoboard-mapping.component';


/**
 * chnages are made for component
 * where the middleware component(Application component)
 * is loading when clicked on Applications Tab
 */
const routes: Routes = [
  {
    path: 'ui-service/community',
    component: ApplicationComponent,
    children: [
      {path: '', redirectTo: 'application', pathMatch: 'full'},
      {
        path: 'application',
        component: CommunitiesComponent,
        canActivate: [CanActivatecommunityService]
      },
      {
        path: 'custom-fields',
        component: CustomFieldComponent,
        canActivate: [CanActivatecommunityService]
      },
      {
        path: 'application-mappings',
        component: CommunityMappingComponent,
        canActivate: [CanActivatecommunityService]
      },
      {
        path: 'board-mappings',
        component: MappingsComponent,
        canActivate: [CanActivatecommunityService]
      },
      {
        path: 'translation',
        component: TranslationComponent,
        canActivate: [CanActivatecommunityService]
      },
      // {
      //   path: 'boardtoboard-mapping',
      //   component: BoardtoboardMappingComponent,
      //   canActivate: [CanActivatecommunityService]
      // },
      
      
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CommunityRoutingModule {
}
