import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ActivateAccountRoutingModule } from './activate-account-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ActivateAccountComponent} from './pages/activate-account.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    ActivateAccountRoutingModule
  ],
  declarations: [ActivateAccountComponent]
})
export class ActivateAccountModule { }
