<div class="spinner" *ngIf="spinner">
    <img src="../../../../assets/images/spinner.gif" height="50px">
</div>
<mat-toolbar style="background-color:#000; color: #fff;">
    <span class="gap"> {{scheduleType==='schedular'?'Refresh':scheduleType | titlecase }}</span>
    <div style="background-color: #000; color: white; padding-left: 80%; float: right;"> <span
            style="background-color: #000; color: white; cursor: pointer;" (click)="close()"><i
                class="fa fa-close fa-lg pull-right"></i> </span> </div>
</mat-toolbar>
<div mat-dialog-content style="display: contents; bottom: #000;">
    <br>
    <div *ngIf="scheduleType === 're-build' " class="row form-group schedule-btn">
        <div class="col-xs-4 form-inline">
            <label for="scheduleFrom" class="label-width left-align"> From: &nbsp;&nbsp;&nbsp;&nbsp; </label>
            <div class="col-xs-4">
                <angular2-date-picker [(ngModel)]="fromDate" [settings]="settings"></angular2-date-picker>
            </div>
        </div>&nbsp;&nbsp;
        <div class="col-xs-4 form-inline">
            <label for="scheduleTo" class="label-width left-align">To: &nbsp;&nbsp;&nbsp;&nbsp; </label>
            <div class="col-xs-4">
                <angular2-date-picker [(ngModel)]="toDate" [settings]="settings"></angular2-date-picker>
            </div>
        </div>
    </div>
    <div *ngIf="scheduleType==='incremental'" class="row form-group schedule-btn">
        <div>
         <cron-editor [(cron)]="cronExpression" [(options)]="cronOptions"></cron-editor>
         <br>
         <div style="margin-left: -15px;">
         <label for="minutes" class="label-width">Process since last: &nbsp;&nbsp;&nbsp;</label>
            <input type="number" id="minute-input" name="minute-input" min="0" max="999999" [(ngModel)]="minutes"
                (keypress)="keyPress($event)"> &nbsp; <button style="background-color: #3c8dbc;color: #fff;" [disabled]="true">Minutes</button>
        <label for="status" class="label-width left-align" style="margin-top: 10px;">Status: {{scheduleActive===true? 'Active':'InActive'}}</label>
         </div>
        </div>
    </div>
    <div *ngIf="scheduleType==='rescanroles'" class="row form-group schedule-btn">
        <div>
        <cron-editor [(cron)]="cronExpression" [(options)]="cronHoursOptions"></cron-editor>
        <br>
         <div style="margin-left: -15px;">
         <label for="minutes" class="label-width">Process since last: &nbsp;&nbsp;&nbsp;</label>
            <input type="number" id="minute-input" name="minute-input" min="1" max="24" [(ngModel)]="minutes"
                (keypress)="keyPress($event)"> &nbsp; <button style="background-color: #3c8dbc;color: #fff;" [disabled]="true">Hours</button>
            <label for="status" class="label-width left-align" style="margin-top: 10px;">Status: {{scheduleActive===true? 'Active':'InActive'}}</label>
         </div>
        </div>
    </div>
    <div *ngIf="scheduleType==='rescanbulkdata'" class="row form-group schedule-btn">
        <div>
        <cron-editor [(cron)]="cronExpression" [(options)]="cronOptions"></cron-editor>
        <br>
         <div style="margin-left: -15px;">
         <label for="minutes" class="label-width">Process since last: &nbsp;&nbsp;&nbsp;</label>
            <input type="number" id="minute-input" name="minute-input" min="1" max="24" [(ngModel)]="minutes"
                (keypress)="keyPress($event)"> &nbsp; <button style="background-color: #3c8dbc;color: #fff;" [disabled]="true">Hours</button>
            <label for="status" class="label-width left-align" style="margin-top: 10px;">Status: {{scheduleActive===true? 'Active':'InActive'}}</label>
         </div>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <div *ngIf="scheduleType==='re-build' " class="row form-group schedule-btn">
        <div class="col-md-4 form-inline">
             <br>
            <button class="btn bg-blue btn-flat margin btn-pdt" style="background-color: #3c8dbc; color: white;"
                (click)="createSchedule(scheduleType)">Schedule</button>&nbsp;&nbsp;&nbsp;   
        </div>

    </div>
    <div *ngIf="scheduleType==='incremental' || scheduleType==='rescanroles' || scheduleType==='rescanbulkdata'" class="row form-group schedule-btn">
        <div class="col-md-12 form-inline">

            <div class="col-md-4 form-inline">
                <button style="background-color: #3c8dbc; color: white; min-width: 100px;" class="btn btn-primary button-left-align"
                    (click)="startOrStopSchedule('start', scheduleType)"><i class="fa fa-play pull-left">&nbsp;&nbsp;Activate </i>
                </button> </div> <div class="col-md-1 form-inline"></div>
            <div *ngIf="scheduleActive" class="col-md-4 form-inline"> <button
                    style="background-color: #3c8dbc; color: white; min-width: 100px;" class="btn btn-primary button-left-align"
                    (click)="startOrStopSchedule('stop', scheduleType)"><i class="fa fa-stop pull-left">&nbsp;&nbsp;Stop </i></button>
            </div>
        </div>

    </div>
</div>
<app-modal></app-modal>
