<section class="content-header" *ngIf="messages.length > 0">
  <h4 class="primary-title">{{communityName.split("_")[0]}}</h4>
</section>

<div class="mt-4 customer-tab">
  <div class="box potential-data-grid" style="width: 370px;">
    <table class="table table-hover">
      <div class="row">
        <div class="col-sm-12">
          <h5 class="box-title font-weight-bold small" *ngIf="messages.length > 0">{{'Search results for '+ searchString + ' and top views'}}</h5>
          <h5 style="color: red" class="box-title font-weight-bold small" *ngIf="messages.length === 0">{{searchString}}</h5>
          <div class="col-sm-12">
            <div class="searchbar_section potential-search">
              <div class="app_search_component">
                <app-search></app-search>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <thead>
        <tr class="row-header input.form-control.search table-headers">
          <th>Interaction Style <i (click)="sort=!sort;targetColumn='board'"
                                   [ngClass]="{'custasc':sort,'custasc2':!sort}"></i></th>
          <th>Subject <i (click)="sort=!sort;targetColumn='subject'"
                         [ngClass]="{'custasc':sort,'custasc2':!sort}"></i></th></tr>
        </thead>
      </div>
      <tbody>
      <div class="scroll-widget">
        <tr *ngFor="let message of messages | sortBy:targetColumn:sort | searchBy: searchComponent.searchString">
          <td>{{message.board | titlecase}}</td>
          <td><a target="_blank" href="{{message.urlLink}}">{{message.subject + ' (' + message.views + ')'}}</a></td>
        </tr>
      </div>
      </tbody>
    </table>
    <!--    <div id="custom-pagnation" class="lia-paging-full-wrapper lia-paging-pager lia-component-search-results-pager" id="pager" ng-hide="myCtrl.maxPage === 0 || myCtrl.maxPage ===1 " ng-cloak>-->
    <!--      <ul>-->
    <!--        <li>-->
    <!--          <a ng-class="{'previousDisable': activePage === 1 }">-->
    <!--            <span title="Previous Page">&#8249;</span>-->
    <!--          </a>-->
    <!--        </li>-->

    <!--        <li *ngIf="activePage > 2">-->
    <!--          <span ng-cloak>...</span>-->
    <!--        </li>-->

    <!--        <li ng-class="{'firstPageDisable':myCtrl.firstPage == 0}">-->
    <!--          <a rel="noreferrer noopener" ng-click=myCtrl.pagnation(myCtrl.firstPage) ng-class="{'lia-link-disabled':myCtrl.firstPage == 0,'anchorDisable':myCtrl.firstPage == 0}" ng-cloak> {{myCtrl.firstPage}} </a>-->
    <!--        </li>-->

    <!--        <li ng-class="{'activePageDisable':myCtrl.activePage == 0}">-->
    <!--          <span ng-cloak> {{myCtrl.activePage}} </span>-->
    <!--        </li>-->

    <!--        <li ng-class="{'lastPage':myCtrl.lastPage == 0, 'clearDisable':myCtrl.lastPage == 0}" ng-show="myCtrl.activePage < myCtrl.maxPage">-->
    <!--          <a rel="noreferrer noopener" ng-click=myCtrl.pagnation(myCtrl.lastPage)> {{myCtrl.lastPage}} </a>-->
    <!--        </li>-->

    <!--        <li ng-show="(myCtrl.activePage + 1) < myCtrl.maxPage ">-->
    <!--          <span ng-cloak>...</span>-->
    <!--        </li>-->

    <!--        <li class="lia-paging-page-next lia-component-next">-->
    <!--          <a ng-class="{'nextDisable':myCtrl.activePage == myCtrl.maxPage,'lastPageDisable':myCtrl.maxPage == 0}" ng-click="myCtrl.nextPage()">-->
    <!--            <span title="Next Page">&#8250;</span>-->
    <!--          </a>-->
    <!--        </li>-->
    <!--      </ul>-->
    <!--    </div>-->
  </div>
  <div *ngIf="communityId === 1001" style="text-align: center;">
    <a target="_blank"
       href="https://msit.powerbi.com/groups/me/reports/817eea65-583b-4b60-a12f-8a48bea1e3b0?ctid=72f988bf-86f1-41af-91ab-2d7cd011db47">Data Analytics Dashboard
    </a>
  </div>
</div>

<app-modal></app-modal>
