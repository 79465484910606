<div class="spinner" *ngIf="spinner">
  <img src="../../../../assets/images/spinner.gif" height="50px">
</div>
<div class="content-header" style="padding: 15px;">
  <h1>Translation</h1>
  <br>
  <mat-divider></mat-divider>
</div>

<div>
  <form [formGroup]="translationForm" (ngSubmit)="submit()">
    <div class="row col-md-12 pl-3">
      <div class="col-md-4">
        <div class="form-group">
          <label>Customer</label><br/>
          <div *ngIf="!superAdmin" class="form-control admin-label">{{customerObj.name}}</div>
          <select *ngIf="superAdmin" id="comm-map-cust" class="form-control" required formControlName="customer"
                  (change)="fetchCommunities($event.target.value)">
            <option value=''>select</option>
            <option *ngFor="let selectedcust of customers" value={{selectedcust.customerId}}>
              {{selectedcust.customerName}}
            </option>
          </select>
          <p class="custom-error"
             *ngIf="translationForm.controls['customer'].hasError('required') && translationForm.controls['customer'].dirty">
            Please select a customer</p>
        </div>
        <div class="form-group">
          <label>Community</label>
          <select id="comm-map-community" class="form-control" required formControlName="community"
                  (change)="checkTranslationStatus($event.target.value, false)">
            <option value=''>select</option>
            <option *ngFor="let community of communities" value={{community.communityId}}>{{community.communityName}}
            </option>
          </select>
          <p class="custom-error"
             *ngIf="translationForm.controls['community'].hasError('required') && translationForm.controls['community'].dirty">
            Select a community</p>
        </div>
      </div>
    </div>
    <div class="row col-md-12">
      <div class="col-md-3" *ngIf="translationForm.controls['community'].value !== ''">
        <label class="chkBoxContainer">
          <span class="inter-font">Enable Translation</span>
          <input id="translation-enable-diable" type="checkbox" formControlName="translation"
                 (change)="populateTransProvider()">
          <span class="checkmark"></span>
        </label>
      </div>
    </div>
    <div class="row col-md-12">
      <div class="col-md-3" *ngIf="translationForm.controls['translation']?.value === true">
        <div class="form-group">
          <label>Translation Provider</label>
          <select id="translation-provider" class="form-control"
                  formControlName="translationProvider" (change)="fetchLanguages($event.target.value)">
            <option value="">select</option>
            <option *ngFor="let provider of translationProviders" value={{provider.name}}>{{provider.value}}</option>
          </select>
          <p class="custom-error"
             *ngIf="translationForm.controls['translationProvider'].hasError('required') && translationForm.controls['translationProvider'].dirty">
            Select a translation provider</p>
        </div>
      </div>
      <div class="col-md-3" *ngIf="translationForm.controls['translationProvider']?.value">
        <div class="form-group">
          <label>Community Language</label>
          <select id="language" class="form-control"
                  formControlName="translationLanguageCode">
            <option value="">select</option>
            <option *ngFor="let language of languages" value={{language.language}}>{{language.name}}</option>
          </select>
          <p class="custom-error"
             *ngIf="translationForm.controls['translationLanguageCode'].hasError('required') && translationForm.controls['translationLanguageCode'].dirty">
            Select a community language for the community</p>
        </div>
      </div>
      <div class="col-md-3" *ngIf="translationForm.controls['translationProvider']?.value === 'google'">
        <div class="form-group">
          <label>Authentication Type</label>
          <select id="authentication-type" class="form-control"
                  formControlName="googleTranslationAuthType">
            <option value="">select</option>
            <option *ngFor="let authtype of translationAuthTypes" value={{authtype.name}}>{{authtype.value}}</option>
            <!-- <option value="OAuth2">oauth2</option>
            <option value="API Key">apikey</option> -->
          </select>
          <p class="custom-error"
             *ngIf="translationForm.controls['googleTranslationAuthType'].hasError('required') && translationForm.controls['googleTranslationAuthType'].dirty">
            Select Authentication Type for the community</p>
        </div>
      </div>

    </div>
    <div class="row col-md-12" *ngIf="translationForm.controls['translationProvider']?.value === 'google'">
      <div class="col-md-3" *ngIf="translationForm.controls['googleTranslationAuthType']?.value === 'apikey'">
        <div class="form-group">
          <label>Translation API Key</label>
          <input id="googleAPIKey" formControlName="googleAPIKey"
                 class="form-control"
                 type="text" placeholder="Translate API Key">
          <p class="custom-error"
             *ngIf="translationForm.controls['googleAPIKey']?.hasError('required') && translationForm.controls['googleAPIKey']?.dirty">
            Please enter API Key</p>
        </div>
      </div>
      <div class="col-md-3" *ngIf="translationForm.controls['googleTranslationAuthType']?.value === 'oauth2'">
        <div class="form-group">
          <label>Translation access token</label>
          <input id="translate-accesstoken" formControlName="translateAccessToken"
                 class="form-control"
                 type="text" placeholder="Translate access token">
          <p class="custom-error"
             *ngIf="translationForm.controls['translateAccessToken']?.hasError('required') && translationForm.controls['translateAccessToken']?.dirty">
            Please enter access token</p>
        </div>
      </div>
      <div class="col-md-3" *ngIf="translationForm.controls['googleTranslationAuthType']?.value === 'oauth2'">
        <div class="form-group">
          <label>Translation refresh token</label>
          <input id="labels" formControlName="translateRefreshToken"
                 class="form-control"
                 type="text" placeholder="Refresh token">
          <p class="custom-error"
             *ngIf="translationForm.controls['translateRefreshToken']?.hasError('required') && translationForm.controls['translateRefreshToken']?.dirty">
            Please enter refresh token</p>
        </div>
      </div>
    </div>
    <div class="row col-md-12" *ngIf="translationForm.controls['translationProvider']?.value === 'google'">
      <div class="col-md-3" *ngIf="translationForm.controls['googleTranslationAuthType']?.value === 'oauth2'">
        <div class="form-group">
          <label> Translation client ID</label>
          <input id="translate-clientid" formControlName="translateClientId"
                 class="form-control"
                 type="text" placeholder="Translate client ID">
          <p class="custom-error"
             *ngIf="translationForm.controls['translateClientId']?.hasError('required') && translationForm.controls['translateClientId']?.dirty">
            Please enter client ID</p>
        </div>
      </div>
      <div class="col-md-3" *ngIf="translationForm.controls['googleTranslationAuthType']?.value === 'oauth2'">
        <div class="form-group">
          <label>Translation client secret</label>
          <input id="translate-clientserect" formControlName="translateClientSecret"
                 class="form-control"
                 type="text" placeholder="Translate client secrect">
          <p class="custom-error"
             *ngIf="translationForm.controls['translateClientSecret']?.hasError('required') && translationForm.controls['translateClientSecret']?.dirty">
            Please enter client Secret</p>
        </div>
      </div>
      <div class="col-md-3" *ngIf="translationForm.controls['googleTranslationAuthType']?.value === 'oauth2'">
        <div class="form-group">
          <label>Translation project id</label>
          <input id="google-projectid" formControlName="googleProjectId"
                 class="form-control"
                 type="text" placeholder="Translate project id">
          <p class="custom-error"
             *ngIf="translationForm.controls['googleProjectId']?.hasError('required') && translationForm.controls['googleProjectId']?.dirty">
            Please enter project id</p>
        </div>
      </div>
    </div>

    <div class="row col-md-12" *ngIf="translationForm.controls['translationProvider']?.value === 'lingotek'">
      <div class="col-md-3">
        <div class="form-group">
          <label>Lingotek translation access key</label>
          <input id="lingo-trans-key" formControlName="lingotekTranslateAccesskey"
                 class="form-control"
                 type="text" placeholder="Lingotek translation access key">
          <p class="custom-error"
             *ngIf="translationForm.controls['lingotekTranslateAccesskey']?.hasError('required') && translationForm.controls['lingotekTranslateAccesskey']?.dirty">
            Please enter lingotek translation access key</p>
        </div>
      </div>
      <div class="col-md-3 center-block" *ngIf="translationForm.controls['community'].value !== ''">
        <label class="chkBoxContainer">
          <span class="inter-font">Enable Auto Detection</span>
          <input id="autoDetectLanguageForLingotek-enable-diable" class="form-control" type="checkbox" formControlName="autoDetectLanguageForLingotek"
                 (change)="populateAutoTransProvider()">
          <span class="checkmark"></span>
        </label>
      </div>
    </div>
    <div class="row col-md-12" *ngIf="translationForm.controls['translationProvider']?.value === 'lingotek'">
            <div class="col-md-3">
              <div class="form-group">
                <label>Callback User Name</label>
                <input id="lingo-callback-username" formControlName="lingotekCallbackUserName"
                       class="form-control"
                       type="text" placeholder="Lingotek Callback User Name">
                <p class="custom-error"
                   *ngIf="translationForm.controls['lingotekCallbackUserName']?.hasError('required') && translationForm.controls['lingotekCallbackUserName']?.dirty">
                  Please enter Callback User Name</p>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Callback Password</label>
                <input id="lingo-callback-password" formControlName="lingotekCallbackPassword"
                       class="form-control"
                       type="password" placeholder="Lingotek Callback Password">
                <p class="custom-error"
                   *ngIf="translationForm.controls['lingotekCallbackPassword']?.hasError('required') && translationForm.controls['lingotekCallbackPassword']?.dirty">
                  Please enter Callback Password</p>
              </div>
            </div>
          </div>
    <div class="row col-md-12" *ngIf="translationForm.controls['translationProvider']?.value === 'bing'">
      <div class="col-md-3">
        <div class="form-group">
          <label>Microsoft translation subscription key</label>
          <input id="msft-sub-key" formControlName="microsoftTranslateSubscriptionKey"
                 class="form-control"
                 type="text" placeholder="Microsoft translation subscription key">
          <p class="custom-error"
             *ngIf="translationForm.controls['microsoftTranslateSubscriptionKey']?.hasError('required') && translationForm.controls['microsoftTranslateSubscriptionKey']?.dirty">
            Please enter microsoft translation subscription key</p>
        </div>
      </div>
    </div>

    <div class="row col-md-12" *ngIf="translationForm.controls['translationProvider']?.value === 'systran'">
      <div class="col-md-3">
        <div class="form-group">
          <label>Translation access token</label>
          <input id="translate-accesstoken" formControlName="translateAccessToken"
                 class="form-control"
                 type="text" placeholder="Translate access token">
          <p class="custom-error"
             *ngIf="translationForm.controls['translateAccessToken']?.hasError('required') && translationForm.controls['translateAccessToken']?.dirty">
            Please enter access token</p>
        </div>
      </div>
    </div>
    <div class="row col-md-12" *ngIf="translationForm.controls['translationProvider']?.value === 'systran'">
      <div class="col-md-3">
        <div class="form-group">
          <label> Translation client ID</label>
          <input id="translate-clientid" formControlName="translateClientId"
                 class="form-control"
                 type="text" placeholder="Translate client ID">
          <p class="custom-error"
             *ngIf="translationForm.controls['translateClientId']?.hasError('required') && translationForm.controls['translateClientId']?.dirty">
            Please enter client ID</p>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label>Translation client secret</label>
          <input id="translate-clientserect" formControlName="translateClientSecret"
                 class="form-control"
                 type="text" placeholder="Translate client secrect">
          <p class="custom-error"
             *ngIf="translationForm.controls['translateClientSecret']?.hasError('required') && translationForm.controls['translateClientSecret']?.dirty">
            Please enter client Secret</p>
        </div>
      </div>
    </div>
    <div class="row col-md-12" *ngIf="translationForm.controls['translationProvider']?.value === 'amazon_translator'">
            <div class="col-md-3">
              <div class="form-group">
                <label>Access Key</label>
                <input id="amazonTranslateAccessKey" formControlName="amazonTranslateAccessKey"
                       class="form-control"
                       type="text" placeholder="AWS Access Key">
                <p class="custom-error"
                   *ngIf="translationForm.controls['amazonTranslateAccessKey']?.hasError('required') && translationForm.controls['amazonTranslateAccessKey']?.dirty">
                  Please Enter Access Key</p>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Secret Key</label>
                <input id="amazonTranslateSecretKey" formControlName="amazonTranslateSecretKey"
                       class="form-control"
                       type="text" placeholder="AWS Secret Key">
                <p class="custom-error"
                   *ngIf="translationForm.controls['amazonTranslateSecretKey']?.hasError('required') && translationForm.controls['amazonTranslateSecretKey']?.dirty">
                  Please Enter Secret Key</p>
              </div>
            </div>
          </div>
          <div class="row col-md-12" *ngIf="translationForm.controls['translationProvider']?.value === 'amazon_translator'">
            <div class="col-md-3">
              <div class="form-group">
                <label>Region</label>
                <input id="amazonTranslateRegion" formControlName="amazonTranslateRegion"
                       class="form-control"
                       type="text" placeholder="Amazon Translate Region">
                <p class="custom-error"
                   *ngIf="translationForm.controls['amazonTranslateRegion']?.hasError('required') && translationForm.controls['amazonTranslateRegion']?.dirty">
                  Please Enter AWS Region</p>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>AWS Translation EndPoint   <a href="https://docs.aws.amazon.com/general/latest/gr/translate-service.html" target="_blank"><i class="fa fa-info-circle"></i></a> </label>
                <input id="amazonTranslateEndpoint" formControlName="amazonTranslateEndpoint"
                       class="form-control"
                       type="text" placeholder="AWS Translation EndPoint">
                       <p class="custom-error"
                   *ngIf="translationForm.controls['amazonTranslateEndpoint']?.hasError('required') && translationForm.controls['amazonTranslateEndpoint']?.dirty">
                  Please Enter AWS Translation EndPoint</p>
              </div>

            </div>
          </div>
          <div class="row col-md-12"
          *ngIf="translationForm.controls['translationProvider']?.value === 'smartling'">
       <fieldset class="map-fieldset" [disabled]="smartlingDataDisable">
         <i class="fa fa-plus-square-o add-button" aria-hidden="true" (click)="addSmartlingProject()" title="Add custom field"></i>
         <legend class="map-legend small">Projects</legend>
         <div class="row col-md-12 customize-list">
           <table class="table table-light">
             <tbody>
             <tr>
               <th>Project ID</th>
               <th>Source Locale Code</th>
               <th>User Identifier</th>
               <th>User Secret</th>
               <th>Interaction Style</th>
               <th>Action</th>
             </tr>
             <tr class="form-group" *ngFor="let customField of smartlingProjects; let i = index">
               <!--                      Removed the one extra column whiich is not required as per the new requirement-->
               <td>
                 <input style="border-radius:5px" class="form-control" type="text" placeholder="Project ID"
                        value="{{customField.projectId}}"
                        [(ngModel)]="customField.projectId" [ngModelOptions]="{standalone: true}">
               </td>
               <td>

               <input style="border-radius:5px" class="form-control" type="text" placeholder="Locale Code"
                       value="{{customField.sourceLocaleCode}}"
                       [(ngModel)]="customField.sourceLocaleCode" [ngModelOptions]="{standalone: true}">
              </td>
              <td>
                <input style="border-radius:5px" class="form-control" type="text" placeholder="User Identifier"
                       value="{{customField.smartlinguseridentifier}}"
                       [(ngModel)]="customField.smartlinguseridentifier" [ngModelOptions]="{standalone: true}">
              </td>
              <td>
                <input style="border-radius:5px" class="form-control" type="password" placeholder="User Secret"
                       value="{{customField.smartlingusersecret}}"
                       [(ngModel)]="customField.smartlingusersecret" [ngModelOptions]="{standalone: true}">
              </td>
              <td>


                   <select id="interation_type" [(ngModel)]="customField.interactionStyle" [ngModelOptions]="{standalone: true}" class="form-control">
                      <option value="">select</option>
                      <option *ngFor="let interationType of interationTypes" [value]="interationType.name">{{interationType.value}}</option>
                    </select>

              </td>
               <td>
                 <i class="fa fa-close delete-btn" title ="Delete custom field"  (click)="delete(customField.id,i)"></i>
               </td>
             </tr>
             </tbody>
           </table>
         </div>
       </fieldset>
     </div>
          <div class="row col-md-12" *ngIf="translationForm.controls['translationProvider']?.value === 'deepl'">
            <div class="col-md-3">
              <div class="form-group">
                <label>Auth Key</label>
                <input id="deeplAuthKey" formControlName="deeplAuthKey"
                       class="form-control"
                       type="text" placeholder="DeepL Auth Key">
                <p class="custom-error"
                   *ngIf="translationForm.controls['deeplAuthKey']?.hasError('required') && translationForm.controls['deeplAuthKey']?.dirty">
                  Please Enter Auth Key</p>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Translation EndPoint</label>
                <input id="deeplTranslateEndpoint" formControlName="deeplTranslateEndpoint"
                       class="form-control"
                       type="text" placeholder="DeepL Translation EndPoint">
                       <p class="custom-error"
                   *ngIf="translationForm.controls['deeplTranslateEndpoint']?.hasError('required') && translationForm.controls['deeplTranslateEndpoint']?.dirty">
                  Please Enter DeepL Translation EndPoint</p>
              </div>

            </div>
          </div>
          <div class="col-md-12">
          <label class="chkBoxContainer" *ngIf="translationForm.controls['community'].value !== ''">
            <span class="inter-font"> Glosseries </span>
            <input
              id="community-events-check"
              type="checkbox"
              [(ngModel)]="eventEnabled"
              [ngModelOptions]="{ standalone: true }"
              (click)="populateLanguages(true)"
            />
            <span class="checkmark"></span>
          </label>
          </div>
          <fieldset class="map-fieldset" *ngIf="eventEnabled && translationForm.controls['community'].value !== ''">
            <div class="deplclass">
              <div class="row m-0">
                <div class="col-md-10">
                </div>
                <div class="col-md-2">
                          <button id="auto-syndicate"
                            class="btn bg-blue btn-lg btn-flat margin fa fa-plus pull-right text-center" type="button"
                            (click)="add()" style="align-items:right">Add
                          </button>
                </div>
                     </div>
                      <div class="col-md-12">
                        <table class="table table-light table-responsive customize-list">
                          <tbody>
                            <tr>
                              <th style="width: 30%">Source Language</th>
                              <th style="width: 20%"></th>
                              <th style="width: 30%">Target language</th>

                              <th style="width: 30%">Glosseries Id</th>
                              <th style="width: 10%">Action</th>
                            </tr>
                            <tr class="form-group" *ngFor="let mappedlang of mappedlanguages; let i = index">
                              <td>
                                <select class="form-control"
                                (change)="sourceLangUpdate($event,i)">
                                  <option value="">select</option>
                                  <!-- <option *ngFor="let language of languages" value={{language.languages}}>{{language.name}}</option> -->
                                  <option
                                  [selected]="srcTranslang.language === mappedlang.sourceLanguage"
                                  *ngFor="let srcTranslang of languages;"
                                    [value]="srcTranslang.language"
                                  >
                                    {{ srcTranslang.name }}
                                  </option>
                                </select>
                              </td>
                              <td>
                                <label style="padding-top: 7px;"></label>
                              </td>
                              <td>
                                <select class="form-control"
                                (change)="targetLangUpdate($event,i)">
                                  <option value="">select</option>
                                  <option
                                  [selected]="targetTrnslang.language === mappedlang.targetLanguage"
                                  *ngFor="let targetTrnslang of languages;"
                                    [value]="targetTrnslang.language"
                                  >
                                    {{ targetTrnslang.name }}
                                  </option>
                                </select>
                              </td>
                              <td>
                                <input
                                  value="{{mappedlang.glossaryId}}"
                                  type="text"
                                  placeholder="Enter Glosseries id"
                                  (blur)='glosseriesId($event,i)'
                                />
                              </td>
                              <td>
                                <i class="fa fa-trash fa-2x delete-btn" style="color: red;" title="Delete field"
                                  (click)="confirm(i)"></i>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </fieldset>
                  <div class="row mt-1">
                    <div class="col-md" style="padding-left: 30px !important">
                      <button
                        id="validate-btn"
                        [disabled]="!translationForm.valid || showSaveButton"
                        (click)="validateTranslationKeys()"
                        *ngIf="!showSaveButton"
                        class="btn bg-blue btn-flat margin pull-left btn-pdt"
                      >
                        Validate
                      </button>
                      <button
                       type="submit" 
                       [disabled]="!isValidationSuccess" 
                       *ngIf="showSaveButton" 
                       class="btn bg-blue btn-flat margin pull-left btn-pdt"
                       >
                       Save
                      </button>
                      <input
                        id="comm-reset-btn"
                        type="button"
                        value="Reset"
                        class="btn bg-blue btn-flat margin btn-pdt"
                        (click)="reset()"
                      />
                    </div>
                  </div>
                  
  </form>
</div>

<app-modal [genericMessage]="messageToDisplay" [canDelete]="isDelete"
           (emitDeleteFuncionality)="confirmRemoveProject($event)"></app-modal>
