<!--<mat-tab-group animationDuration="0ms">-->
<!--  <mat-tab label="Create">-->
<!--    <app-communities></app-communities>-->
<!--  </mat-tab>-->
<!--  <mat-tab label="Custom Fields">-->
<!--    <app-custom-field></app-custom-field>-->
<!--  </mat-tab>-->
<!--  <mat-tab label="Board Mappings">-->
<!--    <app-mappings></app-mappings>-->
<!--  </mat-tab>-->
<!--  <mat-tab label="Application Mappings">-->
<!--    <app-community-mapping></app-community-mapping>-->
<!--  </mat-tab>-->
<!--</mat-tab-group>-->


<nav class="nav nav-pills nav-justified">
  <a class="nav-item nav-link" [routerLink]="['application']" routerLinkActive="active">Applications</a>
  <a class="nav-item nav-link" [routerLink]="['custom-fields']" routerLinkActive="active">Custom Fields</a>
  <a class="nav-item nav-link" [routerLink]="['application-mappings']" routerLinkActive="active">Application Mappings</a>
  <a class="nav-item nav-link" [routerLink]="['board-mappings']" routerLinkActive="active">Board Mappings</a>
  <a class="nav-item nav-link" [routerLink]="['translation']" routerLinkActive="active">Translation</a>
  <!-- <a class="nav-item nav-link" [routerLink]="['boardtoboard-mapping']" routerLinkActive="active">boardtoboard-mapping</a> -->
  
</nav>

<div class="tabs-content">
  <router-outlet></router-outlet>
</div>
