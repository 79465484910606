import {
  Component,
  OnInit,
  NgModule,
  ElementRef,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ModalComponent } from "../../shared/components/modal/pages/modal.component";
import { Customer } from "../../shared/models/customer";
import { Community } from "../../shared/models/community";
import { ClientsService } from "../../core/services/customer/clients.service";
import { CommunityService } from "../../core/services/community/community.service";
import { MappingsService } from "../../core/services/Mappings/mappings.service";
import { CsvImportService } from "../../core/services/csvImport/csv-import-service";
import { Constants } from "../../shared/constants/constants";

import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";

@Component({
  selector: "app-csvimport",
  templateUrl: "./csvimport.component.html",
  styleUrls: ["./csvimport.component.css"],
})
export class CsvimportComponent implements OnInit {
  @ViewChild(ModalComponent, { static: true }) modal;
  @ViewChild("labelImport", { static: true })
  labelImport: ElementRef;
  spinner = false;
  customers: Customer[] = [];
  communities: Community[] = [];
  customer: any;
  community: any;
  fileName: any;
  customerObj = JSON.parse(localStorage.getItem("customerObj"));
  superAdmin = localStorage.getItem("isSuperAdmin") === "yes" ? true : false;
  CSVImportForm: FormGroup;
  config = new MatSnackBarConfig();
  fileToUpload: File = null;
  constructor(
    private formBuilder: FormBuilder,
    private customerService: ClientsService,
    private mappingService: MappingsService,
    private csvImportService: CsvImportService,
    private communityService: CommunityService,
    private snackBar: MatSnackBar
  ) {
    this.buildCSVImportForm();
  }

  ngOnInit() {
    this.config.verticalPosition = "top";
    this.config.horizontalPosition = "end";
    this.config.duration = 5000;
    this.config.panelClass = ["blue-snackbar"];
    this.customer = "";
    this.community = "";
    if (this.superAdmin) {
      this.fetchCustomers();
    } else {
      this.CSVImportForm.controls["customer_id"].setValue(this.customerObj.id);
      this.customer = this.customerObj;
      this.fetchCommunities(this.customerObj.id);
    }
  }

  onFileChange(files: FileList) {
    console.log("files", files);

    this.labelImport.nativeElement.innerText = Array.from(files)
      .map((f) => f.name)
      .join(", ");
    this.fileToUpload = files.item(0);
    if (!this.validateFile(files[0].name)) {
      this.CSVImportForm.controls["importFile"].setValue("");
      this.fileToUpload = null;
      return this.snackBar.open(
        Constants.FILE_EXTENSION_SUPPORTED,
        "Close",
        this.config
      );
    }
  }

  buildCSVImportForm() {
    this.CSVImportForm = this.formBuilder.group({
      customer_id: new FormControl("", [Validators.required]),
      community_id: new FormControl("", [Validators.required]),
      importFile: new FormControl("", [Validators.required]),
    });
  }

  fetchCustomers() {
    this.spinner = true;
    this.customerService
      .getActiveCustomers(localStorage.getItem("loginUserId"))
      .subscribe(
        (response) => {
          this.spinner = false;
          this.customers = response.response.data;
        },
        (error) => {
          this.spinner = false;
          if (
            error.status === 401 &&
            error.error.response.status === "error" &&
            error.error.response.message === "UnAuthorized Access Token Expired"
          ) {
            return this.snackBar.open(
              Constants.SESSION_EXPIRED,
              "Close",
              this.config
            );
          } else {
            return this.snackBar.open(
              error.error.response.message,
              "Close",
              this.config
            );
          }
        }
      );
  }

  fetchCommunities(selectedCustomer: string) {
    if (selectedCustomer !== "") {
      this.spinner = true;
      this.community = "";
      this.mappingService.getCommunitybyCustomerId(+selectedCustomer).subscribe(
        (res) => {
          this.spinner = false;
          this.communities = res.response.data;
        },
        (error) => {
          this.spinner = false;
          if (
            error.status === 401 &&
            error.error.response.status === "error" &&
            error.error.response.message === "UnAuthorized Access Token Expired"
          ) {
            return this.snackBar.open(
              Constants.SESSION_EXPIRED,
              "Close",
              this.config
            );
          } else {
            return this.snackBar.open(
              error.error.response.message,
              "Close",
              this.config
            );
          }
        }
      );
    } else {
      this.communities = [];
    }
  }

  submit() {
    this.customer = this.CSVImportForm.controls["customer_id"].value;
    this.community = this.CSVImportForm.controls["community_id"].value;
    if (
      this.customer === "" ||
      this.community === "" ||
      this.fileToUpload === null
    ) {
      return this.snackBar.open(Constants.SELECT_FIELDS, "Close", this.config);
    }
    if (!this.validateFile(this.fileToUpload.name)) {
      this.fileToUpload = null;
      return this.snackBar.open(
        Constants.FILE_EXTENSION_SUPPORTED,
        "Close",
        this.config
      );
    }
    this.spinner = true;
    this.csvImportService
      .importData(this.community, this.fileToUpload)
      .subscribe(
        (res) => {
          this.spinner = false;
          this.CSVImportForm.reset();

          this.buildCSVImportForm();
          this.fileToUpload = null;
          document.getElementById("labelImports").innerHTML =
            '<i class="fa fa-upload"></i>&nbsp; &nbsp; &nbsp; Choose file';
          this.snackBar.open(
            Constants.FILE_UPLOADED_SUCCESSFULLY,
            "Close",
            this.config
          );
          this.fileName = undefined;
          //  this.modal.populateModal({response: {message: Constants.FILE_UPLOADED_SUCCESSFULLY}});
        },
        (error) => {
          this.spinner = false;
          if (
            error.status === 401 &&
            error.error.response.status === "error" &&
            error.error.response.message === "UnAuthorized Access Token Expired"
          ) {
            return this.snackBar.open(
              Constants.SESSION_EXPIRED,
              "Close",
              this.config
            );
          } else {
            return this.snackBar.open(
              error.error.response.message,
              "Close",
              this.config
            );
          }
        }
      );
  }

  validateFile(name: String) {
    let ext = name.substring(name.lastIndexOf(".") + 1);
    if (ext.toLowerCase() == "csv") {
      return true;
    } else {
      return false;
    }
  }
}
