import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ProdToStageComponent} from './pages/prod-to-stage.component';
import {CanActivatecommunityService} from '../../core/guards/community/can-activatecommunity.service';
import { TabsViewComponent } from './tabs-view/tabs-view.component';
import { MessageMappingsStatusComponent } from './components/message-mappings-status-list/message-mappings-status.component';

const routes: Routes = [
  {
    path: 'ui-service/production-stage-migration',
    component: TabsViewComponent,
    children: [
      {path: '', redirectTo: 'message-mappings', pathMatch: 'full'},
      {
        path: 'message-mappings',
        component: MessageMappingsStatusComponent,
        canActivate: [CanActivatecommunityService]
      },
      {
        path: 'content-migration',
        component: ProdToStageComponent,
        canActivate: [CanActivatecommunityService]
      },

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProdToStageRoutingModule {
}
