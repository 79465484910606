import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {CommunityMaapingRoutingModule} from './community-maaping-routing.module';
import {CommunityMappingService} from '../../core/services/communityMapping/community-mapping.service';
import {CommunityMappingComponent} from './pages/community-mapping.component';
import {ClientsService} from '../../core/services/customer/clients.service';
import {MappingsService} from '../../core/services/Mappings/mappings.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import { MatDividerModule } from '@angular/material/divider';
import {PopulateModalModule} from '../../shared/components/modal/populate-modal.module';
import {SearchModule} from '../../shared/components/search/search.module';
import {MatSelectInfiniteScrollModule} from 'ng-mat-select-infinite-scroll';
import {MatTableModule} from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {PipesModule} from '../../shared/pipes/pipes.module';
import { MatSelectFilterModule } from 'mat-select-filter';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatDividerModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatListModule,
    MatSelectInfiniteScrollModule,
    CommunityMaapingRoutingModule,
    PopulateModalModule,
    SearchModule,
    MatIconModule,
    MatInputModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    PipesModule,
    MatSelectFilterModule
  ],
  declarations: [CommunityMappingComponent],
  exports: [
    CommunityMappingComponent
  ],
  providers: [
    ClientsService,
    MappingsService,
    CommunityMappingService
  ]
})
export class CommunityMappingModule {
}
