import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {TreeViewStructure} from '../../../shared/models/tree-view-structure';

@Injectable()
export class ProdStageService {

  constructor(private http: HttpClient) {
  }

  getCommunityStructure(communityId: number): Observable<TreeViewStructure> {
    const url = `${environment.BASE_URL}/getcategoriesstructure?communityId=${communityId}`;
    return this.http.get<TreeViewStructure>(url);
  }
}
