import { environment } from './../../../../environments/environment';
import { Response } from './../../../shared/models/response';
import { PodsModel } from '../../../shared/models/Pods';
import { Observable } from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class HealthCheckService {
    constructor(private http: HttpClient) {
    }
    getPodStatus(): Observable<PodsModel[]> {
        const url = `${environment.BASE_URL}/getpodsstatus`;
        return this.http.get<PodsModel[]>(url);
    }

    getNodeStatus(): Observable<PodsModel[]> {
        const url = `${environment.BASE_URL}/getnodestatus`;
        return this.http.get<PodsModel[]>(url);
    }

    checkDbConnect(): Observable<boolean> {
        const url = `${environment.BASE_URL}/getdatabaseconnection`;
        return this.http.get<boolean>(url);
    }

    getVersion(): Observable<Response<any>> {
        const url = `${environment.BASE_URL}/releaseversion`;
        return this.http.get<Response<any>>(url);
    }
    getMongoDbStatus(): Observable<PodsModel[]> {
      const url = `${environment.BASE_URL}/mongodbDatabasesStatus`;
      return this.http.get<PodsModel[]>(url);
  }
    scheduleAadTokenExpirationCronJob(isSuspended: boolean): Observable<void> {
      const url = `${environment.BASE_URL}/expiry/scheduleAadTokenExpirationCronJob?isSuspended=${isSuspended}`;
      return this.http.post<void>(url,{})
    }
    getCronJobStatus():Observable<boolean>{
    const url= `${environment.BASE_URL}/expiry/checkCronJobStatus`;
    return this.http.get<boolean>(url);
    }

}
