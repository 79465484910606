<div class="spinner" *ngIf="spinner">
  <img src="../../../../assets/images/spinner.gif" height="50px">
</div>

<div class="col-md-12">
  <div class="col-md-3"></div>
  <div class="col-md-3 pt-3" *ngIf="superAdmin">
    <div class="form-group">
      <label>Customer<span class="label-required">*</span></label>
      <select id="comm-cust" class="form-control"
              (change)="getCommunitiesByCustomerID(+customerId)"
              [(ngModel)]="customerId">
        <option value=0>select</option>
        <option *ngFor="let customer of customers" value={{customer.customerId}}>
          {{customer.customerName}}
        </option>
      </select>
    </div>
  </div>
</div>

<div class="col-md-12" *ngIf="communities.length > 0">
  <div class="row">
    <div class="col-md-4" *ngFor="let community of communities">
      <app-power-bi
        [communityId]="community.communityId"
        [categoryId]="community.categoryId"
        [communityName]="community.communityName">
      </app-power-bi>
    </div>
  </div>
</div>

<app-modal></app-modal>
