import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {ClientsComponent} from './pages/clients.component';
import {CanActivateCustomerService} from '../../core/guards/customer/can-activate-customer.service';

export const clientRoutes: Routes = [
  {
    path: 'ui-service/clients',
    component: ClientsComponent,
    canActivate: [CanActivateCustomerService]
  },
];

@NgModule({
  imports: [RouterModule.forChild(clientRoutes)],
  exports: [RouterModule]
})
export class ClientRouting {
}
