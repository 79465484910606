<div class="spinner" *ngIf="spinner">
  <img src="../../../../assets/images/spinner.gif" height="50px">
</div>

<div class="row">
  <app-potential-table
    [communityId]="communityId"
    [communityName]="communityName"
    [count]="data$ | async">
  </app-potential-table>
</div>

<app-modal></app-modal>
