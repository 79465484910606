import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {CustomFieldRoutingModule} from './custom-field-routing.module';
import {CustomFieldComponent} from './pages/custom-field.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PopulateModalModule} from '../../shared/components/modal/populate-modal.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ClientsService} from '../../core/services/customer/clients.service';
import {MappingsService} from '../../core/services/Mappings/mappings.service';
import { MatDividerModule } from '@angular/material/divider';

@NgModule({
  declarations: [
    CustomFieldComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CustomFieldRoutingModule,
    MatDividerModule,
    BrowserAnimationsModule,
    PopulateModalModule,
  ],
  exports: [
    CustomFieldComponent
  ],
  providers: [
    ClientsService,
    MappingsService
  ]
})
export class CustomFieldModule {
}
