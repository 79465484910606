import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MigrationComponent} from './pages/migration.component';
import {CanActivatecommunityService} from '../../core/guards/community/can-activatecommunity.service';

const routes: Routes = [
  {
    path: 'ui-service/migrate',
    component: MigrationComponent,
    canActivate: [CanActivatecommunityService]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MigrationRoutingModule {
}
