import {Component, OnInit, ViewChild} from '@angular/core';
import {ProdStageService} from '../../../core/services/prod-stage-service/prod-stage-service';
import {TreeViewStructure} from '../../../shared/models/tree-view-structure';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Customer} from '../../../shared/models/customer';
import {Community} from '../../../shared/models/community';
import {ClientsService} from '../../../core/services/customer/clients.service';
import {MappingsService} from '../../../core/services/Mappings/mappings.service';
import {BoardListObject} from '../../../shared/models/board-list-object';
import {CategoryListObj} from '../../../shared/models/category-list-obj';
import {ModalComponent} from '../../../shared/components/modal/pages/modal.component';
import {Constants} from '../../../shared/constants/constants';


@Component({
  selector: 'app-prod-to-stage',
  templateUrl: './prod-to-stage.component.html',
  styleUrls: ['./prod-to-stage.component.css']
})
export class ProdToStageComponent implements OnInit {

  @ViewChild(ModalComponent, {static: true}) modalComponent;

  public mockData: TreeViewStructure;
  public showSpinner = false;
  public prodToStageForm: FormGroup;
  public messageToDisplay = '';
  public allCustomers: Customer[] = [];
  public sourceCommunities: Community[] = [];
  public targetCommunities: Community[] = [];
  public isSuperAdmin = localStorage.getItem('isSuperAdmin') === 'yes' ? true : false;
  public customerObj = JSON.parse(localStorage.getItem('customerObj'));


  constructor(private prodToStageService: ProdStageService,
              private fb: FormBuilder,
              private _clientService: ClientsService,
              private _mappingService: MappingsService) {
    this.buildForm();
  }

  ngOnInit() {
    this.emptyTreeView();
    if (this.isSuperAdmin) {
      this.getActivatedCustomers();
    } else {
      this.renderCommunitiesByCustomer();
    }
  }

  getActivatedCustomers() {
    this.showSpinner = true;
    this._clientService.getActiveCustomers(localStorage.getItem('loginUserId'))
      .subscribe(res => {
          this.showSpinner = false;
          this.allCustomers = res.response.data;
        },
        error => {
          this.showSpinner = false;
          this.modalComponent.populateModal({response: {message: Constants.SERVER_ERROR}});
        });
  }

  buildForm() {
    this.prodToStageForm = this.fb.group({
      'customer_name': new FormControl('', []),
      'source_community': new FormControl('', Validators.required),
      'target_community': new FormControl('', Validators.required)
    });
  }

  onCustomerChange(event) {
    const customer_id = +event.target.value;
    if (customer_id !== 0) {
      this.showSpinner = true;
      this._mappingService.getCommunitybyCustomerId(customer_id)
        .subscribe(res => {
            this.showSpinner = false;
            this.sourceCommunities = res.response.data;
            if (this.sourceCommunities.length === 0) {
              this.modalComponent.populateModal({response: {message: Constants.NO_COMMUNITIES_FOR_CUSTOMER}});
              return;
            }
          },
          error => {
            this.showSpinner = false;
            this.modalComponent.populateModal({response: {message: Constants.SERVER_ERROR}});
          });
    } else {
      this.sourceCommunities = [];
      this.targetCommunities = [];
      this.emptyTreeView();
      this.renderCommunitiesByCustomer();
    }
  }

  onCommunityChange(event) {
    const community_id = +event.target.value;
    if (community_id !== 0) {
      this.targetCommunities = this.sourceCommunities.filter(sourceCommunity => {
        return (sourceCommunity.communityId !== community_id && sourceCommunity.environmentType.environmentTypeId === 2);
      });
      this.showSpinner = true;
      this.prodToStageService.getCommunityStructure(community_id)
        .subscribe(res => {
          this.showSpinner = false;
          this.mockData = res;
        }, error => {
          this.showSpinner = false;
          this.modalComponent.populateModal({response: {message: Constants.SERVER_ERROR}});
        });
    } else {
      this.targetCommunities = [];
      this.emptyTreeView();
    }
  }

  submitmappings() {
    const boardIds: string[] = [];
    const selectedBoardsInCategories = this.getSelectedBoardsFromCategoryList(this.mockData.categoryList, []);
    const selectedBoardsFromCategory = this.getSelectedBoards(this.mockData.boardList);
    selectedBoardsInCategories.push(...selectedBoardsFromCategory);
    const formData = this.prodToStageForm.value;
    selectedBoardsInCategories.forEach(obj => boardIds.push(obj.id));
    if (boardIds.length > 0) {
      const payLoad = {
        'sourceCommunityId': +formData.source_community,
        'targetCommunityId': +formData.target_community,
        'sourceBoardIds': boardIds
      };

      this.showSpinner = true;
      this._mappingService.migrateFromprodToStage(payLoad)
        .subscribe(res => {
          this.showSpinner = false;
          if (res.response.code === 202) {
            this.modalComponent.populateModal({response: {message: Constants.REQUEST_ACCEPTED}});
            this.resetData();
            if (this.isSuperAdmin) {
              this.getActivatedCustomers();
            } else {
              this.renderCommunitiesByCustomer();
            }
          } else {
            this.modalComponent.populateModal(res);
          }
        }, error => {
          this.showSpinner = false;
          this.modalComponent.populateModal({response: {message: Constants.SERVER_ERROR}});
        });
    } else {
      this.modalComponent.populateModal({response: {message: Constants.SELECT_ENITY_TO_MIGRATE}});
    }
  }

  getSelectedBoardsFromCategoryList(categoryList: CategoryListObj[], selectedBoards: BoardListObject[]): BoardListObject[] {
    categoryList.forEach((category) => {
      const selectedCategoryBoards: BoardListObject[] = this.getSelectedBoards(category.boardList);
      selectedBoards.push(...selectedCategoryBoards);
      this.getSelectedBoardsFromCategoryList(category.categoryList, selectedBoards);
    });
    return selectedBoards;
  }

  getSelectedBoards(boards: BoardListObject[]) {
    return boards.filter((board) => board.isChecked);
  }

  getCommunityNameWithEnvironment(community: Community): String {
    return community.communityName + ' (' + community.environmentType.environmentType + ')';
  }

  resetData() {
    this.sourceCommunities = [];
    this.targetCommunities = [];
    this.emptyTreeView();
    this.buildForm();
  }

  emptyTreeView() {
    this.mockData = {
      categoryList: [],
      boardList: []
    };
  }

  renderCommunitiesByCustomer() {
    if (!this.isSuperAdmin) {
      this.onCustomerChange({target: {value: this.customerObj.id}});
    }
  }
}

