import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {LoginService} from '../../services/login/login.service';
import {Constants} from '../../../shared/constants/constants';
import {DomSanitizer} from '@angular/platform-browser';
import { UserIdleService } from 'angular-user-idle';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup;
  public isError = false;
  public isSessionExpiredError = false;
  public errorMessage = '';
  public session = '';
  public isValidMail = false;
  public showSpinner = false;
  public message = localStorage.getItem('message');
  public sessionExpiredMessage = localStorage.getItem('sessionExpired');
  public cloudType = environment.CLOUD_TYPE;

  constructor(private _fb: FormBuilder,
              private _router: Router,
              private _loginService: LoginService,
              private _domSanitizor: DomSanitizer, private userIdle: UserIdleService) {
    this.buildForm();
  }

  ngOnInit() {
    if (this.message !== '' && this.message !== undefined && this.message !== null) {
      this.isError = true;
      this.errorMessage = this.message;
      localStorage.removeItem('message');
    }
    if (this.sessionExpiredMessage !== '' && this.sessionExpiredMessage !== undefined && this.sessionExpiredMessage !== null) {
      this.isSessionExpiredError = true;
      this.session = this.sessionExpiredMessage;
      localStorage.removeItem('sessionExpired');
    } else {
      this.sessionExpiredMessage = '';
      this.isSessionExpiredError = false;
    }
  }

  public buildForm() {
    this.loginForm = this._fb.group({
      userName: new FormControl('', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
      password: new FormControl('', [Validators.required])
    });
  }

  public submit() {
    const formValues = this.loginForm.value;
    const userName: string = formValues.userName;
    // const passWord: string = formValues.password;
    const isEmailValid = (userName === '' || userName === undefined || userName === null) ? false : true;
    // const isPasswordValid = (passWord === '' || passWord === undefined || passWord === null) ? false : true;
    this.isError = !isEmailValid ? true : false;
    if (this.isError) {
      this.errorMessage = 'Please enter user email';
      return;
    }
    this.isValidMail = true;
    this.validateByOauth(userName);
  }

  public forgotPassword() {
    this._router.navigate(['ui-service/forgotPassword']);
  }

  public reset() {
    this.loginForm.reset();
  }

  public validateLogin(userName: string, password: string) {
    this._loginService.validateAndGetUserRole(userName, password)
      .subscribe(res => {
        if (res.response.code === 200 && res.response.status === 'success') {

          this.userIdle.startWatching();
          // Start watching when user idle is starting.
          this.userIdle.onTimerStart().subscribe();
          if (res.response.data.hasOwnProperty('role')) {
            if (res.response.data.role.roleId !== null || res.response.data.role.roleName !== '') {
              localStorage.setItem('role',res.response.data.role.roleName);
              if (res.response.data.role.roleId === 10002 || res.response.data.role.roleName === 'SUPER ADMIN') {
                const custObj = {'id': 0, 'name': ''};
                localStorage.setItem('isSuperAdmin', 'yes');
                localStorage.setItem('loginUserId', res.response.data.loginUserId);
                localStorage.setItem('customerObj', JSON.stringify(custObj));
                this._router.navigate(['ui-service/clients']);
              } else if (res.response.data.role.roleId === 10003 || res.response.data.role.roleName === 'COMMUNITY USER') {
                this._router.navigate(['ui-service/contactSuperAdmin']);
              } else {
                const customer = res.response.data.customer;
                const custObj = {'id': customer.customerId, 'name': customer.customerName};
                localStorage.setItem('isSuperAdmin', 'no');
                localStorage.setItem('loginUserId', res.response.data.loginUserId);
                localStorage.setItem('customerObj', JSON.stringify(custObj));
                this._router.navigate(['ui-service/community']);
              }
            }
          }
          history.pushState(null, null, location.href);
          window.onpopstate = function () {
            history.go(1);
          };
        } else {
          this.isError = true;
          this.errorMessage = 'invalid email or password.';
          this.reset();
        }
      }, error => {
        this.isError = true;
        this.errorMessage = 'invalid email or password.';
        this.reset();
      });
  }

  public validateByOauth(userName: string) {
    this._loginService.authenticateUser(userName)
      .subscribe(res => {
        if (res.response.code === 200 && res.response.status === 'success') {
          this.isValidMail = true;
          history.pushState(null, null, location.href);
          window.onpopstate = function () {
            history.go(1);
          };
          this.isValidMail = false;
          window.location.href = res.response.data.url;
        } else {
          this.isError = true;
          this.isValidMail = false;
          this.errorMessage = res.response.message;
        }
      }, error => {
        this.isValidMail = false;
        this.errorMessage = Constants.SERVER_ERROR;
      });
  }

  getErrorMessage() {
    return this._domSanitizor.bypassSecurityTrustHtml(this.errorMessage);
  }

  getSessionMessage() {
    return this._domSanitizor.bypassSecurityTrustHtml(this.session);

  }
}
