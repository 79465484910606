import { Component, OnInit, ViewChild } from "@angular/core";
import { UserService } from "../../../core/services/user/user.service";
import { User } from "../../../shared/models/user";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Role } from "../../../shared/models/roles";
import { ClientsService } from "../../../core/services/customer/clients.service";
import { Customer } from "../../../shared/models/customer";
import { Router } from "@angular/router";
import { ModalComponent } from "../../../shared/components/modal/pages/modal.component";
import { Constants } from "../../../shared/constants/constants";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
@Component({
  selector: "app-manage-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.css"],
})
export class UsersComponent implements OnInit {
  @ViewChild(ModalComponent, { static: true }) modalComponent;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  dataSource: MatTableDataSource<any>;

  users: User[] = [];
  roles: Role[] = [];
  customers: Customer[] = [];
  userForm: FormGroup;
  targetColumn: any;
  payLoadObj = { roleId: null, userId: null, customerId: null };
  saveOrUpdate = "";
  roleid: number;
  user_id: number;
  userEmail: string;
  messageToDisplay: any;
  isDelete = false;
  userToBeDeleting: any;
  showSpinner: boolean;
  searchString: string = "";
  pageSize = 5;
  total: number;
  activeSort: string = "firstName";
  sortOrder: string = "asc";
  currentPage = 0;
  config = new MatSnackBarConfig();
  tableColumns: string[] = [
    "firstName",
    "lastName",
    "userEmail",
    "customer.customerName",
    "role.roleName",
    "action",
    "status",
  ];

  constructor(
    private customerService: ClientsService,
    private userService: UserService,
    private fb: FormBuilder,
    private router: Router,
    private snackBar: MatSnackBar
  ) {
    this.createUserForm();
    this.userEmail;
  }

  ngOnInit() {
    this.config.verticalPosition = "top";
    this.config.horizontalPosition = "end";
    this.config.duration = 5000;
    this.config.panelClass = ["blue-snackbar"];
    this.showSpinner = false;
    this.getUsers(
      this.pageSize,
      this.currentPage,
      this.activeSort,
      this.sortOrder,
      this.searchString
    );
    this.saveOrUpdate = "Save";
    this.userForm.controls["customer"].enable();
  }

  createUserForm() {
    this.userForm = this.fb.group({
      user_email: new FormControl("", [Validators.required, Validators.email]),
      role: new FormControl("", [Validators.required]),
      last_name: new FormControl("", [
        Validators.required,
        Validators.pattern("^[a-zA-Z]+(\\s[a-zA-Z]+)?$"),
      ]),
      first_name: new FormControl("", [
        Validators.required,
        Validators.pattern("^[a-zA-Z]+(\\s[a-zA-Z]+)?$"),
      ]),
      customer: new FormControl("", [Validators.required]),
    });
  }

  onSortChange(event) {
    this.sortOrder =
      event.direction == ""
        ? this.sortOrder == "asc"
          ? "desc"
          : "asc"
        : this.sortOrder == event.direction
        ? this.sortOrder == "asc"
          ? "desc"
          : "asc"
        : event.direction;
    this.getUsers(
      this.pageSize,
      0,
      event.active,
      this.sortOrder,
      this.searchString
    );
  }

  getUsers(pageSize, currentPage, sortName, sortOrder, searchString): void {
    if (this.searchString == "") {
      this.showSpinner = true;
    }
    this.userService
      .getUsers(
        localStorage.getItem("loginUserId"),
        pageSize,
        currentPage,
        sortName,
        sortOrder,
        searchString
      )
      .subscribe(
        (user) => {
          this.userService.getRoles().subscribe(
            (role) => {
              this.customerService
                .getActiveCustomers(localStorage.getItem("loginUserId"))
                .subscribe(
                  (cust) => {
                    this.showSpinner = false;
                    this.users = user.response.data;
                    this.dataSource = new MatTableDataSource(this.users);
                    this.total = user.response.totalRecordsCount;
                    this.dataSource.paginator = currentPage;
                    if (typeof this.paginator.length === "undefined") {
                      this.paginator.length = currentPage;
                    }
                    this.dataSource.sortingDataAccessor = (item, property) => {
                      if (property.includes("."))
                        return property.split(".").reduce((o, i) => o[i], item);
                      return item[property];
                    };
                    // this.dataSource.sort = this.sort;
                    this.roles = role.response.data;
                    this.customers = cust.response.data;
                    this.paginator.pageIndex = currentPage;
                    this.paginator.pageSize = pageSize;
                    setTimeout(() => {
                      this.paginator.length = this.total;
                    });
                  },
                  (error) => {
                    this.showSpinner = false;
                    if (
                      error.status === 401 &&
                      error.error.response.status === "error" &&
                      error.error.response.message ===
                        "UnAuthorized Access Token Expired"
                    ) {
                      return this.snackBar.open(
                        Constants.SESSION_EXPIRED,
                        "Close",
                        this.config
                      );
                    } else {
                      return this.snackBar.open(
                        error.error.response.message,
                        "Close",
                        this.config
                      );
                    }
                  }
                );
            },
            (error) => {
              this.showSpinner = false;
              if (
                error.status === 401 &&
                error.error.response.status === "error" &&
                error.error.response.message ===
                  "UnAuthorized Access Token Expired"
              ) {
                return this.snackBar.open(
                  Constants.SESSION_EXPIRED,
                  "Close",
                  this.config
                );
              } else {
                return this.snackBar.open(
                  error.error.response.message,
                  "Close",
                  this.config
                );
              }
            }
          );
        },
        (error) => {
          this.showSpinner = false;
          if (
            error.status === 401 &&
            error.error.response.status === "error" &&
            error.error.response.message === "UnAuthorized Access Token Expired"
          ) {
            return this.snackBar.open(
              Constants.SESSION_EXPIRED,
              "Close",
              this.config
            );
          } else {
            return this.snackBar.open(
              error.error.response.message,
              "Close",
              this.config
            );
          }
        }
      );
  }

  submitUser() {
    if (this.userForm.controls["user_email"].value.indexOf(".com") === -1) {
      this.userForm.value.user_email += ".com";
    }

    this.roles = this.roles.filter(
      (role) => role.roleName === this.userForm.controls["role"].value
    );
    this.customers = this.customers.filter(
      (customer) =>
        customer.customerName === this.userForm.controls["customer"].value
    );
    this.payLoadObj.customerId =
      this.customers.length > 0 ? this.customers[0].customerId : null;
    this.payLoadObj.roleId =
      this.roles.length > 0 ? this.roles[0].roleId : null;

    if (this.saveOrUpdate === "Save") {
      this.showSpinner = true;
      this.userService.save(this.userForm.value, this.payLoadObj).subscribe(
        (res) => {
          this.getUsers(
            this.pageSize,
            this.currentPage,
            this.activeSort,
            this.sortOrder,
            this.searchString
          );
          this.createUserForm();
          this.showSpinner = false;
          this.modalComponent.populateModal(res);
        },
        (error) => {
          this.showSpinner = false;
          if (
            error.status === 401 &&
            error.error.response.status === "error" &&
            error.error.response.message === "UnAuthorized Access Token Expired"
          ) {
            return this.snackBar.open(
              Constants.SESSION_EXPIRED,
              "Close",
              this.config
            );
          } else {
            return this.snackBar.open(
              error.error.response.message,
              "Close",
              this.config
            );
          }
        }
      );
    } else {
      this.showSpinner = true;
      this.userService
        .update(
          this.userForm.controls,
          this.payLoadObj.roleId,
          this.user_id,
          this.payLoadObj.customerId
        )
        .subscribe(
          (res) => {
            this.showSpinner = false;
            this.getUsers(
              this.pageSize,
              this.currentPage,
              this.activeSort,
              this.sortOrder,
              this.searchString
            );
            this.resetForm();
            this.modalComponent.populateModal(res);
          },
          (error) => {
            this.showSpinner = false;
            if (
              error.status === 401 &&
              error.error.response.status === "error" &&
              error.error.response.message ===
                "UnAuthorized Access Token Expired"
            ) {
              return this.snackBar.open(
                Constants.SESSION_EXPIRED,
                "Close",
                this.config
              );
            } else {
              return this.snackBar.open(
                error.error.response.message,
                "Close",
                this.config
              );
            }
          }
        );
    }
  }

  onChangePage(event) {
    console.log("first", event);
    if (this.pageSize !== event.pageSize) {
      this.currentPage = 0; // Reset to first page if page size changes
    } else {
      this.currentPage = event.pageIndex;
    }
    this.pageSize = +event.pageSize;
    this.getUsers(
      this.pageSize,
      this.currentPage,
      this.activeSort,
      this.sortOrder,
      this.searchString
    );
  }
  editUser(user: User) {
    this.userForm.controls["first_name"].setValue(user.firstName);
    this.userForm.controls["last_name"].setValue(user.lastName);
    this.userForm.controls["user_email"].setValue(user.userEmail);
    this.userForm.controls["user_email"].disable();
    this.userForm.controls["role"].setValue(user.role.roleName);
    this.userForm.controls["customer"].setValue(user.customer.customerName);
    this.userForm.controls["customer"].disable();
    this.user_id = user.userId;
    this.saveOrUpdate = "Update";
  }

  delete(user: User) {
    this.isDelete = true;
    this.userToBeDeleting = user.userId;
    this.modalComponent.populateModal({
      response: { message: Constants.DELETE_CONFIRMATION_MESSAGE },
    });
  }

  resetForm() {
    this.saveOrUpdate = "Save";
    this.userForm.controls["customer"].enable();
    this.createUserForm();
  }

  confirmDelete(confirmMsg: any) {
    if (confirmMsg) {
      const x = document.getElementById("myModal");
      x.style.display = "none";
      this.showSpinner = true;
      this.userService.delete(this.userToBeDeleting).subscribe(
        (res) => {
          this.showSpinner = false;
          this.resetForm();
          this.getUsers(
            this.pageSize,
            this.currentPage,
            this.activeSort,
            this.sortOrder,
            this.searchString
          );
          this.modalComponent.populateModal(res);
        },
        (error) => {
          this.showSpinner = false;
          if (
            error.status === 401 &&
            error.error.response.status === "error" &&
            error.error.response.message === "UnAuthorized Access Token Expired"
          ) {
            return this.snackBar.open(
              Constants.SESSION_EXPIRED,
              "Close",
              this.config
            );
          } else {
            return this.snackBar.open(
              error.error.response.message,
              "Close",
              this.config
            );
          }
        }
      );
    } else {
      const x = document.getElementById("myModal");
      x.style.display = "none";
    }
    this.isDelete = false;
  }

  updateStatus(event, user: User) {
    this.showSpinner = true;
    this.userService.updateStatus(user.userId, event.checked).subscribe(
      (res) => {
        this.showSpinner = false;
        this.resetForm();
        this.getUsers(
          this.pageSize,
          this.currentPage,
          this.activeSort,
          this.sortOrder,
          this.searchString
        );
        this.modalComponent.populateModal(res);
      },
      (error) => {
        this.showSpinner = false;
        if (
          error.status === 401 &&
          error.error.response.status === "error" &&
          error.error.response.message === "UnAuthorized Access Token Expired"
        ) {
          return this.snackBar.open(
            Constants.SESSION_EXPIRED,
            "Close",
            this.config
          );
        } else {
          return this.snackBar.open(
            error.error.response.message,
            "Close",
            this.config
          );
        }
      }
    );
  }
  applyFilter(filterValue: string) {
    // this.dataSource.filterPredicate = (data, filter) => {
    //   return (
    //     data.firstName.toLocaleLowerCase().includes(filter) ||
    //     data.lastName.toLocaleLowerCase().includes(filter) ||
    //     data.userEmail.toLocaleLowerCase().includes(filter) ||
    //     data.customer.customerName.toLocaleLowerCase().includes(filter) ||
    //     data.role.roleName.toLocaleLowerCase().includes(filter)
    //   );
    // };
    this.searchString = filterValue.trim().toLowerCase();
    this.getUsers(
      this.pageSize,
      0,
      this.activeSort,
      this.sortOrder,
      this.searchString
    );
  }
}
