<div class="spinner" *ngIf="showSpinner">
  <img src="../../../../assets/images/spinner.gif" height="60px">
</div>
<table>
  <tr>
    <th></th>
  </tr>
</table>
<div class="mappings-header paddata medium font-weight-bold">
  <div class="row" style="width: 100% !important;">
    <div class="col-1"></div>
    <div class="col-8 box box-primary">
      <div class="font-weight-bold medium mt-4">Board migration</div>
      <div id="Manage-Users" class="mt-4">
        <form class="form-horizontal" [formGroup]="migrationForm" (ngSubmit)="submitMigration()">
          <div class="box-body">
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label class="control-label small font-weight-bold">Customer Name<span class="label-required">*</span>:</label>
                  <select class="form-control" formControlName="customer_id" (change)="onCustomerChange($event)">
                    <option value=''>select</option>
                    <option *ngFor="let customer of allCustomers" value={{customer.customerId}}>
                      {{customer.customerName}}
                    </option>
                  </select>
                  <div class="custom-error" *ngIf="migrationForm.controls['customer_id'].hasError('required') && migrationForm.controls['customer_id'].dirty">
                    Select a customer
                  </div>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="sourceCommunities.length > 0">
              <div class="col-6">
                <div class="form-group">
                  <label class="control-label small font-weight-bold">Source community<span class="label-required">*</span>:</label>
                  <select class="form-control" formControlName="source_community_id" (change)="onCommunityChange($event)">
                    <option value=''>select</option>
                    <option *ngFor="let community of sourceCommunities" value={{community.communityId}}>{{community.communityName}}</option>
                  </select>
                  <div class="custom-error" *ngIf="migrationForm.controls['source_community_id'].hasError('required') && migrationForm.controls['source_community_id'].dirty">
                    Select a source ommunity
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label class="control-label small font-weight-bold">Target community<span class="label-required">*</span>:</label>
                  <select class="form-control" formControlName="target_community_id">
                    <option value=''>select</option>
                    <option *ngFor="let community of targetCommunities" value={{community.communityId}}>{{community.communityName}}</option>
                  </select>
                  <div class="custom-error" *ngIf="migrationForm.controls['target_community_id'].hasError('required') && migrationForm.controls['target_community_id'].dirty">
                    Select a target community
                  </div>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="categories.length > 0">
              <div class="col-6">
                <div class="form-group">
                  <label class="control-label small font-weight-bold">Category<span class="label-required">*</span>:</label>
                  <select class="form-control" formControlName="category_id" (change)="onCategoryChange($event)">
                    <option value=''>select</option>
                    <option *ngFor="let category of categories" value={{category.categoryId}}>{{category.categoryTitle}}</option>
                  </select>
                  <div class="custom-error" *ngIf="migrationForm.controls['category_id'].hasError('required') && migrationForm.controls['category_id'].dirty">
                    Select a category
                  </div>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="boardMappingList.length > 0">
              <div class="col-6">
                <div class="form-group">
                  <label class="control-label small font-weight-bold">Interaction styles<span class="label-required">*</span>:</label>
                  <select class="form-control" formControlName="interaction_style" (change)="onInteractionStylesChange($event)">
                    <option value=''>select</option>
                    <option *ngFor="let board of boardMappingList;" value={{board.interactionStyle}}>{{board.interactionStyle}}</option>
                  </select>
                  <div class="custom-error" *ngIf="migrationForm.controls['interaction_style'].hasError('required') && migrationForm.controls['interaction_style'].dirty">
                    Select an interaction style
                  </div>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="boards.length > 0 && boardMappingList.length > 0">
              <div class="col-6">
                <div class="form-group">
                  <label class="control-label small font-weight-bold">Boards<span class="label-required">*</span>:</label>
                  <select class="form-control" formControlName="board_id">
                    <option value=''>select</option>
                    <option *ngFor="let board of boards;" value={{board.boardId}}>{{board.boardTitle}}</option>
                  </select>
                  <div class="custom-error" *ngIf="migrationForm.controls['board_id'].hasError('required') && migrationForm.controls['board_id'].dirty">
                    Select a board
                  </div>
                </div>
              </div>
            </div>
            <div class="box-footer" style="margin-bottom: 5em !important;">
              <button [disabled]="!migrationForm.valid" class="btn btn-sm btn-success">Migrate</button>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input class="btn btn-sm btn-primary" type="button" value="Reset" (click)="resetForm()">
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<app-modal [genericMessage]="messageToDisplay" [canDelete]=false></app-modal>
