import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ModalComponent } from '../../../shared/components/modal/pages/modal.component';
import { Constants } from '../../../shared/constants/constants';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CronOptions } from 'cron-editor/lib/CronOptions';
import { ConnectorService } from '../../../core/services/connector/connector.service';

import {
    MatSnackBar, MatSnackBarConfig
} from '@angular/material/snack-bar';

export interface DialogData {
    data: any;
    type: string;
    schedularData: any;
    isReset : string
}

@Component({
    selector: 'schedule-config.component',
    templateUrl: 'schedule-config.component.html',
    styleUrls: ['./schedule-config.component.css']
})
export class ScheduleConfigDialog implements OnInit {
    @ViewChild(ModalComponent, { static: true }) modalComponent;

    fromDate: Date = new Date();
    toDate: Date = new Date();
    createScheduleObj: any;
    scheduleType: any;
    schedularData: any;
    targetCommunityType: any = '';
    hours: any = '';
    minutes: any = '';
    scheduleStatus: any = '';
    scheduleActive: boolean = false;
    spinner = false;
    config = new MatSnackBarConfig();
    fetchWholeCommunity : boolean = false;
    settings = {
        bigBanner: true,
        timePicker: true,
        closeOnSelect: true,
        format: 'dd-MM-yyyy HH:mm:ss',
        defaultOpen: false,
        disableSince: new Date()
    }

    public cronExpression = '0/15 * 1/1 * ?';
    public isCronDisabled = false;
    public cronOptions: CronOptions = {
    formInputClass: 'form-control cron-editor-input',
    formSelectClass: 'form-control cron-editor-select',
    formRadioClass: 'cron-editor-radio',
    formCheckboxClass: 'cron-editor-checkbox',

    defaultTime: '10:00:00',
    use24HourTime: true,

    hideMinutesTab: false,
    hideHourlyTab: false,
    hideDailyTab: false,
    hideWeeklyTab: false,
    hideMonthlyTab: false,
    hideYearlyTab: false,
    hideAdvancedTab: false,
    hideSeconds: true,
    removeSeconds: true,
    removeYears: true
  };
  public cronHoursOptions: CronOptions = {
    formInputClass: 'form-control cron-editor-input',
    formSelectClass: 'form-control cron-editor-select',
    formRadioClass: 'cron-editor-radio',
    formCheckboxClass: 'cron-editor-checkbox',

    defaultTime: '00:00:00',
    use24HourTime: true,


    hideHourlyTab: false,
    hideMinutesTab: true,
    hideDailyTab: true,
    hideWeeklyTab: true,
    hideMonthlyTab: true,
    hideYearlyTab: true,
    hideAdvancedTab: true,
    hideSeconds: true,
    removeSeconds: true,
    removeYears: true
  };
    constructor(
        public dialogRef: MatDialogRef<ScheduleConfigDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData, private connectorService: ConnectorService, private snackBar: MatSnackBar) {
        this.scheduleType = data.type;
        this.targetCommunityType = data.data.targetCommunity.communityType.communityTypeName;
        if (data.schedularData !== undefined) {
            this.schedularData = data.schedularData;
            this.scheduleActive = data.schedularData.scheduleActive;
            this.scheduleStatus = data.schedularData.scheduleStatus;
            this.cronExpression = data.schedularData.schedularInterval;
            this.minutes = data.schedularData.processSince;
        }else{
            if (data.type === 'rescanroles') {
                this.cronExpression = '0 0/1 1/1 * ?';
            }else if(data.type === 'rescanbulkdata') {
                this.cronExpression = '0 0/1 1/1 * ?';
            }
        }
    }

    ngOnInit() {
        this.config.verticalPosition = 'top';
        this.config.horizontalPosition = 'end';
        this.config.duration = 5000;
        this.config.panelClass = ['blue-snackbar'];
    }

    close(): void {
        this.dialogRef.close();
    }
    public createSchedule(scheduleType: any) {
        if (scheduleType === 're-build' ) {
            if (new Date(this.fromDate).getTime() >= new Date(this.toDate).getTime()) {
                return this.snackBar.open(Constants.FROM_DATE_BEFORE_TO_DATE, 'Close', this.config);
            }
            if (new Date(this.fromDate).getTime() >= new Date().getTime()) {
                return this.snackBar.open(Constants.FROM_DATE_BEFORE_CURRENT_DATE, 'Close', this.config);
            }
            if (new Date(this.toDate).getTime() >= new Date().getTime()) {
                return this.snackBar.open(Constants.TO_DATE_BEFORE_CURRENT_DATE, 'Close', this.config);
            }
            const isReset = false;
            this.createScheduleObj = {
                id: 0,
                scheduleType:this.data.type,
                scheduledFrom: new Date(this.fromDate).toISOString(),
                scheduledTo: new Date(this.toDate).toISOString(),
                configuredAt: new Date().toISOString(),
                connectorMappingId: this.data.data.id,
                connectorMapping: {
                  ...this.data.data,
                  salesforceUserReset: isReset ? true : false,
                },
                fetchWholeCommunity: this.fetchWholeCommunity
            }
        }
        this.spinner = true;
        this.connectorService.configSchedule(this.createScheduleObj).subscribe(res => {
            this.spinner = false;
            if (res.response.code === 200 && res.response.status === 'success') {
                this.dialogRef.close();
                return this.snackBar.open(Constants.SCHEDULE_CONFIGURED, 'Close', this.config);
            }
        }, error => {
            this.spinner = false;
            if (error.status === 401 && error.error.response.status === 'error' && error.error.response.message === 'UnAuthorized Access Token Expired') {
                return this.snackBar.open(Constants.SESSION_EXPIRED, 'Close', this.config);
            } else if (error.error.message.includes('409')) {
                return this.snackBar.open(Constants.RE_BUILD_ALREADY_EXISTS, 'Close', this.config);
            } else {
                return this.snackBar.open(error.error.response.message, 'Close', this.config);
            }
        });
    }

    startOrStopSchedule(event: any, scheduleType: any) {
        if (this.cronExpression === '') {
            return this.snackBar.open(Constants.SCHEDULE_CONFIG_TIME, 'Close', this.config);
        }
        let id = 0;
        let schedularState;
        if (this.schedularData !== undefined && this.schedularData.id !== undefined) {
            id = this.schedularData.id;
        }
        if (event === 'start') {
            this.scheduleActive = true;
        } else if (event === 'stop') {
             this.scheduleActive = false;
        }
        this.createScheduleObj = {
            id: id,
            scheduleType: this.data.type,
            configuredAt: new Date().toISOString(),
            schedularInterval: this.cronExpression,
            scheduleActive: this.scheduleActive,
            connectorMappingId: this.data.data.id,
            connectorMapping: this.data.data,
            processSince: this.minutes,
        }
        this.spinner = true;
        this.connectorService.configSchedule(this.createScheduleObj).subscribe(res => {
            this.spinner = false;
            if (res.response.code === 200 && res.response.status === 'success') {
                this.dialogRef.close(res.response.status);
                return this.snackBar.open(Constants.SCHEDULE_CONFIGURED, 'Close', this.config);
            }
        }, error => {
            this.spinner = false;
            if (error.status === 401 && error.error.response.status === 'error' && error.error.response.message === 'UnAuthorized Access Token Expired') {
                return this.snackBar.open(Constants.SESSION_EXPIRED, 'Close', this.config);
            } else {
                return this.snackBar.open(error.error.message, 'Close', this.config);
            }
        });
    }

    keyPress(event: any) {
        const pattern = /[0-9\+\-\ ]/;
        let inputChar = String.fromCharCode(event.charCode);
        if (!pattern.test(inputChar) && event.charCode != '0') {
            event.preventDefault();
        }
    }
}
