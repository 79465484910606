import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators';
import { LoginService } from '../../services/login/login.service';
import { Constants } from 'src/app/shared/constants/constants';
import {environment} from '../../../../environments/environment';

@Injectable()
export class AuthenticationTokenInterceptor implements HttpInterceptor {
  public cloudType = environment.CLOUD_TYPE;
  constructor(private loginService: LoginService) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let authToken = '';
    const storedToken = sessionStorage.getItem('authToken');
    if (storedToken) {
      authToken = `Bearer ${storedToken}`;
    }

    let oid: string;

    if (authToken && this.cloudType !== 'gcp'){
      const base64Url = authToken.split('.')[1];
      const base64 = base64Url.replace('-','+').replace('_','/');
      const decodedToken = JSON.parse(window.atob(base64));
      oid = decodedToken['oid'];
    } else if (authToken && this.cloudType === 'gcp') {
      oid = localStorage.getItem('loginUserId');
    }
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ` + authToken,
        userID :  oid ? oid :''
      }
    });
    return next.handle(request).pipe(tap(() => { },
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status !== 401) {
            return;
          } else {

            this.loginService.logoutFromMicrosoft()
              .subscribe(res => {
                if (res.response.code === 200 && res.response.status === 'success') {
                  localStorage.clear();
                  if (sessionStorage.getItem('jwtToken') !== null) {
                    sessionStorage.removeItem('jwtToken');
                  }
                  window.location.href = res.response.data.url;
                }
              });
            localStorage.setItem("message", Constants.SESSION_EXPIRED);
          }
        }
      }));
  }
}
