<div class="spinner" *ngIf="spinner">
  <img src="../../../../assets/images/spinner.gif" height="50px">
</div>

<div class="content-header">
  <h1>Import Data
  <span style="float: right;">
    <button id="download-template"
            class="btn bg-blue btn-flat margin btn-pdt" (click)="downloadTemplate()"><i class="fa fa-download"></i>Template</button>
  </span>
  </h1>
  <br>
  <mat-divider></mat-divider>
</div>

<div class="box">

  <form [formGroup]="DataMigrationForm" (ngSubmit)="submit()">
    <div>
      <br>
      <div class="container">
      <div class="row form-group">
        <div class="col-xs-3 form-inline">
          <label for="uploadFile" class="file-upload" style="padding-left: 20px !important;">Upload File: &nbsp;&nbsp;&nbsp;&nbsp; </label>
          <div class="input-group col-xs-3">
            <div class="custom-file">
              <input type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" class="form-control custom-file-input" multiple formControlName="importFile" id="importFile" (change)="onFileChange($event.target.files)">

              <label id ="labelImports" class="custom-file-label" #labelImport for="importFile"><i class="fa fa-upload"></i>
                &nbsp; &nbsp; &nbsp; Choose file</label>
            </div>
          </div>
    </div>
      </div> </div>
      <br>
      <div class="row mt-1">
        <div class="col-md-12" style="padding-left: 20px !important;">
          <button id="submit-csv-details"
                  [disabled]="!this.DataMigrationForm.valid"
                  class="btn bg-blue btn-flat margin btn-pdt">Import</button>
        </div>
      </div>

    </div>
  </form>

</div>

<div class="box">
  <mat-table #viewMessageMappingsSort="matSort" [dataSource]="messageMappingListDataSource" matSort matSortActive="startedAt" matSortDirection="desc">
    <mat-header-row *matHeaderRowDef="['header-row-first-group']">
    </mat-header-row>
    <ng-container matColumnDef="header-row-first-group">
      <mat-header-cell *matHeaderCellDef style="text-align: left;">
        <div class="col-sm-12">
          <h2 class="box-title" style="margin-left: -25px;"><strong>Message Mapping Status </strong></h2>
          <div class="row form-group">
            <div class="col-sm-6 pull-right">
            </div>
          <div class="col-sm-6 pull-right">
            <div class="searchbar_section">
              <div class="app_search_component">
              <mat-form-field>
              <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
              </mat-form-field>
              </div>
            </div>
          </div>
          </div>
        </div>
      </mat-header-cell>

    </ng-container>
  <ng-container matColumnDef="startedAt">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Started At </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.startedAt | date:'dd-MM-yyyy HH:mm:ss'}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="completedAt">
    <mat-header-cell *matHeaderCellDef mat-sort-header > Completed At </mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{element.completedAt | date:'dd-MM-yyyy HH:mm:ss'}} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef mat-sort-header > Status </mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{element.status}} </mat-cell>
  </ng-container>


  <ng-container matColumnDef="action">
    <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
    <mat-cell *matCellDef="let element" style="padding-top: 2px;" class="text-nowrap">
    <button  title="View Status"
       type="button" (click)="viewStatus(element)" class="btn btn-box-tool badge bg-red"><i
        class="fa fa-eye fa-2x delete-btn" style="color:#3c8dbc; font-size: 1.5em;" title="View Status"></i></button>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="messageMappingListColumns" style="text-align: center;" class="text-nowrap"></mat-header-row>
  <mat-row *matRowDef="let row; columns: messageMappingListColumns;"></mat-row>
</mat-table>
  <mat-paginator #viewMessageMappingsPaginator="matPaginator" [pageSizeOptions]="[50,100,500,1000]"></mat-paginator>
</div>
<app-modal></app-modal>
