import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {

  @Input() genericMessage = '';
  @Input() canDelete = false;
  @Output() emitDeleteFuncionality = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  confirmDelete(isDelete: boolean) {
    this.emitDeleteFuncionality.emit(isDelete);
  }

  closeModal() {
    const x = document.getElementById('myModal');
    x.style.display = 'none';
  }

  populateModal(res: any) {
    this.genericMessage = '';
    this.genericMessage = res.response.message;
    const x = document.getElementById('myModal');
    x.style.display = 'block';
    document.getElementById('auto-focus-close').focus();
  }
}
