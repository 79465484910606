<div class="spinner" *ngIf="showSpinner">
  <img src="../../../../assets/images/spinner.gif" height="50px" />
</div>
<div class="content-header">
  <h1>Application Mapping</h1>
  <br />
  <mat-divider></mat-divider>
</div>
<div class="community-mapping">
  <form class="mt-4" [formGroup]="communityMappingForm" (ngSubmit)="submit()">
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label>Customer</label><br />
          <div *ngIf="!superAdmin" class="form-control admin-label">
            {{ customerObj.name }}
          </div>
          <select
            *ngIf="superAdmin"
            id="comm-map-cust"
            class="form-control"
            required
            formControlName="customerName"
            (change)="onCustomerChange($event)"
          >
            <option value="">select</option>
            <option
              *ngFor="let selectedcust of commCust"
              value="{{ selectedcust.customerId }}"
            >
              {{ selectedcust.customerName }}
            </option>
          </select>
          <p
            class="custom-error"
            *ngIf="
              communityMappingForm.controls['customerName'].hasError(
                'required'
              ) && communityMappingForm.controls['customerName'].dirty
            "
          >
            Customer is required
          </p>
        </div>
        <div class="form-group">
          <label>Community</label>
          <select
            id="comm-map-community"
            class="form-control"
            formControlName="communityName"
            (change)="onCommunityChange($event)"
          >
            <option value="">select</option>
            <option
              *ngFor="let community of communits"
              value="{{ community.communityId }}"
            >
              {{ community.communityName }}
            </option>
          </select>
          <p
            class="custom-error"
            *ngIf="
              communityMappingForm.controls['communityName'].hasError(
                'required'
              ) && communityMappingForm.controls['communityName'].dirty
            "
          >
            Community is required
          </p>
        </div>
      </div>
    </div>
    <div
      class="row form-group"
      *ngIf="isCommunitySelected !== undefined && isCommunitySelected !== ''"
    >
      <div class="col-md-4">
        <label>Available</label>
        <div class="availavle-list">
          <select
            id="comm-map-available"
            class="form-control"
            formControlName="selectedToAdd"
            multiple
            name="availCommunities"
          >
            <option
              *ngFor="let community of availCommunities"
              value="{{ community.communityId }}"
            >
              {{ community.communityName }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-0 arrowBtn-section">
        <a id="comm-set-target-btn" class="btn btn-link" (click)="setTargets()"
          ><i class="fa fa-arrow-circle-right" aria-hidden="true"></i
        ></a>
        <a
          id="comm-remove=tragrt-btn"
          class="btn btn-link"
          (click)="removeTargets()"
          ><i class="fa fa-arrow-circle-left" aria-hidden="true"></i
        ></a>
      </div>
      <div class="col-md-5">
        <label>Selected</label>
        <div class="selected-list">
          <select
            id="comm-mapp-target-communities"
            class="form-control"
            formControlName="selectedToRemove"
            multiple
            name="targetedCommunities"
          >
            <option
              *ngFor="let community of selectedCommunities"
              value="{{ community.communityName }}"
            >
              {{ community.communityName }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="row col-md-12">
      <div
        class="col-md-3"
        *ngIf="
          isCommunitySelected !== undefined &&
          isCommunitySelected !== '' &&
          showAutoSyndication
        "
      >
        <label class="chkBoxContainer " >
          <span class="inter-font text-nowrap">Enable Auto Syndication</span>
          <input
            id="autoSyndication-enable-diable"
            type="checkbox"
            formControlName="autoSyndication"
            (change)="populateAutoSyndication()"
          />
          <span class="checkmark"></span>
        </label>
      </div>
    </div>
    <div
      class="row col-md-12"
      *ngIf="
        isCommunitySelected !== undefined &&
        isCommunitySelected !== '' &&
        communityMappingForm.controls['autoSyndication'].value !== false &&
        showAutoSyndication
      "
    >
      <fieldset class="map-fieldset static-fieldset">
        <legend class="map-legend small text-nowrap">Auto-Syndication</legend>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <div class="table-container">
        <div class="csvFileUpload">
          <div class="col-xs-3 form-inline">
            <label
              for="exampleInputAmount"
              class="file-upload"
              style="padding-left: 20px !important"
              >Upload File: &nbsp;&nbsp;&nbsp;&nbsp;
            </label>
            <div class="input-group col-xs-3">
              <div class="custom-file">
                <input
                  type="file"
                  accept=".csv"
                  class="form-control custom-file-input"
                  id="importFile"
                  multiple
                  formControlName="importFile"
                  (change)="onFileChange($event.target.files)"
                />
                <label
                  class="custom-file-label"
                  id="csvImportBoards"
                  #csvImportBoard
                  for="importFile"
                  ><i class="fa fa-upload"></i> &nbsp; &nbsp; &nbsp; Choose
                  file</label
                >
              </div>
            </div>
          </div>
          <button
            id="submit"
            class="btn-submit"
            type="button"
            (click)="csvSubmit($event)"
          >
            Submit
          </button>
          <button
            id="download"
            class="download"
            type="button"
            title="click here to download the CSV file"
            (click)="csvDownlaod($event)"
          >
            <i class="fa fa-download" aria-hidden="true"></i>
          </button>
        </div>
        <div class="row col-md-12">
          <div class="row col-md-12">
            <div class="col-md-2">
              <select
                id="autosyndication-types"
                class="form-control"
                value="{{ autoSyndicationType }}"
                [(ngModel)]="autoSyndicationType"
                [ngModelOptions]="{ standalone: true }"
              >
                <option value="board">Board</option>
                <option value="label">Label</option>
              </select>
            </div>
            <div class="col-md-2">
              <button
                id="auto-syndicate"
                class="btn bg-blue btn-lg btn-flat margin fa fa-plus pull-left text-center"
                type="button"
                (click)="add(); isEditButtonClicked = true"
              >
                Add
              </button>
            </div>
            <div class="col-sm-6 pull-right">
              <div class="searchbar_section">
                <div class="app_search_component">
                  <mat-form-field>
                    <input matInput (keyup)="applyAutoSyndicationFilter($event.target.value)" placeholder="Filter">
                    </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <div>
          <mat-table [dataSource]="autosyndicationMappingDataSource" matSort
          >
 <!-- Define Columns -->
 <ng-container matColumnDef="boardLabels">
  <mat-header-cell *matHeaderCellDef>Board Labels</mat-header-cell>

  <mat-cell *matCellDef="let element; let i = index">
    <span *ngIf="element.type === 'label'"><label class="text-center">Label:</label></span>
    <span *ngIf="element.type === 'board'">
      <input
        [readOnly]="element.isUpdate"
        style="border-radius: 5px"
        class="form-control boards-max-width"
        type="text"
        placeholder="Enter Board Labels seperated by comma (,)"
        value="{{ element.boardLabels }}"
        [(ngModel)]="element.boardLabels"
        [ngModelOptions]="{ standalone: true }"
      />
    </span>
  </mat-cell>
</ng-container>

<ng-container matColumnDef="name">
  <mat-header-cell *matHeaderCellDef mat-sort-header>Board</mat-header-cell>
  <mat-cell *matCellDef="let element; let i = index">
    <span *ngIf="element.type === 'label'">
      <input
        [readOnly]="element.isUpdate"
        style="border-radius: 5px"
        class="form-control boards-max-width"
        type="text"
        placeholder="Enter Label"
        value="{{ element.name }}"
        [(ngModel)]="element.name"
        [ngModelOptions]="{ standalone: true }"
      />
    </span>
    <span *ngIf="element.type === 'board'">
      <mat-form-field>
        <mat-select
          (selectionChange)="
            onSourceBoardChange(element)
          "
          [(ngModel)]="element.name"
          [ngModelOptions]="{ standalone: true }"
        >
          <mat-select-filter
            [array]="boards"
            [displayMember]="'title'"
            (filteredReturn)="
              element.filteredBoards = $event
            "
          ></mat-select-filter>
          <mat-option [disabled]="element.isUpdate" value=""
            >select</mat-option
          >
          <mat-option
            [disabled]="element.isUpdate"
            *ngFor="let board of element.filteredBoards"
            [value]="board.id"
            >{{ board.title }}({{ board.id }})</mat-option
          >
        </mat-select>
      </mat-form-field>
    </span>
  </mat-cell>


</ng-container>
<ng-container matColumnDef="targetCommunityId">
  <mat-header-cell *matHeaderCellDef mat-sort-header>Target Community</mat-header-cell>
  <mat-cell *matCellDef="let element; let i = index">
    <mat-form-field id="comm-target-community-id">
      <mat-select
        (selectionChange)="
          onTargetCommunityChange(
            element.targetCommunityId,
            element
          )
        "
        [(ngModel)]="element.targetCommunityId"
        [ngModelOptions]="{ standalone: true }"
      >
        <mat-option
          [disabled]="element.isUpdate"
          [value]="0"
          >select</mat-option
        >
        <mat-option
          [disabled]="element.isUpdate"
          *ngFor="let community of autoSyndicationCommunities"
          [value]="community.communityId"
          >{{ community.communityName }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </mat-cell>
</ng-container>
<ng-container matColumnDef="targetCategoryId">
  <mat-header-cell *matHeaderCellDef mat-sort-header>Target Category</mat-header-cell>
  <mat-cell *matCellDef="let element; let i = index">
    <mat-form-field>
      <mat-select
        (selectionChange)="
          onTargetCategoryChange(
            element.targetCategoryId,
            element.targetCommunityId,
            element
          )
        "
        [(ngModel)]="element.targetCategoryId"
        [ngModelOptions]="{ standalone: true }"
      >
        <mat-select-filter
          [array]="element.categories"
          [displayMember]="'categoryTitle'"
          (filteredReturn)="
            element.filteredTargetCategorie = $event
          "
        ></mat-select-filter>
        <mat-option [disabled]="element.isUpdate" value=""
          >select</mat-option
        >
        <mat-option
          [disabled]="element.isUpdate"
          *ngFor="
            let category of element.filteredTargetCategorie
          "
          [value]="category.categoryId"
          title="{{ category.categoryTitle }} ({{
            category.categoryId
          }})"
          >{{ category.categoryTitle }}({{
            category.categoryId
          }})</mat-option
        >
      </mat-select>
    </mat-form-field>
  </mat-cell>
</ng-container>
<ng-container matColumnDef="targetBoardId">
  <mat-header-cell *matHeaderCellDef mat-sort-header>Target Board</mat-header-cell>
  <mat-cell *matCellDef="let element; let i = index">
    <mat-form-field>
      <mat-select
        msInfiniteScroll
        (infiniteScroll)="getNextBatch(element)"
        [complete]="
          element.targetBoardOffset ===
          element.targetBoards.length
        "
        id="element.id"
        [(ngModel)]="element.targetBoardId"
        [ngModelOptions]="{ standalone: true }"
      >
        <mat-select-filter
          [array]="element.targetBoards"
          [displayMember]="'title'"
          (filteredReturn)="
            element.filteredTargetBoards = $event
          "
        ></mat-select-filter>
        <mat-option [disabled]="element.isUpdate" value=""
          >select</mat-option
        >
        <mat-option
          [disabled]="element.isUpdate"
          *ngFor="
            let board of element.filteredTargetBoards
          "
          [value]="board.id"
          title="{{ board.title }} ({{ board.id }})"
          >{{ board.title }}({{ board.id }})</mat-option
        >
      </mat-select>
    </mat-form-field>
  </mat-cell>
</ng-container>
<ng-container matColumnDef="actions">
  <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
  <mat-cell *matCellDef="let element; let i = index">
    <span>
      <label class="chkBoxContainer post-as-machine-user-container">
        <span class="inter-font text-nowrap"
          >Post As Machine User</span
        >
        <input
          class=""
          [disabled]="element.isUpdate || !isEditButtonClicked"
          id="post-as-machine-user"
          type="checkbox"
          [checked]="element.postAsMachineUser"
          [(ngModel)]="element.postAsMachineUser"
          [ngModelOptions]="{ standalone: true }"
        />
        <span class="checkmark"></span>
      </label>
    </span>
    &nbsp;&nbsp;
    <span *ngIf="element.type === 'board'">
      <label
        class="chkBoxContainer syndicate-solutions-only-container"
      >
        <span class="inter-font text-nowrap"
          >Syndicate solutions only</span
        >
        <input
          class=""
          [disabled]="element.isUpdate || !isEditButtonClicked"
          id="syndicate-solutions-only"
          type="checkbox"
          [checked]="element.syndicateSolutionsOnly"
          [(ngModel)]="element.syndicateSolutionsOnly"
          [ngModelOptions]="{ standalone: true }"
        />
        <span class="checkmark"></span>
      </label>
    </span> &nbsp;&nbsp;
    <span>
      <label
        class="chkBoxContainer"
        *ngIf="element.type === 'board'"
      >
        <span class="inter-font text-nowrap">Syndicate Labels</span>
        <input
          [disabled]="element.isUpdate || !isEditButtonClicked"
          id="autoSyndication-enable-diable"
          type="checkbox"
          [checked]="element.syndicateLabels"
          [(ngModel)]="element.syndicateLabels"
          [ngModelOptions]="{ standalone: true }"
        />
        <span class="checkmark"></span>
      </label>
      &nbsp;&nbsp;&nbsp;
      <button
        *ngIf="element.isUpdate"
        id="{{ element.id + '-edit' }}"
        title="Edit Mappings"
        (click)="editAutoSyndicationMapping(element); isEditButtonClicked = true"
        type="button"
        class="btn btn-box-tool badge bg-red"
        data-widget="remove"
      >
        <i
          class="fa fa-pencil fa-2x delete-btn"
          style="color: #3c8dbc"
          title="Edit Mappings"
        ></i>
      </button>
      <button
        *ngIf="!element.isUpdate"
        id="{{ element.id + '-save' }}"
        title="Save Mappings"
        (click)="saveAutoSyndicationMapping(element)"
        type="button"
        class="btn btn-box-tool badge bg-red"
        data-widget="remove"
      >
        <i
          class="fa fa-save fa-2x delete-btn"
          style="color: #3c8dbc"
          title="Save Mappings"
        ></i>
      </button>
      <button
        id="{{ element.id + '-delete' }}"
        title="Delete"
        (click)="confirm(element)"
        type="button"
        class="btn btn-box-tool badge bg-red"
        data-widget="remove"
      >
        <i
          class="fa fa-trash fa-2x delete-btn"
          style="color: red"
          title="Delete field"
        ></i>
      </button>
    </span>
  </mat-cell>
</ng-container>

<!-- Header Row Definition -->
<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

<!-- Define Data Rows -->
<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>
          <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </div>
        </div>
        </div>
      </fieldset>
    </div>
    <div class="btn-section">
      <button
        id="comm-mapp-submit-btn"
        [disabled]="!this.communityMappingForm.valid"
        class="btn bg-blue btn-flat margin pull-left btn-pdt"
        type="button" (click)="submit()"
      >
        Save All
      </button>
      <button
        id="comm-mapp-reset-btn"
        class="btn bg-blue btn-flat margin"
        type="reset"
        (click)="reset()"
      >
        Reset
      </button>
    </div>
  </form>
  <div class="box">
    <mat-table [dataSource]="dataSource" matSort (matSortChange)="onSortChange($event)">
      <mat-header-row *matHeaderRowDef="['header-row-first-group']">
      </mat-header-row>
      <ng-container matColumnDef="header-row-first-group">
        <mat-header-cell *matHeaderCellDef style="text-align: left">
          <div class="col-sm-12">
            <h2 class="box-title" style="margin-left: -25px">
              <strong>Application Mapping List</strong>
            </h2>
            <div class="row form-group"></div>
          </div>
        </mat-header-cell>
      </ng-container>
      <ng-container matColumnDef="sourceCommunity.communityName">
        <mat-header-cell *matHeaderCellDef>
          Source
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.sourceCommunity.communityName }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="targetCommunity.communityName">
        <mat-header-cell *matHeaderCellDef> Targets </mat-header-cell>
        <mat-cell *matCellDef="let element" class="targets-list">
          <ng-container *ngFor="let targetCommunity of element.targetCommunity">
            {{ targetCommunity.communityName }}
            <br />
          </ng-container>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button
            id="{{ element.sourceCommunity.communityName + '-edit' }}"
            title="Edit"
            (click)="edit(element)"
            type="button"
            class="btn btn-box-tool badge bg-blue"
            data-widget="remove"
          >
            <i class="fa fa-edit"></i>
          </button>
          &nbsp;<button
            id="{{ element.sourceCommunity.communityName + '-delete' }}"
            title="Delete"
            (click)="delete(element)"
            type="button"
            class="btn btn-box-tool badge bg-red"
            data-widget="remove"
          >
            <i class="fa fa-times"></i>
          </button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="metadatastatus">
        <mat-header-cell *matHeaderCellDef>
          MetaData Status
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.metaDataUpdated ? "Success" : "Failed" }}
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: tableColumns"></mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" (page)="onChangePage($event)" [length]="total" [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions" showFirstLastButtons></mat-paginator>
  </div>
</div>

<app-modal
  [genericMessage]="messageToDisplay"
  [canDelete]="isDelete"
  (emitDeleteFuncionality)="confirmDelete($event)"
></app-modal>
