import { AfterViewInit, Inject, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ModalComponent } from '../../../../../shared/components/modal/pages/modal.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataMigrationService } from '../../../../../core/services/data-migration/data-migration.service';

@Component({
  selector: 'app-message-mappings-status-view',
  templateUrl: './message-mappings-status-view.component.html',
  styleUrls: ['./message-mappings-status-view.component.css']
})
export class MessageMappingsStatusViewDialog implements OnInit, AfterViewInit {
  @ViewChild(ModalComponent, { static: true }) modalComponent;
  @ViewChild('viewMessageMappingsStatusPaginator',  {read: MatPaginator, static: false}) viewMessageMappingsStatusPaginator: MatPaginator;
  @ViewChild('viewMessageMappingsStatusSort', {static: false}) viewMessageMappingsStatusSort: MatSort;
  dataSource: MatTableDataSource<any>;
  selectedDataMigration: any;
  spinner = false;
  config = new MatSnackBarConfig();
  messageMappingsStatusColumns = ['sourceCommunityId', 'sourceThreadId', 'sourceMessageId', 'targetCommunityId', 'targetThreadId', 'targetMessageId', 'updatedAt', 'errorDescription'];

  constructor(
      public dialogRef: MatDialogRef<MessageMappingsStatusViewDialog>,
      @Inject(MAT_DIALOG_DATA) public data: any, private dataMigrationService : DataMigrationService, private snackBar: MatSnackBar) {
          this.selectedDataMigration = data.selectedDataMigration;
          this.dataSource = new MatTableDataSource(data.msgmappingsstatuslist);
          this.dataSource.paginator = this.viewMessageMappingsStatusPaginator;
          this.dataSource.sortingDataAccessor = (item, property) => {
            if (property.includes('.')) return property.split('.').reduce((o,i)=>o[i], item)
            return item[property];
         };
          this.dataSource.sort = this.viewMessageMappingsStatusSort;
  }

  ngOnInit() {
      this.config.verticalPosition = 'top';
      this.config.horizontalPosition = 'end';
      this.config.duration = 5000;
      this.config.panelClass = ['blue-snackbar'];
  }

  ngAfterViewInit() {
      this.dataSource.paginator = this.viewMessageMappingsStatusPaginator;
      this.dataSource.sortingDataAccessor = (item, property) => {
        if (property.includes('.')) return property.split('.').reduce((o,i)=>o[i], item)
        return item[property];
     };
      this.dataSource.sort = this.viewMessageMappingsStatusSort;
  }
  close(): void {
      this.dialogRef.close();
  }

reloadData() {
  this.spinner = true;
  this.dataMigrationService.getMessageMappingsStatusList(this.selectedDataMigration.migrationRequestId).subscribe(res => {
    this.dataSource = new MatTableDataSource(res);
    this.dataSource.paginator = this.viewMessageMappingsStatusPaginator;
    this.dataSource.sortingDataAccessor = (item, property) => {
      if (property.includes('.')) return property.split('.').reduce((o, i) => o[i], item)
      return item[property];
    };
    this.dataSource.sort = this.viewMessageMappingsStatusSort;
    this.spinner = false;
  }, error => {
    this.spinner = false;
    return this.snackBar.open(error.error.response.message, 'Close', this.config);
  });
}

}
