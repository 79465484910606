<div class="spinner" *ngIf="showSpinner">
  <img src="../../../../assets/images/spinner.gif" height="50px">
</div>
<div class="content-header">
  <h1>Customer</h1>
  <br>
  <mat-divider></mat-divider>
</div>

<div class="mt-4 customer-tab">

  <form [formGroup]="clientForm" (ngSubmit)="submit()">
    <div class="row">
      <div class="col-md-2">
        <div class="form-group">
          <label>Customer name<span class="label-required">*</span></label>
          <input id="cust-org-name" class="form-control"
                 formControlName="customer_name" type="text"
                 placeholder="customer name">
          <p class="custom-error"
             *ngIf="clientForm.controls['customer_name'].hasError('required') && clientForm.controls['customer_name'].dirty">
            Customer is required</p>
          <p class="custom-error"
             *ngIf="clientForm.controls['customer_name'].hasError('pattern') && clientForm.controls['customer_name'].dirty">
            Customer name can not contain numbers and special characters</p>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label>Address<span class="label-required">*</span></label>
          <input id="address_focus" class="form-control" formControlName="address" type="text" placeholder="address">
          <p class="custom-error"
             *ngIf="clientForm.controls['address'].hasError('required') && clientForm.controls['address'].dirty">Address
            is required</p>
          <p class="custom-error"
             *ngIf="clientForm.controls['address'].hasError('pattern') && clientForm.controls['address'].dirty">Address
            can be alpha numeric values</p>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label>Email<span class="label-required">*</span></label>
          <input id="cust-email" class="form-control" formControlName="email"
                 type="text"
                 placeholder="email">
          <p class="custom-error"
             *ngIf="clientForm.controls['email'].hasError('required') && clientForm.controls['email'].dirty">Email is
            required</p>
          <p class="custom-error"
             *ngIf="clientForm.controls['email'].hasError('email') && clientForm.controls['email'].dirty">Enter a valid
            email address</p>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label>Contact number<span class="label-required">*</span></label>
          <input id="cust-contact" class="form-control" formControlName="contact" type="text"
                 placeholder="contact number">
          <p class="custom-error"
             *ngIf="clientForm.controls['contact'].hasError('required') && clientForm.controls['contact'].dirty">Contact
            number is required</p>
          <p class="custom-error"
             *ngIf="clientForm.controls['contact'].hasError('pattern') && clientForm.controls['contact'].dirty">Should
            be numbers only</p>
          <p class="custom-error"
             *ngIf="clientForm.controls['contact'].hasError('minlength') && clientForm.controls['contact'].dirty">
            Minimum & Maximum length is 10</p>
          <p class="custom-error"
             *ngIf="clientForm.controls['contact'].hasError('maxlength') && clientForm.controls['contact'].dirty">
            Minimum & Maximum length is 10</p>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label>Domains<span class="label-required">*</span></label>
          <input id="cust-domain-names" class="form-control"
                 formControlName="domain_names" type="text"
                 placeholder="domain names with comma seperations">
          <p class="custom-error"
             *ngIf="clientForm.controls['domain_names'].hasError('required') && clientForm.controls['domain_names'].dirty">
            Domain names should be comma seperated</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-2">
        <div class="form-group">
          <label>Login type<span class="label-required">*</span></label>
          <select id="cust-login-type" class="form-control" formControlName="login_type"
                  (change)="checkLoginType($event.target.value)">
            <option value="">select</option>
            <option *ngFor="let logintype of loginTypes"
                    value={{logintype.authProviderId}}>{{logintype.authProviderName}}</option>
          </select>
          <p class="custom-error"
             *ngIf="clientForm.controls['login_type'].hasError('required') && clientForm.controls['login_type'].dirty">
            Select a login type</p>
        </div>
      </div>

      <div class="col-md-2">
        <div *ngIf="isAADEnabled" class="form-group">
          <label>AAD client ID<span class="label-required">*</span></label>
          <input id="cust-aad-clientid" class="form-control"
                 formControlName="aad_client_id" type="password"
                 placeholder="AAD client id">
          <p class="custom-error"
             *ngIf="clientForm.controls['aad_client_id'].hasError('required') && clientForm.controls['aad_client_id'].dirty">
            AAD client id</p>
        </div>
      </div>
      <div class="col-md-2">
        <div *ngIf="isAADEnabled" class="form-group">
          <label>AAD client secret<span class="label-required">*</span></label>
          <input id="cust-aad-clisecrect" class="form-control"
                 formControlName="aad_client_secrect" type="password"
                 placeholder="AAD client secrect">
          <p class="custom-error"
             *ngIf="clientForm.controls['aad_client_secrect'].hasError('required') && clientForm.controls['aad_client_secrect'].dirty">
            AAD client secret</p>
        </div>
      </div>
      <div class="col-md-2">
        <div *ngIf="isAADEnabled" class="form-group">
          <label>AAD tenant ID<span class="label-required">*</span></label>
          <input id="cust-aad-tenantid" class="form-control"
                 formControlName="aad_tenant_id" type="password"
                 placeholder="AAD Tenant Id">
          <p class="custom-error"
             *ngIf="clientForm.controls['aad_tenant_id'].hasError('required') && clientForm.controls['aad_tenant_id'].dirty">
            AAD tenant id is required</p>
        </div>
        <div>
        </div>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-md">
        <button id="cust-submit-form" *ngIf="isValidated"  type="button"
                class="btn bg-blue btn-flat margin pull-left btn-pdt" (click)="submit()">{{submitButton}}</button>
        <button id="cust-validate-form" *ngIf="!isValidated"  type="button" [disabled]="!clientForm.valid"
                class="btn bg-blue btn-flat margin pull-left btn-pdt" (click)="validate()">Validate</button>
        <input id="cust-reset-form" type="button" class="btn bg-blue btn-flat margin" (click)="reset()" value="Reset">
      </div>
    </div>
  </form>
</div>
<div class="box">
    <mat-table [dataSource]="dataSource" matSort (matSortChange)="onSortChange($event)">
      <mat-header-row *matHeaderRowDef="['header-row-first-group']">
      </mat-header-row>
      <ng-container matColumnDef="header-row-first-group">
        <mat-header-cell *matHeaderCellDef> 
          
            <div class="col-sm-12">
              <h2 class="box-title" style="margin-left: -25px;"><strong>Customer List</strong></h2>
              <div class="col-sm-6 pull-right">
                <div class="searchbar_section">
                  <div class="app_search_component">
                  <mat-form-field>
                  <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
                  </mat-form-field>
                  </div>
                </div>
              </div>
            
          </div>
        </mat-header-cell>
        
      </ng-container>
    <ng-container matColumnDef="customerName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Customer Name </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.customerName}} </mat-cell>
    </ng-container>
  
    <ng-container matColumnDef="address">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Address </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.address}} </mat-cell>
    </ng-container>
  
    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Email </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.email}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="contact">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Contact </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.contact}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button id="customer-edit-{{i}}" title="Edit"
        [disabled]="!element.active" (click)="editCustomer(element)" type="button"
        class="btn btn-box-tool bg-blue cart-edit" style="width: 35px; height: 25px; padding-top: 2px;" data-widget="remove"><i class="fa fa-edit"></i>
</button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef> Enable/Disable </mat-header-cell>
      <mat-cell *matCellDef="let element">
         <mat-slide-toggle
        id="cutomer-enable-or-diable-{{element.customerId}}"
        title="{{element.active ? 'disable' : 'enable'}}"
        [checked]="element.active"
        (change)="updateStatus($event,element)">
      </mat-slide-toggle> </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: tableColumns;"></mat-row>
  </mat-table>
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" (page)="onChangePage($event)" [length]="total" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" showFirstLastButtons></mat-paginator>
</div>

<app-modal [genericMessage]="messageToDisplay" [canDelete]="isDelete"></app-modal>
