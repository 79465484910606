import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BoardMappingList } from '../../../shared/models/board-mapping-list';
import { BoardMappingsSaved } from '../../../shared/models/board-mappings-saved';
import { Category } from '../../../shared/models/category';
import { Community } from '../../../shared/models/community';
import { InteractionStyles } from '../../../shared/models/interaction-styles';
import { LithiumBoard } from '../../../shared/models/lithium-board';
import { PotentialData } from '../../../shared/models/potential-data';
import { Response } from '../../../shared/models/response';

@Injectable()
export class MappingsService {

  constructor(private http: HttpClient) {
  }

  getCommunitybyCustomerId(customerId: number): Observable<Response<Community[]>> {
    const url = `${environment.BASE_URL}/getallcommunitydetailsbycustomerid?customerId=${customerId}`;
    return this.http.get<Response<Community[]>>(url);
  }

  getInteractionStyles(communityType: string): Observable<Response<InteractionStyles[]>> {
    const url = `${environment.BASE_URL}/getinteractionstylesbycommunitytype?communityTypeName=${communityType}`;
    return this.http.get<Response<InteractionStyles[]>>(url);
  }

  getCategories(communityId: number): Observable<Response<Category[]>> {
    const url = `${environment.BASE_URL}/getcategories?communityId=${communityId}`;
    return this.http.get<Response<Category[]>>(url);
  }

  getAllCategories(communityId: number): Observable<Response<Category[]>> {
    const url = `${environment.BASE_URL}/getallcategories?communityId=${communityId}`;
    return this.http.get<Response<Category[]>>(url);
  }

  getCommunityBoards(communityId: number): Observable<LithiumBoard[]> {
    const url = `${environment.BASE_URL}/boardsbycommunityid/${communityId}` + '/false';
    return this.http.get<LithiumBoard[]>(url);
  }
  getBoards(communityId: number, categoryId: string, isHidden: string,nodeType:string): Observable<Response<BoardMappingList[]>> {
    const url = `${environment.BASE_URL}/getboards?communityId=${communityId}&categoryId=${categoryId}&hidden=${isHidden}&nodeType=${nodeType}`;
    return this.http.get<Response<BoardMappingList[]>>(url);
  }

  save(payLoad: any): Observable<Response<any>> {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    const url = `${environment.BASE_URL}/saveorupdateboardmappings`;
    return this.http.post <Response<any>>(url, payLoad, {
      headers
    });
  }

  getMapping(communityId: number): Observable<Response<BoardMappingsSaved>> {
    const url = `${environment.BASE_URL}/getboardmappings?communityId=${communityId}`;
    return this.http.get<Response<BoardMappingsSaved>>(url);
  }

  migrate(payLoad: any): Observable<Response<any>> {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    const url = `${environment.BASE_URL}/migrateboards`;
    return this.http.post <Response<any>>(url, payLoad, {
      headers
    });
  }

  migrateFromprodToStage(payLoad: any): Observable<Response<any>> {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    const url = `${environment.BASE_URL}/createcommunitystructureandmigrateboards`;
    return this.http.post <Response<any>>(url, payLoad, {
      headers
    });
  }

  getPotentialData(communityId: number, communityName: string, categoryId: String): Observable<Response<PotentialData[]>> {
    const url = `${environment.BASE_URL}/getmessagesbycommunitynames/${communityId}/${communityName}?categoryId=${categoryId}&limit=100&offset=0`;
    return this.http.get<Response<PotentialData[]>>(url);
  }

  getCategoriesByCommunity(communityId: number): Observable<Response<Category[]>> {
    const url = `${environment.BASE_URL}/getallcategoriesbycommunity?communityId=${communityId}`;
    return this.http.get<Response<Category[]>>(url);
  }

  getBoardsByCategory(communityId: number, categoryId: string, isHidden: string, autoSyndicate: any): Observable<Response<any>> {
    let url = '';
    let pageIndex = 0;
    if (autoSyndicate.targetBoardOffset > 0) {
      let index = autoSyndicate.targetBoardOffset + 1;
      pageIndex = index/100;
      pageIndex = Math.round(pageIndex);
    }
    url = `${environment.BASE_URL}/getallboardsbycategory/` + autoSyndicate.conversationStyle +`s/`+ autoSyndicate.targetBoardlimit +`/`+ pageIndex + `?communityId=${communityId}&categoryId=${categoryId}&hidden=${isHidden}`;
    return this.http.get<Response<any>>(url);
  }
  getBoardsByCategoryId(communityId: number, categoryId: string, isHidden: string,nodeType:string): Observable<Response<any>> {
    let  url = `${environment.BASE_URL}/getboardsbycategoryid?communityId=${communityId}&categoryId=${categoryId}&hidden=${isHidden}&nodeType=${nodeType}`;
    return this.http.get<Response<any>>(url);
  }
  getGroupHubsByCommunityId(communityId: number){
    let url = `${environment.BASE_URL}/getgrouphubsbycommunityid?communityId=${communityId}`;
    return this.http.get<Response<any>>(url);
  }

  generateApplication(applicationName: any){
    let url = `${environment.BASE_URL}/generate/application/${applicationName}`;
    return this.http.get(url,
      {responseType: 'blob'});
  }
}
