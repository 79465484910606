import { Component, ViewChild } from "@angular/core";
import { UserService } from "../../../core/services/user/user.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ActivitylogsService } from "src/app/core/services/activitylogs/activitylogs.service";
import { Router } from "@angular/router";
import { User } from "../../../shared/models/user";
import { ActivityLogsData } from "src/app/shared/models/activitylogsdata";
import { Constants } from "src/app/shared/constants/constants";
import { ModalComponent } from "src/app/shared/components/modal/pages/modal.component";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { Directive, ElementRef, HostListener } from "@angular/core";

@Component({
  selector: "app-activitylogs",
  templateUrl: "./activitylogs.component.html",
  styleUrls: ["./activitylogs.component.css"],
})
export class ActivityLogsComponent {
  @ViewChild(ModalComponent, { static: true }) modalComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  activitylogsData: ActivityLogsData[] = [];
  userData: User[] = [];
  clientForm: FormGroup;
  successMessage: string;
  displayedColumns: string[] = ["userName", "date", "action"];
  dataSource = new MatTableDataSource<ActivityLogsData>([]);
  showSpinner = false;
  isDateType = false;
  pageIndex: string;
  maxPageIndex: number;
  currentPageIndex: number = 1;
  pages: number[] = [];
  defalutPageIndex = null;
  hasData = false;

  constructor(
    private elementRef: ElementRef,
    private formBuilder: FormBuilder,
    private activityLogsService: ActivitylogsService,
    private router: Router,
    private userService: UserService
  ) {
    this.clientForm = this.formBuilder.group({
      username: ["", Validators.required],
      startDate: ["", Validators.required],
      endDate: ["", Validators.required],
      action: ["", Validators.required],
      pageIndexValue: ["", Validators.required],
    });
    this.dataSource = new MatTableDataSource<ActivityLogsData>([]);
  }

  handleFocus() {
    this.isDateType = true;
  }

  handleBlur() {
    if (!this.elementRef.nativeElement.value) {
      this.isDateType = false;
    }
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  getFormattedTodayDate(): string {
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const formattedDay = day < 10 ? `0${day}` : `${day}`;
    const formattedMonth = month < 10 ? `0${month}` : `${month}`;
    return `${year}-${formattedMonth}-${formattedDay}`;
  }

  ngOnInit() {
    const today = new Date().toISOString().split("T")[0];
    this.maxPageIndex;
    this.updatePages();
    this.userService.getUserDetails(localStorage.getItem("loginUserId")).subscribe(
      (user) => {
        this.userData = user.response.data;
      },
      (error) => {
        console.error("Error:", error);
      }
    );
  }

  resetForm() {
    this.clientForm.reset({
      username: "",
      startDate: "",
      endDate: "",
      action: "",
    });
  }

  getDetails(defalutPageIndex: number) {
    this.showSpinner = true;
    if (defalutPageIndex == null) {
      this.currentPageIndex = 1;
      defalutPageIndex = 0;
    } else {
      defalutPageIndex = defalutPageIndex - 1;
    }
    let username = (this.clientForm.get("username") || {}).value;
    let startDate = (this.clientForm.get("startDate") || {}).value;
    let endDate = (this.clientForm.get("endDate") || {}).value;
    let action = (this.clientForm.get("action") || {}).value;
    let pageIndex = defalutPageIndex.toString();

    const activityLog = {
      userName: username || "",
      startDate: startDate || "",
      endDate: endDate || "",
      action: action || "",
      pageIndex: pageIndex || "0",
    };

    this.activityLogsService.getDetails(activityLog).subscribe(
      (response) => {
        const maxPageIndexList = response.map((item) => item.maxPageIndex);
        this.maxPageIndex = Math.max(...maxPageIndexList);
        if (this.maxPageIndex > 0) {
          this.hasData = true;
          this.maxPageIndex=this.maxPageIndex+1;
        } else {
          this.hasData = false;
        }
        this.updatePages();

        if (response && response.length > 0) {
          this.showSpinner = false;
          this.dataSource.data = response.sort((a, b) => {
            return new Date(b.date).getTime() - new Date(a.date).getTime();
          });
        } else {
          this.modalComponent.populateModal({
            response: { message: Constants.SERVER_ERROR },
          });
        }
      },
      (error) => {
        console.error("Error:", error);
      }
    );
  }
  changePageIndex(newPageIndex: number | "previous" | "next"): void {
    if (newPageIndex === "previous" && this.currentPageIndex > 1) {
      this.currentPageIndex--;
    } else if (
      newPageIndex === "next" &&
      this.currentPageIndex < this.maxPageIndex
    ) {
      this.currentPageIndex++;
    } else if (
      typeof newPageIndex === "number" &&
      newPageIndex >= 1 &&
      newPageIndex <= this.maxPageIndex
    ) {
      this.currentPageIndex = newPageIndex;
    }
    this.updatePages();
    const formValues = this.clientForm.value;
    const apiParams = {
      ...formValues,
      pageIndexValue: this.currentPageIndex,
    };
    this.getDetails(this.currentPageIndex);
  }
  updatePages(): void {
    const numberOfButtonsToShow = 5;
    let start = Math.max(
      1,
      this.currentPageIndex - Math.floor(numberOfButtonsToShow / 2)
    );
    let end = Math.min(start + numberOfButtonsToShow - 1, this.maxPageIndex);
    start = Math.max(1, end - numberOfButtonsToShow + 1);
    end = Math.min(this.maxPageIndex, start + numberOfButtonsToShow - 1);
    this.pages = [];
    for (let i = start; i <= end; i++) {
      this.pages.push(i);
    }
  }
}
