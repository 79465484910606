import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Response} from '../../../shared/models/response';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {CommunityMappings} from '../../../shared/models/communityMappings';
import {Community} from '../../../shared/models/community';

@Injectable()
export class LoginService {

  private userEmail: string;
  public cloudType = environment.CLOUD_TYPE;

  setUserEmail(email: string) {
    this.userEmail = email;
  }

  getUserEmail(): string {
    return this.userEmail;

  }

  constructor(private http: HttpClient) {
  }

  getCusrrentUserdetails(loginId: any): Observable<Response<any>> {
    const url = `${environment.BASE_URL}/getuserbyloginuserid?loginUserId=${loginId}`;
    return this.http.get<Response<any>>(url);
  }

  validateAndGetUserRole(userName: string, passWord: string): Observable<any> {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    const url = `${environment.AAD_BASE_URL}/xcommunityaad/aadlogin?userEmail=${userName}&password=${passWord}`;
    return this.http.post <Response<CommunityMappings>>(url, {
      headers
    });
  }

  resetPassword(email: string): Observable<Response<any>> {
    const url = `${environment.AAD_BASE_URL}/xcommunityaad/forgotPassword?email=${email}`;
    return this.http.get<Response<any>>(url);
  }

  public authenticateUser(userEmail: string): Observable<Response<any>> {
    const url = `${environment.AAD_BASE_URL}/xcommunityaad/authentication-redirect-url?userEmail=${userEmail}`;
    this.setUserEmail(userEmail);
    return this.http.get<Response<any>>(url);
  }

  public postJwtTokenandState(jwtToken: string, state: string): Observable<Response<any>> {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    const payLoad = {
      'jwt': jwtToken,
      'useremail': state
    };
    const url = `${environment.AAD_BASE_URL}/xcommunityaad/user-login`;
    return this.http.post <Response<Community>>(url, payLoad, {
      headers
    });
  }

  public logoutFromMicrosoft(): Observable<Response<any>> {
    const url = `${environment.AAD_BASE_URL}/xcommunityaad/logout-url`;
    return this.http.get<Response<any>>(url);
  }

}
