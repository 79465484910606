import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Community } from '../../../shared/models/community';
import { CommunityLanguage } from '../../../shared/models/community-language';
import { CommunityTypeDef } from '../../../shared/models/community-type-def';
import { EnvironmentType } from '../../../shared/models/environment_type';
import { InteractionStyles } from '../../../shared/models/interaction-styles';
import { Response } from '../../../shared/models/response';
import { User } from '../../../shared/models/user';

@Injectable()
export class CommunityService {
  constructor(private http: HttpClient) {}

  getCommunities(
    loginUserId: String,
    pageSize,
    currentPage,
    activeSort,
    sortOrder,
    customerId,
    searchString
  ): Observable<Response<Community[]>> {
    const url = `${environment.BASE_URL}/getcommunitydetailsbyuserloginid?loginUserId=${loginUserId}&pageIndex=${currentPage}&pageLimit=${pageSize}&sortOrder=${sortOrder}&sortColumn=${activeSort}&customerId=${customerId}&filter=${searchString}`;
    return this.http.get<Response<Community[]>>(url);
  }

  save(community: Community, stylesMappings: Array<any>, isUpdate: boolean) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    const url = `${environment.BASE_URL}/createcommunity`;

    const payLoad: any = this.preparePayLoad(community, stylesMappings);
    if (isUpdate) {
      const url = `${environment.BASE_URL}/updatecommunity/false`;
      return this.http.put<Response<any>>(url, payLoad, {
        headers,
      });
    } else {
      return this.http.post<Response<Community>>(url, payLoad, {
        headers,
      });
    }
  }

  delete(communityId: number) {
    const url = `${environment.BASE_URL}/deletecommunity?communityid=${communityId}`;
    return this.http.delete<Response<Community>>(url);
  }

  getInteractionStyles(
    communityType: String
  ): Observable<Response<InteractionStyles[]>> {
    const url = `${environment.BASE_URL}/getinteractionstylesbycommunitytype?communityTypeName=${communityType}`;
    return this.http.get<Response<InteractionStyles[]>>(url);
  }

  getOwners(customerId: number): Observable<Response<User[]>> {
    const url = `${environment.BASE_URL}/getusersbycustomerid?customerId=${customerId}`;
    return this.http.get<Response<User[]>>(url);
  }

  getCommunityTypes(): Observable<Response<CommunityTypeDef[]>> {
    const url = `${environment.BASE_URL}/getallcommunitytypes`;
    return this.http.get<Response<CommunityTypeDef[]>>(url);
  }

  getEnvironments(): Observable<Response<EnvironmentType[]>> {
    const url = `${environment.BASE_URL}/getallcommunityenvironments`;
    return this.http.get<Response<EnvironmentType[]>>(url);
  }

  updateStatus(
    communityId: number,
    isActive: boolean
  ): Observable<Response<any>> {
    const headers = new HttpHeaders().set("content-type", "application/json");
    const url = `${environment.BASE_URL}/enableordisablecommunity?communityid=${communityId}&active=${isActive}`;
    return this.http.put<Response<any>>(url, {
      headers,
    });
  }

  update(payLoad: any, enabledTranslation: boolean): Observable<Response<any>> {
    const headers = new HttpHeaders().set("content-type", "application/json");
    const url = `${environment.BASE_URL}/updatecommunity/${enabledTranslation}`;
    return this.http.put<Response<any>>(url, payLoad, {
      headers,
    });
  }
  validateTranslationKeys(payLoad: any): Observable<any> {
    const headers = new HttpHeaders().set("content-type", "application/json");
    const url = `${environment.BASE_URL}/validatetranslationkeys`;
    return this.http.put<any>(url, payLoad, { headers });
  }
  validateCommunity(
    community: Community,
    interactionStylesMappinsgList: Array<any>
  ): Observable<Response<any>> {
    // const headers = new HttpHeaders().set('content-type', 'multipart/form-data');
    const payLoad = this.preparePayLoad(
      community,
      interactionStylesMappinsgList
    );
    const formdata: FormData = new FormData();
    formdata.append("mTLSKeyFile", community.mTLSKeyFileUpload);
    formdata.append("mTLSCertificateFile", community.mTLSKeyCertificateUpload);
    formdata.append("gcpKeyFile", community.gcpKeyFileUpload);
    formdata.append("payload", JSON.stringify(payLoad));
    const url = `${environment.BASE_URL}/validatecommunity`;
    return this.http.post<Response<Community>>(url, formdata);
  }
  deleteSmartlingProjectById(id: number, payload: any): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const url = `${environment.BASE_URL}/deletesmartlingprojectbyid/${id}`;
    const options = { headers, body: payload }; 
    return this.http.delete<any>(url, options);
  }
  

  preparePayLoad(community: Community, interactionMapping: Array<any>): any {
    if (community.nodeType === "" || community.nodeType === "community") {
      community.nodeId = "";
    }
    let key = "";
    if (community.accessdetail.azureauthtype == "ManagedIdentity") {
      key = "azureStorageAccountName";
    } else {
      key = "azureStorageConnectionUrl";
    }
    const formdata: FormData = new FormData();
    formdata.append("mTLSKeyFile", community.mTLSKeyFileUpload);
    formdata.append("mTLSCertificateFile", community.mTLSKeyCertificateUpload);
    formdata.append("gcpKeyFile", community.gcpKeyFileUpload);
    const payLoad = {
      accessdetail: {
        clientId: community.accessdetail.clientId,
        clientSecret: community.accessdetail.clientSecret,
        authenticationToken: community.accessdetail.authenticationToken,
        verifyToken: community.accessdetail.verifyToken,
        htAccess: community.accessdetail.htAccess,
        accessToken: community.accessdetail.accessToken,
        smartlingSignature: community.accessdetail.smartlingSignature,
        refreshToken: community.accessdetail.refreshToken,
        bulkDataCommunityId: community.accessdetail.bulkDataCommunityId,
        bulkDataClientId: community.accessdetail.bulkDataClientId,
        bulkAPIAccessToken: community.accessdetail.bulkAPIAccessToken,
        // added below fields for sharepoint community type
        tenantName: community.accessdetail.tenantName,
        accessTokenURL: community.accessdetail.accessTokenURL,
        [key]: community.accessdetail.azureStorageConnectionUrl,
        azureFileShare: community.accessdetail.azureFileShare,
        azureFolder: community.accessdetail.azureFolder,
        coveoOrganizationId: community.accessdetail.coveoOrganizationId,
        coveoDatasourceId: community.accessdetail.coveoDatasourceId,
        coveoAPIKey: community.accessdetail.coveoAPIKey,
        securityIdentityProviderName:
          community.accessdetail.securityIdentityProviderName,
        awsAPIKey: community.accessdetail.awsAPIKey,
        chamaAPIKey: community.accessdetail.chamaAPIKey,
        chamaMACKey: community.accessdetail.chamaMACKey,
        smartsheetAPIKey: community.accessdetail.smartsheetAPIKey,
        communityUserAuthenticationToken:
          community.accessdetail.communityUserAuthenticationToken,
        zendeskDatabaseUserName: community.accessdetail.zendeskDatabaseUserName,
        zendeskDatabasePassword: community.accessdetail.zendeskDatabasePassword,
        zendeskDatabaseServer: community.accessdetail.zendeskDatabaseServer,
        zendeskDatabaseName: community.accessdetail.zendeskDatabaseName,
        azureAuthenticationType: community.accessdetail.azureauthtype,
        azureStorageType: community.accessdetail.storageType,
        azureResourceGroup: community.accessdetail.resourceGroup,
        azureSubscriptionId: community.accessdetail.subscriptionId,
      },
      active: true,
      apiUrl: community.apiUrl,
      communityId:
        community.communityId === undefined || community.communityId === null
          ? null
          : community.communityId,
      communityName: community.communityName,
      communityType: community.communityType,
      communityUrl: community.communityUrl,
      customerName: community.user.customer.customerName,
      tenantId: community.tenantId,
      tenantName:
        community.accessdetail.tenantName === undefined
          ? null
          : community.accessdetail.tenantName,
      zendeskScope: community.zendeskScope,
      redirectUri: environment.redirectUrl_MTL,
      communityFunction: community.communityFunction,
      userId: community.user.userId,
      interactionStyleMappingsList: interactionMapping.length
        ? interactionMapping
        : [],
      registrationUrl: community.registrationUrl,
      resourceType:
        community.resourceType === "" || community.resourceType === undefined
          ? null
          : community.resourceType,
      grantType:
        community.grantType === "" || community.resourceType === undefined
          ? null
          : community.grantType,
      siteName:
        community.siteName === "" || community.siteName === undefined
          ? null
          : community.siteName,
      subredditName:
        community.subredditName === "" || community.subredditName === undefined
          ? null
          : community.subredditName,
      projectId:
        community.projectId === "" || community.projectId === undefined
          ? null
          : community.projectId,
      bucketName:
        community.bucketName === "" || community.bucketName === undefined
          ? null
          : community.bucketName,
      datastoreURL:
        community.datastoreURL === "" || community.datastoreURL === undefined
          ? null
          : community.datastoreURL,
      datastoreId:
        community.datastoreId === "" || community.datastoreId === undefined
          ? null
          : community.datastoreId,
      authenticationType:
        community.authenticationType === "" ||
        community.authenticationType === undefined
          ? null
          : community.authenticationType,
      higherlogicCommunityName:
        community.higherlogicCommunityName === "" ||
        community.higherlogicCommunityName === undefined
          ? null
          : community.higherlogicCommunityName,
      fieldMappingEnabled:
        community.fieldMappingEnabled === undefined ||
        community.fieldMappingEnabled === null ||
        community.fieldMappingEnabled.toString() === ""
          ? false
          : community.fieldMappingEnabled,
      sharepointStorageId:
        community.sharepointStorageId === "" ||
        community.sharepointStorageId === undefined
          ? null
          : community.sharepointStorageId,
      environmentType: community.environmentType,
      userValidateBy: community.userValidateBy,
      nodeId:
        community.nodeId === "" || community.nodeId === undefined
          ? null
          : community.nodeId,
      nodeType:
        community.nodeType === "" || community.nodeType === undefined
          ? null
          : community.nodeType,
      syndicateTags:
        community.syndicateTags === undefined ||
        community.syndicateTags === null
          ? false
          : community.syndicateTags,
      syndicateLabels:
        community.syndicateLabels === undefined ||
        community.syndicateLabels === null
          ? false
          : community.syndicateLabels,
      icsTenantId:
        community.icsTenantId === "" ||
        community.icsTenantId === undefined ||
        community.icsTenantId === null
          ? null
          : community.icsTenantId,
      pageId:
        community.pageId === "" ||
        community.pageId === undefined ||
        community.pageId === null
          ? null
          : community.pageId,
      linkedInpageId:
        community.linkedInpageId === "" ||
        community.linkedInpageId === undefined ||
        community.linkedInpageId === null
          ? null
          : community.linkedInpageId,
      smartsheetFolderId:
        community.smartsheetFolderId === "" ||
        community.smartsheetFolderId === undefined ||
        community.smartsheetFolderId === null
          ? null
          : community.smartsheetFolderId,
      templateSheetId:
        community.templateSheetId === "" ||
        community.templateSheetId === undefined ||
        community.templateSheetId === null
          ? null
          : community.templateSheetId,
      chamaEndpointUrl:
        community.chamaEndpointUrl === "" ||
        community.chamaEndpointUrl === undefined ||
        community.chamaEndpointUrl === null
          ? null
          : community.chamaEndpointUrl,
      awsEndpoint:
        community.awsEndpoint === "" ||
        community.awsEndpoint === undefined ||
        community.awsEndpoint === null
          ? null
          : community.awsEndpoint,
      awsS3Url:
        community.awsS3Url === "" ||
        community.awsS3Url === undefined ||
        community.awsS3Url === null
          ? null
          : community.awsS3Url,
      hiddenBoardIds:
        community.hiddenBoardIds === "" ||
        community.hiddenBoardIds === undefined ||
        community.hiddenBoardIds === null
          ? null
          : community.hiddenBoardIds,
      coveoExtensionId:
        community.coveoExtensionId === "" ||
        community.coveoExtensionId === undefined ||
        community.coveoExtensionId === null
          ? ""
          : community.coveoExtensionId,
      excludedRoles:
        community.excludedRoles === "" ||
        community.excludedRoles === undefined ||
        community.excludedRoles === null
          ? ""
          : community.excludedRoles,
      includedRoles:
        community.includedRoles === "" ||
        community.includedRoles === undefined ||
        community.includedRoles === null
          ? ""
          : community.includedRoles,
      khorosPullMechanismEnabled:
        community.khorosPullMechanismEnabled === undefined ||
        community.khorosPullMechanismEnabled === null ||
        community.khorosPullMechanismEnabled.toString() === ""
          ? false
          : community.khorosPullMechanismEnabled,
      postAsMachineUser:
        community.postAsMachineUser === undefined ||
        community.postAsMachineUser === null ||
        community.postAsMachineUser.toString() === ""
          ? false
          : community.postAsMachineUser,
      canonicalUrlEnabled:
        community.canonicalUrlEnabled === undefined ||
        community.canonicalUrlEnabled === null ||
        community.canonicalUrlEnabled.toString() === ""
          ? false
          : community.canonicalUrlEnabled,
      targetMessageUpdatesSyndication:
        community.targetMessageUpdatesSyndication === undefined ||
        community.targetMessageUpdatesSyndication === null ||
        community.targetMessageUpdatesSyndication.toString() === ""
          ? false
          : community.targetMessageUpdatesSyndication,
      targetThreadRepliesSyndication:
        community.targetThreadRepliesSyndication === undefined ||
        community.targetThreadRepliesSyndication === null ||
        community.targetThreadRepliesSyndication.toString() === ""
          ? false
          : community.targetThreadRepliesSyndication,
      khorosSleUpdate:
        community.khorosSleUpdate === undefined ||
        community.khorosSleUpdate === null ||
        community.khorosSleUpdate.toString() === ""
          ? false
          : community.khorosSleUpdate,
      messageHiddenTagSyndication:
        community.messageHiddenTagSyndication === undefined ||
        community.messageHiddenTagSyndication === null ||
        community.messageHiddenTagSyndication.toString() === ""
          ? false
          : community.messageHiddenTagSyndication,
      hierarchicalLabels:
        community.hierarchicalLabels === undefined ||
        community.hierarchicalLabels === null ||
        community.hierarchicalLabels.toString() === ""
          ? false
          : community.hierarchicalLabels,
      communityWebhooks: community.communityWebhooks,
      mTLSKey: community.mTLSKeyFileUpload,
      mTLSCertificate: community.mTLSKeyCertificateUpload,
      gcpKey: community.gcpKeyFileUpload,
    };
    formdata.append("payload", JSON.stringify(payLoad));
    return payLoad;
  }

  // updating metadata based on community while clicking on Publish under Application
  updateMetaData(communityId: any): Observable<Response<any>> {
    const headers = new HttpHeaders().set("content-type", "application/json");
    const url = `${environment.BASE_URL}/publishmetadata/${communityId}`;
    return this.http.post<Response<any>>(
      url,
      {},
      {
        headers,
      }
    );
  }

  getSupportedLanguages(
    provider: string
  ): Observable<Response<CommunityLanguage[]>> {
    const url = `${environment.BASE_URL}/translation/supportedlanguages?provider=${provider}`;
    return this.http.get<Response<CommunityLanguage[]>>(url);
  }

  getCommunitiesByCustomerID(customerID): Observable<Response<Community[]>> {
    const url = `${environment.BASE_URL}/getcommunitydetailsbycustomerid?customerId=${customerID}`;
    return this.http.get<Response<Community[]>>(url);
  }

  regenrateICSTenantId(icsTenantId): Observable<Response<any>> {
    const headers = new HttpHeaders().set("content-type", "application/json");
    const url = `${environment.BASE_URL}/regenerateicstenantid?icsTenantId=${icsTenantId}`;
    return this.http.put<Response<any>>(
      url,
      {},
      {
        headers,
      }
    );
  }

  getCommunitiesByCommunityId(communityId): Observable<Response<Community>> {
    const url = `${environment.BASE_URL}/getcommunitybyid?communityId=${communityId}`;
    return this.http.get<Response<Community>>(url);
  }

  getFacebookAuthorizationRedirectUrl(communityId): Observable<Response<any>> {
    const url = `${environment.BASE_URL}/login-dialog?communityId=${communityId}`;
    return this.http.get<Response<any>>(url);
  }

  getFacebookAccessToken(
    code: any,
    communityId: any
  ): Observable<Response<any>> {
    const url =
      `${environment.BASE_URL}/exchangetoken?code=` +
      code +
      `&communityId=` +
      communityId;
    return this.http.get<Response<any>>(url);
  }
}