import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {CustomFieldComponent} from './pages/custom-field.component';
import {CanActivatecommunityService} from '../../core/guards/community/can-activatecommunity.service';

const routes: Routes = [
  {
    path: 'ui-service/custom-field',
    component: CustomFieldComponent,
    canActivate: [CanActivatecommunityService]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomFieldRoutingModule {
}
