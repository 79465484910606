<!--Spinner-->
<div class="spinner" *ngIf="showSpinner">
  <img src="../../../../assets/images/spinner.gif" height="50px">
</div>
<div>
  <form [formGroup]="interactionStylesForm">
    <div class="row col-md-12">
      <div class="col-md-3">
        <div class="form-group">
          <label>Application<span class="label-required">*</span></label>
          <div class="form-group">
            <select id="comm-type" class="form-control" formControlName="applicationType"
                    (change)="getInteractionStyles($event, false);">
              <option [disabled]="isUpdate" value="">select</option>
              <option [disabled]="isUpdate" *ngFor="let communityType of communityTypeList" value={{communityType.communityTypeName}}>
                {{communityType.communityTypeName}}
              </option>
            </select>
            <p class="custom-error"
               *ngIf="interactionStylesForm.controls['applicationType'].hasError('required') && interactionStylesForm.controls['applicationType'].dirty">
              Select a community type</p>
          </div>
        </div>
      </div>
      <div class="col-md-3" *ngIf="interactionStylesForm.controls['applicationType'].value !== ''">
        <div class="form-group">
          <label>Customer<span class="label-required">*</span></label>
          <input *ngIf="!superAdmin" class="form-control" type="text" value="{{customerObj.name}}" disabled>
          <select *ngIf="superAdmin" id="comm-cust" class="form-control" formControlName="customer"
                  (change)="getOwners($event)">
            <option [disabled]="isUpdate" value="">select</option>
            <option [disabled]="isUpdate" *ngFor="let selectedcust of customers" value={{selectedcust.customerName}}>
              {{selectedcust.customerName}}
            </option>
          </select>
          <p class="custom-error"
             *ngIf="interactionStylesForm.controls['customer'].hasError('required') && interactionStylesForm.controls['customer'].dirty">
            Select a customer name</p>
        </div>
      </div>
      <div class="col-md-3" *ngIf="isUpdate && interactionStylesForm.controls['applicationType'].value === 'FACEBOOK'" style="padding: 25px;">
        <div class="form-group">
        <button id="comm-submit-btn" style="min-height: 35px;"
        class="tn bg-blue btn-flat margin btn-pdt"
        (click)="authorizeFaceBookUser()">
        <i class="fa fa-facebook" aria-hidden="true"></i> Authorize
        </button>
        </div>
      </div>
    </div>
    <div class="row col-md-12">
      <div class="col-md-6">
        <div *ngIf="interactionStylesList.length > 0 && interactionStylesForm.controls['applicationType'].value !== ''">
          <label>Source interaction styles</label>
          <div class="form-group">
            <ul class="interaction_styles">
              <li *ngFor="let interactionstyles of interactionStylesList">
                <label class="chkBoxContainer"><span class="inter-font">{{interactionstyles.interactionStyle}}</span>
                  <input id="comm-{{interactionstyles.interactionStyle}}" type="checkbox"
                         formControlName={{interactionstyles.interactionStyle}} (change) = "onInteractionStyleChanged($event, interactionstyles.interactionStyle)" checked="checked">
                  <span class="checkmark"></span>
                </label>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-3" *ngIf="interactionStylesForm.controls['applicationType'].value !== ''">
        <div class="form-group">
          <label>Owner<span class="label-required">*</span></label>
          <select id="comm-owner" class="form-control" formControlName="communityOwner">
            <option value="">select</option>
            <option *ngFor="let owner of owners" value={{owner.userId}}>{{getName(owner.firstName, owner.lastName)}}
            </option>
          </select>
          <p class="custom-error"
             *ngIf="interactionStylesForm.controls['communityOwner'].hasError('required') && interactionStylesForm.controls['communityOwner'].dirty">
            Please select the owner</p>
        </div>
      </div>
      <div class="col-md-3" *ngIf="showPageId">
        <div class="form-group">
          <label *ngIf="!isChannelInteractionStyles">Page ID<span class="label-required">*</span></label>
          <label *ngIf="isChannelInteractionStyles">Channel ID<span class="label-required">*</span></label>
          <input id="facebook-page-id" formControlName="pageId"
                 class="form-control"
                 matTooltip="Page ID"
                 [matTooltipPosition]="'above'"
                 aria-label="Button that displays a tooltip in various positions"
                 type="text" [attr.Placeholder]="isChannelInteractionStyles ? 'Channel ID' :'Page ID'">
          <p class="custom-error"
             *ngIf="interactionStylesForm.controls['pageId'].hasError('required') && interactionStylesForm.controls['pageId'].dirty">
            Page ID is required</p>
        </div>
      </div>
    </div>
  </form>
</div>
