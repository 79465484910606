import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SearchModule} from '../../shared/components/search/search.module';
import {MappingsService} from '../../core/services/Mappings/mappings.service';
import {ClientsService} from '../../core/services/customer/clients.service';
import {PipesModule} from '../../shared/pipes/pipes.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {PopulateModalModule} from '../../shared/components/modal/populate-modal.module';
import { ConnectorComponent } from './connector.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatInputModule} from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { ConnectorService } from '../../core/services/connector/connector.service';
import { ScheduleConfigDialog } from '../connector/pages/schedule-config.component';
import { ScheduleStatusDialog } from '../connector/schedule-status/schedule-status.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { AngularDateTimePickerModule } from 'angular2-datetimepicker';
import { MatSortModule } from '@angular/material/sort';
import { CronEditorModule } from 'cron-editor';
import {MatButtonModule} from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    ScheduleConfigDialog,
    ScheduleStatusDialog,
    ConnectorComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    PopulateModalModule,
    MatExpansionModule,
    CronEditorModule,
    MatInputModule,
    MatDividerModule,
    MatButtonToggleModule,
    MatToolbarModule,
    MatButtonModule,
    MatDialogModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    AngularDateTimePickerModule,
    SearchModule,
    MatTooltipModule
  ],
  entryComponents:[
    ScheduleConfigDialog,
    ScheduleStatusDialog
   // AngularDateTimePickerModule,
  ],
  providers: [
    MappingsService,
    ClientsService,
    ConnectorService
  ]
})
export class ConnectorModule { }
