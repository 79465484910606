<div id="myModal" class="custom-modal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" aria-label="Close" id="modal-close-btn"
                id="auto-focus-close"
                (click)="closeModal()">
          <span aria-hidden="true">×</span></button>
        <h4 class="modal-title"></h4>
      </div>
      <div class="modal-body">
        <div class="display-message">{{genericMessage === '' ? 'Internal Server error. Please try again later' : genericMessage}}</div>
        <div *ngIf="canDelete" class="delete-alert-div">
          <button id="btn-confitm-delete-btn" class="btn btn-sm btn-danger" (click)="confirmDelete(true)">Yes</button>
          <button id="btn-not-confirm-del-btn" class="btn btn-sm btn-primary" (click)="confirmDelete(false)">No</button>
        </div>
      </div>
      <div class="modal-footer">
        <button id="modal-close-btn" *ngIf="!canDelete" id="auto-focus-close" class="btn btn-primary"
                (click)="closeModal()">Ok
        </button>
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
</div>
