<div class="spinner" *ngIf="showSpinner">
  <img src="../../../../assets/images/spinner.gif" height="50px">
</div>
<div class="login_wrapper">
  <div class="row main-div login-main-sec">
    <div class="col-sm-12 login_header">
      <span *ngIf="cloudType==='gcp'; else elseBlock">
      <img class="smartconxlogin-image" src="../../../../assets/images/smartconx-logo.png">
      </span>
      <ng-template #elseBlock>
        <img class="login-image" src="../../../../assets/images/microsoft-logo.png">
      </ng-template>
    </div>
    <div class="container login_main_div">
      <div class="row">
    <form [formGroup]="loginForm" class="sign-in-page col-sm-12" (ngSubmit)="submit()">

        <div class="col-sm-12">
          <p><b>Sign In</b></p>
          <p  class="custom-error"><i *ngIf="isSessionExpiredError" class="fa fa-exclamation-circle" [innerHTML]=getSessionMessage()></i></p>
          <p  class="custom-error" *ngIf="isError"><i class="fa fa-exclamation-circle" [innerHTML]=getErrorMessage()></i></p>
          <div class="form-group">
            <input id="login-email" (focus)="isError=false" class="userame form-control" formControlName="userName" placeholder="User Email">
            <p class="custom-error" *ngIf="loginForm.controls['userName'].hasError('required') && loginForm.controls['userName'].dirty">
              <i class="fa fa-exclamation-circle"></i> User name is required
            </p>
            <p class="custom-error" *ngIf="loginForm.controls['userName'].hasError('pattern') && loginForm.controls['userName'].dirty">
              <i class="fa fa-exclamation-circle"></i> Enter a valid email
            </p>
          </div>
<!--          <div class="form-group"><input id="login-password" class="userame form-control" formControlName="password" type="password" placeholder="Password"></div>-->
<!--          <p  class="custom-error" *ngIf="loginForm.controls['password'].hasError('required') && loginForm.controls['password'].dirty">-->
<!--            <i class="fa fa-exclamation-circle"></i> Password is required</p>-->
          <br>
        </div>
        <div class="row">
<!--          <div class="col-sm-6">-->
<!--            &lt;!&ndash;<a href="" (click)="forgotPassword()" class="login-forgot-password">Forgot password?</a>&ndash;&gt;-->
<!--            <input id="login-forgot-password" type="button" class="btn btn-link login-forgot-password" value="Forgot password?"-->
<!--                   (click)="forgotPassword()">-->
<!--          </div>-->
          <div class="col-sm-6"></div>
          <div class="col-sm-6">
          <button id="login-btn" class="btn btn-primary mb-4 login_btn pull-right">Sign In</button>
          </div>
        </div>
      <div class="row">
        <div class="col-sm-12">
          <p *ngIf="isValidMail">Please wait...
            <br>You are being redirected to your organization's login page</p>
        </div>
      </div>
    </form>
      </div>
    </div>
  </div>
</div>

