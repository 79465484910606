<div class="spinner" *ngIf="showSpinner">
  <img src="../../../../assets/images/spinner.gif" height="50px">
</div>
<div class="login_wrapper">
  <div class="row main-div login-main-sec">
    <div class="col-sm-12 login_header">
      <img class="login-image" src="../../../../assets/images/microsoft-logo.png">
    </div>
    <div class="container login_main_div">
      <div class="row">
<div id="forgot-password-maindiv" >
  <div *ngIf="!isDisplyaMessage">
    <p><b>Forgot Password</b></p>
    <p class="font-weight-bold">Please enter your email address below and we will send you information to change your password.</p>
    <form [formGroup]="forgetPasswordForm" (ngSubmit)="submit()" class="sign-in-page col-sm-12">
      <div class="col-sm-12">
        <div class="form-group row">
        <label class="font-weight-bold small" style="display: none">User Email<span class="label-required">*</span></label>
        <input id="forgot-password-email" formControlName="reset_email" class="form-control" type="text" placeholder="User Email">
        <p class="custom-error" *ngIf="forgetPasswordForm.controls['reset_email'].hasError('required') && forgetPasswordForm.controls['reset_email'].dirty">
          <i class="fa fa-exclamation-circle"></i> Email is required</p>
        <p class="custom-error" *ngIf="forgetPasswordForm.controls['reset_email'].hasError('pattern') && forgetPasswordForm.controls['reset_email'].dirty">
          <i class="fa fa-exclamation-circle"></i> enter a valid email</p>
        </div>


      </div>
      <div class="row">
        <div class="col-sm-12 pull-right">
          <button id="forgot-password-submit" [disabled]="forgetPasswordForm.invalid" class="btn btn-primary mb-4 login_btn">Submit</button>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="isDisplyaMessage" class="col-sm-12 pl-5">
    <h5 style="color: red">{{message}}</h5>
    <a id="forgot-password-tologin" routerLink="" class="font-weight-bold">Back tologin</a>
  </div>
</div>
      </div>

    </div>
  </div>
</div>

