import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Customer} from '../../../shared/models/customer';
import {Response} from '../../../shared/models/response';
import {environment} from '../../../../environments/environment';
import {Authenticationprovider} from '../../../shared/models/Authenticationprovider';

@Injectable()
export class ClientsService {
  constructor(private http: HttpClient) {}

  getCustomers(
    loginUserId: string,
    pageSize: Number,
    currentPage: Number,
    activeSort: Number,
    sortOrder: Number,
    searchString: string
  ): Observable<Response<Customer[]>> {
    const url = `${environment.BASE_URL}/getallcustomersbyloginuseridbypagination?loginUserId=${loginUserId}&pageIndex=${currentPage}&pageLimit=${pageSize}&sortOrder=${sortOrder}&sortColumn=${activeSort}&filter=${searchString}`;
    // const url = `${environment.BASE_URL}/getallcustomersbyloginuserid?loginUserId=${loginUserId}`;
    return this.http.get<Response<Customer[]>>(url);
  }

  getCustomerDetails(loginUserId: string): Observable<Response<Customer[]>> {
    // const url = `${environment.BASE_URL}/getallcustomersbyloginuseridbypagination?loginUserId=${loginUserId}`;
    const url = `${environment.BASE_URL}/getallcustomersbyloginuserid?loginUserId=${loginUserId}`;
    return this.http.get<Response<Customer[]>>(url);
  }

  save(customer): Observable<Response<any>> {
    const headers = new HttpHeaders().set("content-type", "application/json");
    const domains: Array<string> = customer.domain_names.split(",");
    const domainsArray: Array<any> = [];
    domains.map((domain: string) => {
      domainsArray.push({ domainName: domain.trim() });
    });
    const save_payLoad = {
      address: customer.address.trim(),
      contact: +customer.contact,
      customerName: customer.customer_name.trim(),
      email: customer.email.trim(),
      domains: domainsArray,
      clientaccessdetail: {
        clientId: customer.aad_client_id,
        clientSecret: customer.aad_client_secrect,
        applicationId: customer.aad_tenant_id,
      },
      authenticationProvider: { authProviderId: +customer.login_type },
    };
    const url = `${environment.BASE_URL}/createcustomer`;
    return this.http.post<Response<any>>(url, save_payLoad, {
      headers,
    });
  }

  update(customer): Observable<Response<any>> {
    const headers = new HttpHeaders().set("content-type", "application/json");
    const domains: Array<string> = customer.domain_names.split(",");
    const domainsArray: Array<any> = [];
    domains.map((domain: string) => {
      domainsArray.push({ domainName: domain.trim() });
    });
    const update_payLoad = {
      customerId: customer.customer_id,
      address: customer.address.trim(),
      contact: customer.contact,
      customerName: customer.customer_name.trim(),
      email: customer.email.trim(),
      active: customer.active,
      domains: domainsArray,
      clientaccessdetail: {
        clientId: customer.aad_client_id,
        clientSecret: customer.aad_client_secrect,
        applicationId: customer.aad_tenant_id,
      },
      authenticationProvider: { authProviderId: +customer.login_type },
    };

    const url = `${environment.BASE_URL}/updatecustomer`;
    return this.http.put<Response<any>>(url, update_payLoad, {
      headers,
    });
  }

  delete(customerId: Number): Observable<Response<any>> {
    const url = `${environment.BASE_URL}/deletecustomer?customerid=${customerId}`;
    return this.http.delete<Response<any>>(url);
  }

  updateStatus(customerid: number, isActive: boolean) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    const url = `${environment.BASE_URL}/enableordisablecustomer?customerid=${customerid}&active=${isActive}`;
    return this.http.put<Response<any>>(url, {
      headers,
    });
  }

  getActiveCustomers(loginUserId: string): Observable<Response<Customer[]>> {
    const url = `${environment.BASE_URL}/getallactivecustomersbyloginuserid?loginUserId=${loginUserId}`;
    return this.http.get<Response<Customer[]>>(url);
  }

  public authemticationProviers(): Observable<
    Response<Authenticationprovider[]>
  > {
    const url = `${environment.BASE_URL}/authenticationproviders`;
    return this.http.get<Response<Authenticationprovider[]>>(url);
  }

  public validateCustomerCredentials(payload: any): Observable<any> {
    const headers = new HttpHeaders().set("content-type", "application/json");
    const url = `${environment.AAD_BASE_URL}/xcommunityaad/validatecredentials`;
    return this.http.post<Response<any>>(url, payload, {
      headers,
    });
  }
}
