<div class="spinner" *ngIf="spinner">
  <img src="../../../../assets/images/spinner.gif" height="50px">
</div>
<mat-toolbar style="background-color: #000; color: #fff;">
  Message Mappings Status
  <span style="padding-left: 70%;">
  <button mat-mini-fab style="background-color: #fff" (click)="reloadData()">
    <i class="fa fa-refresh" aria-hidden="true"></i>
    </button>
  </span>
  <button mat-mini-fab style="background-color: #fff" class="close-button" [mat-dialog-close]="true">
    <i class="fa fa-times" aria-hidden="true"></i>
  </button>
</mat-toolbar>
<div mat-dialog-content style="bottom: #000;margin: 0px;">


              <mat-table #viewMessageMappingsStatusSort="matSort" [dataSource]="dataSource" matSort matSortActive="updatedAt" matSortDirection="desc">
                <ng-container matColumnDef="sourceCommunityId">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Source Community Id </mat-header-cell>
                  <mat-cell *matCellDef="let row"> {{row.sourceCommunityId}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="sourceThreadId">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Source Thread Id </mat-header-cell>
                  <mat-cell *matCellDef="let row"> {{row.sourceThreadId}} </mat-cell>
                </ng-container>
                <!-- Name Column -->
                <ng-container matColumnDef="sourceMessageId">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Source Message Id </mat-header-cell>
                  <mat-cell *matCellDef="let row"> {{row.sourceMessageId}} </mat-cell>
                </ng-container>


                <ng-container matColumnDef="targetCommunityId">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Target Community Id </mat-header-cell>
                  <mat-cell *matCellDef="let row" style="white-space:pre-line"> {{row.targetCommunityId}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="targetThreadId">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Target Thread Id </mat-header-cell>
                  <mat-cell *matCellDef="let row" style="white-space:pre-line;"> {{row.targetThreadId}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="targetMessageId">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Target Message Id </mat-header-cell>
                  <mat-cell *matCellDef="let row"> {{row.targetMessageId}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="updatedAt">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Updated At </mat-header-cell>
                  <mat-cell *matCellDef="let row"> {{row.updatedAt | date:'dd-MM-yyyy HH:mm:ss'}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="errorDescription">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Error Description </mat-header-cell>
                  <mat-cell *matCellDef="let row"> {{row.errorDescription}} </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="messageMappingsStatusColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: messageMappingsStatusColumns;">
                </mat-row>
              </mat-table>
              <mat-paginator #viewMessageMappingsStatusPaginator="matPaginator" [pageSizeOptions]="[25, 50, 100]"></mat-paginator>
</div>
<div mat-dialog-actions>
  <button mat-button class="btn bg-blue btn-flat margin btn-pdt" style="background-color: #3c8dbc;margin-left: 30px; text-align: center;" (click)="close()" [mat-dialog-close]="true" cdkFocusInitial>Close</button>
</div>
<app-modal></app-modal>
