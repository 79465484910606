<div class="health-check-section">
  <div class="row">
    <div class="col-sm-6" (click)="getPodsDescription(pods, 'Pods')">
      <div class="section-box has-border-top-1">
        <div class="ribbon-tag-pod">
          Pods
          <i></i>
        </div>
        <div class="column">
          <span class="count"> Total </span>
          <span class="count">{{ pods.length }}</span>
        </div>
        <div class="result-health-pod">
          <span class="running-pod">Running/Succeeded: {{ runningPods.length }}</span>
          <span class="failed-pod">Failed: {{ pods.length - runningPods.length }}</span>
        </div>
      </div>
    </div>

    <div class="col-sm-6" (click)="getPodsDescription(nodes, 'Nodes')">
      <div class="section-box has-border-top-2">
        <div class="ribbon-tag-node">
          Nodes
          <i></i>
        </div>
        <div class="column">
          <span class="count"> Total </span>
          <span class="count">{{ nodes.length }}</span>
        </div>
        <div class="result-health-pod">
          <span class="running-pod">Running: {{ nodes.length }}</span>
          <span class="failed-pod">Failed: {{ nodes.length - runningNodes.length }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6">
      <div class="section-box has-border-top-3">
        <div class="ribbon-tag-activemq">
          Active MQ
          <i></i>
        </div>
        <div class="column">
          <span class="count"><i [ngClass]="
                activemqStatus === 'Running' ? 'fa fa-check' : 'fa fa-times'
              "></i></span>
        </div>
      </div>
    </div>

    <div class="col-sm-6">
      <div class="section-box has-border-top-4">
        <div class="ribbon-tag-database">
          Data Base
          <i></i>
        </div>
        <div class="column">
          <span class="count"><i [ngClass]="checkDbConnection ? 'fa fa-check' : 'fa fa-times'"></i></span>
        </div>
        <!-- <div class="result-health-pod">
            <span class="running-pod">Running: {{nodes.length}}</span>
            <span class="failed-pod">Failed: {{nodes.length - runningNodes.length}}</span>
          </div> -->
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6">
      <div class="section-box has-border-top-5">
        <div class="ribbon-tag-customerexpiration">
          Customer Token Expiration Alert<br>KHOROS ICS_ADMIN connection  Alert<br>Smartling Translation Failed Messages Alert
          <i></i>
        </div>
        <div class="columnToggle">
          <label class="switch">
            <input type="checkbox" [checked]="!toggleState" (click)="toggleCustomerExpiration()" />
            <span class="slider"></span>
          </label>
        </div>
      </div>
    </div>

    <div class="col-sm-6" (click)="getMongoDbStatus(mongoDbstatus,'MongoDbstatus')">
      <div class="section-box has-border-top-6">
        <div class="ribbon-tag-mongodb">
          Mongo DB
          <i></i>
        </div>
        <div class="column">
          <span class="count"><i [ngClass]="getMongoDbStatusIcon()"></i></span>
        </div>
        <div class="result-health-pod">
        <span class="running-pod">Active Databases: {{mongoDbActive.length}}</span>
          <span class="failed-pod">Inactive Databases: {{mongoDbstatus.length - mongoDbActive.length}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
