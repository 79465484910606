import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {SearchComponent} from '../../../shared/components/search/pages/search.component';
import {ModalComponent} from '../../../shared/components/modal/pages/modal.component';
import {Customer} from '../../../shared/models/customer';
import {Community} from '../../../shared/models/community';
import {PotentialData} from '../../../shared/models/potential-data';
import {FormGroup} from '@angular/forms';
import {Count} from '../../../shared/models/count';
import {NgSwitchCase} from '@angular/common';
import {Constants} from '../../../shared/constants/constants';

@Component({
  selector: 'app-potential-table',
  templateUrl: './potential-table.component.html',
  styleUrls: ['./potential-table.component.css']
})
export class PotentialTableComponent {

  @Input()
  set count(data: PotentialData[]) {
    if (!!data && !!data) {
      this.messages = data;
      this.searchString = this.messages[0] ? this.messages[0].searchString : Constants.NO_RESULTS_FOUND;
    }
  }

  @Input()
  communityId: number;

  @Input()
  communityName: string;

  @ViewChild(SearchComponent, {static: true}) searchComponent;
  @ViewChild(ModalComponent, {static: true}) modal;

  messages: PotentialData[] = [];
  sort = false;
  targetColumn = '';
  // communityId = null;
  searchString = '';
}
