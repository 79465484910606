import { GenerateAppComponent } from './modules/generate-app/generate-app.component';
import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './core/authentication/login/login.component';
import {LogoutComponent} from './core/authentication/logout/logout.component';
import {ForgotPasswordComponent} from './core/authentication/forgot-password/forgot-password.component';
import {InvalidLoginComponent} from './core/invalid-login/invalid-login.component';
import {AuthenticaticateComponent} from './core/authentication/authenticaticate/authenticaticate.component';
import {PowerBiReportsComponent} from './core/power-bi-reports/power-bi-reports.component';
import { CsvimportComponent } from './modules/csvimport/csvimport.component';
import { CanActivatecommunityService } from './core/guards/community/can-activatecommunity.service';
import { ConnectorComponent } from './modules/connector/connector.component';
import { ActivityLogsComponent } from './modules/activity-logs/pages/activitylogs.component';

/**
 * Removed some of the routes, because
 * those are moved to community creation
 * page as tab view
 */
export const routes: Routes = [
  {path: '', redirectTo: 'ui-service', pathMatch: 'full'},
  {
    path: 'ui-service',
    children: [
      {path: '', redirectTo: 'login', pathMatch: 'full'},
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'clients',
        loadChildren: () => import('./modules/clients/client.module').then(({ ClientModule }) => ClientModule)
       // loadChildren: './modules/clients/client.module#ClientModule',
      },
      {
        path: 'users',
        loadChildren: './modules/user/user.module#UserModule',
      },
      {
        path: 'community',
        loadChildren: './modules/community/community.module#CommunityModule'
      },
      {
        path: 'production-stage-migration',
        loadChildren: './modules/prod-to-stage/prod-to-stage.module#ProdToStageModule'
      },
      {
        path: 'csv-import',
        component: CsvimportComponent,
        canActivate: [CanActivatecommunityService]
      },
      {
        path: 'generate-app',
        component: GenerateAppComponent,
        canActivate: [CanActivatecommunityService]
      },
      {
        path: 'potenitial-data',
        loadChildren: './modules/potential-data/potential-data.module#PotentialDataModule'
      },
      {
        path: 'health-check',
        loadChildren: './modules/health-check/health-check.module#HealthCheckModule'
      },
      {
        path: 'connector',
        component: ConnectorComponent,
        canActivate: [CanActivatecommunityService]
      },
      {
        path: 'logout',
        component: LogoutComponent
      },
      {
        path: 'forgotPassword',
        component: ForgotPasswordComponent
      },
      {
        path: 'contactSuperAdmin',
        component: InvalidLoginComponent
      },
      {
        path: 'authenticate',
        component: AuthenticaticateComponent
      },
      {
        path: 'reports',
        component: PowerBiReportsComponent,
        canActivate: [CanActivatecommunityService]
      },
      {
        path: 'activitylogs',
        component: ActivityLogsComponent
      },

    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
