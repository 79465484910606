import { MatDialogModule } from '@angular/material/dialog';
import { HealthCheckModule } from './modules/health-check/health-check.module';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {routes} from './app-routing.module';
import { UserIdleModule } from 'angular-user-idle';
import {CanActivateCustomerService} from './core/guards/customer/can-activate-customer.service';
import {CanActivatecommunityService} from './core/guards/community/can-activatecommunity.service';
import {LoginComponent} from './core/authentication/login/login.component';
import {LogoutComponent} from './core/authentication/logout/logout.component';
import {LoginService} from './core/services/login/login.service';
import {Okta} from './core/services/okta/okta.service';
import {ClientModule} from './modules/clients/client.module';
import {UserModule} from './modules/user/user.module';
import {ActivateAccountModule} from './modules/activate-account/activate-account.module';
import {CommunityModule} from './modules/community/community.module';
import {BoardMappingModule} from './modules/board-mapping/board-mapping.module';
import {CommunityMappingModule} from './modules/community-mapping/community-maaping.module';
import {MigrationModule} from './modules/migration/migration.module';
import {ProdToStageModule} from './modules/prod-to-stage/prod-to-stage.module';
import {NavigationModule} from './core/navigation/navigation.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {ForgotPasswordComponent} from './core/authentication/forgot-password/forgot-password.component';
import {InvalidLoginComponent} from './core/invalid-login/invalid-login.component';
import {AuthenticaticateComponent} from './core/authentication/authenticaticate/authenticaticate.component';
import {CustomFieldModule} from './modules/custom-field/custom-field.module';
import {PotentialDataModule} from './modules/potential-data/potential-data.module';
import { PowerBiReportsComponent } from './core/power-bi-reports/power-bi-reports.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthenticationTokenInterceptor } from './core/authentication/interceptor/AuthenticationTokenInterceptor';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CSVImportModule } from './modules/csvimport/csvimport.module';
import { environment } from './../environments/environment';
import { ConfirmationDialogComponent } from './shared/confirmationDialog/confirmationdialog.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ConnectorModule } from './modules/connector/connector.module';
import { AngularDateTimePickerModule } from 'angular2-datetimepicker';
//import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { GenerateAppModule } from './modules/generate-app/generate-app.module';
import { ActivityLogsComponent } from './modules/activity-logs/pages/activitylogs.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatDividerModule } from '@angular/material/divider';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    LoginComponent,
    LogoutComponent,
    AppComponent,
    ForgotPasswordComponent,
    InvalidLoginComponent,
    AuthenticaticateComponent,
    PowerBiReportsComponent,
    ConfirmationDialogComponent,
    ActivityLogsComponent,

  ],
  imports: [
    HttpClientModule,
    RouterModule.forRoot(routes),
    UserIdleModule.forRoot({idle:environment.IDLE_TIMEOUT_IN_SECS, timeout:300, ping:environment.PING_IN_SECONDS}),
    NavigationModule,
    ClientModule,
    UserModule,
    CommunityModule,
    CSVImportModule,
    CommunityMappingModule,
    BoardMappingModule,
    GenerateAppModule,
    MigrationModule,
    ProdToStageModule,
    ActivateAccountModule,
    CustomFieldModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    PotentialDataModule,
    HealthCheckModule,
    ConnectorModule,
    MatDialogModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatIconModule,
    BrowserModule,
    HttpClientModule,
    MatAutocompleteModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDividerModule,
    MatFormFieldModule,
    MatTooltipModule,
  ],
  providers: [
    CanActivateCustomerService,
    CanActivatecommunityService,
    LoginService,
    Okta,
    { provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationTokenInterceptor,
      multi: true
    }
  ],
  entryComponents: [
    ConfirmationDialogComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
