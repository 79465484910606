import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PodsModel } from './../../../shared/models/Pods';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { DialogData } from '../pages/health-check/health-check.component';

@Component({
  selector: 'app-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.css']
})
export class DescriptionComponent implements OnInit {

  pods: PodsModel[] = [];
  name: string;

  constructor(
    public dialogRef: MatDialogRef<DescriptionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
      this.pods = data.content;
      this.name = data.name;
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
  }
}
