import {Component, EventEmitter, NgZone, OnInit, Output, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
//import {el} from '@angular/platform-browser/testing';
import { HealthCheckService } from '../services/health-check/health-check.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  @Output() logoutEvent = new EventEmitter();
  @Output() toggleEvent = new EventEmitter();

  user: any;
  navigateTo: string;
  version: string = undefined;
  public cloudType = environment.CLOUD_TYPE;

  constructor(private router: Router,
    private healthCheckService: HealthCheckService
    ) {
  }

  ngOnInit() {
    const isSuperAdmin = localStorage.getItem('isSuperAdmin');
    if (isSuperAdmin !== null) {
      if (localStorage.getItem('version') === null) {
        this.getVersion();
      } else {
        this.version = localStorage.getItem('version');
      }
      if (isSuperAdmin === 'yes') {
        this.navigateTo = 'ui-service/clients';
      } else {
        this.navigateTo = 'ui-service/community';

      }
    } else {
      this.navigateTo = 'ui-service/login';
    }
  }

  toggleNavBar() {
    this.toggleEvent.emit();
  }

  logout() {
    this.logoutEvent.emit();
  }

  getVersion() {
    this.healthCheckService.getVersion()
    .subscribe(res => {
      this.version = res.response.message;
      localStorage.setItem("version", this.version);
    },
    error => {
      console.log(error);
      this.version = '0.0';
    });
  }

}
