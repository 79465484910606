import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Constants} from '../../../shared/constants/constants';
import {CommunityService} from '../../../core/services/community/community.service';
import {InteractionStyles} from '../../../shared/models/interaction-styles';
import {CommunityTypeDef} from '../../../shared/models/community-type-def';
import {Customer} from '../../../shared/models/customer';
import {User} from '../../../shared/models/user';
import {ClientsService} from '../../../core/services/customer/clients.service';
import {Community} from '../../../shared/models/community';
//import {el} from '@angular/platform-browser/testing/src/browser_util';
import {CommunitiesComponent} from '../pages/communities.component';
import { ConfirmationDialogComponent } from '../../../shared/confirmationDialog/confirmationdialog.component';
import { MatDialog } from '@angular/material/dialog';
import {Router, ActivatedRoute} from '@angular/router';
import {
  MatSnackBar, MatSnackBarConfig
} from '@angular/material/snack-bar';

@Component({
  selector: 'app-interaction-styles',
  templateUrl: './interaction-styles.component.html',
  styleUrls: ['./interaction-styles.component.css']
})
export class InteractionStylesComponent implements OnInit {
  @Output() showMessage = new EventEmitter();
  @Output() emitData = new EventEmitter();
  @Output() emitCommunityTypeName = new EventEmitter();
  @Output() emitInteractionStyles = new EventEmitter();
  @Output() emitValidation = new EventEmitter();
  @Input() customers: Customer[];
  interactionStylesForm: FormGroup;
  showSpinner = false;
  selectedCommunityId: any;
  selectedCommunityName: any;
  superAdmin = localStorage.getItem('isSuperAdmin') === 'yes' ? true : false;
  isDelete: boolean;
  messageToDisplay: string;
  showPageId: boolean = false;
  isChannelInteractionStyles: boolean = false;
  customerObj = JSON.parse(localStorage.getItem('customerObj'));
  interactionStylesList: InteractionStyles[] = [];
  communityTypeList: CommunityTypeDef[] = [];
  //commCust: Customer[] = [];
  owners: User[] = [];
  communityType: string;
  isUpdate = false;
  config = new MatSnackBarConfig();
  constructor(private _fb: FormBuilder,
              private communityService: CommunityService,
              private customerService: ClientsService, private snackBar: MatSnackBar, private dialog: MatDialog, private activatedRoute: ActivatedRoute) {
    this.buildForm();
  }

  ngOnInit() {
    this.config.verticalPosition = 'top';
    this.config.horizontalPosition = 'end';
    this.config.duration = 5000;
    this.showPageId = false;
    this.config.panelClass = ['blue-snackbar'];
    this.fetchCommunityTypes();
    if (this.superAdmin) {
     // this.fetchCustomers();
    } else {
      const id = this.customerObj.id;
      this.interactionStylesForm.controls['customer'].setValue(this.customerObj.name);
      this.getOwners({target: {value: id}});
    }
    const redirectUrl = window.location.href;
    if (redirectUrl.indexOf('code') > 0) {
      this.activatedRoute.queryParams.subscribe(params => {
        const code = params['code'];
        let state = params['state'].split('#')[0];
        this.selectedCommunityId = state.split('_')[0];
        this.selectedCommunityName = state.split('_')[1];
        if (code !== undefined && state !== undefined) {
          this.validateFacebookAccess(code, this.selectedCommunityId);
        }
      });
  }
  }

  buildForm() {
    this.interactionStylesForm = this._fb.group({
      applicationType: new FormControl('', [Validators.required]),
      customer: new FormControl('', [Validators.required]),
      communityOwner: new FormControl('', [Validators.required]),
      pageId: new FormControl('',)
    });
  }

  /**
   * Get interaction styles
   * based on community type
   * reset the form when
   * changing the community
   * @param event
   */
  getInteractionStyles(event, isEdit: boolean) {
    if (event.target.value !== '') {
      this.communityType = event.target.value;
      this.showSpinner = true;
      const communityType = event.target.value; //=== Constants.COMMUNITY_TYPE_LITHIUM ? Constants.LITHIUM : event.target.value;
      // if (communityType === 'SFDC') {
      //   this.emitValidation.emit(true);
      // } else {
      //   this.emitValidation.emit(false);
      // }
      this.emitValidation.emit(false);
      this.emitCommunityTypeName.emit(this.communityType);
      this.communityService.getInteractionStyles(communityType)
        .subscribe(res => {
          this.showSpinner = false;
          this.interactionStylesList = res.response.data;
          this.emitInteractionStyles.emit(this.interactionStylesList);
          this.addInteractionStylesFormCOntrols(this.interactionStylesList);
          const communityTypeInfo = communityType; // === Constants.LITHIUM ? Constants.COMMUNITY_TYPE_LITHIUM : communityType;
          if (isEdit) {
            this.interactionStylesForm.controls['applicationType'].setValue(communityTypeInfo);
            // this.interactionStylesForm.get('applicationType').disable();
          } else {
            this.interactionStylesForm.reset();
            this.interactionStylesForm.controls['applicationType'].setValue(communityTypeInfo);
            if (this.superAdmin) {
            this.interactionStylesForm.controls['customer'].setValue('');
            } else {
              this.interactionStylesForm.controls['customer'].setValue(this.customerObj.name);
            }
            this.interactionStylesForm.controls['communityOwner'].setValue('');
            this.interactionStylesForm.controls['pageId'].setValue('');
          }
        }, error => {
          this.showSpinner = false;
          if (error.status === 401 && error.error.response.status === 'error' && error.error.response.message === 'UnAuthorized Access Token Expired') {
            return this.snackBar.open(Constants.SESSION_EXPIRED, 'Close', this.config);
           } else {
          this.showMessage.emit(Constants.SERVER_ERROR);
           } 
        });
    }
  }

  /**
   * Dynamically render interaction styles form fields based on community type
   * @param interactionStyles
   */
  addInteractionStylesFormCOntrols(interactionStyles: InteractionStyles[]) {
    interactionStyles.map(interactionStyle => {
      this.interactionStylesForm.addControl(interactionStyle.interactionStyle, new FormControl(false));
    });
  }

  getName(fname, lname): string {
    const firstName = (fname === '' || fname === undefined) ? '' : fname;
    const lastName = (lname === '' || lname === undefined) ? '' : lname;
    return (firstName + ' ' + lastName);
  }

  onInteractionStyleChanged(event: any, interactionStyle: any) {
    if (interactionStyle === 'group' && event.target.checked) {
      this.showPageId = true;
      this.isChannelInteractionStyles =false;
    } else if (interactionStyle === 'group' && !event.target.checked) {
      this.showPageId = false;
      this.isChannelInteractionStyles =false;
    }if (interactionStyle === 'channel' && event.target.checked) {
      this.showPageId = true;
      this.isChannelInteractionStyles =true;
    } else if (interactionStyle === 'channel' && !event.target.checked) {
      this.showPageId = false;
      this.isChannelInteractionStyles =false;
    }
  }
  /**
   * fetch users dropdown list based on community type
   * @param event
   */
  getOwners(event) {
    let customerId = null;
    if (event.target.value !== '') {
      if (this.superAdmin) {
        customerId = this.customers.filter((customer: Customer) => customer.customerName === event.target.value)[0].customerId;
      } else {
        customerId = +event.target.value;
      }
      this.showSpinner = true;
      this.communityService.getOwners(customerId)
        .subscribe(res => {
          this.showSpinner = false;
          this.owners = res.response.data;
          const data = {
            'communityTypes': this.communityTypeList,
            'owners': this.owners,
          };
          this.emitData.emit(data);
          if (this.owners.length === 0) {
            this.showMessage.emit('There are no owners for the customer');
            return;
          }
        }, error => {
          this.showSpinner = false;
          if (error.status === 401 && error.error.response.status === 'error' && error.error.response.message === 'UnAuthorized Access Token Expired') {
            return this.snackBar.open(Constants.SESSION_EXPIRED, 'Close', this.config);
           } else {
          this.showMessage.emit(Constants.SERVER_ERROR);
           }
        });
    } else {
      this.owners = [];
    }
  }

  /**
   * fetch Community types
   */
  fetchCommunityTypes() {
    this.showSpinner = true;
    this.communityService.getCommunityTypes()
      .subscribe(res => {
        this.showSpinner = false;
        this.communityTypeList = res.response.data;
     //   this.communityTypeList.map((communityTyeDef: CommunityTypeDef) => {
      /*    if (communityTyeDef.communityTypeName === Constants.LITHIUM) {
            communityTyeDef.communityTypeName = Constants.COMMUNITY_TYPE_LITHIUM;
          } */
     //   });
      }, error => {
        this.showSpinner = false;
        if (error.status === 401 && error.error.response.status === 'error' && error.error.response.message === 'UnAuthorized Access Token Expired') {
          return this.snackBar.open(Constants.SESSION_EXPIRED, 'Close', this.config);
         } else {
        this.showMessage.emit(Constants.SERVER_ERROR);
         }
      });
  }
/*
  fetchCustomers() {
    const loginUserId = localStorage.getItem('loginUserId');
    this.showSpinner = true;
    this.customerService.getActiveCustomers(loginUserId)
      .subscribe(res => {
          this.showSpinner = false;
          this.commCust = res.response.data;
        },
        error => {
          this.showSpinner = false;
          if (error.status === 401 && error.error.response.status === 'error' && error.error.response.message === 'UnAuthorized Access Token Expired') {
            return this.snackBar.open(Constants.SESSION_EXPIRED, 'Close', this.config);
           } else {
          this.showMessage.emit(Constants.SERVER_ERROR);
           }
        });
  }
*/
  reset() {
    this.interactionStylesForm.reset();
  }

  setDefaultValuesForCormControls() {
    this.interactionStylesList.map(obj => {
      if (this.interactionStylesForm.controls[obj.interactionStyle] !== undefined) {
      this.interactionStylesForm.controls[obj.interactionStyle].setValue(false);
      } else { 
        this.interactionStylesForm.reset();
      } 
    });
  }

  private setDataToForm(communityObject: Community) {
    const communityType = communityObject.communityType.communityTypeName; // === Constants.LITHIUM ? Constants.COMMUNITY_TYPE_LITHIUM
    //  : communityObject.communityType.communityTypeName;
    this.interactionStylesForm.controls['applicationType'].setValue(communityType);
    this.interactionStylesForm.controls['customer'].setValue(communityObject.customer.customerName);
    this.interactionStylesForm.controls['communityOwner'].setValue(communityObject.user.userId);
    if (communityObject.pageId !== undefined && communityObject.pageId !== '') {
      this.interactionStylesForm.controls['pageId'].setValue(communityObject.pageId);
    }
    // this.interactionStylesForm.get('applicationType').disable();
    // this.interactionStylesForm.get('customer').disable();
    if (this.superAdmin) {
      this.getOwners({target: {value: communityObject.customer.customerName}});
    } else {
      this.getOwners({target: {value: communityObject.customer.customerId}});
    }
    this.getInteractionStyles({target: {value: communityObject.communityType.communityTypeName}}, true);
    if (communityObject.interactionStyleMappings.length > 0) {
      communityObject.interactionStyleMappings.map(obj => {
        this.interactionStylesForm.addControl(obj.interactionStyle.interactionStyle, new FormControl(obj.active));
        this.interactionStylesForm.controls[obj.interactionStyle.interactionStyle].setValue(obj.active);
       
      });
      communityObject.interactionStyleMappings.map(obj => {
        if (obj.interactionStyle.interactionStyle === 'group' && obj.active) {
          this.showPageId = true;
        }
      });
    } else if (communityObject.communityType.communityTypeName !== Constants.COMMUNITY_TYPE_COVEO) {
      this.setDefaultValuesForCormControls();
    }
  }

  edit(community: Community) {
    this.selectedCommunityId = community.communityId;
    this.selectedCommunityName = community.communityName;
    this.buildForm();
    this.setDataToForm(community);
    this.isUpdate = true;
  }

  enableFormFields() {
    this.interactionStylesList = [];
    this.isUpdate = false;
    this.interactionStylesForm.reset();
    this.interactionStylesForm.controls['applicationType'].setValue('');
    // this.interactionStylesForm.controls['applicationType'].enable();
    this.interactionStylesForm.controls['customer'].setValue('');
    // this.interactionStylesForm.controls['customer'].enable();
  }

  authorizeFaceBookUser() {
    const communityId = this.selectedCommunityId;
    this.showSpinner = true;
    this.communityService.getFacebookAuthorizationRedirectUrl(communityId).subscribe(res => {
        this.showSpinner = false;
        if (res.response.code === 200) {
        window.location.href = res.response.message;
        } else {
          return this.snackBar.open(Constants.FAILED_TO_AUTHORIZE_FB_USER, 'Close', this.config);    
        }
    }, error => {
      this.showSpinner = false;
      return this.snackBar.open(error.error.response.message, 'Close', this.config);
    });
  }

  validateFacebookAccess(code: any, communityId: any) {
    
    this.showSpinner = true;
    this.communityService.getFacebookAccessToken(code, communityId).subscribe(res => {
    this.showSpinner = false;
        if (res.response.code === 200) {
          this.displayStatus(Constants.FB_AUTHENTICATION_SUCCESS + this.selectedCommunityName);
        } else {
          this.displayStatus(Constants.FAILED_TO_AUTHORIZE_FB_USER + this.selectedCommunityName);              
        }
    }, error => {
      this.showSpinner = false;
      return this.snackBar.open(error.error.response.message, 'Close', this.config);
    });
  }

  displayStatus(status) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      height:'200px',
      data: { 
        message: status,
        title: 'Status',
        type: Constants.COMMUNITY_TYPE_FACEBOOK
      }
    });
  }

}
