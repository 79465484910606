import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';

@Injectable()
export class CanActivatecommunityService implements CanActivate {

  userRole: string;

  constructor(private router: Router) {
  }

  canActivate(): boolean {
    let canNavigate = false;
    this.userRole = localStorage.getItem('isSuperAdmin');
    if (this.userRole !== null) {
      canNavigate = true;
    } else {
      this.router.navigate(['ui-service/login']);
      canNavigate = false;
    }
    return canNavigate;
  }
}
