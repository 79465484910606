<div class="spinner" *ngIf="spinner">
  <img src="../../../../assets/images/spinner.gif" height="50px" />
</div>
<div class="content-header">
  <h1>CSV Import</h1>
  <br />
  <mat-divider></mat-divider>
</div>

<div class="box">
  <form [formGroup]="CSVImportForm" (ngSubmit)="submit()">
    <div>
      <br />
      <div class="container">
        <div class="row form-group">
          <div class="col-xs-3 form-inline">
            <label for="exampleInputAmount" class="label-width"
              >Customer: &nbsp;&nbsp;&nbsp;&nbsp;
            </label>
            <div class="input-group col-xs-3">
              <div *ngIf="!superAdmin" class="admin-label text-nowrap">
                <label class="font-weight-bold low">{{
                  customerObj.name
                }}</label>
              </div>
              <select
                *ngIf="superAdmin"
                id="csv-imp-cust"
                class="form-control select-minwidth"
                required
                formControlName="customer_id"
                (change)="fetchCommunities($event.target.value)"
              >
                <option value="">select</option>
                <option
                  *ngFor="let selectedcust of customers"
                  value="{{ selectedcust.customerId }}"
                >
                  {{ selectedcust.customerName }}
                </option>
              </select>
              <p
                class="custom-error text-nowrap"
                *ngIf="
                  CSVImportForm.controls['customer_id'].hasError('required') &&
                  CSVImportForm.controls['customer_id'].dirty
                "
              >
                Please select a customer
              </p>
            </div>
          </div>
          <div
            class="col-xs-3 form-inline"
            style="padding-left: 20px !important"
          >
            <label for="exampleInputAmount2"
              >Community: &nbsp;&nbsp;&nbsp;&nbsp;
            </label>
            <div class="input-group col-xs-3">
              <select
                id="csv-imp-community"
                class="form-control form-inline select-minwidth"
                required
                formControlName="community_id"
              >
                <option value="">select</option>
                <option
                  *ngFor="let community of communities"
                  value="{{ community.communityId }}"
                >
                  {{ community.communityName }}
                </option>
              </select>
              <p
                class="custom-error text-nowrap"
                *ngIf="
                  CSVImportForm.controls['community_id'].hasError('required') &&
                  CSVImportForm.controls['community_id'].dirty
                "
              >
                Select a community
              </p>
            </div>
          </div>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <div class="col-xs-3 form-inline">
            <label
              for="exampleInputAmount"
              class="file-upload"
              style="padding-left: 20px !important"
              >Upload File: &nbsp;&nbsp;&nbsp;&nbsp;
            </label>
            <div class="input-group col-xs-3">
              <div class="custom-file">
                <input
                  type="file"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  class="form-control custom-file-input"
                  multiple
                  formControlName="importFile"
                  id="importFile"
                  (change)="onFileChange($event.target.files)"
                />

                <label
                  id="labelImports"
                  class="custom-file-label"
                  #labelImport
                  for="importFile"
                  ><i class="fa fa-upload"></i> &nbsp; &nbsp; &nbsp; Choose
                  file</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <!-- <div class="row">
        <div class="col-md-6 form-inline">
        
         
          <label for="exampleInputAmount" class="file-upload"><strong>Upload File</strong> </label>
          <div class="input-group col-md-8">
            <div class="custom-file select-minwidth">
              <input type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" class="custom-file-input" multiple formControlName="importFile" id="importFile" (change)="onFileChange($event.target.files)">
              
              <label class="custom-file-label" #labelImport for="importFile"><i class="fa fa-upload"></i>
                &nbsp; &nbsp; &nbsp; Choose file</label>
            </div>
          </div>
    </div>
      </div> -->
      <div class="row mt-1">
        <div class="col-md-12" style="padding-left: 20px !important">
          <button
            id="submit-csv-details"
            [disabled]="!this.CSVImportForm.valid"
            class="btn bg-blue btn-flat margin btn-pdt"
          >
            Import
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<app-modal></app-modal>
