import {Component, OnInit} from '@angular/core';
import {LoginService} from '../services/login/login.service';
import {Constants} from '../../shared/constants/constants';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-invalid-login',
  templateUrl: './invalid-login.component.html',
  styleUrls: ['./invalid-login.component.css']
})
export class InvalidLoginComponent implements OnInit {

  public message = '';

  constructor(
    private loginService: LoginService,
    private sanitization: DomSanitizer
  ) {
  }

  ngOnInit() {
    this.message = Constants.ROLE_INVALID_CONTACT_SUPERADMIN
      .concat(Constants.CONTACT_SUPER_ADMIN)
      .concat(' for access.');
  }

  getMessage() {
    return this.sanitization.bypassSecurityTrustHtml(this.message);
  }

  backToLogin() {
    this.loginService.logoutFromMicrosoft()
      .subscribe(res => {
        if (res.response.code === 200) {
          this.message = Constants.LOG_OUT_FROM_MICROSOFT;
          window.location.href = res.response.data.url;
        }
      });
  }

}
