import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Route, Router} from '@angular/router';
import { UserIdleService } from 'angular-user-idle';
import { LoginService } from './core/services/login/login.service';
import { Constants } from '././shared/constants/constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  user;
  constructor(private activatedRoute: ActivatedRoute, private userIdle: UserIdleService, private loginService: LoginService) {
  }

  ngOnInit() {
   //Start watching for user inactivity.	
   this.userIdle.startWatching();    
    
	// Start watching when user idle is starting and reset if user action is there.
	this.userIdle.onTimerStart().subscribe(count=> {
	var eventList= ["click", "mouseover", "keydown", "DOMMouseScroll", "mousewheel",
	"mousedown", "touchstart", "touchmove", "scroll", "keyup"];
	for (let event of eventList) {
	document.body.addEventListener(event, () =>this.userIdle.resetTimer());
	}
	});
	// Start watch when time is up.
	this.userIdle.onTimeout().subscribe(() => {
	this.loginService.logoutFromMicrosoft()
	.subscribe(res => {
	if (res.response.code === 200 && res.response.status === 'success') {
		localStorage.clear(); 
	 if (sessionStorage.getItem('authToken') !== null) {
		sessionStorage.removeItem('authToken');
		}
	localStorage.setItem('sessionExpired', 'Session Expired, Please Login Again');
    window.location.href = res.response.data.url;
    }
  }); 
	localStorage.setItem("message", Constants.SESSION_EXPIRED);
  });
 }
}
