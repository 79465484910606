<div class="spinner" *ngIf="spinner">
  <img src="../../../../assets/images/spinner.gif" height="50px">
</div>
<div class="content-header">
  <h1>Connectors</h1><br>
  <mat-divider></mat-divider>
</div>
<br>

<mat-accordion>
  <mat-expansion-panel #connectorMapping (closed)="reset()">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Connector Mapping
      </mat-panel-title>
      <mat-panel-description>
        Maps the source application with target application
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div class="box">

      <form [formGroup]="ConnectorForm" (ngSubmit)="submit()">
        <div>
          <br>
          <div class="container">
            <div class="connectoMapBlock">
              <div class="row form-group col-xs-4 form-inline">
                <div class="connectoMappingBlocks">
                  <label class="label-width label-allign">Customer:</label>
                  <div *ngIf="!superAdmin" class="form-control boards-max-width">{{customerObj.name}}</div>
                  <div *ngIf="superAdmin" class="select-container select-right">
                    <select id="comm-map-cust" class="form-control boards-max-width" required formControlName="customer"
                      (change)="fetchCommunitiesOnCustomerChange($event)">
                      <option value=''>select</option>
                      <option *ngFor="let selectedcust of customers" value={{selectedcust.customerId}}>
                        {{selectedcust.customerName}}
                      </option>
                    </select>
                    <p class="custom-error" *ngIf="ConnectorForm.controls['customer'].hasError('required') && ConnectorForm.controls['customer'].dirty">
                      Please select a customer
                    </p>
                  </div>
                </div>
              </div>

              <div class="row form-group col-xs-4 form-inline">
                <div class="connectoMappingBlocks">
                <label for="sourceCommunity" class="label-width label-allign">Source Community:</label>
                <div class="select-container select-right">
                  <select id="connector-source-community" class="form-control boards-max-width" required
                    [(ngModel)]="sourceCommunity" [ngModelOptions]="{standalone: true}"
                    (change)="onSourceCommunityChange($event)">
                    <option value="">select</option>
                    <option *ngFor="let selectedcomt of sourceCommunities" value={{selectedcomt.communityId}}>
                      {{selectedcomt.communityName}}
                    </option>
                  </select>
                  <p class="custom-error text-nowrap"
                    *ngIf="ConnectorForm.controls['source_community_id'].hasError('required') && ConnectorForm.controls['source_community_id'].dirty">
                    Please select a source community
                  </p>
                </div>
                </div>
              </div>

              <div class="row form-group col-xs-4 form-inline">
                <div class="connectoMappingBlocks">
                <label for="targetCommunity" class="label-width label-allign">Target Community:</label>
                <div class="select-container select-right">
                  <select id="connector-target-community" class="form-control boards-max-width" required
                    [(ngModel)]="targetCommunity" [ngModelOptions]="{standalone: true}"
                    (change)="onTargetCommunityChange($event)">
                    <option value="">select</option>
                    <option *ngFor="let community of targetCommunities" value={{community.communityId}}>
                      {{community.communityName}}
                    </option>
                  </select>
                  <p class="custom-error text-nowrap"
                    *ngIf="ConnectorForm.controls['target_community_id'].hasError('required') && ConnectorForm.controls['target_community_id'].dirty">
                    Select a target community
                  </p>
                </div>
                </div>
              </div>

              <div class="row form-group col-xs-4 form-inline">
               <div class="connectoMappingBlocks">
                 <label class="label-width label-allign">Entity Type:   &nbsp;&nbsp;&nbsp;</label>
                 <select  id="comm-map-entity" class="form-control boards-max-width" formControlName="entityType" >
                   <option value=''>select</option>
                   <option *ngFor="let selectedEntity of entities" value={{selectedEntity.id}} >
                    {{selectedEntity.name}}
                   </option>
                 </select>
                </div>
              </div>

              <div class="row form-group form-inline" *ngIf="ConnectorForm.controls['entityType']?.value?.includes('data-migration')" >
                <div class="col-xs-4">
                  <label class="label-width label-allign">Object Type:   &nbsp;&nbsp;&nbsp;</label>
                  <select  id="comm-map-entity" class="form-control boards-max-width" formControlName="objectType" >
                    <option value=''>select</option>
                    <option *ngFor="let selectedObject of objects" value={{selectedObject.id}} >
                     {{selectedObject.name}}
                    </option>
                  </select>
                </div>

                <div class="col-xs-4">
                  <input formControlName="messagesOrUserIds" type="text" name="object-ids" placeholder="Enter comma seperated values" pattern="^\d+(\s*,\s*\d+)*$">
                </div>
                <div class="pl-5">
                  <input type="button" value="Submit" (click)="submitIds()">
                </div>
              </div>

              <div *ngIf="ConnectorForm.controls['entityType']?.value ===  'bulkdata'">
                <label class="chkBoxContainer">
                  <span class="inter-font">Syndicate Solution Message</span>
                  <input
                    id="syndication-solution-message"
                    type="checkbox"
                    [(ngModel)]="syndicateSolutionMessage"
                    [ngModelOptions]="{ standalone: true }"
                  >
                  <span class="checkmark"></span>
                </label><br>
                <h6 style="font-weight: bold;">Bulkdata action keys</h6>
                <div class="checkbox-container">
                  <div class="checkbox-row">
                    <label class="chkBoxContainer">
                      <span class="inter-font">Message Create</span>
                      <input
                        id="message-create"
                        type="checkbox"
                        [(ngModel)]="messageCreate"
                        [ngModelOptions]="{ standalone: true }"
                      >
                      <span class="checkmark"></span>
                    </label>
                    <label class="chkBoxContainer">
                      <span class="inter-font">Solution Update</span>
                      <input
                        id="solution-update"
                        type="checkbox"
                        [(ngModel)]="solutionUpdate"
                        [ngModelOptions]="{ standalone: true }"
                      >
                      <span class="checkmark"></span>
                    </label>
                
                
                    <label class="chkBoxContainer">
                      <span class="inter-font">SPAM</span>
                      <input
                        id="spam"
                        type="checkbox"
                        [(ngModel)]="spam"
                        [ngModelOptions]="{ standalone: true }"
                      >
                      <span class="checkmark"></span>
                    </label>
                  </div>
                
                  <div class="checkbox-row">
                    <label class="chkBoxContainer">
                      <span class="inter-font">Message Update</span>
                      <input
                        id="message-update"
                        type="checkbox"
                        [(ngModel)]="messageUpdate"
                        [ngModelOptions]="{ standalone: true }"
                      >
                      <span class="checkmark"></span>
                    </label>
                    <label class="chkBoxContainer">
                      <span class="inter-font">Message Delete</span>
                      <input
                        id="message-delete"
                        type="checkbox"
                        [(ngModel)]="messageDelete"
                        [ngModelOptions]="{ standalone: true }"
                      >
                      <span class="checkmark"></span>
                    </label>
                
                
                    <label class="chkBoxContainer">
                      <span class="inter-font">HAM</span>
                      <input
                        id="ham"
                        type="checkbox"
                        [(ngModel)]="ham"
                        [ngModelOptions]="{ standalone: true }"
                      >
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
                
                
                
              </div>
              

              <!-- For file upload functionality -->
              <div *ngIf="ConnectorForm.controls['entityType']?.value === 'data-migration'">
                <div class="csvFileUpload">
                    <div class="col-xs-3 form-inline">
                    <label
                      class="file-upload"
                      style="padding-left: 20px !important"
                      >Upload File: &nbsp;&nbsp;&nbsp;&nbsp;
                    </label>
                    <div class="input-group col-xs-3">
                      <div class="custom-file">
                        <input
                          type="file"
                          accept=".csv"
                          class="form-control custom-file-input"
                          id="importFile"
                          multiple
                          formControlName="importFile"
                          (change)="uploadFile($event)"
                        />
                        <label
                          class="custom-file-label"
                          id="csvImportBoards"
                          #csvImportBoard
                          for="importFile"
                          ><i class="fa fa-upload"></i> &nbsp; &nbsp; &nbsp; Choose
                          file</label
                        >
                      </div>
                    </div>
                  </div>
                  <button
                    id="submit"
                    class="btn-submit"
                    type="button"
                    (click)="submitFile()"
                  >
                    Upload
                  </button>
                  <button
                    id="download"
                    class="download"
                    type="button"
                    title="click here to download the CSV file"
                    (click)="csvDownlaod($event)"
                  >
                    <i class="fa fa-download" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              
            </div>
            <div *ngIf="ConnectorForm.controls['entityType']?.value === 'messages'">
              <div class="row col-md-12 pl-3">
                  <div class="col-md-12 message-container" style="margin-bottom: 15px !important;">
                    <div class="private-message-checkbox-container">
                      <label class="chkBoxContainer" style="margin: 0 0 5px 0 !important;">
                          <span class="inter-font">Private Messages</span>
                          <input id="comm-private-messages" type="checkbox" formControlName="privateMessagesEnabled" (change)="changedPrivateMessagesEnabled($event)">
                          <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>  
              </div>
            </div>
            <div class="col-md-3"
              *ngIf="ConnectorForm.controls['entityType']?.value === 'userCleanup'">
              <div class="form-group">
                <label>Communication Email<span class="label-required">*</span></label>
                <input id="communicationemail" class="form-control" type="text"
                  placeholder="Communication Email" name="userCleanup" formControlName="communicationEmail" >
                <p class="custom-error"
                  *ngIf="ConnectorForm.controls['entityType'].hasError('required') && ConnectorForm.controls['entityType'].dirty">
                  Communication Email is required.</p>
              </div>
            </div>
            <div class="col-md-3"
              *ngIf="ConnectorForm.controls['entityType']?.value === 'userCleanup'">
              <div class="form-group">
                <label>Excluding Roles<span class="label-required">*</span></label>
                <input id="excludedRoles" class="form-control" type="text"
                  placeholder="Excluding Roles" name="userCleanup" formControlName="excludedRoles">
                <p class="custom-error"
                  *ngIf="ConnectorForm.controls['entityType'].hasError('required') && ConnectorForm.controls['entityType'].dirty">
                  Excluding roles is required.</p>
              </div>
            </div>
            <div class="col-md-3"
            *ngIf="ConnectorForm.controls['entityType']?.value === 'userCleanup'">
            <div class="form-group">
              <label>ExcludeEmail Domains<span class="label-required">*</span></label>
              <input id="excludeEmailDomains" class="form-control" type="text"
                placeholder="ExcludeEmail Domains" name="userCleanup" formControlName="excludeEmailDomains" >
              <p class="custom-error"
                *ngIf="ConnectorForm.controls['entityType'].hasError('required') && ConnectorForm.controls['entityType'].dirty">
                ExcludeEmail is required.</p>
            </div>
          </div>
            <div class="row col-md-12" style="border: 1px solid #ccc;margin: 5px 0;" *ngIf="targetCommunityType === 'COVEO' || targetCommunityType === 'WEB-PROXY' || targetCommunityType === 'GOOGLE-CLOUD-STORAGE' || targetCommunityType === 'VERTEX-AI'">
              <h4>CMS Entity Mappings</h4>
              <div class="row col-md-12 pl-3">
                  <div class="col-md-12 message-container" style="margin-bottom: 15px !important;"> 
                    <div class="message-checkbox-container">
                          <label class="chkBoxContainer" style="margin: 0 0 5px 0 !important;">
                              <span class="inter-font">Messages</span>
                              <input id="comm-coveo-messages" type="checkbox" formControlName="coveoMessagesEnabled" (change)="changedCoveoMessagesEnabled($event)">
                              <span class="checkmark"></span>
                          </label>
                          <label class="chkBoxContainer" style="margin: 20px 0 5px 0 !important;">
                            <span class="inter-font">Public Messages</span>
                            <input id="comm-coveo-messages" type="checkbox" formControlName="publicMessages" (change)="changedPublicMessages($event)">
                            <span class="checkmark"></span>
                        </label>
                      </div>
                      <div class="pl-3 message-entity-container" *ngIf="ConnectorForm.controls['coveoMessagesEnabled']?.value">
                          <div class="form-group">
                              <label class="coveo-content-config">
                                  <input type="radio"  formControlName="khorosAllCommunity"  [value]="true" [checked]="ConnectorForm.controls['khorosAllCommunity']?.value == 'true'|| ConnectorForm.controls['khorosAllCommunity']?.value == true ? 'checked' : null">
                                  <span>All Community</span>
                              </label>
                              <label class="coveo-content-config" style="margin-left: 10px;">
                                <input type="radio"  formControlName="khorosAllCommunity" [value]="false">
                                <span>Custom</span>
                              </label>
                          </div>
                          <div class="form-group">

                              <div class="form-group" *ngIf="ConnectorForm.controls['khorosAllCommunity']?.value == false">
                                <div class="community-boards-list row col-md-12" style="margin-bottom: 20px;margin-top: 10px;">
                                  <div class="category-details-header col-md-12" style="margin-bottom: 10px;">
                                    <div class="category-list-title" style="float: left;">
                                      <h5>Community Level Boards</h5>
                                    </div>

                                    <div class="col-md-2" style="float: right;">
                                      <button id="auto-syndicate"
                                        class="btn bg-blue btn-lg btn-flat margin fa fa-plus pull-right text-center" type="button"
                                        (click)="addCommunityBoardsList()" style="align-items:right">Add
                                      </button>
                                    </div>
                                  </div>
                                  <div class="row col-md-12" style="margin-top: 10px; margin-bottom: 10px;" *ngFor='let selectedcommunityBoard of selectedCommunityBoards; let commBoardIndex = index'>

                                    <div class="col-md-10">
                                      <select class="col-md-12" (change)="changedBoard($event,commBoardIndex,'community')">
                                        <option>Select</option>
                                        <option *ngFor="let board of communityBoardsList" [value]="board.id" title="{{board.title}} ({{board.id}})" [selected]="board.id== selectedcommunityBoard.boardId">{{board.title}}({{board.id}}) - {{board.conversation_style}}</option>
                                      </select>
                                    </div>
                                    <div class="col-md-2">
                                        <i class="fa fa-trash fa-2x " style="color: red;font-size: 18px;padding: 0;cursor: pointer;" title="Delete field"
                                        (click)="deleteCoveoCmsEntityMapping(commBoardIndex,'community')"></i>
                                    </div>
                                  </div>
                                </div>
                                <mat-divider></mat-divider>
                                <div class="category-list row col-md-12" style="margin-bottom: 20px;margin-top: 10px;">
                                  <div class="category-details-header col-md-12" style="margin-bottom: 10px;">
                                    <div class="category-list-title" style="float: left;">
                                      <h5>Categories</h5>
                                    </div>

                                    <div class="col-md-2" style="float: right;">
                                      <button id="auto-syndicate"
                                        class="btn bg-blue btn-lg btn-flat margin fa fa-plus pull-right text-center" type="button"
                                        (click)="addCategoriesList()" style="align-items:right">Add
                                      </button>
                                    </div>
                                  </div>

                                  <div class="row col-md-12 " *ngFor='let selectedCategory of selectedCategories;  let i = index' style="margin-bottom: 10px;margin-top: 10px;">
                                    <div class="col-md-5">
                                      <select class="cat-selector col-md-12" (change)="getBoardsListBySourceCategoryId($event.target.value,i,'category')">
                                        <option value="">Select</option>
                                        <option *ngFor="let category of  categoriesList" [selected]="category.categoryId == selectedCategory.categoryId" [value]="category.categoryId" title="{{category.categoryTitle}} ({{category.categoryId}})">{{category.categoryTitle}}({{category.categoryId}})</option>
                                      </select>
                                    </div>
                                    <div class="col-md-5">
                                      <select class="board-selector col-md-12" (change)="changedBoard($event,i,'category')">
                                        <option value="">Select</option>
                                        <option *ngFor="let board of  selectedCategory.boardList" [value]="board.id" title="{{board.title}} ({{board.id}})" [selected]="board.id==selectedCategory.boardId">{{board.title}}({{board.id}}) - {{board.conversation_style}}</option>
                                      </select>
                                    </div>
                                    <div class="col-md-2">
                                      <i class="fa fa-trash fa-2x " style="color: red;font-size: 18px;padding: 0;cursor: pointer;" title="Delete field"
                                      (click)="deleteCoveoCmsEntityMapping(i,'category')"></i>
                                    </div>
                                  </div>
                                </div>
                                <mat-divider></mat-divider>
                                <div class="grouphub-list row col-md-12" style="margin-bottom: 10px;margin-top: 10px;">
                                  <div class="col-md-12" style="display: inline-block;margin-bottom: 10px;">
                                    <div class="row col-md-6"style='float: left;'>
                                      <label class="chkBoxContainer">
                                        <span class="inter-font">GroupHubs</span>
                                        <input type="checkbox" formControlName="groupHubsEnabled" (change)="getGroupHubs($event)">
                                        <span class="checkmark"></span>
                                      </label>
                                    </div>
                                    <div class="col-md-2" style="float: right;">
                                      <button id="auto-syndicate"
                                        class="btn bg-blue btn-lg btn-flat margin fa fa-plus pull-right text-center" type="button"
                                        (click)="addGroupHubList()" style="align-items:right" [disabled]="ConnectorForm.controls['allGroupHubsEnabled']?.value">Add
                                      </button>
                                    </div>
                                  </div>
                                  <div class="col-md-12" style="display: inline-block;margin-bottom: 10px;">
                                    <div class="row col-md-6"style='float: left;'>
                                      <label class="chkBoxContainer">
                                        <span class="inter-font">All GroupHubs</span>
                                        <input type="checkbox" formControlName="allGroupHubsEnabled" (change)="selectedAllGroupHubsEnabled($event)">
                                        <span class="checkmark"></span>
                                      </label>
                                    </div>
                                    <div class="row col-md-6"style='float: left;'>
                                      <label class="chkBoxContainer">
                                        <span class="inter-font">All Events Out Side GroupHubs</span>
                                        <input type="checkbox" formControlName="allEventsOutSideGroupHubsEnabled" >
                                        <span class="checkmark"></span>
                                      </label>
                                    </div>
                                  </div>
                                  <div class="grouphub-list-container row col-md-12" *ngFor="let selectedGrouphub of selectedGrouphubs; let i = index" style="margin-bottom: 10px;margin-top: 10px;" >
                                    <div class="col-md-5">
                                      <select class="col-md-12" (change)="getBoardsListBySourceCategoryId($event.target.value,i,'grouphub')">
                                        <option>
                                          Select GroupHub
                                        </option>
                                        <option  *ngFor="let grouphub of grouphubsList;let ronNo= index" value="{{grouphub.groupHubId}}"  [selected]="grouphub.groupHubId === selectedGrouphub.groupHubId" title="{{grouphub.groupHubTitle}}({{grouphub.groupHubId}})">{{grouphub.groupHubTitle}}({{grouphub.groupHubId}})</option>
                                      </select>
                                    </div>
                                    <div class="col-md-5">
                                      <select class="col-md-12" (change)="changedBoard($event,i,'grouphub')">
                                        <option>Select Boards</option>
                                        <option *ngFor="let board of  selectedGrouphub.boardList" [value]="board.id" title="{{board.title}} ({{board.id}})" [selected]="board.id==selectedGrouphub.boardId">{{board.title}}({{board.id}}) - {{board.conversation_style}}</option>
                                      </select>
                                    </div>
                                    <div class="col-md-2">
                                      <i class="fa fa-trash fa-2x " style="color: red;font-size: 18px;padding: 0;cursor: pointer;" title="Delete field"
                                      (click)="deleteCoveoCmsEntityMapping(i,'grouphub')"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
            <!-- <p> entity type : {{selectedEntityType}}</p>
            <p>  target ::  {{targetCommunityName}}</p> -->
            <fieldset *ngIf="entityType === 'messages' || targetCommunityType === 'COVEO' || targetCommunityType === 'WEB-PROXY' || targetCommunityType === 'GOOGLE-CLOUD-STORAGE' || targetCommunityType === 'VERTEX-AI'" class="map-fieldset">
              <mat-expansion-panel class="map-expansion-panel">
                <mat-expansion-panel-header class="map-expansion-panel-header">
                  <mat-panel-title class="map-panel-title small text-nowrap">Field Mappings</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row col-md-12">
                  <div class="row col-md-12">
                    <div class="col-md-10">
                    </div>
                    <div class="col-md-2">
                      <button id="auto-syndicate" class="btn bg-blue btn-lg btn-flat margin fa fa-plus pull-right text-center" type="button" (click)="add()" style="align-items:right">Add</button>
                    </div>
                  </div>
                  <table class="table table-light table-responsive customize-list">
                    <tbody>
                      <tr>
                        <th style="width: 30%">Source Field</th>
                        <th style="width: 30%"></th>
                        <th style="width: 30%">Target Field</th>
                        <th style="width: 10%">Action</th>
                      </tr>
                      <tr class="form-group" *ngFor="let customfield of communityCustomFields; let i = index">
                        <td>
                          <select id="comm-fields-source" class="form-control boards-max-width" value="{{customfield.sourceFieldName}}" [(ngModel)]="customfield.sourceFieldName" [ngModelOptions]="{standalone: true}">
                            <option value=''>select</option>
                            <option *ngFor="let attribute of sourceCustomAttributes;" value={{attribute.field}}>
                              {{attribute.field}}
                            </option>
                          </select>
                        </td>
                        <td>
                          <label style="padding-top: 7px;"></label>
                        </td>
                        <td *ngIf="targetCommunityType === 'COVEO' || targetCommunityType === 'SFDC' || targetCommunityType === 'WEB-PROXY' || targetCommunityType === 'GOOGLE-CLOUD-STORAGE' || targetCommunityType === 'VERTEX-AI'">
                          <select id="comm-boards-source" class="form-control boards-max-width" value="{{customfield.targetFieldName}}" [(ngModel)]="customfield.targetFieldName" [ngModelOptions]="{standalone: true}">
                            <option value=''>select</option>
                            <option *ngFor="let attribute of customAttributes;" value={{attribute.field}}>
                              {{attribute.field}}
                            </option>
                          </select>
                        </td>
                        <td *ngIf="targetCommunityType !== 'COVEO' || targetCommunityType !== 'SFDC' || targetCommunityType !== 'WEB-PROXY' || targetCommunityType !== 'GOOGLE-CLOUD-STORAGE' || targetCommunityType !== 'VERTEX-AI'">
                          <input style="border-radius:5px" class="form-control boards-max-width" type="text" placeholder="Enter Source Field" value="{{customfield.targetFieldName}}" [(ngModel)]="customfield.targetFieldName" [ngModelOptions]="{standalone: true}">
                        </td>
                        <td>
                          <i class="fa fa-trash fa-2x delete-btn" style="color: red;" title="Delete field" (click)="confirm(i)"></i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </mat-expansion-panel>
            </fieldset>
            <fieldset *ngIf="entityType === 'users'" class="map-fieldset">
              <legend style="font-size: 14px; color: #252525;">User Mappings</legend>
              <div class="row col-md-12">
                <div class="row col-md-12">
                  <div class="col-md-10">
                  </div>
                  <div class="col-md-2">
                    <button id="auto-syndicate" class="btn bg-blue btn-lg btn-flat margin fa fa-plus pull-right text-center" type="button" (click)="add()" style="align-items:right">Add</button>
                  </div>
                </div>
                <table class="table table-light table-responsive customize-list">
                  <tbody>
                    <tr>
                      <th style="width: 30%">Source Field</th>
                      <th style="width: 30%">Target Field</th>
                    </tr>
                    <tr class="form-group" *ngFor="let customfield of communityCustomFields; let i = index">
                      <td>
                        <select id="comm-fields-source" class="form-control boards-max-width" value="{{customfield.sourceFieldName}}" [(ngModel)]="customfield.sourceFieldName" [ngModelOptions]="{standalone: true}">
                          <option value=''>select</option>
                          <option *ngFor="let attribute of sourceCustomAttributes;" value={{attribute.field}}>
                            {{attribute.field}}
                          </option>
                        </select>
                      </td>
                      <td *ngIf="targetCommunityType === 'COVEO' || targetCommunityType === 'SFDC' || targetCommunityType === 'WEB-PROXY' || targetCommunityType === 'GOOGLE-CLOUD-STORAGE' || targetCommunityType === 'VERTEX-AI'">
                        <select id="comm-boards-source" class="form-control boards-max-width" value="{{customfield.targetFieldName}}" [(ngModel)]="customfield.targetFieldName" [ngModelOptions]="{standalone: true}">
                          <option value=''>select</option>
                          <option *ngFor="let attribute of customAttributes;" value={{attribute.field}}>
                            {{attribute.field}}
                          </option>
                        </select>
                      </td>

                      <td>
                        <i class="fa fa-trash fa-2x delete-btn" style="color: red;" title="Delete field" (click)="confirm(i)"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </fieldset>
          </div>
          <div *ngIf="sourceCommunity !== '' && targetCommunity !== ''" class="row mt-1">
            <div class="col-md-1" style="padding-left: 40px !important;">
              <button id="submit-connector-details" [disabled]="!this.ConnectorForm.valid"
                class="btn bg-blue btn-flat margin btn-pdt">Save</button>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-1">
              <button id="close-connector-details" (click)="reset()" type="button"
                class="btn bg-blue btn-flat margin btn-pdt">Close</button>
            </div>
            <div class="col-md-9"></div>
          </div>
           <br>
        </div>
      </form>

    </div>
  </mat-expansion-panel>
</mat-accordion>
<div class="box">
    <mat-table #viewConnectorMappingsSort="matSort" [dataSource]="connectorMappingListDataSource" matSort (matSortChange)="onSortChange($event)">
      <mat-header-row *matHeaderRowDef="['header-row-first-group']">
      </mat-header-row>
      <ng-container matColumnDef="header-row-first-group">
        <mat-header-cell *matHeaderCellDef style="text-align: left;">
          <div class="col-sm-12">
            <h2 class="box-title" style="margin-left: -25px;"><strong>Connector Mapping List </strong></h2>
            <div class="row form-group">
              <div *ngIf="!superAdmin" class="col-sm-6 form-inline">
                <label>
                  <h3 class="box-title" style="margin-left: -25px">
                    <strong> Customer: </strong>
                  </h3> </label
                >&nbsp;<label class="font-weight-bold medium">
                  <h2 class="box-title">{{customerObj.name}}</h2>
                </label>
              </div>
              <div *ngIf="superAdmin" class="col-sm-6 form-inline">
                <div class="col-sm-2 left-align">
                  <label>Customer</label>
                </div>
                <div class="col-sm-4">
                  <select
                    *ngIf="superAdmin"
                    class="form-control customer-minwidth"
                    required
                    (change)="filterConnectorMappings($event.target.value)"
                  >
                    <option value="">All</option>
                    <option
                      *ngFor="let selectedcust of customers"
                      value="{{selectedcust.customerId}}"
                    >
                      {{selectedcust.customerName}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-sm-6 pull-right">
                <div class="searchbar_section">
                  <div class="app_search_component">
                    <mat-form-field>
                      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
                      </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-header-cell>

      </ng-container>
    <ng-container matColumnDef="customer.customerName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Customer </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.customer.customerName}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="entityType">
      <mat-header-cell *matHeaderCellDef mat-sort-header > Entity Type </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{entityName(element.entityType)}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="sourceCommunity.communityName">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Source Community </mat-header-cell>
      <mat-cell *matCellDef="let element" style="white-space:pre-line"> {{element.sourceCommunity.communityName}} </mat-cell>
    </ng-container>

   <!-- <ng-container matColumnDef="sourceCategoryName">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Category </mat-header-cell>
      <mat-cell *matCellDef="let element" style="white-space:pre-line"> {{element.sourceCategoryName}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="sourceBoardName">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Board </mat-header-cell>
      <mat-cell *matCellDef="let element" style="white-space:break-spaces"> {{element.sourceBoardName}} </mat-cell>
    </ng-container>  -->
    <ng-container matColumnDef="targetCommunity.communityName">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Target Community </mat-header-cell>
      <mat-cell *matCellDef="let element" style="white-space:pre-line"> {{element.targetCommunity.communityName}} </mat-cell>
    </ng-container>
   <!-- <ng-container matColumnDef="targetCategoryName">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Category </mat-header-cell>
      <mat-cell *matCellDef="let element" style="white-space:pre-line"> {{element.targetCategoryName}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="targetBoardName">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Board </mat-header-cell>
      <mat-cell *matCellDef="let element" style="white-space:pre-line"> {{element.targetBoardName}} </mat-cell>
    </ng-container> -->
    <ng-container matColumnDef="schedule">
      <mat-header-cell *matHeaderCellDef> Schedule </mat-header-cell>
      <mat-cell *matCellDef="let element" style="padding-top: 2px;" class="text-nowrap">
        <button [disabled]="element.entityType === 'newly-registered-users'" id="schedule-rebuild-details" style="background-color: #3c8dbc; color: white;"
                (click)="configSchedule('re-build', element)" type="button"
                class="btn btn-flat margin btn-pdt">
          <strong>Re-Build</strong>
        </button>
        <button id="schedule-incremental-details" style="background-color: #3c8dbc; color: white;"
                (click)="configSchedule('incremental', element)" type="button"
                class="btn btn-flat margin btn-pdt"
                [ngStyle]="element.scheduleStatus && element.scheduleStatus=='true' ? {'background-color':'#0FDA06'} : element.scheduleStatus && element.scheduleStatus=='false' ? {'background-color': '#F95626'}:{'background-color': '#3c8dbc'}">
          <strong>Incremental</strong>
        </button>
        <button *ngIf="element.entityType !== 'users'" id="schedule-rescanroles-details"
                style="background-color: #3c8dbc; color: white;"
                (click)="configSchedule('rescanroles', element)" type="button" class="btn btn-flat margin btn-pdt"
                [ngStyle]="element.rescanRolesScheduleStatus && element.rescanRolesScheduleStatus == 'true' ? {'background-color':'#0FDA06'} : element.rescanRolesScheduleStatus && element.rescanRolesScheduleStatus == 'false' ? {'background-color': '#F95626'} : {'background-color': '#3c8dbc'}"
                [disabled]="disableRescanButtons(element)">
          <strong>Rescan-Roles</strong>
        </button>
        <button *ngIf="element.entityType !== 'users'" id="schedule-rescanbulkdata-details"
                style="background-color: #3c8dbc; color: white;"
                (click)="configSchedule('rescanbulkdata', element)" type="button" class="btn btn-flat margin btn-pdt"
                [ngStyle]="element.rescanBulkDataScheduleStatus && element.rescanBulkDataScheduleStatus == 'true' ? {'background-color':'#0FDA06'} : element.rescanBulkDataScheduleStatus && element.rescanBulkDataScheduleStatus == 'false' ? {'background-color': '#F95626'} : {'background-color': '#3c8dbc'}"
                [disabled]="disableRescanButtons(element)">
          <strong>Rescan-Bulkdata</strong>
        </button>
        <button *ngIf="element.entityType === 'users'" id="reset-users-details"
                style="background-color: #3c8dbc; color: white;"
                (click)="resetUsers(element,true)"  type="button" class="btn btn-flat margin btn-pdt">
          <strong>Reset-Users</strong>
        </button>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
      <mat-cell *matCellDef="let element" style="padding-top: 2px;" class="text-nowrap">
        <button id="{{element.sourceCommunity.communityName + '-edit'}}" title="Edit" (click)="edit(element)"
        type="button" class="btn btn-box-tool badge bg-red" data-widget="remove"><i
          class="fa fa-pencil fa-2x delete-btn" style="color:#3c8dbc" title="Edit"></i></button>
      <button id="{{element.sourceCommunity.communityName + '-view'}}" title="View Schedules"
        (click)="schedular(element)" type="button" class="btn btn-box-tool badge bg-red" data-widget="remove"><i
          class="fa fa-eye fa-2x delete-btn" style="color:#3c8dbc" title="View Schedules"></i></button>
      <button id="{{element.sourceCommunity.communityName + '-delete'}}" title="Delete"
        (click)="delete(element)" type="button" class="btn btn-box-tool badge bg-red" data-widget="remove"><i
          class="fa fa-trash fa-2x delete-btn" style="color: red;" title="Delete field"></i></button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="connectorMappingListColumns" style="text-align: center;" class="text-nowrap"></mat-header-row>
    <mat-row *matRowDef="let row; columns: connectorMappingListColumns;"></mat-row>
  </mat-table>
    <mat-paginator [pageSizeOptions]="[50,100,500,1000]" (page)="onChangePage($event)" [length]="total"
      [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" showFirstLastButtons></mat-paginator>
</div>
<app-modal></app-modal>
