import { DescriptionComponent } from '../../description/description.component';
import { getTestBed } from '@angular/core/testing';
import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { PodsModel } from 'src/app/shared/models/Pods';
import { HealthCheckService } from 'src/app/core/services/health-check/health-check.service';
import { Constants } from 'src/app/shared/constants/constants';
import { MatDialog} from '@angular/material/dialog';
import {
  MatSnackBar, MatSnackBarConfig
} from '@angular/material/snack-bar';

export interface DialogData {
  content: PodsModel[];
  name: string;

}

@Component({
  selector: 'app-health-check',
  templateUrl: './health-check.component.html',
  styleUrls: ['./health-check.component.css']
})
export class HealthCheckComponent implements OnInit {
  @Output() showMessage = new EventEmitter();
  showSpinner = false;

  constructor(
    private healthCheckService: HealthCheckService,
    public dialog: MatDialog, private snackBar: MatSnackBar,
  ) { }

  pods: PodsModel[] = [];
  nodes: PodsModel[] = [] ;
  runningPods: PodsModel[] = [];
  runningNodes: PodsModel[] = [];
  mongoDbActive: PodsModel[] = [];
  checkDbConnection: boolean;
  mongoDbstatus: PodsModel[] = [];
  activemq: PodsModel;
  config = new MatSnackBarConfig();
  activemqStatus = 'failed';
  panelOpenState = false;
  toggleState: boolean;

  ngOnInit() {
    this.config.verticalPosition = 'top';
    this.config.horizontalPosition = 'end';
    this.config.duration = 5000;
    this.config.panelClass = ['blue-snackbar'];
    this.getCronJobStatus();
    this.getPods();
    this.getNodes();
    this.checkDbConnect();
    this.getMongoDbDatabaseStatus();
   }

  getMongoDbStatusIcon(): string {
    const difference = this.mongoDbstatus.length - this.mongoDbActive.length;
    return difference === 0 ? 'fa fa-check' : 'fa fa-times';
  }
  getMongoDbDatabaseStatus() {
    this.showSpinner=true;
    this.healthCheckService.getMongoDbStatus().subscribe(res => {
      this.mongoDbstatus = res;
      this.mongoDbActive = this.mongoDbstatus.filter(word => word.status === 'ACTIVE');
    }, error => {
      this.showSpinner = false;
      if (error.status === 401 && error.error.response.status === 'error' && error.error.response.message === 'UnAuthorized Access Token Expired') {
        return this.snackBar.open(Constants.SESSION_EXPIRED, 'Close', this.config);
       } else {
        return this.snackBar.open(error.error.response.message, 'Close', this.config);
       }
    });

  }


  toggleCustomerExpiration() {
    this.toggleState = !this.toggleState;
    const storedToggleState: boolean = this.toggleState;
    this.healthCheckService.scheduleAadTokenExpirationCronJob(storedToggleState).subscribe(
      () => {
        if (storedToggleState) {
          this.snackBar.open(Constants.CRONJOB_STOPPED, 'Close', this.config);
        } else {
          this.snackBar.open(Constants.CRONJOB_STARTED, 'Close', this.config);
        }
      },
      error => {
        this.toggleState = !this.toggleState;
        this.snackBar.open(Constants.SERVER_ERROR, 'Close', this.config);
      }
    );
  }
  getCronJobStatus() {
    this.showSpinner = true;
    this.healthCheckService.getCronJobStatus().subscribe(
      (res: boolean) => {
        this.showSpinner = false;
        this.toggleState = !res;
      },
      (error) => {
        this.showSpinner = false;
        if (error.status === 401 && error.error.response.status === 'error' && error.error.response.message === 'UnAuthorized Access Token Expired') {
          return this.snackBar.open(Constants.SESSION_EXPIRED, 'Close', this.config);
         } else {
          this.snackBar.open(Constants.CRONJOB_STATUS, 'Close', this.config);
        }
      }

    );
  }
  getPods() {
    this.showSpinner = true;
    this.healthCheckService.getPodStatus().subscribe(res => {
      this.pods = res;
       this.runningPods = this.pods.filter(word => (word.status === 'Running' || word.status === 'Succeeded'));
       this.activemq = this.pods.filter(word => word.name.includes('activemq'))[0];
       this.activemqStatus = this.activemq.status;
    },
    error => {
      this.showSpinner = false;
      if (error.status === 401 && error.error.response.status === 'error' && error.error.response.message === 'UnAuthorized Access Token Expired') {
        return this.snackBar.open(Constants.SESSION_EXPIRED, 'Close', this.config);
       } else {
        return this.snackBar.open(error.error.response.message, 'Close', this.config);
       }
    });
}

getNodes() {
  this.showSpinner = true;
  this.healthCheckService.getNodeStatus().subscribe(res => {
    this.nodes = res;
    this.runningNodes = this.nodes.filter(word => word.status === 'Ready');
  },
  error => {
    this.showSpinner = false;
    if (error.status === 401 && error.error.response.status === 'error' && error.error.response.message === 'UnAuthorized Access Token Expired') {
      return this.snackBar.open(Constants.SESSION_EXPIRED, 'Close', this.config);
     } else {
      return this.snackBar.open(error.error.response.message, 'Close', this.config);
     }
  });
}

checkDbConnect() {
  this.showSpinner = true;
  this.healthCheckService.checkDbConnect().subscribe(res => {
    this.showSpinner = false;
    this.checkDbConnection = res;
  },
  error => {
    this.showSpinner = false;
    if (error.status === 401 && error.error.response.status === 'error' && error.error.response.message === 'UnAuthorized Access Token Expired') {
      return this.snackBar.open(Constants.SESSION_EXPIRED, 'Close', this.config);
     } else {
      return this.snackBar.open(error.error.response.message, 'Close', this.config);
     }
  });
}

getPodsDescription(list: PodsModel[], name: string) {
    const dialogRef = this.dialog.open(DescriptionComponent, {
      data: {content: list, name: name}
    });
  }
  getMongoDbStatus(list: PodsModel[], name: string) {
    const dialogRef = this.dialog.open(DescriptionComponent, {
      data: {content: list, name: name}
    });
  }
  }





