<div width="100%" style="height: 400px; overflow: scroll;">
    <div class="header-name">{{name}}</div>
    <div class="col-sm-12">
<table class="table">
    <thead>
      <th>Name</th>
      <th>Status</th>
    </thead>
    <tr *ngFor="let pod of pods">
      <td>{{pod.name}}</td>
      <td ng-class="{'color-green': pod.status === 'Running', 'color-green': pod.status === 'Ready'}">{{pod.status}}</td>
    </tr>
</table>
</div>
</div>