import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {PotentialDataComponent} from './potential-data/potential-data.component';
import {CanActivatecommunityService} from '../../core/guards/community/can-activatecommunity.service';

const routes: Routes = [
  {path: 'ui-service/potenitial-data', component: PotentialDataComponent, canActivate: [CanActivatecommunityService]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PotentialDataRoutingModule { }
