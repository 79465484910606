import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {CommunityMappingComponent} from './pages/community-mapping.component';
import {CanActivatecommunityService} from '../../core/guards/community/can-activatecommunity.service';

const routes: Routes = [
  {
    path: 'ui-service/community-mapping',
    component: CommunityMappingComponent,
    canActivate: [CanActivatecommunityService]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CommunityMaapingRoutingModule {
}
