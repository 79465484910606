import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HealthCheckComponent } from './pages/health-check/health-check.component';

const routes: Routes = [
  {path: 'ui-service/health-check', component: HealthCheckComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HealthCheckRoutingModule { }
