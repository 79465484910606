import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {Response} from '../../../shared/models/response';
import {CustomField} from '../../../shared/models/custom-field';

@Injectable({
  providedIn: 'root'
})
export class CustomFieldsService {

  constructor(private http: HttpClient) {
  }

  public customFieldsByCommunityId(communityId: number): Observable<Response<CustomField[]>> {
    const url = `${environment.BASE_URL}/customfield?id=${communityId}`;
    return this.http.get<Response<CustomField[]>>(url);
  }

  public save(payLoad: Array<any>, communityId: number): Observable<Response<any>> {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    const url = `${environment.BASE_URL}/savecustomfields?id=${communityId}`;
    return this.http.post <Response<any>>(url, payLoad, {
      headers
    });
  }

  public update(payLoad: Array<any>, communityId: number): Observable<Response<any>> {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    const url = `${environment.BASE_URL}/modifycustomfields?id=${communityId}`;
    return this.http.put<Response<any>>(url, payLoad, {
      headers
    });
  }

  public getKhorosMetaDataFields(payLoad: Array<any>, id: number): Observable<Response<any>> {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    const url = `${environment.BASE_URL}/getkhorosclassicmetadatabycommunityid?id=${id}`;
    return this.http.get<Response<any>>(url, {
      headers
    });
  }

  public KhorosMetaDataUpdate(payLoad: Array<any>, id: number): Observable<Response<any>> {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    const url = `${environment.BASE_URL}/modifykhorosclassicmetadata?id=${id}`;
    return this.http.put<Response<any>>(url, payLoad, {
      headers
    });
  }

  public KhorosMetaDataSave(payLoad: Array<any>, id: number): Observable<Response<any>> {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    const url = `${environment.BASE_URL}/savekhorosclassicmetadata?id=${id}`;
    return this.http.post<Response<any>>(url, payLoad, {
      headers
    });
  }
}
