import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MappingsComponent} from './pages/mappings.component';
import {CanActivatecommunityService} from '../../core/guards/community/can-activatecommunity.service';

const routes: Routes = [
  {
    path: 'ui-service/mappings',
    component: MappingsComponent,
    canActivate: [CanActivatecommunityService]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BoardMappingRoutingModule {
}
