import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MatSnackBar, MatSnackBarConfig
} from '@angular/material/snack-bar';
import { forkJoin } from 'rxjs';
import { CommunityService } from '../../../core/services/community/community.service';
import { ClientsService } from '../../../core/services/customer/clients.service';
import { MappingsService } from '../../../core/services/Mappings/mappings.service';
import { ModalComponent } from '../../../shared/components/modal/pages/modal.component';
import { Constants } from '../../../shared/constants/constants';
import { Board } from '../../../shared/models/board';
import { BoardMappingList } from '../../../shared/models/board-mapping-list';
import { BoardMappings } from '../../../shared/models/board-mappings';
import { BoardMappingsSaved } from '../../../shared/models/board-mappings-saved';
import { Category } from '../../../shared/models/category';
import { Community } from '../../../shared/models/community';
import { CommunityMappingList } from '../../../shared/models/community_mapping';
import { Customer } from '../../../shared/models/customer';
import { InteractionStyles } from '../../../shared/models/interaction-styles';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-mappings',
  templateUrl: './mappings.component.html',
  styleUrls: ['./mappings.component.css']
})
export class MappingsComponent implements OnInit {

  @ViewChild(ModalComponent, {static: true}) modalComponent;

  public mappingForm: FormGroup;
  public boardMappingsSaved: BoardMappingsSaved;
  public allCommunities: CommunityMappingList[] = [];
  public allCustomers: Customer[] = [];
  public allInteractionStyles: InteractionStyles[] = [];
  public allCategories: Category[] = [];
  public allGroupHubs:any[]=[];
  public messageToDisplay: any;
  public showSpinner = false;
  public communities: Community[] = [];
  public allBoards: BoardMappingList[] = [];
  public localCommunity: any;
  public saveOrUpdate = 'Save';
  public customerObj = JSON.parse(localStorage.getItem('customerObj'));
  public superAdmin = false;
  selectedCommunity: Community;
  selectedCategory: Category;
  telligentBoardLimit:100;
  config = new MatSnackBarConfig();
  myControl = new FormControl();
  filteredCategories: Observable<any[]>;

  constructor(private fb: FormBuilder,
              private mappingService: MappingsService,
              private clientsService: ClientsService,
              private comunityService: CommunityService, private snackBar: MatSnackBar) {
    this.buildForm();
  }

  ngOnInit() {
    this.superAdmin = localStorage.getItem('isSuperAdmin') === 'yes';
    this.config.verticalPosition = 'top';
    this.config.horizontalPosition = 'end';
    this.config.duration = 5000;
    this.config.panelClass = ['blue-snackbar'];
    if (this.superAdmin) {
      this.getCustomers();
    } else {
      this.populateCommunities();
    }
    this.filterDataFun();
  }
   filterDataFun(){
    this.filteredCategories = this.mappingForm.controls['category_name'].valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value)),
    );
  }
  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();

    return this.allCategories.filter(category => category.categoryTitle.toLowerCase().includes(filterValue) || category.categoryId.toLowerCase().includes(filterValue));
  }

  /**
   * creating form fields
   * applying validations here
   */
  buildForm() {
    this.mappingForm = this.fb.group({
      customer_name: new FormControl('', []),
      category_name: new FormControl('', [Validators.required]),
      community_name: new FormControl('', [Validators.required])
    });
  }

  /**
   * Fetch list of customers
   */
  getCustomers() {
    this.showSpinner = true;
    this.clientsService.getActiveCustomers(localStorage.getItem('loginUserId'))
      .subscribe(res => {
        this.showSpinner = false;
        this.allCustomers = res.response.data;
      }, error => {
        this.showSpinner = false;
        if (error.status === 401 && error.error.response.status === 'error' && error.error.response.message === 'UnAuthorized Access Token Expired') {
         return this.snackBar.open(Constants.SESSION_EXPIRED, 'Close', this.config);
        } else {
         return this.snackBar.open(error.error.response.message, 'Close', this.config);
        }
      });
  }

  /**
   * Fetch list of communities based on customer
   * @param event
   */
  onCustomerChange(event) {
    let customerId = null;
    this.allBoards = [];
    this.allCategories = [];
    const selectedVal = event.target.value;
    if (selectedVal !== '') {
      if (typeof selectedVal === 'string') {
        this.allCustomers.map((obj) => {
          if (obj.customerName === event.target.value) {
            customerId = obj.customerId;
          }
        });
      } else {
        customerId = +selectedVal;
      }

      this.showSpinner = true;
      this.mappingService.getCommunitybyCustomerId(customerId)
        .subscribe(res => {
          this.showSpinner = false;
          this.communities = res.response.data;
          if (this.communities.length === 0) {
            this.buildForm();
            this.allInteractionStyles = [];
            this.allBoards = [];
            this.allCategories = [];
            this.mappingForm.controls['customer_name'].setValue(selectedVal);
          }
        }, error => {
          this.showSpinner = false;
          if (error.status === 401 && error.error.response.status === 'error' && error.error.response.message === 'UnAuthorized Access Token Expired') {
           return this.snackBar.open(Constants.SESSION_EXPIRED, 'Close', this.config);
          } else {
           return this.snackBar.open(error.error.response.message, 'Close', this.config);
           }
        });
    } else {
      this.resetForm();
    }
  }

  /**
   * Fetch existing mapping
   * based on mapping prepopulate the category and boards
   * if mapping doesn't exist it will fetch categories and boards and popuate on UI
   * save or update can happen based on fetching exist mapping
   * @param event
   */
  onCommunityChange(event) {
    this.mappingForm.controls['category_name'].setValue('');
    const selectedCommunityVal = event.target.value;
    if (selectedCommunityVal !== '') {
      this.communities.map((obj) => {
        if (obj.communityName === selectedCommunityVal) {
          this.localCommunity = obj;
        }
      });
      this.selectedCommunity = this.communities.filter(data => data.communityName === selectedCommunityVal)[0];
      this.showSpinner = true;
      forkJoin(this.mappingService.getMapping(this.localCommunity.communityId),
      this.mappingService.getCategoriesByCommunity(this.localCommunity.communityId),this.mappingService.getGroupHubsByCommunityId(this.localCommunity.communityId)).subscribe(([mappings, categories,grouphubs]) => {
        this.showSpinner = false;
        this.boardMappingsSaved = mappings.response.data;
        this.allCategories = categories.response.data;
        this.allGroupHubs = grouphubs.response.data;
        this.allCategories.map(obj => {
          obj.nodeType = "category";
        });
        this.allGroupHubs.map(obj => {
          let  cat:any ={};
          cat.nodeType = "grouphub";
          cat.categoryId = obj.groupHubId;
          cat.categoryTitle = obj.groupHubTitle;
          this.allCategories.push(cat);
        });
        this.filterDataFun();
        if (this.boardMappingsSaved && (Object.keys(this.boardMappingsSaved).length !== 0)) {
          this.allCategories.map(obj => {
            if (this.boardMappingsSaved.communityCategoryId === obj.categoryId) {
              // obj.isSelected = true;
              this.mappingForm.controls['category_name'].setValue(obj.categoryId);
              this.onCategoryChange( obj.categoryId);
            } else {
              this.saveOrUpdate = 'Save';
            }
          });

        } else {
          this.saveOrUpdate = 'Save';
        }
      }, error => {
        this.showSpinner = false;
        if (error.status === 401 && error.error.response.status === 'error' && error.error.response.message === 'UnAuthorized Access Token Expired') {
          return this.snackBar.open(Constants.SESSION_EXPIRED, 'Close', this.config);
         } else {
          return this.snackBar.open(error.error.response.message, 'Close', this.config);
         }
      });
    } else {
      this.populateCommunities();
      this.resetForm();
    }
  }

  /**
   * populate list boards based on category selection
   * @param event
   */
  onCategoryChange(categoryIdValue:String) {
    let categoryId = null;
    let selectedNodeType = "";
    const selectedCategoryVal = categoryIdValue;
    if (selectedCategoryVal !== '') {
      this.allCategories.map((obj) => {
        if (obj.categoryId === selectedCategoryVal) {
          categoryId = obj.categoryId;
          selectedNodeType = obj.nodeType;
        }
      });
      this.selectedCategory = this.allCategories.filter(data => data.categoryId === selectedCategoryVal)[0];
      this.showSpinner = true;
      forkJoin(this.comunityService.getInteractionStyles(this.localCommunity.communityType.communityTypeName),
      this.mappingService.getBoards(this.localCommunity.communityId, categoryId, 'all',selectedNodeType)).subscribe(([interactionStyles, boards]) => {
        this.showSpinner = false;
        this.allInteractionStyles = interactionStyles.response.data;
          this.renderFormControlesForInteractionStyles(this.allInteractionStyles);
          this.allBoards = boards.response.data;
          for (let interactionStyle of this.allInteractionStyles) {
            let totalCount = 0;
          if (this.selectedCommunity.communityType.communityTypeName === Constants.COMMUNITY_TYPE_TELLIGENT) {
            totalCount = this.allBoards.filter(obj => obj.interactionStyle === interactionStyle.interactionStyle)[0].TotalCount;
          } else {
            totalCount = this.allBoards.filter(obj => obj.interactionStyle === interactionStyle.interactionStyle)[0].boardsMappingList.length;
          }
          let offset = this.allBoards.filter(obj => obj.interactionStyle === interactionStyle.interactionStyle)[0].boardsMappingList.length;
          let board = this.allBoards.filter(obj => obj.interactionStyle === interactionStyle.interactionStyle)[0];
          board.offSet = offset;
          board.TotalCount = totalCount;
          let index = this.allBoards.indexOf(board);
          this.allBoards[index] = board;
        }
            // if (this.boardMappingsSaved && (Object.keys(this.boardMappingsSaved).length !== 0)) {
              this.allBoards.map((board: BoardMappingList) => {
                board.filteredBoardsMappingList = board.boardsMappingList;
                board.boardsMappingList.map((boardObject: Board) => {
                  if(this.boardMappingsSaved.boardMappings){
                    this.boardMappingsSaved.boardMappings.map((boardMapping: BoardMappings) => {
                      if (boardMapping.interactionStyleName === board.interactionStyle
                        && boardObject.boardId.toString() === boardMapping.communityBoardId) {
                        this.mappingForm.controls[boardMapping.interactionStyleName].setValue(boardObject.boardId);
                        this.saveOrUpdate = 'Update';
                      }
                    });
                  }
                });
              });
      }, error => {
        this.showSpinner = false;
        if (error.status === 401 && error.error.response.status === 'error' && error.error.response.message === 'UnAuthorized Access Token Expired') {
          return this.snackBar.open(Constants.SESSION_EXPIRED, 'Close', this.config);
         } else {
          return this.snackBar.open(error.error.response.message, 'Close', this.config);
         }
      });
    } else {
      this.resetForm();
    }
  }

  /**
   * @param form object
   * save or update mapping
   */
  submit() {
    let count = 0;
    this.allInteractionStyles.map(obj => {
      if (obj.interactionStyle === '') {
        count = count + 1;
      }
    });
    if (count > 0) {
      this.modalComponent.populateModal({response: {message: Constants.SELECT_ALL_HIDDENT_BOARDS}});
    } else {
      let communityId: number = null;
      const data: any[] = [];
      const formObj = this.mappingForm.value;
      this.allCommunities.map(obj => {
        if (formObj.community_name === obj.communityName) {
          communityId = obj.communityId;
        }
      });
      if (this.boardMappingsSaved && (Object.keys(this.boardMappingsSaved).length === 0)) {
        this.allBoards.map(boardObject => {
          boardObject.boardsMappingList.map((mappingObj, index) => {
            this.allInteractionStyles.map(interactionStyleObj => {
              if (interactionStyleObj.interactionStyle === boardObject.interactionStyle
                && mappingObj.boardId === formObj[interactionStyleObj.interactionStyle]) {
                const objectAsPartofPayload = {
                  'interactionStyleId': interactionStyleObj.interactionStylesDefId,
                  'communityCategoryId': '',
                  'boardMappingsId': this.saveOrUpdate === 'Save' ? null : this.boardMappingsSaved.boardMappings[index].boardMappingsId,
                  'communityBoardId': mappingObj.boardId
                };
                data.push(objectAsPartofPayload);
              }
            });
          });
        });
      } else {
        this.allBoards.map(obj => {
          obj.boardsMappingList.map(obj1 => {
            this.boardMappingsSaved.boardMappings.map(obj3 => {
              if (obj.interactionStyle === obj3.interactionStyleName && obj1.boardId === formObj[obj3.interactionStyleName]) {
                const objectAsPartofPayload = {
                  'interactionStyleId': obj3.interactionStyleId,
                  'communityCategoryId': '',
                  'boardMappingsId': this.saveOrUpdate === 'Save' ? null : obj3.boardMappingsId,
                  'communityBoardId': obj1.boardId
                };
                data.push(objectAsPartofPayload);
              }else{
                let savedContent = this.boardMappingsSaved.boardMappings.filter(styles => styles.interactionStyleName === obj.interactionStyle)
                if(obj1.boardId === formObj[obj.interactionStyle] && savedContent.length === 0){
                  let resList = this.allInteractionStyles.filter(obj1 => obj1.interactionStyle === obj.interactionStyle)
                  const objectAsPartofPayload = {
                    'interactionStyleId':resList[0].interactionStylesDefId,
                    'communityCategoryId': '',
                    'boardMappingsId': null,
                    'communityBoardId': obj1.boardId
                  };
                  data.push(objectAsPartofPayload);
                }
              }
            });
          });
        });
      }


      this.allCategories.map(obj => {
        data.map(obj1 => {
          if (obj.categoryId === formObj.category_name) {
            obj1.communityCategoryId = obj.categoryId;
          }
        });
      });
      const payLoad = {
        'communityId': this.localCommunity.communityId,
        'data': (data && data.length > 0) ? data.filter((value, index, array) => index == array.findIndex(item => item.interactionStyleId === value.interactionStyleId)): []
      };

      const responsemessage = {response: {message: ''}};

      if (this.saveOrUpdate === 'Save') {
        responsemessage.response.message = Constants.BOARDS_CREATED_SUCCESSFULLY;
      } else {
        responsemessage.response.message = Constants.BOARDS_UPDATED_SUCCESSFULLY;
      }

      if (data.length <= 0) {
        return this.modalComponent.populateModal({response: {message: Constants.SELECT_BOARD_MAPPING}});
      }
      this.showSpinner = true;
      this.mappingService.save(payLoad)
        .subscribe(res => {
            this.showSpinner = false;
            this.saveOrUpdate = 'Save';
            this.modalComponent.populateModal(responsemessage);
            this.resetForm();
            if (this.superAdmin) {
              this.getCustomers();
            } else {
              this.populateCommunities();
            }
          },
          error => {
            this.showSpinner = false;
            if (error.status === 401 && error.error.response.status === 'error' && error.error.response.message === 'UnAuthorized Access Token Expired') {
              return this.snackBar.open(Constants.SESSION_EXPIRED, 'Close', this.config);
            } else {
              return this.snackBar.open(error.error.response.message, 'Close', this.config);
             }
          });
    }
  }


  /**
   * Reset the form
   */
  resetForm() {
    this.buildForm();
    this.allInteractionStyles = [];
    this.allBoards = [];
    this.allCategories = [];
    if (this.superAdmin) {
      this.communities = [];
    } else {
      this.populateCommunities();
    }
    this.mappingForm.controls['community_name'].setValue('');
    this.saveOrUpdate = 'Save';
  }

  /**
   * Dynamically reneder interaction style form values
   * @param interactionSTyles
   */
  renderFormControlesForInteractionStyles(interactionSTyles: InteractionStyles[]) {
    interactionSTyles.map((obj: InteractionStyles) => {
      this.mappingForm.setControl(obj.interactionStyle, new FormControl(''));
    });
  }

  /**
   * When ADMIN logins
   * populating communities based on customer ID
   */
  populateCommunities() {
    if (!this.superAdmin) {
      const customerId = this.customerObj.id;
      this.onCustomerChange({target: {value: customerId}});
    }
  }

  getNextBatch(allboards: any, board:any, interactionStyle: any) {
    if (this.selectedCommunity.communityType.communityTypeName === Constants.COMMUNITY_TYPE_TELLIGENT) {
      if (board.boardsMappingList.length >= board.TotalCount && board.boardsMappingList.length >= (board.offSet-1)) {
        return;
      } else {
        let data = {
          conversationStyle: interactionStyle.interactionStyle,
          targetBoardlimit:100,
          targetBoardOffset: board.offSet
        }
        this.showSpinner = true;
        this.mappingService.getBoardsByCategory(this.selectedCommunity.communityId, this.selectedCategory.categoryId, 'all', data).subscribe(res => {
          let index = this.allBoards.indexOf(board);
          let response = this.filterData(res.response.data.boardsMappingList);
          board.boardsMappingList.push(...response);
          board.boardsMappingList = this.uniq(board.boardsMappingList, 'boardId');
          board.offSet = board.boardsMappingList.length + 1;
          this.allBoards[index] = board;
          this.showSpinner = false;
        }, error =>{
          this.showSpinner = false;
           return this.snackBar.open(error.error.response.message, 'Close', this.config);
       });
      }
    }

  }

  uniq(a, param){
    return a.filter(function(item, pos, array){
        return array.map(function(mapItem){ return mapItem[param]; }).indexOf(item[param]) === pos;
    })
}

filterData(boardsMappingList: any): any {
  let boardsList = [];
  for (let entry of boardsMappingList) {
    let data = {
      boardId: entry.id,
      boardTitle: entry.title
    }
    boardsList.push(data);
}
return boardsList;
}

}
