<div class="spinner" *ngIf="showSpinner">
  <img src="../../../../assets/images/spinner.gif" height="50px">
</div>
<div class="content-header">
  <h1>User</h1>
  <br>
  <mat-divider></mat-divider>
</div>
<div class="mt-4 customer-tab">
  <form [formGroup]="userForm" (ngSubmit)="submitUser()" *ngIf="saveOrUpdate !== 'Save'">
    <div class="row">
      <div class="col-md-2">
        <div class="form-group">
          <label>First name<span class="label-required">*</span></label>
          <input id="usr-fname" class="form-control" type="text" placeholder="first name" formControlName="first_name">
          <p class="custom-error"
             *ngIf="userForm.controls['first_name'].hasError('required') && userForm.controls['first_name'].dirty">First
            name should not be empty</p>
          <p class="custom-error"
             *ngIf="userForm.controls['first_name'].hasError('pattern') && userForm.controls['first_name'].dirty">First
            name can not contain numbers.</p>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label>Last name<span class="label-required">*</span></label>
          <input id="usr-lname" class="form-control" type="text" placeholder="last name" formControlName="last_name">
          <p class="custom-error"
             *ngIf="userForm.controls['last_name'].hasError('required') && userForm.controls['last_name'].dirty">Last
            name should not be empty</p>
          <p class="custom-error"
             *ngIf="userForm.controls['last_name'].hasError('pattern') && userForm.controls['last_name'].dirty">Last
            name can not contain numbers.</p>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label>Email<span class="label-required">*</span></label>
          <input id="usr-email" class="form-control" type="text" placeholder="email" formControlName="user_email">
          <p class="custom-error"
             *ngIf="userForm.controls['user_email'].hasError('required') && userForm.controls['user_email'].dirty">Email
            is required.</p>
          <p class="custom-error"
             *ngIf="userForm.controls['user_email'].hasError('email') && userForm.controls['user_email'].dirty">Enter a
            valid email.</p>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label>Role<span class="label-required">*</span></label>
          <div class="form-group">
            <select id="usr-role" class="form-control" formControlName="role">
              <option value="">select</option>
              <option *ngFor="let role of roles" value={{role.roleName}}>{{role.roleName}}</option>
            </select>
            <p class="custom-error"
               *ngIf="userForm.controls['role'].hasError('required') && userForm.controls['role'].dirty">Select a
              role.</p>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label>Customer<span class="label-required">*</span></label>
          <div class="form-group">
            <select id="usr-customer" class="form-control" formControlName="customer">
              <option value=''>select</option>
              <option *ngFor="let customer of customers"
                      value="{{customer.customerName}}">{{customer.customerName}}</option>
            </select>
            <!--<p class="custom-error" *ngIf="userForm.controls['customer'].hasError('required') && userForm.controls['customer'].dirty">Select Customer</p>-->
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-md">
        <button id="usr-submit-btn" [disabled]="!userForm.valid"
                class="btn bg-blue btn-flat margin pull-left btn-pdt">{{saveOrUpdate}}</button>
        <input id="usr-reset-btn" type="button" class="btn bg-blue btn-flat margin" (click)="resetForm()" value="Reset">
      </div>
    </div>
  </form>
</div>
  <div class="box">
      <mat-table [dataSource]="dataSource" matSort (matSortChange)="onSortChange($event)">
        <mat-header-row *matHeaderRowDef="['header-row-first-group']">
        </mat-header-row>
        <ng-container matColumnDef="header-row-first-group">
          <mat-header-cell *matHeaderCellDef style="text-align: left;"> 
            <div class="col-sm-12">
              <h3 class="box-title" style="margin-left: -25px;"><strong>Users List </strong></h3>
              <div class="col-sm-6 pull-right">
                <div class="searchbar_section">
                  <div class="app_search_component">
                    <mat-form-field>
                      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
                      </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </mat-header-cell></ng-container>
      <ng-container matColumnDef="firstName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>First Name </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.firstName}} </mat-cell>
      </ng-container>
    
      <ng-container matColumnDef="lastName">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.lastName}} </mat-cell>
      </ng-container>
    
      <ng-container matColumnDef="userEmail">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Email </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.userEmail}} </mat-cell>
      </ng-container>
  
      <ng-container matColumnDef="customer.customerName">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Customer </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{!element.hasOwnProperty('customer') ? 'Not Applicable' : element.customer.customerName}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="role.roleName">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Role </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.role.roleName}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button id="user-edit-{{i}}" title="Edit" [disabled]="!element.active" (click)="editUser(element)" type="button"
          class="btn btn-box-tool badge bg-blue" style="width: 30%; height: 35%;" data-widget="remove"><i class="fa fa-edit"></i></button>
          <button id="user-delete-{{i}}" title="Delete" (click)="delete(element)" type="button"
                class="btn btn-box-tool badge bg-red" style="width: 30%; height: 35%;" data-widget="remove"><i class="fa fa-times"></i>
</button>   
        </mat-cell>
      </ng-container>
  
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef> Enable/Disable </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <mat-slide-toggle
          id="user-enable-or-diable-{{i}}"
          title="{{element.active ? 'disable' : 'enable'}}"
          [checked]="element.active"
          (change)="updateStatus($event,element)">
        </mat-slide-toggle>  
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: tableColumns;"></mat-row>
    </mat-table>
      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" (page)="onChangePage($event)" [length]="total" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" showFirstLastButtons></mat-paginator>
</div>

<app-modal [genericMessage]="messageToDisplay" [canDelete]="isDelete"
           (emitDeleteFuncionality)="confirmDelete($event)"></app-modal>
