<div class="spinner" *ngIf="showSpinner">
  <img src="../../../../assets/images/spinner.gif" height="50px">
</div>
<section class="content-header">
  <h1>Content Migration</h1>
</section>
<div class="medium ">
  <div class="row" style="width: 100% !important;">
    <div class="col-md-12">
      <div id="Manage-Users" class="mt-4">
        <form class="form-horizontal" [formGroup]="prodToStageForm" (ngSubmit)="submitmappings()">
          <div class="box-body">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label>Customer name<span class="label-required">*</span>:</label>
                  <input *ngIf="!isSuperAdmin" class="form-control" type="text" value="{{customerObj.name}}" disabled>
                  <select *ngIf="isSuperAdmin" id="mig-cust-name" autofocus class="form-control"
                          formControlName="customer_name"
                          (change)="onCustomerChange($event)">
                    <option value="">select</option>
                    <option *ngFor="let customer of allCustomers" value={{customer.customerId}}>
                      {{customer.customerName}}
                    </option>
                  </select>
                  <div class="custom-error pt-1"
                       *ngIf="prodToStageForm.controls['customer_name'].hasError('required') && prodToStageForm.controls['customer_name'].dirty">
                    Select a customer
                  </div>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="sourceCommunities.length > 0">
              <div class="form-group col-md-4">
                <label>Source community<span
                  class="label-required">*</span>:</label>
                <select id="mig-source-community" class="form-control" formControlName="source_community"
                        (change)="onCommunityChange($event)">
                  <option value="">select</option>
                  <option *ngFor="let sourceCommunity of sourceCommunities" value={{sourceCommunity.communityId}}>
                    {{getCommunityNameWithEnvironment(sourceCommunity)}}
                  </option>
                </select>
                <div class="custom-error pt-1"
                     *ngIf="prodToStageForm.controls['source_community'].hasError('required') && prodToStageForm.controls['source_community'].dirty">
                  Select a source community
                </div>
              </div>
              <div class="form-group col-md-4">
                <label>Target community<span
                  class="label-required">*</span>:</label>
                <select id="mig-target-comm" class="form-control" formControlName="target_community">
                  <option value="">select</option>
                  <option *ngFor="let targetCommunity of targetCommunities" value={{targetCommunity.communityId}}>
                    {{getCommunityNameWithEnvironment(targetCommunity)}}
                  </option>
                </select>
                <div class="custom-error pt-1"
                     *ngIf="prodToStageForm.controls['target_community'].hasError('required') && prodToStageForm.controls['target_community'].dirty">
                  Select a target community
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12" *ngIf="mockData.boardList !== undefined || mockData.boardList !== undefined">
                <div class="tree-view">
                  <label>Select entity to migrate<span
                    class="label-required">*</span>:</label>
                  <ul class="pad-tree-view font-weight-normal">
                    <app-category *ngFor="let category of mockData.categoryList" [data]="mockData.categoryList"
                                  [parent]="mockData.categoryList" [category]="category"></app-category>
                    <app-board *ngFor="let board of mockData.boardList" [parent]="null" [board]="board"></app-board>
                  </ul>
                </div>
              </div>
            </div>
            <div class="box-footer" style="margin-bottom: 5em !important;">
              <button id="mig-submit-btn" [disabled]="!prodToStageForm.valid"
                      class="btn bg-blue btn-flat margin pull-left btn-pdt">Migrate
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<app-modal [genericMessage]="messageToDisplay" [canDelete]=false></app-modal>
