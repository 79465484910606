import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { CommunityMappingService } from "../../../core/services/communityMapping/community-mapping.service";
import { Customer } from "../../../shared/models/customer";
import { Community } from "../../../shared/models/community";
import { LithiumBoard } from "../../../shared/models/lithium-board";
import { CustomField } from "../../../shared/models/custom-field";
import { CommunityMappings } from "../../../shared/models/communityMappings";
import { ClientsService } from "../../../core/services/customer/clients.service";
import { MappingsService } from "../../../core/services/Mappings/mappings.service";
import { ModalComponent } from "../../../shared/components/modal/pages/modal.component";
import { forkJoin } from "rxjs";
import { Constants } from "../../../shared/constants/constants";
import { Category } from "../../../shared/models/category";
import { Autosyndication } from "../../../shared/models/autosyndication";
// import {Observable} from 'rxjs';
// import {map, startWith} from 'rxjs/operators';
// import {SearchOptionsPipe} from '../../../shared/pipes/search/searchCategories'
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationDialogComponent } from "src/app/shared/confirmationDialog/confirmationdialog.component";

@Component({
  selector: "app-community-mapping",
  templateUrl: "./community-mapping.component.html",
  styleUrls: ["./community-mapping.component.css"],
})
export class CommunityMappingComponent implements OnInit {
  @ViewChild(ModalComponent, { static: true }) modalComponent;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatPaginator, { static: false })
  autosyndicationMappingsPaginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) autosyndicationMappingsSort: MatSort;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild("csvImportBoard") csvImportBoard;
  dataSource: MatTableDataSource<any>;
  autosyndicationMappingDataSource: MatTableDataSource<any>;
  public communityMappingForm: FormGroup;
  communities: Community[] = [];
  fileToUpload: File = null;
  communits: Community[] = [];
  boards: LithiumBoard[] = [];
  filteredBoards: LithiumBoard[] = [];
  categories: Category[] = [];
  autosyndications: Autosyndication[];
  autoSyndicationCommunities: Community[] = [];
  changesinautoSyndicationMappings: [];
  communitiesForAutoSyndication: any[] = [];
  communityMapping: CommunityMappings[] = [];
  public customFields: CustomField[] = [];
  commCust: Customer[];
  availCommunities: any[] = [];
  selectedCommunities: any[] = [];
  selectedCommunityValues: any[] = [];
  messageToDisplay: any;
  customerId: any;
  selectedCommunity: any;
  downloadCSV: any;
  total: number;
  activeSort: string = "sourceCommunity.communityId";
  sortOrder: string = "asc";
  board: any = "";
  sourceCommunityId: number;
  targetCommunityId: number;
  isCommunitySelected: any;
  autoSyndication: any;
  public selectedVal: any[] = [];
  showSpinner = false;
  isDelete = false;
  communityTobeSave: any;
  pageSize: number = 5;
  currentPage: number = 0;
  showAutoSyndication: boolean = false;
  autoSyndicationType: string = "board";
  isEditButtonClicked = false;
  config = new MatSnackBarConfig();
  displayedColumns: string[] = [
    "boardLabels",
    "name",
    "targetCommunityId",
    "targetCategoryId",
    "targetBoardId",
    "actions",
  ];
  tableColumns: string[] = [
    "sourceCommunity.communityName",
    "targetCommunity.communityName",
    "action",
    "metadatastatus",
  ];
  customerObj = JSON.parse(localStorage.getItem("customerObj"));
  superAdmin = false;
  public index;

  constructor(
    private fb: FormBuilder,
    private communityMappingService: CommunityMappingService,
    private customerService: ClientsService,
    private mappingService: MappingsService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
    this.buildForm();
  }

  ngOnInit() {
    this.config.verticalPosition = "top";
    this.config.horizontalPosition = "end";
    this.config.duration = 5000;
    this.config.panelClass = ["blue-snackbar"];
    this.superAdmin =
      localStorage.getItem("isSuperAdmin") === "yes" ? true : false;
    if (this.superAdmin) {
      this.getCustomersAndCommunityMappings(
        this.pageSize,
        this.currentPage,
        this.activeSort,
        this.sortOrder
      );
    } else if (!this.superAdmin) {
      this.getCommunityMappingsByCustomerId();
      this.populateCommunities();
    }
  }

  buildForm() {
    this.communityMappingForm = this.fb.group({
      customerName: new FormControl("", []),
      communityName: new FormControl("", [Validators.required]),
      selectedToAdd: new FormControl([], []),
      selectedToRemove: new FormControl([], []),
      autoSyndication: new FormControl(false),
      importFile: new FormControl("", []),
    });
  }

  /** onCustomerChange*/
  onCustomerChange(event) {
    this.communits = [];
    this.communityMappingForm.controls["communityName"].setValue("");
    this.customerId = event.target.value;
    if (this.customerId !== "" && this.customerId !== undefined) {
      this.customerId = +this.customerId;
      this.getCommunitybyCustomerId(this.customerId);
    } else {
      this.reset();
    }
  }

  /** getCommunitybyCustomerId retrieves the list of community respective to customer*/
  getCommunitybyCustomerId(customerId) {
    this.showSpinner = true;
    this.mappingService.getCommunitybyCustomerId(this.customerId).subscribe(
      (res) => {
        this.showSpinner = false;
        this.communities = res.response.data;
        this.getAutoSyndicationBoardsAndCategories(this.selectedCommunity);
        this.autoSyndicationCommunities = this.communities.filter(
          (community) => community.active === true
        );
        if (this.communities.length > 0) {
          this.communits = [];
          this.availCommunities = [];
          this.communities.map((obj) => {
            if (obj.communityFunction === "SOURCE") {
              this.communits.push(obj);
            } else if (obj.communityFunction === "TARGET") {
              this.availCommunities.push(obj);
            } else if (obj.communityFunction === "BOTH") {
              this.communits.push(obj);
            }
          });
        }
      },
      (error) => {
        this.showSpinner = false;
        if (
          error.status === 401 &&
          error.error.response.status === "error" &&
          error.error.response.message === "UnAuthorized Access Token Expired"
        ) {
          return this.snackBar.open(
            Constants.SESSION_EXPIRED,
            "Close",
            this.config
          );
        } else {
          return this.snackBar.open(
            error.error.response.message,
            "Close",
            this.config
          );
        }
      }
    );
  }

  onCommunityChange(event) {
    const communitId = +event.target.value;
    this.isCommunitySelected = event.target.value;
    this.autoSyndicationCommunities = this.autoSyndicationCommunities.filter(
      (community) => community.communityId !== this.isCommunitySelected
    );
    if (communitId !== 0) {
      this.getCommunityMappingsDataByCustomerId(communitId);
      console.log("first", this.communityMapping);
    } else {
      this.selectedCommunities = [];
      this.availCommunities = [];
    }
  }

  getCommunityMappingsDataByCustomerId(communitId) {
    this.showSpinner = true;
    this.communityMappingService
      .getCommunityMappingsByCommunityId(communitId)
      .subscribe(
        (mappings) => {
          this.showSpinner = false;
          const exisMapping = mappings.response.data.filter(
            (mapping: CommunityMappings) => {
              return mapping.sourceCommunity.communityId === communitId;
            }
          );

          let selectedCommunity = this.communits.filter(
            (community) => community.communityId === communitId
          );
          if (
            selectedCommunity[0].communityType.communityTypeName === "KHOROS"
          ) {
            this.showAutoSyndication = true;
            this.getCommunitySourceBoards(selectedCommunity[0].communityId);
          } else {
            this.showAutoSyndication = false;
          }
          if (exisMapping.length > 0) {
            this.edit(exisMapping[0]);
          } else {
            const arr: any[] = [];
            const selectedValue = communitId;
            this.sourceCommunityId = communitId;
            this.availCommunities = this.communities.slice();
            this.communities.map((obj) => {
              if (obj.communityFunction !== "SOURCE") {
                arr.push(obj);
              }
            });
            this.availCommunities = arr;
            this.availCommunities.map((obj) => {
              if (obj.communityId === selectedValue) {
                this.availCommunities.splice(
                  this.availCommunities.indexOf(obj),
                  1
                );
                this.selectedCommunities = [];
              }
            });
          }
        },
        (error) => {
          this.showSpinner = false;
          if (
            error.status === 401 &&
            error.error.response.status === "error" &&
            error.error.response.message === "UnAuthorized Access Token Expired"
          ) {
            return this.snackBar.open(
              Constants.SESSION_EXPIRED,
              "Close",
              this.config
            );
          } else {
            return this.snackBar.open(
              error.error.response.message,
              "Close",
              this.config
            );
          }
        }
      );
  }

  onFileChange(files: FileList) {
    this.csvImportBoard.nativeElement.innerText = Array.from(files)
      .map((f) => f.name)
      .join(", ");
    this.fileToUpload = files.item(0);
    if (!this.validateFile(files[0].name)) {
      this.communityMappingForm.controls["importFile"].setValue("");
      this.fileToUpload = null;
      return this.snackBar.open(
        Constants.FILE_EXTENSION_SUPPORTED,
        "Close",
        this.config
      );
    }
  }
  validateFile(name: String) {
    let ext = name.substring(name.lastIndexOf(".") + 1);
    if (ext.toLowerCase() == "csv") {
      return true;
    } else {
      return false;
    }
  }
  setTargets() {
    const selectedValues =
      this.communityMappingForm.controls["selectedToAdd"].value;
    this.selectedVal = selectedValues;
    this.selectedVal.map((obj) => {
      this.availCommunities.map((obj1) => {
        this.targetCommunityId = obj1.communityId;
        if (+obj === obj1.communityId) {
          this.selectedCommunities.push(obj1);
          this.availCommunities.splice(this.availCommunities.indexOf(obj1), 1);
        }
      });
    });
  }

  removeTargets() {
    const selectedValues: Array<any> =
      this.communityMappingForm.controls["selectedToRemove"].value;
    this.selectedCommunityValues = selectedValues;
    this.selectedCommunityValues.map((obj) => {
      this.selectedCommunities.map((obj1) => {
        if (obj === obj1.communityName) {
          this.availCommunities.push(obj1);
          this.selectedCommunities.splice(
            this.selectedCommunities.indexOf(obj1),
            1
          );
        }
      });
    });
  }

  csvDownlaod(event) {
    this.sourceCommunityId =
      +this.communityMappingForm.controls["communityName"].value;
    this.communityMappingService.downloadCSV(this.sourceCommunityId).subscribe(
      (res: any) => {
        if (res.length > 0) {
          const csvRows = [];
          const headers = Object.keys(res[0]);
          csvRows.push(headers);
          for (const row of res) {
            const values = headers.map((header) => row[header]);
            csvRows.push(values);
          }
          const csvString = csvRows.map((row) => row.join(",")).join("\n");
          const blob = new Blob([csvString], { type: "text/csv" });
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = "Auto-Syndication Data.csv";
          link.click();
        }
      },
      (error) => {
        this.showSpinner = false;
        if (
          error.status === 401 &&
          error.error.response.status === "error" &&
          error.error.response.message === "UnAuthorized Access Token Expired"
        ) {
          return this.snackBar.open(
            Constants.SESSION_EXPIRED,
            "Close",
            this.config
          );
        } else {
          return this.snackBar.open(
            error.error.response.message,
            "Close",
            this.config
          );
        }
      }
    );
  }

  csvSubmit(event) {
    this.showSpinner = true;
    this.sourceCommunityId =
      +this.communityMappingForm.controls["communityName"].value;
    const isAutosyndicationSelected =
      this.communityMappingForm.controls["autoSyndication"].value;
    if (
      isAutosyndicationSelected &&
      this.communitiesForAutoSyndication.length === 0 &&
      this.fileToUpload == null
    ) {
      this.showSpinner = false;
      return this.modalComponent.populateModal({
        response: { message: Constants.SELECT_ATLEAST_ONE_MAPPING },
      });
    }
    if (this.selectedCommunities.length === 0) {
      this.showSpinner = false;
      this.modalComponent.populateModal({
        response: { message: Constants.TARGET_COMMUNITY_REQUIRED_MESSAGE },
      });
      return;
    }
    let targetIdList: any[] = [];
    const isMappingExist = this.communityMapping.filter(
      (mapping) =>
        mapping.sourceCommunity.communityId === this.sourceCommunityId
    )[0];
    if (isMappingExist) {
      const exitingTargets = isMappingExist.targetCommunity;
      const exitingTargetids = exitingTargets.map(
        (community: Community) => community.communityId
      );
      const selectedCommunityIds = this.selectedCommunities.map(
        (community: Community) => community.communityId
      );

      if (
        JSON.stringify(exitingTargetids.sort()) ===
        JSON.stringify(selectedCommunityIds.sort())
      ) {
        targetIdList = [];
      } else {
        this.selectedCommunities.map((mapObj) => {
          targetIdList.push({ communityId: mapObj.communityId });
        });
      }
    } else {
      this.selectedCommunities.map((mapObj) => {
        targetIdList.push({ communityId: mapObj.communityId });
      });
    }

    if (!this.superAdmin) {
      this.customerId = this.customerObj.id;
    }
    this.communityMappingService
      .csvSubmit(
        this.customerId,
        this.sourceCommunityId,
        targetIdList,
        isAutosyndicationSelected,
        this.fileToUpload
      )
      .subscribe(
        (res) => {
          this.showSpinner = false;
          if (this.superAdmin) {
            this.getCustomersAndCommunityMappings(
              this.pageSize,
              this.currentPage,
              this.activeSort,
              this.sortOrder
            );
          } else {
            this.getCommunityMappingsByCustomerId();
          }
          this.showSpinner = false;
          this.reset();
          if (!this.superAdmin) {
            this.populateCommunities();
          }

          this.modalComponent.populateModal(res);
        },
        (error) => {
          this.showSpinner = false;
          if (
            error.status === 401 &&
            error.error.response.status === "error" &&
            error.error.response.message === "UnAuthorized Access Token Expired"
          ) {
            return this.snackBar.open(
              Constants.SESSION_EXPIRED,
              "Close",
              this.config
            );
          } else {
            return this.snackBar.open(
              error.error.response.message,
              "Close",
              this.config
            );
          }
        }
      );
  }

  submit() {
    this.sourceCommunityId =
      +this.communityMappingForm.controls["communityName"].value;
    const isAutosyndicationSelected =
      this.communityMappingForm.controls["autoSyndication"].value;
    if (
      isAutosyndicationSelected &&
      this.communitiesForAutoSyndication.length === 0
    ) {
      return this.modalComponent.populateModal({
        response: { message: Constants.SELECT_ATLEAST_ONE_MAPPING },
      });
    }
    if (isAutosyndicationSelected) {
      for (let entry of this.communitiesForAutoSyndication) {
        if (entry.targetCommunityId === 0 || entry.name === "") {
          if (isAutosyndicationSelected) {
            return this.modalComponent.populateModal({
              response: { message: Constants.SELECT_TARGET_COMMUNITY },
            });
          }
        }
        entry.sourceCommunityId = this.sourceCommunityId;
        entry.targetCommunityId = parseInt(entry.targetCommunityId);
      }
      // added validation for checking duplicate entities for AutoSyndication Mappings
      for (let dataObj of this.communitiesForAutoSyndication) {
        if (
          dataObj.targetCommunityId === 0 ||
          dataObj.name === "" ||
          dataObj.targetCategoryId === undefined ||
          dataObj.targetCategoryId === "" ||
          dataObj.targetBoardId === undefined ||
          dataObj.targetBoardId === ""
        ) {
          return this.modalComponent.populateModal({
            response: { message: Constants.SELECT_TARGET_COMMUNITY },
          });
        }
        let k = this.communitiesForAutoSyndication.filter(
          (obj) =>
            obj.name === dataObj.name &&
            obj.type === dataObj.type &&
            obj.targetCommunityId === dataObj.targetCommunityId
        ).length;
        if (k > 1) {
          return this.modalComponent.populateModal({
            response: { message: Constants.DUPLICATE_ENTITIES_FOUND },
          });
        }
      }
    }
    if (this.selectedCommunities.length === 0) {
      this.modalComponent.populateModal({
        response: { message: Constants.TARGET_COMMUNITY_REQUIRED_MESSAGE },
      });
      return;
    }
    for (let entry of this.communitiesForAutoSyndication) {
      let sourceBoard = this.boards.filter((obj) => obj.id === entry.name)[0];
      if (sourceBoard !== undefined) {
        entry.sourceBoardTitle = sourceBoard.title;
      }
      let targetCategory = entry.categories.filter(
        (obj) => obj.categoryId === entry.targetCategoryId
      )[0];
      if (targetCategory !== undefined) {
        entry.targetCategoryTitle = targetCategory.categoryTitle;
      }
      let targetBoards = entry.targetBoards.filter(
        (obj) => obj.id.toString() === entry.targetBoardId + ""
      )[0];
      if (targetBoards !== undefined) {
        entry.targetBoardTitle = targetBoards.title;
      }
    }
    let targetIdList: any[] = [];
    const isMappingExist = this.communityMapping.filter(
      (mapping) =>
        mapping.sourceCommunity.communityId === this.sourceCommunityId
    )[0];
    if (isMappingExist) {
      const exitingTargets = isMappingExist.targetCommunity;
      const exitingTargetids = exitingTargets.map(
        (community: Community) => community.communityId
      );
      const selectedCommunityIds = this.selectedCommunities.map(
        (community: Community) => community.communityId
      );

      if (
        JSON.stringify(exitingTargetids.sort()) ===
        JSON.stringify(selectedCommunityIds.sort())
      ) {
        targetIdList = [];
      } else {
        this.selectedCommunities.map((mapObj) => {
          targetIdList.push({ communityId: mapObj.communityId });
        });
      }
    } else {
      this.selectedCommunities.map((mapObj) => {
        targetIdList.push({ communityId: mapObj.communityId });
      });
    }

    if (!this.superAdmin) {
      this.customerId = this.customerObj.id;
    }
    this.showSpinner = true;
    let autoSyndicationObjects = this.communitiesForAutoSyndication.map(
      (object) => ({ ...object })
    );

    // let changesinautoSyndicationMappings = this.changesinautoSyndicationMappings
    for (let entry of autoSyndicationObjects) {
      let sourceCommunity = this.communities.filter(
        (data) => data.communityId === entry.sourceCommunityId
      )[0];
      let targetCommunity = this.communities.filter(
        (data) => data.communityId === entry.targetCommunityId
      )[0];
      let sourceCommunityType = sourceCommunity.communityType.communityTypeName;
      entry.sourceCommunityType = sourceCommunityType;
      entry.sourceCommunity = sourceCommunity;
      entry.targetCommunity = targetCommunity;
      entry.categories = null;
      entry.targetBoards = null;
    }

    this.communityMappingService
      .save(
        this.customerId,
        this.sourceCommunityId,
        targetIdList,
        isAutosyndicationSelected
      )
      .subscribe(
        (res) => {
          if (this.superAdmin) {
            this.getCustomersAndCommunityMappings(
              this.pageSize,
              this.currentPage,
              this.activeSort,
              this.sortOrder
            );
          } else {
            this.getCommunityMappingsByCustomerId();
          }
          this.showSpinner = false;
          this.reset();
          if (!this.superAdmin) {
            this.populateCommunities();
          }

          this.modalComponent.populateModal(res);
        },
        (error) => {
          this.showSpinner = false;
          if (
            error.status === 401 &&
            error.error.response.status === "error" &&
            error.error.response.message === "UnAuthorized Access Token Expired"
          ) {
            return this.snackBar.open(
              Constants.SESSION_EXPIRED,
              "Close",
              this.config
            );
          } else {
            return this.snackBar.open(
              error.error.response.message,
              "Close",
              this.config
            );
          }
        }
      );
  }

  edit(community) {
    this.selectedCommunities = [];
    this.selectedCommunity = community;
    this.communityMappingForm.controls["customerName"].setValue(
      community.customer.customerId
    );
    this.onCustomerChange({ target: { value: community.customer.customerId } });
    this.communityMappingForm.controls["communityName"].setValue(
      community.sourceCommunity.communityId
    );
    this.communityMappingForm.controls["autoSyndication"].setValue(
      community.sourceCommunity.autoSyndicate
    );
    this.selectedCommunities = community.targetCommunity.slice();
    //this.showSpinner = true;
    this.isCommunitySelected = community.sourceCommunity.communityName;
    if (
      community.sourceCommunity.communityType.communityTypeName === "KHOROS"
    ) {
      this.showAutoSyndication = true;
      this.getCommunitySourceBoards(community.sourceCommunity.communityId);
    } else {
      this.showAutoSyndication = false;
    }
  }

  getAutoSyndicationBoardsAndCategories(community: any) {
    if (community !== undefined && community !== "") {
      let communities: Community[];
      const targetIds: Array<number> = [];
      this.availCommunities = [];
      this.showSpinner = true;
      this.communityMappingService
        .getAutoSyndicationMappings(community.sourceCommunity.communityId)
        .subscribe(
          (result) => {
            this.communitiesForAutoSyndication = result.response.data;
            // console.log(result.response.data, 'data which needed to be edited and sent with changes');
            // this.storeFetchedData(this.communitiesForAutoSyndication)
            // console.log(result.response.data[0])
            // console.log(result.response.data[0].targetBoards)

            for (let entry of this.communitiesForAutoSyndication) {
              entry.categories = this.uniq(entry.categories, "categoryId");
              entry.filteredTargetCategorie = entry.categories;
              entry.filteredTargetBoards = entry.targetBoards;
              entry.filteredBoards = entry.sourceBoards;
              entry.isUpdate = true;
            }
            this.autosyndicationMappingDataSource = new MatTableDataSource();
            this.updateAutosyndicationDataSource();
            if (
              community.sourceCommunity.communityType.communityTypeName ===
              "KHOROS"
            )
              this.showSpinner = true;
            this.mappingService
              .getCommunitybyCustomerId(community.customer.customerId)
              .subscribe(
                (res) => {
                  this.showSpinner = false;
                  // this.getAutoSyndicationMappings(this.sourceCommunityId);
                  this.autoSyndicationCommunities =
                    this.autoSyndicationCommunities.filter(
                      (community) =>
                        community.communityName !== this.isCommunitySelected
                    );
                  communities = res.response.data;
                  communities = this.communities.slice();
                  let leftOverData: Community[] = communities.filter(
                    (communityTwo: Community) => {
                      return (
                        communityTwo.communityId !==
                        community.sourceCommunity.communityId
                      );
                    }
                  );
                  community.targetCommunity.map((target: Community) => {
                    targetIds.push(target.communityId);
                  });
                  leftOverData = leftOverData.filter(
                    (val) => !targetIds.includes(val.communityId)
                  );

                  this.availCommunities = leftOverData.slice();

                  this.availCommunities = this.availCommunities.filter(
                    (availableCommunity: Community) => {
                      return availableCommunity.communityFunction !== "SOURCE";
                    }
                  );
                },
                (error) => {
                  this.showSpinner = false;
                  return this.snackBar.open(
                    error.error.response.message,
                    "Close",
                    this.config
                  );
                }
              );
          },
          (error) => {
            this.showSpinner = false;
            if (
              error.status === 401 &&
              error.error.response.status === "error" &&
              error.error.response.message ===
                "UnAuthorized Access Token Expired"
            ) {
              return this.snackBar.open(
                Constants.SESSION_EXPIRED,
                "Close",
                this.config
              );
            } else {
              return this.snackBar.open(
                error.error.response.message,
                "Close",
                this.config
              );
            }
          }
        );
    }
  }

  uniq(a, param) {
    return a.filter(function (item, pos, array) {
      return (
        array
          .map(function (mapItem) {
            return mapItem[param];
          })
          .indexOf(item[param]) === pos
      );
    });
  }
  reset() {
    this.buildForm();
    this.selectedCommunities = [];
    this.autoSyndicationCommunities = [];
    this.communitiesForAutoSyndication = [];
    this.updateAutosyndicationDataSource();
    this.isCommunitySelected = undefined;
    this.communityMappingForm.controls["autoSyndication"].setValue(false);
    this.availCommunities = [];
    if (this.superAdmin) {
      this.communits = [];
    } else {
      this.populateCommunities();
    }
  }

  delete(community: Community) {
    this.communityTobeSave = community;
    this.isDelete = true;
    this.modalComponent.populateModal({
      response: { message: Constants.DELETE_CONFIRMATION_MESSAGE },
    });
  }

  confirmDelete(confirmMsg: any) {
    if (confirmMsg) {
      const isAutosyndicationSelected =
        this.communityMappingForm.controls["autoSyndication"].value;
      this.modalComponent.closeModal();
      this.showSpinner = true;
      const customerId = this.communityTobeSave.customer.customerId;
      const communityId = this.communityTobeSave.sourceCommunity.communityId;
      const communityMappingId = this.communityTobeSave.communityMappingId;
      this.communityMappingService
        .delete(
          communityMappingId,
          customerId,
          communityId,
          [],
          isAutosyndicationSelected
        )
        .subscribe(
          (res) => {
            this.showSpinner = false;
            if (this.superAdmin) {
              this.getCustomersAndCommunityMappings(
                this.pageSize,
                this.currentPage,
                this.activeSort,
                this.sortOrder
              );
            } else {
              this.getCommunityMappingsByCustomerId();
            }
            this.reset();
            if (!this.superAdmin) {
              this.populateCommunities();
            }
            this.modalComponent.populateModal({
              response: { message: Constants.COMMUNITY_DELTED_SUCCESSFULLY },
            });
          },
          (error) => {
            this.showSpinner = false;
            if (
              error.status === 401 &&
              error.error.response.status === "error" &&
              error.error.response.message ===
                "UnAuthorized Access Token Expired"
            ) {
              return this.snackBar.open(
                Constants.SESSION_EXPIRED,
                "Close",
                this.config
              );
            } else {
              return this.snackBar.open(
                error.error.response.message,
                "Close",
                this.config
              );
            }
          }
        );
    } else {
      this.modalComponent.closeModal();
    }
    this.isDelete = false;
  }

  onChangePage(event) {
    if (this.pageSize !== event.pageSize) {
      this.currentPage = 0; // Reset to first page if page size changes
    } else {
      this.currentPage = event.pageIndex;
    }
    this.pageSize = +event.pageSize;
    if (this.superAdmin) {
      this.getCustomersAndCommunityMappings(
        this.pageSize,
        this.currentPage,
        this.activeSort,
        this.sortOrder
      );
    } else if (!this.superAdmin) {
      this.getCommunityMappingsByCustomerId();
      this.populateCommunities();
    }
  }
  /*retrieves active customers and existing community mappings*/
  getCustomersAndCommunityMappings(
    pageSize,
    currentPage,
    activeSort,
    sortOrder
  ) {
    this.showSpinner = true;
    forkJoin(
      this.customerService.getActiveCustomers(
        localStorage.getItem("loginUserId")
      ),
      this.communityMappingService.getCommunityMappings(
        pageSize,
        currentPage,
        activeSort,
        sortOrder
      )
    ).subscribe(
      ([customers, mappings]) => {
        this.showSpinner = false;
        this.commCust = customers.response.data;
        this.communityMapping = mappings.response.data;
        this.dataSource = new MatTableDataSource();
        this.dataSource.data = this.communityMapping;
        // this.dataSource.paginator = this.paginator;
        this.total = mappings.response.totalRecordsCount;
        this.dataSource.paginator = currentPage;
        if (
          this.paginator != undefined &&
          typeof this.paginator.length === "undefined"
        ) {
          this.paginator.length = currentPage;
        }
        this.paginator.pageIndex = currentPage;
        this.paginator.pageSize = pageSize;
        this.dataSource.sortingDataAccessor = (item, property) => {
          if (property.includes("."))
            return property.split(".").reduce((o, i) => o[i], item);
          return item[property];
        };

        // this.dataSource.sort = this.sort;
      },
      (error) => {
        this.showSpinner = false;
        if (
          error.status === 401 &&
          error.error.response.status === "error" &&
          error.error.response.message === "UnAuthorized Access Token Expired"
        ) {
          return this.snackBar.open(
            Constants.SESSION_EXPIRED,
            "Close",
            this.config
          );
        } else {
          return this.snackBar.open(
            error.error.response.message,
            "Close",
            this.config
          );
        }
      }
    );
  }

  getCommunityMappingsByCustomerId() {
    this.showSpinner = true;
    this.communityMappingService
      .getCommunityMappingsByCustomerId(this.customerObj.id)
      .subscribe(
        (mappings) => {
          this.showSpinner = false;
          this.communityMapping = mappings.response.data;
          this.dataSource = new MatTableDataSource();
          this.dataSource.data = this.communityMapping;
          this.dataSource.paginator = this.paginator;
          this.dataSource.sortingDataAccessor = (item, property) => {
            if (property.includes("."))
              return property.split(".").reduce((o, i) => o[i], item);
            return item[property];
          };

          this.dataSource.sort = this.sort;
        },
        (error) => {
          this.showSpinner = false;
          if (
            error.status === 401 &&
            error.error.response.status === "error" &&
            error.error.response.message === "UnAuthorized Access Token Expired"
          ) {
            return this.snackBar.open(
              Constants.SESSION_EXPIRED,
              "Close",
              this.config
            );
          } else {
            return this.snackBar.open(
              error.error.response.message,
              "Close",
              this.config
            );
          }
        }
      );
  }
  onSortChange(event) {
    this.sortOrder =
      event.direction == ""
        ? this.sortOrder == "asc"
          ? "desc"
          : "asc"
        : this.sortOrder == event.direction
        ? this.sortOrder == "asc"
          ? "desc"
          : "asc"
        : event.direction;
    if (this.superAdmin) {
      this.getCustomersAndCommunityMappings(
        this.pageSize,
        this.currentPage,
        event.active,
        this.sortOrder
      );
    } else if (!this.superAdmin) {
      this.getCommunityMappingsByCustomerId();
      this.populateCommunities();
    }
  }
  populateCommunities() {
    if (!this.superAdmin) {
      const customerId = this.customerObj.id;
      this.onCustomerChange({ target: { value: customerId } });
    }
  }

  public add() {
    const autoSyndicationSelectedLength =
      this.communitiesForAutoSyndication.length;
    if (autoSyndicationSelectedLength > 0) {
      let autoSyndicate =
        this.communitiesForAutoSyndication[autoSyndicationSelectedLength - 1];

      if (
        autoSyndicate.targetCommunityId === 0 ||
        autoSyndicate.name === "" ||
        autoSyndicate.targetCategoryId === undefined ||
        autoSyndicate.targetCategoryId === "" ||
        autoSyndicate.targetBoardId === undefined ||
        autoSyndicate.targetBoardId === ""
      ) {
        return this.modalComponent.populateModal({
          response: { message: Constants.SELECT_TARGET_COMMUNITY },
        });
      }
    }
    const limit = this.autoSyndicationCommunities.length;

    this.communitiesForAutoSyndication.push({
      id: 0,
      label: "",
      name: "",
      type: this.autoSyndicationType,
      targetCommunityId: 0,
      sourceCommunityId: 0,
      syndicateLabels: false,
      boardLabels: "",
      sourceCommunityType: "",
      isUpdate: false,
      targetCategoryId: "",
      targetBoardId: "",
      sourceBoardTitle: "",
      targetBoardTitle: "",
      targetCategoryTitle: "",
      targetBoards: ([] = []),
      postAsMachineUser: false,
      filteredBoards: this.boards,
    });
    this.updateAutosyndicationDataSource();
  }
  public getCommunitiesForAutoSyndicate(index): Community[] {
    let communityList: any[];
    if (index === 0) {
      return this.autoSyndicationCommunities;
    } else {
      for (let entry of this.communitiesForAutoSyndication) {
        communityList = this.autoSyndicationCommunities.filter(
          (community) => community.communityName !== entry.targetCommunity
        );
      }
      return communityList;
    }
  }
  public confirm(autoSyndicate: any) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "376px",
      height: "197px",
      data: {
        title: "Delete Auto-syndication",
        message: "Are you sure you want to delete this row?",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let editableData = this.getAutoSyndicationMappingById(
          this.communitiesForAutoSyndication,
          autoSyndicate.id
        );
        editableData.isUpdate = true;
        const index = this.communitiesForAutoSyndication.findIndex(
          (record) => record.id === autoSyndicate.id
        );
        if (editableData.id) {
          this.communitiesForAutoSyndication[index] = editableData;
          this.showSpinner = true;
          this.communityMappingService
            .deleteAutoSyndicationMapping(editableData.id)
            .subscribe(
              (res) => {
                this.showSpinner = false;
                this.communitiesForAutoSyndication.splice(index, 1);
                this.updateAutosyndicationDataSource();
                this.snackBar.open(
                  Constants.AUTOSYNDICATION_MAPPINGS_DELETED_SUCCESSFULLY,
                  "Close",
                  this.config
                );
              },
              (error) => {
                console.error("Error while deleting row:", error);
                this.showSpinner = false;
                if (
                  error.status === 401 &&
                  error.error.response.status === "error" &&
                  error.error.response.message ===
                    "UnAuthorized Access Token Expired"
                ) {
                  this.snackBar.open(
                    Constants.SESSION_EXPIRED,
                    "Close",
                    this.config
                  );
                } else {
                  this.snackBar.open(
                    "Deletion of row has failed",
                    "Close",
                    this.config
                  );
                }
              }
            );
        } else {
          this.communitiesForAutoSyndication.splice(index, 1);
          this.updateAutosyndicationDataSource();
        }
      }
    });
  }

  populateAutoSyndication() {
    const checked = this.communityMappingForm.controls["autoSyndication"].value;
    if (checked) {
      const selectedCommunity =
        this.communityMappingForm.controls["communityName"].value;
      this.autoSyndicationCommunities = this.autoSyndicationCommunities.filter(
        (community) => community.communityId !== selectedCommunity
      );
    }
  }

  public getCategories(data: any): Category[] {
    let categoriesOfCommunity: any[];
    if (
      data !== undefined &&
      data !== "" &&
      this.autosyndications !== undefined
    ) {
      categoriesOfCommunity = this.autosyndications.filter((obj) => {
        obj.communityId === data;
      });
    }
    return categoriesOfCommunity;
  }

  onSourceBoardChange(autoSyndicate: any) {
    if (
      autoSyndicate.name === undefined ||
      autoSyndicate.name === "" ||
      autoSyndicate.name === "undefined"
    ) {
      return this.snackBar.open("Select Source Board", "Close", this.config);
    }
    let updatedCommunity = this.getAutoSyndicationMappingById(
      this.communitiesForAutoSyndication,
      autoSyndicate.id
    );
    const index = this.communitiesForAutoSyndication.findIndex(
      (record) => record.id === autoSyndicate.id
    );
    updatedCommunity.targetCategoryId = "";
    updatedCommunity.targetBoardId = "";
    this.communitiesForAutoSyndication[index] = updatedCommunity;
    this.updateAutosyndicationDataSource();
  }

  onTargetCommunityChange(data: any, autoSyndicate?: any) {
    if (data !== "" && data !== undefined && data !== 0) {
      this.showSpinner = true;
      this.mappingService.getCategoriesByCommunity(data).subscribe(
        (res) => {
          if (
            this.communitiesForAutoSyndication !== undefined &&
            this.communitiesForAutoSyndication.length > 0
          ) {
            let updatedCommunity = this.getAutoSyndicationMappingById(
              this.communitiesForAutoSyndication,
              autoSyndicate.id
            );
            const index = this.communitiesForAutoSyndication.findIndex(
              (record) => record.id === autoSyndicate.id
            );
            updatedCommunity.categories = res.response.data;
            updatedCommunity.categories = this.uniq(
              updatedCommunity.categories,
              "categoryId"
            );
            updatedCommunity.filteredTargetCategorie =
              updatedCommunity.categories;
            updatedCommunity.targetBoards = [];
            updatedCommunity.filteredTargetBoards =
              updatedCommunity.targetBoards;
            updatedCommunity.targetCategoryId = "";
            updatedCommunity.targetBoardId = "";
            updatedCommunity.communitId = parseInt(updatedCommunity.communitId);
            this.communitiesForAutoSyndication[index] = updatedCommunity;
            this.updateAutosyndicationDataSource();
            this.showSpinner = false;
          }
          this.showSpinner = false;
        },
        (error) => {
          this.showSpinner = false;
          return this.snackBar.open(
            error.error.response.message,
            "Close",
            this.config
          );
        }
      );
    }
  }

  onTargetCategoryChange(data: any, communitId: any, autoSyndicate?: any) {
    if (data !== "" && data !== undefined) {
      this.showSpinner = true;
      let updatedCommunity = this.getAutoSyndicationMappingById(
        this.communitiesForAutoSyndication,
        autoSyndicate.id
      );
      if (
        updatedCommunity.name === undefined ||
        updatedCommunity.name === "" ||
        updatedCommunity.name === "undefined"
      ) {
        this.showSpinner = false;
        return this.snackBar.open(
          "Select Source Board or Label(s)",
          "Close",
          this.config
        );
      }
      let selectedBoard = this.boards.filter(
        (data) => data.id === updatedCommunity.name
      )[0];
      updatedCommunity.targetBoardlimit = 100;
      updatedCommunity.targetBoardOffset = 0;
      if (updatedCommunity.type === "board" && selectedBoard !== undefined) {
        updatedCommunity.conversationStyle = selectedBoard.conversation_style;
      }
      let selectedTargetCommunity = this.autoSyndicationCommunities.filter(
        (data) => data.communityId === parseInt(communitId)
      )[0];
      if (
        selectedTargetCommunity.communityType.communityTypeName ===
          Constants.COMMUNITY_TYPE_TELLIGENT &&
        (updatedCommunity.conversationStyle === undefined ||
          (updatedCommunity.conversationStyle !== "forum" &&
            updatedCommunity.conversationStyle !== "blog"))
      ) {
        updatedCommunity.targetBoards = [];
        updatedCommunity.filteredTargetBoards = updatedCommunity.targetBoards;
        const index = this.communitiesForAutoSyndication.findIndex(
          (record) => record.id === autoSyndicate.id
        );
        this.communitiesForAutoSyndication[index] = updatedCommunity;
        this.updateAutosyndicationDataSource();
        this.showSpinner = false;
        return this.snackBar.open(
          Constants.TELLIGENT_SUPPORTED_BOARDS,
          "Close",
          this.config
        );
      } else {
        this.mappingService
          .getBoardsByCategory(
            parseInt(communitId),
            data,
            "all",
            updatedCommunity
          )
          .subscribe(
            (res) => {
              if (
                this.communitiesForAutoSyndication !== undefined &&
                this.communitiesForAutoSyndication.length > 0
              ) {
                const index = this.communitiesForAutoSyndication.findIndex(
                  (record) => record.id === autoSyndicate.id
                );
                updatedCommunity.targetBoards =
                  res.response.data.boardsMappingList;
                updatedCommunity.filteredTargetBoards =
                  res.response.data.boardsMappingList;
                if (
                  selectedTargetCommunity.communityType.communityTypeName ===
                  Constants.COMMUNITY_TYPE_TELLIGENT
                ) {
                  updatedCommunity.targetBoardSize =
                    res.response.data.TotalCount;
                  updatedCommunity.targetBoardOffset =
                    res.response.data.boardsMappingList.length - 1;
                } else {
                  updatedCommunity.targetBoardSize =
                    res.response.data.boardsMappingList.length;
                  updatedCommunity.targetBoardOffset =
                    res.response.data.boardsMappingList.length;
                  updatedCommunity.targetBoardlimit =
                    res.response.data.boardsMappingList.length;
                }
                updatedCommunity.targetBoardId = "";
                updatedCommunity.communitId = parseInt(
                  updatedCommunity.communitId
                );
                this.communitiesForAutoSyndication[index] = updatedCommunity;
                this.updateAutosyndicationDataSource();
                this.showSpinner = false;
              }
              this.showSpinner = false;
            },
            (error) => {
              this.showSpinner = false;
              return this.snackBar.open(
                error.error.response.message,
                "Close",
                this.config
              );
            }
          );
      }
    }
  }

  editAutoSyndicationMapping(autoSyndicate: any) {
    this.getCategoriesAndCommunities(autoSyndicate);
    let editableData = this.getAutoSyndicationMappingById(
      this.communitiesForAutoSyndication,
      autoSyndicate.id
    );

    editableData.isUpdate = false;
    const index = this.communitiesForAutoSyndication.findIndex(
      (record) => record.id === autoSyndicate.id
    );
    this.communitiesForAutoSyndication[index] = editableData;
    this.updateAutosyndicationDataSource();
    let sourceCommunityId = this.selectedCommunity.sourceCommunity.communityId;
    if (
      this.selectedCommunity.sourceCommunity.communityType.communityTypeName ===
      "KHOROS"
    ) {
      this.getCommunitySourceBoards(sourceCommunityId, editableData);
    }
  }

  saveAutoSyndicationMapping(autoSyndicate: any) {
    if (
      autoSyndicate.targetCommunityId === 0 ||
      autoSyndicate.name === "" ||
      autoSyndicate.targetCategoryId === undefined ||
      autoSyndicate.targetCategoryId === "undefined" ||
      autoSyndicate.targetCategoryId === "" ||
      autoSyndicate.targetBoardId === undefined ||
      autoSyndicate.targetBoardId === "undefined" ||
      autoSyndicate.targetBoardId === ""
    ) {
      return this.modalComponent.populateModal({
        response: { message: Constants.SELECT_TARGET_COMMUNITY },
      });
    }

    let editableData = this.getAutoSyndicationMappingById(
      this.communitiesForAutoSyndication,
      autoSyndicate.id
    );
    const index = this.communitiesForAutoSyndication.findIndex(
      (record) => record.id === autoSyndicate.id
    );
    this.communitiesForAutoSyndication[index] = editableData;
    this.updateAutosyndicationDataSource();
    let payload = {
      id: editableData.id,
      name: editableData.name,
      label: editableData.boardLabels,
      type: editableData.type,
      targetCategoryId: editableData.targetCategoryId,
      targetBoardId: editableData.targetBoardId,
      boardUid: editableData.boardUid,
      sourceBoardTitle: this.getBoardTitleById(
        editableData.filteredBoards,
        editableData.name
      ),
      sourceCommunity: {
        communityId: this.selectedCommunity.sourceCommunity.communityId,
      },
      sourceCommunityId: this.selectedCommunity.sourceCommunity.communityId,
      boardLabels: editableData.boardLabels,
      targetCommunity: { communityId: editableData.targetCommunityId },
      targetCommunityId: editableData.targetCommunityId,
      targetCategoryTitle: this.getCategoryTitleById(
        editableData.filteredTargetCategorie,
        editableData.targetCategoryId
      ),
      targetBoardTitle: this.getBoardTitleById(
        editableData.filteredTargetBoards,
        editableData.targetBoardId
      ),
      postAsMachineUser: editableData.postAsMachineUser,
      syndicateSolutionsOnly: editableData.syndicateSolutionsOnly,
      syndicateLabels: editableData.syndicateLabels,
    };

    this.showSpinner = true;
    this.communityMappingService
      .postAutoSyndicationMapping(editableData.type, payload)
      .subscribe(
        (res) => {
          this.showSpinner = false;
          editableData.id = res.id;
          editableData.isUpdate = true;
          //editableData.filteredBoards = res;
          this.communitiesForAutoSyndication[index] = editableData;
          this.updateAutosyndicationDataSource();
          this.snackBar.open(
            Constants.AUTOSYNDICATION_MAPPINGS_SAVED_SUCCESSFULLY,
            "Close",
            this.config
          );
        },
        (error) => {
          this.showSpinner = false;
          if (
            error.status === 401 &&
            error.error.response.status === "error" &&
            error.error.response.message === "UnAuthorized Access Token Expired"
          ) {
            this.snackBar.open(Constants.SESSION_EXPIRED, "Close", this.config);
          } else {
            this.snackBar.open(
              error.error.response.message,
              "Close",
              this.config
            );
          }
        }
      );
    console.log(payload, "new payload");
  }

  getCategoriesAndCommunities(autoSyndicate: any) {
    if (
      autoSyndicate.name === undefined ||
      autoSyndicate.name === "" ||
      autoSyndicate.name === "undefined"
    ) {
      return this.snackBar.open("Select Source Board", "Close", this.config);
    }
    this.showSpinner = true;
    let selectedBoard = this.boards.filter(
      (data) => data.id === autoSyndicate.name
    )[0];
    let selectedTargetCommunity = this.autoSyndicationCommunities.filter(
      (data) => data.communityId === parseInt(autoSyndicate.targetCommunityId)
    )[0];
    autoSyndicate.targetBoardlimit = 100;
    autoSyndicate.targetBoardOffset = 0;
    if (selectedBoard !== undefined) {
      autoSyndicate.conversationStyle = selectedBoard.conversation_style;
    }
    if (
      selectedTargetCommunity.communityType.communityTypeName ===
        Constants.COMMUNITY_TYPE_TELLIGENT &&
      (autoSyndicate.conversationStyle === undefined ||
        (autoSyndicate.conversationStyle !== "forum" &&
          autoSyndicate.conversationStyle !== "blog"))
    ) {
      let updatedCommunity = this.getAutoSyndicationMappingById(
        this.communitiesForAutoSyndication,
        autoSyndicate.id
      );
      updatedCommunity.targetBoards = [];
      updatedCommunity.filteredTargetBoards = updatedCommunity.targetBoards;
      const index = this.communitiesForAutoSyndication.findIndex(
        (record) => record.id === autoSyndicate.id
      );
      if (index !== -1) {
        this.communitiesForAutoSyndication[index] = updatedCommunity;
      }
      //this.communitiesForAutoSyndication[id] = updatedCommunity;
      this.updateAutosyndicationDataSource();
      this.showSpinner = false;
      return this.snackBar.open(
        Constants.TELLIGENT_SUPPORTED_BOARDS,
        "Close",
        this.config
      );
    } else {
      forkJoin(
        this.mappingService.getCategoriesByCommunity(
          autoSyndicate.targetCommunityId
        ),
        this.mappingService.getBoardsByCategory(
          parseInt(autoSyndicate.targetCommunityId),
          autoSyndicate.targetCategoryId,
          "all",
          autoSyndicate
        )
      ).subscribe(
        ([categories, boards]) => {
          if (
            this.communitiesForAutoSyndication !== undefined &&
            this.communitiesForAutoSyndication.length > 0
          ) {
            let updatedCommunity = this.getAutoSyndicationMappingById(
              this.communitiesForAutoSyndication,
              autoSyndicate.id
            );
            let index = this.communitiesForAutoSyndication.findIndex(
              (record) => record.id === autoSyndicate.id
            );

            updatedCommunity.categories = categories.response.data;
            updatedCommunity.categories = this.uniq(
              updatedCommunity.categories,
              "categoryId"
            );
            updatedCommunity.filteredTargetCategorie =
              updatedCommunity.categories;
            updatedCommunity.targetBoards =
              boards.response.data.boardsMappingList;
            updatedCommunity.filteredTargetBoards =
              boards.response.data.boardsMappingList;
            if (
              selectedTargetCommunity.communityType.communityTypeName ===
              Constants.COMMUNITY_TYPE_TELLIGENT
            ) {
              updatedCommunity.targetBoardId = parseInt(
                updatedCommunity.targetBoardId
              );
              updatedCommunity.targetBoardSize =
                boards.response.data.TotalCount;
              updatedCommunity.targetBoardOffset =
                boards.response.data.boardsMappingList.length - 1;
            } else {
              updatedCommunity.targetBoardSize =
                boards.response.data.boardsMappingList.length;
              updatedCommunity.targetBoardOffset =
                boards.response.data.boardsMappingList.length;
              updatedCommunity.targetBoardlimit =
                boards.response.data.boardsMappingList.length;
            }
            updatedCommunity.communitId = parseInt(updatedCommunity.communitId);
            this.communitiesForAutoSyndication[index] = updatedCommunity;
            this.updateAutosyndicationDataSource();
            this.showSpinner = false;
          }
        },
        (error) => {
          this.showSpinner = false;
          if (
            error.status === 401 &&
            error.error.response.status === "error" &&
            error.error.response.message === "UnAuthorized Access Token Expired"
          ) {
            return this.snackBar.open(
              Constants.SESSION_EXPIRED,
              "Close",
              this.config
            );
          } else {
            return this.snackBar.open(
              error.error.response.message,
              "Close",
              this.config
            );
          }
        }
      );
    }
  }

  getNextBatch(autoSyndicate: any) {
    this.showSpinner = true;
    let updatedCommunity = this.getAutoSyndicationMappingById(
      this.communitiesForAutoSyndication,
      autoSyndicate.id
    );
    const index = this.communitiesForAutoSyndication.findIndex(
      (record) => record.id === autoSyndicate.id
    );
    if (
      updatedCommunity.name === undefined ||
      updatedCommunity.name === "" ||
      updatedCommunity.name === "undefined"
    ) {
      this.showSpinner = false;
      return this.snackBar.open("Select Source Board", "Close", this.config);
    }
    let selectedBoard = this.boards.filter(
      (data) => data.id === autoSyndicate.name
    )[0];
    updatedCommunity.conversationStyle = selectedBoard.conversation_style;
    let selectedTargetCommunity = this.autoSyndicationCommunities.filter(
      (data) => data.communityId === parseInt(autoSyndicate.targetCommunityId)
    )[0];
    if (
      selectedTargetCommunity.communityType.communityTypeName ===
        Constants.COMMUNITY_TYPE_TELLIGENT &&
      updatedCommunity.conversationStyle !== "forum" &&
      updatedCommunity.conversationStyle !== "blog"
    ) {
      updatedCommunity.targetBoards = [];
      updatedCommunity.filteredTargetBoards = updatedCommunity.targetBoards;
      this.communitiesForAutoSyndication[index] = updatedCommunity;
      this.updateAutosyndicationDataSource();
      this.showSpinner = false;
      return this.snackBar.open(
        Constants.TELLIGENT_SUPPORTED_BOARDS,
        "Close",
        this.config
      );
    } else if (
      selectedTargetCommunity.communityType.communityTypeName ===
        Constants.COMMUNITY_TYPE_TELLIGENT &&
      updatedCommunity.targetBoards.length >=
        updatedCommunity.targetBoardSize &&
      updatedCommunity.targetBoards.length >=
        updatedCommunity.targetBoardOffset - 1
    ) {
      this.showSpinner = false;
      return;
    } else {
      this.mappingService
        .getBoardsByCategory(
          parseInt(autoSyndicate.targetCommunityId),
          autoSyndicate.targetCategoryId,
          "all",
          updatedCommunity
        )
        .subscribe(
          (res) => {
            if (
              this.communitiesForAutoSyndication !== undefined &&
              this.communitiesForAutoSyndication.length > 0
            ) {
              const index = this.communitiesForAutoSyndication.findIndex(
                (record) => record.id === autoSyndicate.id
              );
              updatedCommunity.targetBoards.push(
                ...res.response.data.boardsMappingList
              );
              updatedCommunity.targetBoards = this.uniq(
                updatedCommunity.targetBoards,
                "id"
              );
              updatedCommunity.filteredTargetBoards =
                updatedCommunity.targetBoards;
              if (
                selectedTargetCommunity.communityType.communityTypeName ===
                Constants.COMMUNITY_TYPE_TELLIGENT
              ) {
                updatedCommunity.targetBoardOffset =
                  updatedCommunity.targetBoards.length + 1;
              } else {
                updatedCommunity.targetBoardSize =
                  res.response.data.boardsMappingList.length;
                updatedCommunity.targetBoardOffset =
                  res.response.data.boardsMappingList.length;
                updatedCommunity.targetBoardlimit =
                  res.response.data.boardsMappingList.length;
              }
              updatedCommunity.targetBoardId = "";
              updatedCommunity.communitId = parseInt(
                updatedCommunity.communitId
              );
              this.communitiesForAutoSyndication[index] = updatedCommunity;
              this.updateAutosyndicationDataSource();
              this.showSpinner = false;
            }
            this.showSpinner = false;
          },
          (error) => {
            this.showSpinner = false;
            return this.snackBar.open(
              error.error.response.message,
              "Close",
              this.config
            );
          }
        );
    }
  }

  getBoardTitleById(data: any[], id: string) {
    let filteredBoardRecords = data.filter((d) => d.id === id);
    if (filteredBoardRecords && filteredBoardRecords.length > 0) {
      return filteredBoardRecords[0].title;
    }
  }

  getCategoryTitleById(data: any[], id: string) {
    let filteredCategoryRecords = data.filter((d) => d.categoryId === id);
    if (filteredCategoryRecords && filteredCategoryRecords.length > 0) {
      return filteredCategoryRecords[0].categoryTitle;
    }
  }

  updateAutosyndicationDataSource() {
    this.autosyndicationMappingDataSource.data =
      this.communitiesForAutoSyndication;
    this.autosyndicationMappingDataSource.paginator =
      this.autosyndicationMappingsPaginator;
    this.autosyndicationMappingDataSource.sort =
      this.autosyndicationMappingsSort;
  }

  getCommunitySourceBoards(communityId: any, editableData?: any) {
    this.showSpinner = true;
    this.mappingService.getCommunityBoards(communityId).subscribe(
      (res) => {
        this.showSpinner = false;
        if (editableData) {
          editableData.filteredBoards = res;
        } else {
          this.boards = res;
          this.filteredBoards = this.boards;
        }
      },
      (error) => {
        this.showSpinner = false;
        return this.snackBar.open(
          error.error.response.message,
          "Close",
          this.config
        );
      }
    );
  }

  getAutoSyndicationMappingById(data: any[], id: any): any | undefined {
    return data.find((record) => record.id === id);
  }

  applyAutoSyndicationFilter(filterValue: string) {
    if (filterValue) {
      this.autosyndicationMappingDataSource.filter = filterValue
        .trim()
        .toLowerCase();
    }
  }
}
