import {Component, OnInit, SecurityContext} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-power-bi-reports',
  templateUrl: './power-bi-reports.component.html',
  styleUrls: ['./power-bi-reports.component.css']
})
export class PowerBiReportsComponent implements OnInit {

  url = 'https://msit.powerbi.com/reportEmbed?reportId=49390f82-ada9-4ce0-ae5d-d2603fe6bf35&autoAuth=true&ctid=72f988bf-86f1-41af-91ab-2d7cd011db47' +
    '&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9kZi1tc2l0LXNjdXMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D';

  constructor(private sanitization: DomSanitizer) {
    
  }

  ngOnInit() {

  }

  sanitizeUrl() {
    return this.sanitization.bypassSecurityTrustResourceUrl(this.url);
  }

}
