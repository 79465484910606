import {Component, OnInit, ViewChild} from '@angular/core';
import {CommunityService} from '../../../core/services/community/community.service';
import {Community} from '../../../shared/models/community';
import {Constants} from '../../../shared/constants/constants';
import {ModalComponent} from '../../../shared/components/modal/pages/modal.component';
import {ClientsService} from '../../../core/services/customer/clients.service';
import {Customer} from '../../../shared/models/customer';

@Component({
  selector: 'app-potential-data',
  templateUrl: './potential-data.component.html',
  styleUrls: ['./potential-data.component.css']
})
export class PotentialDataComponent implements OnInit {

  @ViewChild(ModalComponent, {static: true}) modal;

  superAdmin = localStorage.getItem('isSuperAdmin') === 'yes' ? true : false;
  customerObject: any = JSON.parse(localStorage.getItem('customerObj'));
  spinner = false;
  customerId = 0;
  customers: Customer[] = [];
  communities: Community[] = [];
  pageSize = 5;
  currentPage = 0;
  constructor(private communityService: CommunityService,
              private customerService: ClientsService) {
  }

  ngOnInit() {
    if (this.superAdmin) {
      this.getCustomersByLoginUserId();
    } else {
      this.getCommunitiesByCustomerID(this.customerObject.id);
    }
  }

  getCustomersByLoginUserId() {
    const loginUserId = localStorage.getItem('loginUserId');
    this.spinner = true;
    this.customerService
      .getCustomerDetails(loginUserId)
      .subscribe(
        (res) => {
          this.spinner = false;
          this.customers = res.response.data;
        },
        (error) => {
          this.spinner = false;
          this.modal.populateModal({
            response: { message: Constants.SERVER_ERROR },
          });
        }
      );
  }

  getCommunitiesByCustomerID(customerId: number) {
    if (customerId !== 0) {
      this.spinner = true;
      this.communityService.getCommunitiesByCustomerID(customerId)
        .subscribe(res => {
          this.spinner = false;
          let communities: Community[] = res.response.data;
          communities = communities.filter((community: Community) => {
            return community.communityType.communityTypeName === Constants.COMMUNITY_TYPE_KHOROS || community.communityType.communityTypeName === Constants.COMMUNITY_TYPE_KHOROS_CLASSIC;
          });
          this.communities = communities.slice(0, 3);
        }, error => {
          this.spinner = false;
          this.communities = [];
          this.modal.populateModal({response: {message: Constants.SERVER_ERROR}});
        });
    } else {
      this.communities = [];
    }
  }
}
