import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Customer} from '../../../shared/models/customer';
import {Community} from '../../../shared/models/community';
import {Category} from '../../../shared/models/category';
import {Board} from '../../../shared/models/board';
import {BoardMappingList} from '../../../shared/models/board-mapping-list';
import {ClientsService} from '../../../core/services/customer/clients.service';
import {MappingsService} from '../../../core/services/Mappings/mappings.service';
import {ModalComponent} from '../../../shared/components/modal/pages/modal.component';
import {Constants} from '../../../shared/constants/constants';
import {
  MatSnackBar, MatSnackBarConfig
} from '@angular/material/snack-bar';

@Component({
  selector: 'app-migration',
  templateUrl: './migration.component.html',
  styleUrls: ['./migration.component.css']
})
export class MigrationComponent implements OnInit {

  @ViewChild(ModalComponent, {static: true}) modalComponent;

  public migrationForm: FormGroup;
  public allCustomers: Customer[] = [];
  public sourceCommunities: Community[] = [];
  public targetCommunities: Community[] = [];
  public categories: Category[] = [];
  public boards: Board[] = [];
  public boardMappingList: BoardMappingList[] = [];
  public showSpinner = false;
  public messageToDisplay = '';
  public payLoad: any;
  config = new MatSnackBarConfig();
  constructor(private formBuilder: FormBuilder,
              private customerService: ClientsService,
              private mappingService: MappingsService, private snackBar: MatSnackBar) {
    this.buildMigrationForm();
  }

  ngOnInit() {
    this.config.verticalPosition = 'top';
    this.config.horizontalPosition = 'end';
    this.config.duration = 5000;
    this.config.panelClass = ['blue-snackbar'];
    this.getAllCustomersList();
  }

  buildMigrationForm() {
    this.migrationForm = this.formBuilder.group({
      customer_id: new FormControl('', [Validators.required]),
      source_community_id: new FormControl('', [Validators.required]),
      target_community_id: new FormControl('', [Validators.required]),
      category_id: new FormControl('', [Validators.required]),
      interaction_style: new FormControl('', [Validators.required]),
      board_id: new FormControl('', [Validators.required])
    });
  }

  getAllCustomersList() {
    this.showSpinner = true;
    this.customerService.getActiveCustomers(localStorage.getItem('loginUserId'))
      .subscribe(res => {
        this.showSpinner = false;
        this.allCustomers = res.response.data;
      }, error => {
        this.showSpinner = false;
        if (error.status === 401 && error.error.response.status === 'error' && error.error.response.message === 'UnAuthorized Access Token Expired') {
          return this.snackBar.open(Constants.SESSION_EXPIRED, 'Close', this.config);
         } else {
          return this.snackBar.open(error.error.response.message, 'Close', this.config);
         }
      });
  }

  submitMigration() {
    const payLoad = {
      'sourceCommunityId': +this.migrationForm.value.source_community_id,
      'targetCommunityId': +this.migrationForm.value.target_community_id,
      'sourceBoardIds': [this.migrationForm.value.board_id]
    };

    this.showSpinner = true;
    this.mappingService.migrate(payLoad)
      .subscribe(res => {
        this.showSpinner = false;
        if (res.response.code === 202) {
          this.resetForm();
          this.modalComponent.populateModal({response: {message: Constants.REQUEST_ACCEPTED}});
        } else {
          this.modalComponent.populateModal(res);
        }
      }, error => {
        this.showSpinner = false;
        if (error.status === 401 && error.error.response.status === 'error' && error.error.response.message === 'UnAuthorized Access Token Expired') {
          return this.snackBar.open(Constants.SESSION_EXPIRED, 'Close', this.config);
         } else {
          return this.snackBar.open(error.error.response.message, 'Close', this.config);
         }
      });
  }

  onCustomerChange(event) {
    const customerId = +event.target.value;
    if (customerId !== 0) {
      this.showSpinner = true;
      this.mappingService.getCommunitybyCustomerId(customerId)
        .subscribe(res => {
          this.showSpinner = false;
          this.sourceCommunities = res.response.data;
          if (this.sourceCommunities.length === 0) {
            this.modalComponent.populateModal({response: {message: Constants.NO_COMMUNITIES_FOR_CUSTOMER}});
          }
        }, error => {
          this.showSpinner = false;
          if (error.status === 401 && error.error.response.status === 'error' && error.error.response.message === 'UnAuthorized Access Token Expired') {
            return this.snackBar.open(Constants.SESSION_EXPIRED, 'Close', this.config);
           } else {
            return this.snackBar.open(error.error.response.message, 'Close', this.config);
           }
        });
    } else {
      this.sourceCommunities = [];
      this.targetCommunities = [];
      this.categories = [];
      this.boardMappingList = [];
      this.boards = [];
    }
  }

  onCommunityChange(event) {
    const communityId = +event.target.value;
    this.payLoad = {'targetCommunityId': communityId};
    if (communityId !== 0) {
      this.targetCommunities = this.sourceCommunities.slice();
      this.targetCommunities.map(obj => {
        if (obj.communityId === communityId) {
          this.targetCommunities.splice(this.targetCommunities.indexOf(obj), 1);
        }
      });

      this.mappingService.getAllCategories(communityId)
        .subscribe(res => {
          this.categories = res.response.data;
        }, error => {
          this.showSpinner = false;
          if (error.status === 401 && error.error.response.status === 'error' && error.error.response.message === 'UnAuthorized Access Token Expired') {
            return this.snackBar.open(Constants.SESSION_EXPIRED, 'Close', this.config);
           } else {
            return this.snackBar.open(error.error.response.message, 'Close', this.config);
           }
        });
    } else {
      this.targetCommunities = [];
      this.categories = [];
      this.boardMappingList = [];
      this.boards = [];
    }
  }

  onCategoryChange(event) {
    const categoryId = event.target.value;
    if (categoryId !== '') {
      this.showSpinner = true;
      this.mappingService.getBoards(this.payLoad.targetCommunityId, categoryId, 'all','')
        .subscribe(res => {
          this.showSpinner = false;
          this.boardMappingList = res.response.data;
          if (this.boardMappingList.length === 0) {
            this.modalComponent.populateModal({response: {message: Constants.NO_BOARDS_FOR_CATEGORY}});
          }
        }, error => {
          this.showSpinner = false;
          if (error.status === 401 && error.error.response.status === 'error' && error.error.response.message === 'UnAuthorized Access Token Expired') {
            return this.snackBar.open(Constants.SESSION_EXPIRED, 'Close', this.config);
           } else {
            return this.snackBar.open(error.error.response.message, 'Close', this.config);
           }
        });
    } else {
      this.boardMappingList = [];
      this.boards = [];
    }
  }

  onInteractionStylesChange(event) {
    const interactionStyle = event.target.value;
    if (interactionStyle !== '') {
      this.boardMappingList.map(obj => {
        if (obj.interactionStyle === interactionStyle) {
          this.boards = obj.boardsMappingList;
        }
      });
    } else {
      this.boards = [];
    }
  }

  resetForm() {
    this.buildMigrationForm();
    this.targetCommunities = [];
    this.sourceCommunities = [];
    this.categories = [];
    this.boardMappingList = [];
    this.boards = [];
  }
}
