<div class="spinner" *ngIf="showSpinner">
  <img src="../../../../assets/images/spinner.gif" height="50px">
</div>
<div class="content-header">
  <h1 style="margin-bottom: 20px;">Audit Logs</h1>
  <mat-divider></mat-divider>
</div>
<div class="activity-logs-filter mt-4 top-margin">
<form [formGroup]="clientForm" (ngSubmit)="getDetails(defalutPageIndex)">
 <div class="row">
    <div class="col-md-2">
      <div class="form-group">
        <label>User Email</label>
        <select id="username" class="form-control" formControlName="username">
          <option value="">Select User</option>
          <option *ngFor="let user of userData" [value]="user.userEmail">{{ user.userEmail }}</option>
        </select>
      </div>
    </div>
    <div class="col-md-2 custom-margin">
      <div class="form-group">
        <label>Start Date</label>
        <input id="startDate" class="form-control" formControlName="startDate" [type]="isDateType ? 'date' : 'text'"
        (focus)="handleFocus()"
        (blur)="handleBlur()" placeholder="Select Start Date"  [max]="getFormattedTodayDate()" >
      </div>
    </div>
    <div class="col-md-2 custom-margin">
      <div class="form-group">
        <label>End Date</label>
        <input id="endDate" class="form-control" formControlName="endDate" [type]="isDateType ? 'date' : 'text'"
        (focus)="handleFocus()"
        (blur)="handleBlur()" placeholder="Select End Date"  [max]="getFormattedTodayDate()">
      </div>
    </div>
    <div class="col-md-2 custom-margin">
      <div class="form-group">
        <label>Action</label>
        <input type="text" class="form-control" formControlName="action" placeholder="Enter the type of action">
      </div>
    </div>
  </div>
  <button type="submit" class="btn btn-primary mr-2" style="font-size: 14px;width: 10%;">Get Details</button>
  <button type="button" class="btn btn-primary mr-2" (click)="resetForm()" style="font-size: 14px;width: 10%;">Reset</button>
</form>
</div>
<div class="box">
<div class="mat-header-cell">
  <h2 class="box-title"><strong>Audit Logs List</strong></h2>
</div>
<mat-table [dataSource]="dataSource" matSort>
  <ng-container matColumnDef="userName">
    <mat-header-cell *matHeaderCellDef mat-sort-header>User Email</mat-header-cell>
    <mat-cell *matCellDef="let activity" class="username">{{ activity.userName }}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="date">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Time Stamp</mat-header-cell>
    <mat-cell *matCellDef="let activity" class="timestamp">{{ activity.date | date: 'dd/MM/yyyy' }} • {{ activity.date | date: 'HH:mm:ss' }}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="action">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Action</mat-header-cell>
    <mat-cell *matCellDef="let activity">{{ activity.action }}</mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
</div>

<nav aria-label="..." *ngIf="hasData">
  <ul class="pagination justify-content-end">
    <li class="page-item">
      <a class="page-link" (click)="changePageIndex('previous')"
        [ngClass]="{ disabled: currentPageIndex <= 1 }">Previous</a>
    </li>
    <li class="page-item" *ngFor="let page of pages" [class.active]="currentPageIndex === page">
      <a class="page-link" (click)="changePageIndex(page)">{{ page }}</a>
    </li>
    <li class="page-item">
      <a class="page-link" (click)="changePageIndex('next')"
        [ngClass]="{ disabled: currentPageIndex >= maxPageIndex }">Next</a>
    </li>
  </ul>
  <div class="text-right">
    <span style="margin-left: 50px">{{ currentPageIndex }} of {{ maxPageIndex }}</span>
  </div>
</nav>