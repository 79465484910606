import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HealthCheckRoutingModule } from './health-check-routing.module';
import { HealthCheckService } from 'src/app/core/services/health-check/health-check.service';
import {MatExpansionModule} from '@angular/material/expansion';
import { MatSlideToggleModule} from '@angular/material/slide-toggle';
import { MatTabsModule} from '@angular/material/tabs';
import { MatTooltipModule} from '@angular/material/tooltip';
import { DescriptionComponent } from './description/description.component';
import { FormsModule } from '@angular/forms';
import { HealthCheckComponent } from './pages/health-check/health-check.component';


@NgModule({
  imports: [
    CommonModule,
    HealthCheckRoutingModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatTooltipModule,
    MatDialogModule,
    FormsModule,
    // MatDialogRef,

  ],
  declarations: [HealthCheckComponent, DescriptionComponent],
  entryComponents: [ DescriptionComponent ],
  providers: [
    HealthCheckService
  ]
})
export class HealthCheckModule { }
