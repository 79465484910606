import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SortBy} from './sort/sortBy';
import {SearchByColumn} from './search/search';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    SortBy,
    SearchByColumn
  ],
  exports: [
    SortBy,
    SearchByColumn
  ]
})
export class PipesModule {
}
