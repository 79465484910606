import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {MigrationRoutingModule} from './migration-routing.module';
import {MigrationComponent} from './pages/migration.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ClientsService} from '../../core/services/customer/clients.service';
import {MappingsService} from '../../core/services/Mappings/mappings.service';
import {PopulateModalModule} from '../../shared/components/modal/populate-modal.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MigrationRoutingModule,
    PopulateModalModule
  ],
  declarations: [
    MigrationComponent
  ],
  providers: [
    ClientsService,
    MappingsService
  ]
})
export class MigrationModule {
}
