<div class="activate-header">
  <div class="container">
    <figure class="main-logo">
      <img src=".././../assets/images/iTalent.png">
    </figure>
  </div>
</div>

<div class="resetPassword-section">
  <div class="welcome-box">
    <h2>Welcome to italentdigital-dev-912537, <span>Sunil!</span></h2>
    <h3>Create your italentdigital-dev-912537 account</h3>
    <form>
      <div class="change-password col-md-12">
        <div class="row">
          <div class="col-md-1">
            <div class="icon-wrapper">
              <div class="choose-password-32 glyph-icon"></div>
            </div>
          </div>
          <div class="col-md-11">
              <div class="form-group">
                <label>Enter new password</label>
                <input type="password" class="new-password form-control" required>
                <div class="validation-info">
                  Password requirements: at least 8 characters, a lowercase letter, an uppercase letter, a number, no parts of your username.
                </div>
              </div>
              <div class="fomr-group">
                <label>Repeat new password</label>
                <input type="text" class="resetNew-password form-control" required>
              </div>
          </div>
        </div>
      </div>
      <div class="sequrity-questions col-md-12">
        <div class="row">
          <div class="col-md-1">
            <div class="icon-wrapper">
              <div class="choose-question-32 glyph-icon"></div>
            </div>
          </div>
          <div class="col-md-11">
            <label>Choose a forgot password question</label>
            <select class="form-control">
              <option>question1</option>
              <option>question2</option>
              <option>question3</option>
              <option>question4</option>
              <option>question5</option>
              <option>question6</option>
              <option>question7</option>
            </select>
            <label>Answer</label>
            <input  type="text" class="form-control">
          </div>
        </div>
      </div>
      <div class="createMy-account col-md-12">
        <button type="button" class="btn btn-sm pull-right">Create My Accout</button>
      </div>
    </form>

  </div>
</div>

