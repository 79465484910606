import { DataMigrationService } from '../../../../core/services/data-migration/data-migration.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ClientsService } from '../../../../core/services/customer/clients.service';
import { Constants } from '../../../../shared/constants/constants';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MessageMappingsStatusViewDialog } from '../message-mappings-status-view/message-mappings-status-view/message-mappings-status-view.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-data-migration',
  templateUrl: './message-mappings-status.component.html',
  styleUrls: ['./message-mappings-status.component.css']
})
export class MessageMappingsStatusComponent implements OnInit {
  @ViewChild('labelImport', {static: true})
  labelImport: ElementRef;
  @ViewChild('viewMessageMappingsPaginator', {read: MatPaginator, static: true}) viewMessageMappingsPaginator: MatPaginator;
  @ViewChild('viewMessageMappingsSort', {static: true}) viewMessageMappingsSort: MatSort;
  messageMappingListColumns = ['startedAt', 'completedAt', 'status', 'action'];
  spinner = false;
  customer: any;
  fileName: any;
  selectedDataMigration: any;
  messageMappingsStatusList: any[] = [];
  messageMappingListDataSource: MatTableDataSource<any>;
  customerObj = JSON.parse(localStorage.getItem('customerObj'));
  superAdmin = localStorage.getItem('isSuperAdmin') === 'yes' ? true : false;
  DataMigrationForm: FormGroup;
  config = new MatSnackBarConfig();
  fileToUpload: File = null;
  constructor(private formBuilder: FormBuilder, private dataMigrationService : DataMigrationService, private snackBar: MatSnackBar, private dialog: MatDialog) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.config.verticalPosition = 'top';
    this.config.horizontalPosition = 'end';
    this.config.duration = 5000;
    this.config.panelClass = ['blue-snackbar'];
    this.customer = '';
    this.getDataMigrationRequestStatusList();
  }


  buildForm() {
    this.DataMigrationForm = this.formBuilder.group({
      importFile: new FormControl('', [Validators.required])
    });
}

onFileChange(files: FileList) {
  this.labelImport.nativeElement.innerText = Array.from(files)
    .map(f => f.name)
    .join(', ');
  this.fileToUpload = files.item(0);
  if (!this.validateFile(files[0].name)) {
    this.DataMigrationForm.controls['importFile'].setValue('');
    this.fileToUpload = null;
    return this.snackBar.open(Constants.FILE_EXTENSION_SUPPORTED, 'Close', this.config);
}
}
submit() {
  if (this.fileToUpload === null) {
    return this.snackBar.open(Constants.SELECT_FIELDS, 'Close', this.config);
  }
  if (!this.validateFile(this.fileToUpload.name)) {
    this.fileToUpload = null;
    return this.snackBar.open(Constants.FILE_EXTENSION_SUPPORTED, 'Close', this.config);
  }
  this.spinner = true;
    this.dataMigrationService.importData(this.fileToUpload).subscribe (res => {
    this.spinner = false;
    this.DataMigrationForm.reset();
    this.buildForm();
    this.fileToUpload = null;
    document.getElementById("labelImports").innerHTML = '<i class="fa fa-upload"></i>&nbsp; &nbsp; &nbsp; Choose file';
    this.snackBar.open(Constants.FILE_UPLOADED_SUCCESSFULLY, 'Close', this.config);
      this.fileName = undefined;
    }, error => {
      this.spinner = false;
      if (error.status === 401 && error.error.response.status === 'error' && error.error.response.message === 'UnAuthorized Access Token Expired') {
        return this.snackBar.open(Constants.SESSION_EXPIRED, 'Close', this.config);
       } else {
        return this.snackBar.open(error.error.response.message, 'Close', this.config);
       }
    });
}

validateFile(name: String) {
  let ext = name.substring(name.lastIndexOf('.') + 1);
  if (ext.toLowerCase() == 'csv') {
      return true;
  }
  else {
      return false;
  }
}

downloadTemplate() {
  let link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = '/assets/templates/Message-Mappings.csv';
    link.download = 'Message-Mappings.csv';
    document.body.appendChild(link);
    link.click();
    link.remove();
}

getDataMigrationRequestStatusList() {
  this.spinner = true;
  this.dataMigrationService.getDataMigrationStatusList().subscribe(res => {
    this.spinner = false;
    if (res) {
      this.messageMappingsStatusList = res;
      this.messageMappingListDataSource = new MatTableDataSource(this.messageMappingsStatusList);
      this.messageMappingListDataSource.paginator = this.viewMessageMappingsPaginator;
      this.messageMappingListDataSource.sortingDataAccessor = (item, property) => {
        if (property.includes('.')) return property.split('.').reduce((o,i)=>o[i], item)
        return item[property];
     };
     this.messageMappingListDataSource.sort = this.viewMessageMappingsSort;
    }
  }, error => {
    this.spinner = false;
    if (error.status === 401 && error.error.response.status === 'error' && error.error.response.message === 'UnAuthorized Access Token Expired') {
      return this.snackBar.open(Constants.SESSION_EXPIRED, 'Close', this.config);
    } else {
      return this.snackBar.open(error.error.response.message, 'Close', this.config);
    }
  });
}

applyFilter(filterValue: string) {
  this.messageMappingListDataSource.data = this.messageMappingsStatusList;
    this.messageMappingListDataSource.filterPredicate = (data, filter) => {
      return data.startedAt.toLocaleLowerCase().includes(filter) ||
        data.completedAt.toLocaleLowerCase().includes(filter) ||
        data.status.toLocaleLowerCase().includes(filter);
    };
    this.messageMappingListDataSource.filter = filterValue.trim().toLowerCase();
}

viewStatus(data: any) {
  this.selectedDataMigration = data;
    this.spinner = true;
    this.dataMigrationService.getMessageMappingsStatusList(data.migrationRequestId).subscribe(res => {
    let response = res;
      const dialogRef = this.dialog.open(MessageMappingsStatusViewDialog, {
        minWidth: '750px',
        minHeight: '500px',
        data: { msgmappingsstatuslist: response, selectedDataMigration: this.selectedDataMigration}
      });
      dialogRef.afterClosed().subscribe(result => {
      });
      this.spinner = false;
    }, error => {
      this.spinner = false;
      if (error.status === 401 && error.error.response.status === 'error' && error.error.response.message === 'UnAuthorized Access Token Expired') {
        return this.snackBar.open(Constants.SESSION_EXPIRED, 'Close', this.config);
      } else {
        return this.snackBar.open(error.error.response.message, 'Close', this.config);
      }
    });
}

}
