<div class="spinner" *ngIf="spinner">
  <img src="../../../../assets/images/spinner.gif" height="50px">
</div>
<div class="content-header">
  <h1>Generate App</h1>
  <br>
  <mat-divider></mat-divider>
</div>
<div class="box">
<div class="mt-4 customer-tab">
  <div class="container">
    <div class="col-xs-3 form-inline text-nowrap">

        <label>Application Name<span class="label-required">*</span></label>
        <input id="app-name"
        [(ngModel)]="applicationName" type="text"
               placeholder="Application Name">

  <div style="padding-left: 20px !important;">
    <button id="submit-details"
            class="btn bg-blue btn-flat margin btn-pdt" (click)="submitForm()">Generate</button>
  </div>

    </div>
  </div>
</div>
</div>
