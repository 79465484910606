import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {LoginService} from '../../services/login/login.service';
import {Constants} from '../../../shared/constants/constants';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  public forgetPasswordForm: FormGroup;
  public isDisplyaMessage = false;
  public message = '';
  public showSpinner = false;

  constructor(private formBuilder: FormBuilder,
              private loginService: LoginService) {
    this.generateForm();
  }

  ngOnInit() {
  }

  public generateForm() {
    this.forgetPasswordForm = this.formBuilder.group({
      reset_email: new FormControl('', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')])
    });
  }

  submit() {
    const email = this.forgetPasswordForm.value.reset_email;
    this.showSpinner = true;
    this.loginService.resetPassword(email)
      .subscribe(res => {
        this.showSpinner = false;
        this.isDisplyaMessage = true;
        if (res.response.data.hasOwnProperty('authenticationProvider')) {
          if (res.response.data.authenticationProvider === Constants.TYPE_AAD) {
            this.message = Constants.RESET_PASSWORD_AAD;
          } else {
            this.message = Constants.RESET_PASSWORD_OKTA;
          }
        } else {
          this.message = Constants.EMAIL_NOT_EXIST;
        }
      }, error => {
        this.showSpinner = false;
        this.isDisplyaMessage = true;
        this.message = Constants.EMAIL_NOT_EXIST;
      });
  }

}
