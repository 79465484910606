import {
  Component,
  EventEmitter,
  OnInit,
  Input,
  Output,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import { Community } from "../../../shared/models/community";
import { Customer } from "../../../shared/models/customer";
import { CommunityService } from "../../../core/services/community/community.service";
import { ClientsService } from "../../../core/services/customer/clients.service";
import { Constants } from "../../../shared/constants/constants";
import { ConfirmationDialogComponent } from "../../../shared/confirmationDialog/confirmationdialog.component";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";

@Component({
  selector: "app-community-list",
  templateUrl: "./community-list.component.html",
  styleUrls: ["./community-list.component.css"],
})
export class CommunityListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  dataSource: MatTableDataSource<any>;

  @Output() editCommunity = new EventEmitter();
  @Output() showMessage = new EventEmitter();
  @Output() reset = new EventEmitter();
  @Input() customers: Customer[];
  list: Community[] = [];
  filteredCommunities: Community[] = [];
  loginUserId: string = localStorage.getItem("loginUserId");
  superAdmin = localStorage.getItem("isSuperAdmin") === "yes" ? true : false;
  customerObj = JSON.parse(localStorage.getItem("customerObj"));
  spinner = false;
  targetColumn = "";
  messageToDisplay = "";
  isDelete = false;
  pageSize = 5;
  currentPage = 0;
  searchString: string = "";
  total: number;
  activeSort: string = "communityId";
  sortOrder: string = "asc";
  customerId: string = "";
  constants = Constants;
  config = new MatSnackBarConfig();
  tableColumns: string[] = [
    "communityName",
    "communityUrl",
    "communityType.communityTypeName",
    "customerName",
    "isCategoryAsCommunity",
    "action",
    "metaDataUpdated",
    "status",
  ];
  constructor(
    private communityService: CommunityService,
    private clientService: ClientsService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private changeDetectorRefs: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.config.verticalPosition = "top";
    this.config.horizontalPosition = "end";
    this.config.duration = 5000;
    this.config.panelClass = ["blue-snackbar"];

    this.communityList(
      this.pageSize,
      this.currentPage,
      this.activeSort,
      this.sortOrder,
      this.customerId,
      this.searchString
    );
  }

  public communityList(
    pageSize,
    currentPage,
    activeSort,
    sortOrder,
    customerId,
    searchString
  ) {
    if (searchString == "") {
      this.spinner = true;
    }
    this.communityService
      .getCommunities(
        this.loginUserId,
        pageSize,
        currentPage,
        activeSort,
        sortOrder,
        customerId,
        searchString
      )
      .subscribe(
        (listOfCommunities) => {
          this.spinner = false;
          this.list = listOfCommunities.response.data;
          this.filteredCommunities = this.list;
          this.dataSource = new MatTableDataSource();
          this.dataSource.data = this.filteredCommunities;
          this.dataSource.paginator = currentPage;
          this.dataSource.sortingDataAccessor = (item, property) => {
            if (property.includes("."))
              return property.split(".").reduce((o, i) => o[i], item);
            return item[property];
          };
          if (typeof this.paginator.length === "undefined") {
            this.paginator.length = currentPage;
          }
          this.paginator.pageIndex = currentPage;
          this.paginator.pageSize = pageSize;
          // this.dataSource.sort = this.sort;
          this.total = listOfCommunities.response.totalRecordsCount;
          this.paginator.pageIndex = currentPage;
          this.setCategoryAsCommunity(this.filteredCommunities);
          setTimeout(() => {
            this.paginator.length = this.total;
          });
        },
        (error) => {
          this.spinner = false;
          if (
            error.status === 401 &&
            error.error.response.status === "error" &&
            error.error.response.message === "UnAuthorized Access Token Expired"
          ) {
            return this.snackBar.open(
              Constants.SESSION_EXPIRED,
              "Close",
              this.config
            );
          } else {
            return this.snackBar.open(
              error.error.response.message,
              "Close",
              this.config
            );
          }
        }
      );
  }

  public customersList() {
    this.spinner = true;
    this.clientService.getActiveCustomers(this.loginUserId).subscribe(
      (listOfCustomers) => {
        this.spinner = false;
        this.customers = listOfCustomers.response.data;
      },
      (error) => {
        this.spinner = false;
        if (
          error.status === 401 &&
          error.error.response.status === "error" &&
          error.error.response.message === "UnAuthorized Access Token Expired"
        ) {
          return this.snackBar.open(
            Constants.SESSION_EXPIRED,
            "Close",
            this.config
          );
        } else {
          return this.snackBar.open(
            error.error.response.message,
            "Close",
            this.config
          );
        }
      }
    );
  }
  /**
   * For enable or disable the commmunity
   * @param event
   * @param community
   */
  updateStatus(event, community: Community) {
    let index = this.filteredCommunities.indexOf(community);
    let status = "";
    if (community.active) {
      status = "Disable";
    } else {
      status = "Enable";
    }
    community.active = !community.active;
    event.source.checked = !event.checked;
    this.filteredCommunities[index] = community;
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "450px",
      height: "200px",
      data: {
        message: "Are you sure, you want to " + status + " the Application?",
        type: "",
        title: "Confirm Action",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.reset.emit();
        this.spinner = true;
        this.communityService
          .updateStatus(community.communityId, event.checked)
          .subscribe(
            (res) => {
              this.spinner = false;
              this.communityList(
                this.pageSize,
                this.currentPage,
                this.activeSort,
                this.sortOrder,
                this.customerId,
                this.searchString
              );
              this.showMessage.emit(res.response.message);
            },
            (error) => {
              this.spinner = false;
              if (
                error.status === 401 &&
                error.error.response.status === "error" &&
                error.error.response.message ===
                  "UnAuthorized Access Token Expired"
              ) {
                return this.snackBar.open(
                  Constants.SESSION_EXPIRED,
                  "Close",
                  this.config
                );
              } else {
                return this.snackBar.open(
                  error.error.response.message,
                  "Close",
                  this.config
                );
              }
            }
          );
      } else {
        let index = this.filteredCommunities.indexOf(community);
        community.active = !community.active;
        event.source.checked = !event.checked;
        this.filteredCommunities[index] = community;
      }
    });
  }

  onChangePage(event) {
    console.log("first", event);
    if (this.pageSize !== event.pageSize) {
      this.currentPage = 0; // Reset to first page if page size changes
    } else {
      this.currentPage = event.pageIndex;
    }
    this.pageSize = +event.pageSize;
    this.communityList(
      this.pageSize,
      this.currentPage,
      this.activeSort,
      this.sortOrder,
      this.customerId,
      this.searchString
    );
  }

  onSortChange(event) {
    var sort = "";
    if (event.active == "customerName") {
      sort = "customer.customerName";
    } else if (event.active == "isCategoryAsCommunity") {
      sort = "communityType.communityTypeName";
    } else {
      sort = event.active;
    }
    this.sortOrder =
      event.direction == ""
        ? this.sortOrder == "asc"
          ? "desc"
          : "asc"
        : this.sortOrder == event.direction
        ? this.sortOrder == "asc"
          ? "desc"
          : "asc"
        : event.direction;
    this.communityList(
      this.pageSize,
      0,
      sort,
      this.sortOrder,
      this.customerId,
      this.searchString
    );
  }

  edit(community: Community) {
    this.spinner = true;
    this.communityService
      .getCommunitiesByCommunityId(community.communityId)
      .subscribe((res) => {
        this.spinner = false;
        if (res.response.code === 200 && res.response.status === "success") {
          community = res.response.data;
          this.editCommunity.emit(community);
        }
      });
  }
  isChecked(communityDetail: Community) {
    return communityDetail.active;
  }

  // update community metadata on click on publish Application
  updateMetaData(community: Community) {
    this.spinner = true;
    this.communityService.updateMetaData(community.communityId).subscribe(
      (res) => {
        this.spinner = false;
        this.communityList(
          this.pageSize,
          this.currentPage,
          this.activeSort,
          this.sortOrder,
          this.customerId,
          this.searchString
        );
        if (res.response.code === 200 && res.response.status === "success") {
          this.showMessage.emit(res.response.message);
        }
      },
      (error) => {
        this.spinner = false;
        if (
          error.status === 401 &&
          error.error.response.status === "error" &&
          error.error.response.message === "UnAuthorized Access Token Expired"
        ) {
          return this.snackBar.open(
            Constants.SESSION_EXPIRED,
            "Close",
            this.config
          );
        } else {
          return this.snackBar.open(
            error.error.response.message,
            "Close",
            this.config
          );
        }
      }
    );
  }

  fetchCommunities(data: any) {
    // if (data === "") {
    // this.filteredCommunities = this.list;
    //   this.dataSource.data = this.filteredCommunities;
    //   this.dataSource.paginator = this.paginator;
    //   this.dataSource.sortingDataAccessor = (item, property) => {
    //     if (property.includes("."))
    //       return property.split(".").reduce((o, i) => o[i], item);
    //     return item[property];
    //   };
    //   this.dataSource.sort = this.sort;
    //   this.dataSource.filter = "";
    // this.setCategoryAsCommunity(this.fetchCommunities);
    // } else {
    //   let customerId = parseInt(data);
    //   this.filteredCommunities = this.list.filter(
    //     (communityObj) => communityObj.customerId === customerId
    //   );
    //   this.dataSource.data = this.filteredCommunities;
    //   this.dataSource.paginator = this.paginator;
    //   this.dataSource.sortingDataAccessor = (item, property) => {
    //     if (property.includes("."))
    //       return property.split(".").reduce((o, i) => o[i], item);
    //     return item[property];
    //   };
    //   this.dataSource.sort = this.sort;
    //   this.setCategoryAsCommunity(this.filteredCommunities);
    // }
    this.customerId = data;
    this.communityList(
      this.pageSize,
      this.currentPage,
      this.activeSort,
      this.sortOrder,
      data,
      this.searchString
    );
  }

  setCategoryAsCommunity(list: any) {
    for (let entry of this.filteredCommunities) {
      if (
        entry.nodeId !== null &&
        entry.nodeId !== undefined &&
        entry.nodeId !== ""
      ) {
        entry.isCategoryAsCommunity = "Yes";
      } else {
        entry.isCategoryAsCommunity = "No";
      }
    }
  }

  applyFilter(filterValue: string) {
    // for (let entry of this.filteredCommunities) {
    //   if (entry.communityUrl === undefined) {
    //     entry.communityUrl = "";
    //   }
    // }
    // this.dataSource.data = this.filteredCommunities;
    // this.dataSource.filterPredicate = (data, filter) => {
    //   return (
    //     data.communityName.toLocaleLowerCase().includes(filter) ||
    //     data.communityUrl.toLocaleLowerCase().includes(filter) ||
    //     data.communityType.communityTypeName
    //       .toLocaleLowerCase()
    //       .includes(filter) ||
    //     data.customerName.toLocaleLowerCase().includes(filter) ||
    //     data.isCategoryAsCommunity.toLocaleLowerCase().includes(filter)
    //   );
    // };
    this.searchString = filterValue.trim().toLowerCase();
    this.communityList(
      this.pageSize,
      0,
      this.activeSort,
      this.sortOrder,
      this.customerId,
      this.searchString
    );
  }
}
