import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";
import { environment } from "../../../../environments/environment";
import { ActivityLogs } from "../../../shared/models/activitylogs";

@Injectable({
  providedIn: "root",
})
export class ActivitylogsService {
  constructor(private http: HttpClient) {}

  getDetails(activityLog: ActivityLogs): Observable<any> {
    const url = `${environment.BASE_URL}/activityLogs`;
    const params = {
      userName: activityLog.userName || '',
      startDate: activityLog.startDate || '',
      endDate: activityLog.endDate || '',
      action: activityLog.action || '',
      pageIndex: activityLog.pageIndex || '',
      
    };
    return this.http.get(url, { params },);
  }
  
}

