<div class="spinner" *ngIf="spinner">
    <img src="../../../../assets/images/spinner.gif" height="50px">
</div>
<mat-toolbar style="background-color: #000; color: #fff;">
    Schedule Status
    <span style="padding-left: 75%;">
    <button mat-mini-fab *ngIf="entityType!=='skill-jar-events'" style="background-color: #fff" (click)="reloadData()">
      <i class="fa fa-refresh" aria-hidden="true"></i>
      </button>
    </span>
    <button mat-mini-fab style="background-color: #fff" class="close-button" [mat-dialog-close]="true">
      <i class="fa fa-times" aria-hidden="true"></i>
    </button>
  </mat-toolbar>
<div mat-dialog-content style="bottom: #000;margin: 0px;">


                <mat-table *ngIf="entityType!=='skill-jar-events'" #viewSchedulesSort="matSort" [dataSource]="dataSource" matSort matSortActive="scheduledFrom" matSortDirection="desc">
                  <ng-container matColumnDef="scheduledFrom">
                    <mat-header-cell *matHeaderCellDef mat-sort-header > Scheduled From </mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.scheduledFrom | date:'dd-MM-yyyy HH:mm:ss'}} </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="completedAt">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Completed At </mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.completedAt | date:'dd-MM-yyyy HH:mm:ss'}} </mat-cell>
                  </ng-container>
                  <!-- Name Column -->
                  <ng-container matColumnDef="type">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Type </mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.type==='schedular'?'Refresh':row.type | titlecase}} </mat-cell>
                  </ng-container>


                  <ng-container matColumnDef="sourceStatus">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Source Status </mat-header-cell>
                    <mat-cell *matCellDef="let row" style="white-space:pre-line"> {{row.sourceStatus}} </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="targetStatus">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Target Status </mat-header-cell>
                    <mat-cell *matCellDef="let row" style="white-space:pre-line;"> {{row.targetStatus}} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="scheduleStatus">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.scheduleStatus}} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="totalCount">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Total </mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.totalCount}} </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="successCount">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Success </mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.successCount}} </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="droppedCount">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Dropped </mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.droppedCount}} </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="failedCount">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Failure </mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.failedCount}} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="retrySchedule">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Retry </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                      <button *ngIf="row.scheduleStatus == 'FAILED'" id="retry-schedule-block" title="View Schedules"
                        (click)="retrySchedule(row)" type="button" class="btn btn-box-tool badge bg-red" data-widget="remove"><i
                        class="fa fa-refresh fa-2x delete-btn" style="color:#3c8dbc" title="Retry Schedules"></i></button>
                    </mat-cell>
                  </ng-container>

                  <mat-header-row *matHeaderRowDef="scheduleListColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: scheduleListColumns;">
                  </mat-row>
                </mat-table>
                <mat-table *ngIf="entityType === 'skill-jar-events'" #viewSchedulesSort="matSort" [dataSource]="dataSource" matSort>
                  <ng-container matColumnDef="eventDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Event Date </mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.eventDate | date:'dd-MM-yyyy HH:mm:ss'}} </mat-cell>
                  </ng-container>
                  
                  <ng-container matColumnDef="userEmail">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> User Email </mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.id.userEmail}} </mat-cell>
                  </ng-container>
                  
                  <ng-container matColumnDef="courseTitle">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Course Title </mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.id.courseTitle}} </mat-cell>
                  </ng-container>
                  
                  <ng-container matColumnDef="message">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Message </mat-header-cell>
                    <mat-cell *matCellDef="let row" matTooltip="{{row.errorDescription}}" matTooltipPosition="above" matTooltipShowDelay="500" style="white-space:pre-line;">
                      {{row.message}}
                    </mat-cell>
                  </ng-container>
                  
                  <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.status}} </mat-cell>
                  </ng-container>
                  
                  <ng-container matColumnDef="retryButton">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Retry Button </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                      <button *ngIf="row.status === 'FAILED'" (click)="reprocessSkillJarEvents(row)" type="button" class="btn btn-box-tool badge bg-red">
                        <i class="fa fa-refresh fa-2x delete-btn" style="color:#3c8dbc"></i>
                      </button>
                    </mat-cell>
                  </ng-container>
                  
                  <mat-header-row *matHeaderRowDef="scheduleListColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: scheduleListColumns;"></mat-row>
                </mat-table>
                
                
                
                <mat-paginator #viewSchedulesPaginator="matPaginator" [pageSizeOptions]="[25, 50, 100]"></mat-paginator>



</div>
<div mat-dialog-actions>
    <button mat-button class="btn bg-blue btn-flat margin btn-pdt" style="background-color: #3c8dbc;margin-left: 30px; text-align: center;" (click)="close()" [mat-dialog-close]="true" cdkFocusInitial>Close</button>
</div>
<app-modal></app-modal>
