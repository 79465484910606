import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Response } from "../../../shared/models/response";
import { CommunityMappings } from "../../../shared/models/communityMappings";
import { Observable } from "rxjs";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class CommunityMappingService {
  constructor(private http: HttpClient) {}

  getCommunityMappings(
    pageSize,
    currentPage,
    activeSort,
    sortOrder
  ): Observable<Response<CommunityMappings[]>> {
    const url = `${environment.BASE_URL}/getlistofmappingswithpagerequest/0?pageIndex=${currentPage}&pageLimit=${pageSize}&sortOrder=${sortOrder}&sortColumn=sourceCommunity.communityId`;
    return this.http.get<Response<CommunityMappings[]>>(url);
  }

  getCommunityMappingsByCustomerId(
    customerId
  ): Observable<Response<CommunityMappings[]>> {
    const url =
      `${environment.BASE_URL}/getlistofcommunitymappings/` + customerId;
    return this.http.get<Response<CommunityMappings[]>>(url);
  }

  getCommunityMappingsByCommunityId(
    communityId
  ): Observable<Response<CommunityMappings[]>> {
    const url =
      `${environment.BASE_URL}/getlistofmappingswithcommunityId/` + communityId;
    return this.http.get<Response<CommunityMappings[]>>(url);
  }

  getAutoSyndicationMappings(sourceCommunityId): Observable<Response<any[]>> {
    const url = `${environment.BASE_URL}/autosyndication/` + sourceCommunityId;
    return this.http.get<Response<any[]>>(url);
  }

  postAutoSyndicationMapping(type: string, payload): Observable<any> {
    const url = `${environment.BASE_URL}/saveautosyndication/${type}`;
    const headers = new HttpHeaders({ "Content-Type": "application?/json" });
    return this.http.post<any>(url, payload);
  }

  deleteAutoSyndicationMapping(boardUid): Observable<Response<any>> {
    const url = `${environment.BASE_URL}/deleteautosyndication/${boardUid}`;
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http.delete<Response<any>>(url, { headers });
  }

  save(
    customerId,
    sourceCommunityId,
    targetCommunityIds: any[],
    isAutoSyndicationEnabled: boolean
    // changesinautoSyndicationMappings : any[]
  ) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    const save_payLoad = {
      customer: { customerId: +customerId },
      sourceCommunity: { communityId: +sourceCommunityId },
      targetCommunity: targetCommunityIds,
      autoSyndication: isAutoSyndicationEnabled,
      // changesinautoSyndicationMappings : changesinautoSyndicationMappings
      //autoSyndicationMappings: [autoSyndicationMappings, changesinautoSyndicationMappings]
    };
    console.log(save_payLoad, "sending payload");
    const url = `${environment.BASE_URL}/savecommunitymappings`;
    return this.http.post<Response<CommunityMappings>>(url, save_payLoad, {
      headers,
    });
  }

  delete(
    communityMappingId,
    customerId,
    sourceCommunityId,
    targetCommunityIds: any[],
    isAutoSyndicationEnabled: boolean
  ) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    const save_payLoad = {
      customer: { customerId: +customerId },
      sourceCommunity: { communityId: +sourceCommunityId },
      targetCommunity: targetCommunityIds,
      autoSyndication: isAutoSyndicationEnabled,
    };
    const url = `${environment.BASE_URL}/deletecommunitymappings/${communityMappingId}`;
    return this.http.post<Response<CommunityMappings>>(url, save_payLoad, {
      headers,
    });
  }

  csvSubmit(
    customerId,
    sourceCommunityId,
    targetCommunityIds: any[],
    isAutoSyndicationEnabled: boolean,
    file: File
  ) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    const save_payLoad = {
      customer: { customerId: +customerId },
      sourceCommunity: { communityId: +sourceCommunityId },
      targetCommunity: targetCommunityIds,
      autoSyndication: isAutoSyndicationEnabled,
      autoSyndicationMappings: [],
    };
    const formdata: FormData = new FormData();
    formdata.append("file", file);
    formdata.append("communityMappings", JSON.stringify(save_payLoad));
    const url = `${environment.BASE_URL}/savecommunitymappingsfile?file`;
    return this.http.post<Response<CommunityMappings>>(url, formdata);
  }
  downloadCSV(sourceCommunityId) {
    // const headers = new HttpHeaders().set("content-type", "application/json");
    const httpOptions = {
      responseType: "text",
      // headers: new HttpHeaders({
      //   "Content-Type": "application/json",
      // }),
      // responseType: "blob" as "json",
    };
    // const save_payLoad = {
    //   customer: { customerId: +customerId },
    //   sourceCommunity: { communityId: +sourceCommunityId },
    //   targetCommunity: targetCommunityIds,
    //   autoSyndication: isAutoSyndicationEnabled,
    //   autoSyndicationMappings: []
    // };
    // const formdata: FormData = new FormData();
    // formdata.append("file", file);
    // formdata.append("communityMappings", JSON.stringify(save_payLoad));
    const url = `${environment.BASE_URL}/downloadcommunitymappingsfile?communityId=${sourceCommunityId}`;
    return this.http.post<any>(url, httpOptions);
  }
}
