import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Response} from '../../../shared/models/response';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataMigrationService {

  constructor(private http: HttpClient) { }

  importData(file: File) {
     const url = `${environment.BASE_URL}/data-migration/uploadfile`;
     const formdata: FormData = new FormData();
     formdata.append('file', file);
     return this.http.post <Response<any>>(url, formdata);
   }

   getDataMigrationStatusList() {
    const url = `${environment.BASE_URL}/datamigration/list`;
    return this.http.get<any[]>(url);
  }
  getMessageMappingsStatusList(id: any) {
    const url = `${environment.BASE_URL}/datamigration/messagemappingsstatus/`+ id;
    return this.http.get<any[]>(url);
  }

}
