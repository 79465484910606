<!--Spinner-->
<div class="spinner" *ngIf="spinner">
  <img src="../../../../assets/images/spinner.gif" height="50px">
</div>


<div class="box">
  <mat-table [dataSource]="dataSource" matSort (matSortChange)="onSortChange($event)">
    <mat-header-row *matHeaderRowDef="['header-row-first-group']">
    </mat-header-row>
    <ng-container matColumnDef="header-row-first-group">
      <mat-header-cell *matHeaderCellDef style="text-align: left;">

        <div class="col-sm-12">
          <h2 class="box-title" style="margin-left: -25px;"><strong>Community List</strong></h2>
          <div class="row form-group">
            <div *ngIf="!superAdmin" class="col-sm-6 form-inline">

              <label>
                <h2 class="box-title" style="margin-left: -25px;"> <strong> Customer: </strong> </h2>
              </label>&nbsp;<label class="font-weight-bold medium">
                <h2 class="box-title"> {{customerObj.name}} </h2>
              </label>
            </div>

            <div *ngIf="superAdmin" class="col-sm-6 form-inline">
              <div class="col-sm-2 left-align">
                <label>Customer</label>
              </div>
              <div class="col-sm-4">
                <select *ngIf="superAdmin" class="form-control customer-minwidth" required
                  (change)="fetchCommunities($event.target.value)">
                  <option value="">All</option>
                  <option *ngFor="let selectedcust of customers" value={{selectedcust.customerId}}>
                    {{selectedcust.customerName}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-sm-6 pull-right">
              <div class="searchbar_section">
                <div class="app_search_component">
                  <mat-form-field>
                    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-header-cell>

    </ng-container>
    <ng-container matColumnDef="communityName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Community Name </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.communityName}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="communityUrl">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Community URL </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.communityUrl}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="communityType.communityTypeName">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Community Type </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.communityType.communityTypeName}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="customerName">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Customer </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.customerName}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="isCategoryAsCommunity">
      <mat-header-cell *matHeaderCellDef style="text-align: left; white-space:pre-line; padding-right: 80px;"> Category
        as Community </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.isCategoryAsCommunity}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button id="community-edit-{{i}}" title="Edit" [disabled]="!element.active" (click)="edit(element)"
          type="button" class="btn btn-box-tool badge bg-blue" style="width: 30%; height: 35%;" data-widget="remove"><i
            class="fa fa-edit"></i>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="metaDataUpdated">
      <mat-header-cell *matHeaderCellDef> MetaData Status </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <input type="button" style="border-radius: 10px;" value="{{element.metaDataUpdated ? 'Published' : 'Publish'}}"
          [disabled]="element.metaDataUpdated" (click)="updateMetaData(element)"
          matTooltip="{{element.metaDataUpdated ? 'Success' : element.message}}" [matTooltipPosition]="'above'">
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef> Enable/Disable </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-slide-toggle id="community-enable-or-disable-{{element.communityId}}"
          title="{{element.active ? 'disable' : 'enable'}}" [checked]="isChecked(element)"
          (change)="updateStatus($event, element)"></mat-slide-toggle>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: tableColumns;"></mat-row>
  </mat-table>
  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" (page)="onChangePage($event)" [length]="total"
    [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" showFirstLastButtons></mat-paginator>
</div>