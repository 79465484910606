import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BoardListObject} from '../../../../shared/models/board-list-object';
import {CategoryListObj} from '../../../../shared/models/category-list-obj';

@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.css']
})
export class BoardComponent {
  @Input() board: BoardListObject;
  @Input() parent: CategoryListObj;
  @Output() boardEvent = new EventEmitter();

  onChange(event) {
    this.board.isChecked = event.target.checked;
    const data = {board: this.board, parent: this.parent};
    this.boardEvent.emit(data);
  }
}
