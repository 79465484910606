import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Response} from '../../../shared/models/response';
import {User} from '../../../shared/models/user';
import {Role} from '../../../shared/models/roles';
import {Customer} from '../../../shared/models/customer';
import {environment} from '../../../../environments/environment';

@Injectable()
export class UserService {
  constructor(private http: HttpClient) {}

  getUsers(
    loginUserid: string,
    pageSize: number,
    currentPage: number,
    activeSort: string,
    sortOrder: string,
    searchString:string
  ): Observable<Response<User[]>> {
    const url = `${environment.BASE_URL}/getallusersbyloginuserid?loginUserId=${loginUserid}&pageIndex=${currentPage}&pageLimit=${pageSize}&sortOrder=${sortOrder}&sortColumn=${activeSort}&filter=${searchString}`;
    // const url = `${environment.BASE_URL}/getallusersbyloginuserid?loginUserId=${loginUserid}`;
    return this.http.get<Response<User[]>>(url);
  }

  getUserDetails(loginUserid: string): Observable<Response<User[]>> {
    // const url = `${environment.BASE_URL}/getallusersbyloginuserid?loginUserId=${loginUserid}&pageIndex=${currentPage}&pageLimit=${pageSize}&sortOrder=${sortOrder}&sortColumn=${activeSort}`;
    const url = `${environment.BASE_URL}/getallusersbyloginuserid?loginUserId=${loginUserid}`;
    return this.http.get<Response<User[]>>(url);
  }
  getRoles(): Observable<Response<Role[]>> {
    const url = `${environment.BASE_URL}/getallroles`;
    return this.http.get<Response<Role[]>>(url);
  }

  save(user, payLoadObj) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    const save_payLoad = {
      active: true,
      firstName: user.first_name.trim(),
      lastName: user.last_name.trim(),
      userEmail: user.user_email.trim(),
      roleId: payLoadObj.roleId,
      customerId: payLoadObj.customerId,
    };
    const url = `${environment.BASE_URL}/createuser`;
    return this.http.post<Response<any>>(url, save_payLoad, {
      headers,
    });
  }

  update(user, roleid: number, userid: number, customerid: number) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    const update_payLoad = {
      active: true,
      firstName: user["first_name"].value.trim(),
      lastName: user["last_name"].value.trim(),
      userEmail: user["user_email"].value.trim(),
      roleId: roleid,
      userId: userid,
      customerId: customerid,
    };
    const url = `${environment.BASE_URL}/updateuser`;
    return this.http.put<Response<any>>(url, update_payLoad, {
      headers,
    });
  }

  delete(userId: number) {
    const url = `${environment.BASE_URL}/deleteuser?userid=${userId}`;
    return this.http.delete<Response<any>>(url);
  }

  updateStatus(userId: number, isActive: boolean) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    const url = `${environment.BASE_URL}/enableordisableuser?userId=${userId}&active=${isActive}`;
    return this.http.put<Response<any>>(url, {
      headers,
    });
  }
}

