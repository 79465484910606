<div class="spinner" *ngIf="showSpinner">
  <img src="../../../../assets/images/spinner.gif" height="50px">
</div>
<div class="content-header">
  <h1>Board Mappings</h1>
  <br>
  <mat-divider></mat-divider>
</div>

<div class="medium ">
<div class="row" style="width: 100% !important;">

<div class="col-8">

<div id="Manage-Users" class="mt-4">
  <form class="form-horizontal" [formGroup]="mappingForm" (ngSubmit)="submit()">
    <div class="box-body">
      <div class="row">
        <div class="col-6">
            <div class="form-group">
              <label >Customer<span class="label-required">*</span>:</label>
              <input *ngIf="!superAdmin" class="form-control" type="text" value="{{customerObj.name}}" disabled>
              <select *ngIf="superAdmin" id="brd-map-cust" class="form-control" formControlName="customer_name" (change)="onCustomerChange($event)">
                <option value="">select</option>
                <option *ngFor="let customer of allCustomers" value={{customer.customerName}}>{{customer.customerName}}</option>
              </select>
              <div class="custom-error" *ngIf="mappingForm.controls['customer_name'].hasError('required') && mappingForm.controls['customer_name'].dirty">Select a customer</div>
            </div>
            <div class="form-group">
                <label >Community<span class="label-required">*</span>:</label>
                  <select id="brd-map-comm" class="form-control" formControlName="community_name" (change)="onCommunityChange($event)">
                    <option value="">select</option>
                    <option *ngFor="let community of communities" value={{community.communityName}}>{{community.communityName}}</option>
                  </select>
                  <div class="custom-error" *ngIf="mappingForm.controls['community_name'].hasError('required') && mappingForm.controls['community_name'].dirty">Select a community name</div>
              </div>
              <div class="form-group">
                <label >Category<span class="label-required">*</span>:</label>
                  <div class="category-dropdown">
                    <input type="text" placeholder="serach category" aria-label="Category" matInput formControlName="category_name" [matAutocomplete]="auto">
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="onCategoryChange($event.option.value)">
                      <mat-option *ngFor="let category of filteredCategories | async" value={{category.categoryId}} title="{{category.categoryTitle}}({{category.categoryId}})-{{category.nodeType}}">
                        {{category.categoryTitle}}({{category.categoryId}})-{{category.nodeType}}
                      </mat-option>
                    </mat-autocomplete>
                  </div>
                  <div class="custom-error" *ngIf="mappingForm.controls['category_name'].hasError('required') && mappingForm.controls['category_name'].dirty">Select a category</div>
              </div>
        </div>
      </div>
      <fieldset class= "map-fieldset" *ngIf="allBoards.length > 0">
        <legend class="map-legend small">Boards</legend>
        <div class="row">
          <div class="form-group col-6" *ngFor="let interactionItem of allInteractionStyles;">
            <div class="field">
              <label>{{interactionItem.interactionStyle}}&nbsp;&nbsp;</label>
              <mat-form-field>
                <mat-select id="brd-ma-{{interactionItem.interactionStyle}}" class="form-control" formControlName={{interactionItem.interactionStyle}}>          
                  <ng-container *ngFor="let board of allBoards;">
                    <mat-select-filter [array]="board.boardsMappingList" [displayMember]="'boardTitle'" (filteredReturn)="board.filteredBoardsMappingList= $event" *ngIf="board.interactionStyle === interactionItem.interactionStyle"></mat-select-filter>
                    <mat-option value="" *ngIf="board.interactionStyle === interactionItem.interactionStyle">select</mat-option>
                    <ng-container msInfiniteScroll (infiniteScroll)="getNextBatch(allBoards, board, interactionItem)" [debounceTime]=400 [complete]="board.boardsMappingList.length === board.TotalCount"  *ngIf="board.interactionStyle === interactionItem.interactionStyle">
                      <mat-option *ngFor="let mappings of board.filteredBoardsMappingList" [value]="mappings.boardId" title="{{mappings.boardTitle}}({{mappings.boardId}})">{{mappings.boardTitle}}({{mappings.boardId}})</mat-option>
                    </ng-container>
                  </ng-container>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>

      </fieldset>
      <div class="box-footer" style="margin-bottom: 5em !important;">
        <button id="brd-map-btn"  [disabled]="!mappingForm.valid" class="btn bg-blue btn-flat margin pull-left btn-pdt">{{saveOrUpdate}}</button>
        <input id="brd-map-reset" class="btn bg-blue btn-flat margin" type="button" value="Reset" (click)="resetForm()">
      </div>
    </div>
  </form>
</div>
</div>
</div>
</div>

<app-modal [canDelete]=false [genericMessage]="messageToDisplay"></app-modal>
