import {Component, OnInit} from '@angular/core';
import {LoginService} from '../../services/login/login.service';
import {Router} from '@angular/router';
import {Constants} from '../../../shared/constants/constants';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-authenticaticate',
  templateUrl: './authenticaticate.component.html',
  styleUrls: ['./authenticaticate.component.css']
})
export class AuthenticaticateComponent implements OnInit {
  public showSpinner = false;
  public cloudType = environment.CLOUD_TYPE;
  queryParamsMap: Map<string, string>;
  constructor(
    private loginservice: LoginService,
    private _router: Router
  ) {
  }

  ngOnInit() {
    const redirectUrl = window.location.href;
    if (this.cloudType === 'gcp') {
      const queryParams = new URLSearchParams(window.location.hash.slice(1));
      this.queryParamsMap = new Map<string, string>();
      queryParams.forEach((value, key) => {
        this.queryParamsMap.set(key, value);
    });
    }
    if (redirectUrl.indexOf('id_token') > 0 || redirectUrl.indexOf('access_token') > 0) {
      const data: Array<any> = redirectUrl.split('#')[1].split('&');
      const jwtToken = this.cloudType === 'gcp'? this.queryParamsMap.get('access_token'): data[0].split('=')[1];
      const state = this.cloudType === 'gcp'? this.queryParamsMap.get('state'): data[1].split('=')[1];
      this.showSpinner = true;
      this.loginservice.postJwtTokenandState(jwtToken, state)
        .subscribe(res => {
          this.showSpinner = false;
          if (res.response.code === 200 || res.response.status === 'success') {
            sessionStorage.setItem('authToken', jwtToken);
            if (res.response.data.hasOwnProperty('role')) {
              if (res.response.data.role.roleId !== null || res.response.data.role.roleName !== '') {
                localStorage.setItem('role',res.response.data.role.roleName);
                localStorage.setItem('loginUserId', res.response.data.loginUserId);
                switch ( res.response.data.role.roleId ) {
                  case 10001:const customer = res.response.data.customer;
                            localStorage.setItem('isSuperAdmin', 'no');
                            localStorage.setItem('customerObj', JSON.stringify({'id': customer.customerId, 'name': customer.customerName}));
                            this._router.navigate(['ui-service/community']);
                      break;
                  case 10002:localStorage.setItem('isSuperAdmin', 'yes');
                             localStorage.setItem('customerObj', JSON.stringify({'id': 0, 'name': ''}));
                             this._router.navigate(['ui-service/clients']);
                      break;
                  case 10003:this._router.navigate(['ui-service/contactSuperAdmin']);
                      break;
                  case 10004:localStorage.setItem('isSuperAdmin', 'no');
                             localStorage.setItem('customerObj', JSON.stringify({'id': 0, 'name': ''}));
                             this._router.navigate(['ui-service/health-check']);
                      break;
                  case 10005:localStorage.setItem('isSuperAdmin', 'no');
                             localStorage.setItem('customerObj', JSON.stringify({'id': 0, 'name': ''}));
                             this._router.navigate(['ui-service/generate-app']);
                      break;
                  default:  localStorage.setItem('isSuperAdmin', 'no');
                            this._router.navigate(['ui-service/login']);
                      break;
               }
              }
            }
            history.pushState(null, null, location.href);
            window.onpopstate = function () {
              history.go(1);
            };
          } else {
            this.showSpinner = false;
            localStorage.setItem('message', res.response.message);
            this._router.navigate(['ui-service/login']);
          }
        }, error => {
          this.showSpinner = false;
          localStorage.setItem('message', Constants.SERVER_ERROR);
          this._router.navigate(['ui-service/login']);
        });
    } else if (redirectUrl.indexOf('error') > 0) {
      localStorage.setItem('message', 'The required parameter is missing. Please enter a valid Parameter.');
      this._router.navigate(['ui-service/login']);
    } else {
      localStorage.setItem('message', 'You do not have the permissions to access the  authenticate page.');
      this._router.navigate(['ui-service/login']);
    }
  }
}
