import { Component, OnInit } from "@angular/core";
import { MappingsService } from "src/app/core/services/Mappings/mappings.service";
import { saveAs } from "file-saver";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";

@Component({
  selector: "app-generate-app",
  templateUrl: "./generate-app.component.html",
  styleUrls: ["./generate-app.component.css"],
})
export class GenerateAppComponent implements OnInit {
  spinner = false;
  applicationName: string;
  regex = /^[A-Za-z0-9_]+$/;
  config = new MatSnackBarConfig();
  constructor(
    private mappingService: MappingsService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.config.verticalPosition = "top";
    this.config.horizontalPosition = "end";
    this.config.duration = 5000;
    this.config.panelClass = ["blue-snackbar"];
  }

  submitForm() {
    if (this.applicationName) {
      let isValid = this.regex.test(this.applicationName);
      if (isValid) {
        this.mappingService.generateApplication(this.applicationName).subscribe(
          (res) => {
            saveAs(res, this.applicationName + ".zip");
            this.applicationName = "";
            return this.snackBar.open(
              "Project Created Successfully.",
              "Close",
              this.config
            );
          },
          (error) => {
            return this.snackBar.open(
              "Failed to generate Project.",
              "Close",
              this.config
            );
          }
        );
      } else {
        return this.snackBar.open(
          "Application Name is not valid.",
          "Close",
          this.config
        );
      }
    } else {
      return this.snackBar.open(
        "Application Name is not valid.",
        "Close",
        this.config
      );
    }
  }
}
