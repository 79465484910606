<div class="spinner" *ngIf="showSpinner">
  <img src="../../../../assets/images/spinner.gif" height="50px" />
</div>
<div class="content-header">
  <h1>Custom Fields</h1>
  <br />
  <mat-divider></mat-divider>
</div>

<div class="medium">
  <div class="row" style="width: 100% !important">
    <div class="col-8 pl-3">
      <div id="Manage-Users" class="mt-4">
        <form
          class="form-horizontal"
          [formGroup]="customFieldForm"
          (ngSubmit)="submit()"
        >
          <div class="box-body">
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label
                    >Customer name<span class="label-required">*</span>:</label
                  >
                  <input
                    *ngIf="!superAdmin"
                    class="form-control"
                    type="text"
                    value="{{ customerObj.name }}"
                    disabled
                  />
                  <select
                    *ngIf="superAdmin"
                    id="brd-map-cust"
                    class="form-control"
                    formControlName="customerId"
                    (change)="customerChange($event.target.value)"
                  >
                    <option value="">select</option>
                    <option
                      *ngFor="let customer of customers"
                      value="{{ customer.customerId }}"
                    >
                      {{ customer.customerName }}
                    </option>
                  </select>
                  <div
                    class="custom-error"
                    *ngIf="
                      customFieldForm.controls['customerId'].hasError(
                        'required'
                      ) && customFieldForm.controls['customerId'].dirty
                    "
                  >
                    Select a customer
                  </div>
                </div>
                <div class="form-group">
                  <label
                    >Community name<span class="label-required">*</span>:</label
                  >
                  <select
                    id="brd-map-comm"
                    class="form-control"
                    formControlName="communityId"
                    (change)="communityChange($event.target.value)"
                  >
                    <option value="">select</option>
                    <option
                      *ngFor="let community of communities"
                      value="{{ community.communityId }}"
                    >
                      {{ community.communityName }}
                    </option>
                  </select>
                  <div
                    class="custom-error"
                    *ngIf="
                      customFieldForm.controls['communityId'].hasError(
                        'required'
                      ) && customFieldForm.controls['communityId'].dirty
                    "
                  >
                    Select a community.
                  </div>
                </div>
              </div>
            </div>
            <div
              class="row col-md-12"
              *ngIf="
                customFieldForm.controls['communityId'].value !== '' &&
                communities.length &&
                selectedCommunity.communityType.communityTypeName !== 'KHOROS-CLASSIC'
              "
            >
              <fieldset class="map-fieldset">
                <i
                  class="fa fa-plus-square-o add-button"
                  aria-hidden="true"
                  (click)="add()"
                  title="Add custom field"
                ></i>
                <legend class="map-legend small">Custom fields</legend>
                <div class="row col-md-12 customize-list">
                  <table class="table table-light">
                    <tbody>
                      <tr>
                        <th>Key</th>
                        <th>Action</th>
                      </tr>
                      <tr
                        class="form-group"
                        *ngFor="let customField of customFields; let i = index"
                      >

                        <td>
                          <input
                            style="border-radius: 5px"
                            class="form-control"
                            type="text"
                            placeholder="Meta Data key"
                            value="{{ customField.communityKey }}"
                            [(ngModel)]="customField.communityKey"
                            [ngModelOptions]="{ standalone: true }"
                          />
                        </td>
                        <td>
                          <i
                            class="fa fa-close delete-btn"
                            title="Delete custom field"
                            (click)="confirm(i)"
                          ></i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </fieldset>
            </div>
            <div
              class="row col-lg-12"
              *ngIf="showMetaDataFieldform && selectedCommunity && selectedCommunity.communityType && selectedCommunity.communityType.communityTypeName === 'KHOROS-CLASSIC'"

            >
              <fieldset class="metadata">
                <legend class="map-legend small">MetaData fields</legend>
                <div class="row col-md-12 metaData-list">
                  <table class="table table-light">
                    <tbody>
                      <tr>
                        <th>Label</th>
                        <th>Value</th>
                      </tr>
                      <tr
                        class="form-group"
                        *ngFor="let field of MetaDataFields; let i = index"
                      >
                        <td>
                          <label style="border-radius: 5px; width: 150px; border: 1px ;">
                            {{ field.name }}
                          </label>
                        </td>
                        <td>
                          <input
                            style="border-radius: 5px; width: 500px"
                            class="form-control"
                            type="text"
                            placeholder="Meta Data value"
                            [(ngModel)]="field.value"
                            [ngModelOptions]="{ standalone: true }"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </fieldset>
            </div>

            <div class="box-footer" style="margin-bottom: 5em !important">
              <button
                id="brd-map-btn"
                class="btn bg-blue btn-flat margin pull-left btn-pdt"
                type="submit"
              >
                {{ saveOrUpdate }}
              </button>
              <input
                id="brd-map-reset"
                class="btn bg-blue btn-flat margin"
                type="button"
                value="Reset"
                (click)="reset()"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<app-modal
  [genericMessage]="messageToDisplay"
  [canDelete]="isDelete"
  (emitDeleteFuncionality)="delete($event)"
></app-modal>
