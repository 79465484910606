<header class="main-header">
  <a id="header-main-header" routerLink="{{navigateTo}}" class="logo">
    <span class="small font-weight-bold cis_logo" *ngIf="cloudType==='gcp'; else elseBlock"> <img class="smartconxheader-image" src="../../../assets/images/smartconx-logo.png" alt="SmartConX" title="SmartConX"/></span>
    <ng-template #elseBlock>
      <img class="login-image" src="../../../assets/images/microsoft-logo-white.png" alt="Microsoft ICS" title="Microsoft ICS">
    </ng-template>
  </a>
  <nav class="navbar navbar-static-top">
    <button id="header-toggle-btn" (click)="toggleNavBar()" class="sidebar-toggle cust_cursor"></button>
    <h1 style="color: white;font-size: 22px;">Intelligent Content Syndication</h1>


    <span>
    <i id="header-logout-btn" (click)="logout()" class="fa fa-sign-out pull-right header-btn cust_cursor"
       aria-hidden="true">&nbsp;<span>Logout</span></i><br/><span class="header version-number" style="color: white;">v{{version}}</span>
      </span>
    <div class="navbar-custom-menu" style="display: none">
      <ul class="nav navbar-nav">
        <li class="dropdown user user-menu"><a href="#" class="dropdown-toggle" data-toggle="dropdown"> <img
          src="../../../assets/images/user2-160x160.jpg" class="user-image" alt="User Image"> <span class="hidden-xs">Data Syndication</span>
        </a>
          <ul class="dropdown-menu">
            <li class="user-header"><img src="../../../assets/images/user2-160x160.jpg" class="img-circle"
                                         alt="User Image">
              <p> Cross Community login
                <small>Member since Nov. 2012</small>
              </p>
            </li>
            <li class="user-footer">
              <div class="pull-left"><a href="#" class="btn btn-default btn-flat">Profile</a></div>
              <div class="pull-right"><a href="#" class="btn btn-default btn-flat">Sign out</a></div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
</header>
