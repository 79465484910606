<!--Spinner-->
<div class="spinner" *ngIf="showSpinner">
  <img src="../../../../assets/images/spinner.gif" height="50px">
</div>

<div class="pb-3">
  <div class="content-header">
    <h1>Applications</h1>
    <br>
    <mat-divider></mat-divider>
  </div>
</div>

<mat-accordion>
  <mat-expansion-panel (opened)="panelOpenState = true"
                       (closed)="panelOpenState = false; resetForm()">
    <mat-expansion-panel-header>
      <mat-panel-title>Create Application</mat-panel-title>
    </mat-expansion-panel-header>
    <div>
      <div>
        <app-interaction-styles [customers] = "customers"
          (showMessage)="showMessage($event)"
          (emitData)="getEmittedData($event)"
          (emitCommunityTypeName)="getCommunityTypeName($event)"
          (emitInteractionStyles)="getStyles($event)"
          (emitValidation)= "canValidate($event)">
        </app-interaction-styles>

        <app-access-details
          (showMessage)="showMessage($event)"
          (emitData)="getEnvironmentList($event)">
        </app-access-details>

        <app-community-info (emitIcstenantId)="regenerateIcsTenantId($event)" [isValidated]="isValidated"></app-community-info>

        <div class="container container-fluid">
          <label class="chkBoxContainer" *ngIf="communityTypeName === 'KHOROS' && icstenantid != ''">
            <span class="inter-font"> Community Events </span>
            <input
              id="community-events-check"
              type="checkbox"
              [(ngModel)]="eventsEnabled"
              [ngModelOptions]="{ standalone: true }"
            />
            <span class="checkmark"></span>
          </label>
          <div class="community-events-container" *ngIf="eventsEnabled">
            <div>
              <input class="mt-3 mb-2" type="button" value="+" (click)="add()" />

            </div>
            <table class="table" style="height: 200px">
              <caption style="caption-side: top; text-align: center">
                Community Events
               </caption>
              <tr>
                <td>Event type</td>
                <td>Callback URL</td>
                <td>Actions</td>
              </tr>
              <tbody>
                <tr *ngFor="let event of communityEvents; let i = index">
                  <td>
                    <select
                      class="form-control"
                      id="community-event-type"
                      value="{{ event.eventType }}"
                      [(ngModel)]="event.eventType"
                      [ngModelOptions]="{ standalone: true }"
                      (change)="changeHandler(event.eventType)"
                      [disabled] = "event.disabled"
                    >
                      <option value="">select</option>
                      <option
                        *ngFor="let eventType of eventTypes"
                        value="{{ eventType.value }}"
                      >
                        {{ eventType.name }}
                      </option>
                    </select>
                  </td>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Call back URL"
                      value="{{ event.callBackUrl }}"
                      [(ngModel)]="event.callBackUrl"
                      [ngModelOptions]="{ standalone: true }"
                      [disabled] = "event.disabled"
                    />
                  </td>
                  <td>
                    
                    <input
                      type="submit"
                      value="-"
                      class="btn btn-sm btn-danger center"
                      (click)="handleDelete(i)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
      <div class="row mt-1">
        <div class="col-md" style="padding-left: 30px !important;">
          <button id="comm-submit-btn"
                  *ngIf="isValidated"
                  class="btn bg-blue btn-flat margin pull-left btn-pdt"
                  (click)="submit()">
            {{saveOrUpdate}}
          </button>
          <input id="comm-validate-btn"
                 *ngIf="!isValidated"
                 [disabled]="(!this.interactionStylesComponent.interactionStylesForm.valid ||
                              !this.accessDetailsComponent.accessDetailsForm.valid ||
                              !this.communityInfoComponent.communityInfoForm.valid)"
                 type="button"
                 value="Validate"
                 class="btn bg-blue btn-flat margin btn-pdt"
                 (click)="validateCommunity()">
          <input id="comm-reset-btn"
                 type="button"
                 value="Reset"
                 class="btn bg-blue btn-flat margin btn-pdt"
                 (click)="resetForm()">
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>

<app-community-list [customers] = "customers"
  (editCommunity)="edit($event)"
  (showMessage)="showMessage($event)"
  (reset)="resetFormData()"></app-community-list>

<app-modal></app-modal>
