<mat-toolbar style="background-color: #000; color: #fff;">
  <span class="gap">{{ title }}</span>
</mat-toolbar>
<br />
<div mat-dialog-content class="row schedule-btn">
  {{ message }}
</div>
<div mat-dialog-actions class="button-container">
  <button mat-button *ngIf="type !== 'FACEBOOK'" class="btn btn-light confirm" (click)="onNoClick()">No</button>
  <button mat-button *ngIf="type !== 'FACEBOOK'" class="btn btn-primary confirm" style="background-color: #3c8dbc;" [mat-dialog-close]="true" cdkFocusInitial>Yes</button>
  <button mat-button *ngIf="type === 'FACEBOOK'" class="btn btn-primary confirm" style="background-color: #3c8dbc;" [mat-dialog-close]="true" cdkFocusInitial>Ok</button>
</div>
