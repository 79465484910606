import {Pipe} from '@angular/core';
import {PipeTransform} from '@angular/core';

@Pipe({
  name: 'sortBy',
  pure: false
})
export class SortBy implements PipeTransform {
  transform(array: Array<any>, args?: any, sortReverse?: any): any {
    if (args === '' || args === undefined) {
      return array;
    } else if (sortReverse) {
      array.sort((a: any, b: any) => {
        if (typeof a[args] === 'number') {
          if (a[args] < b[args]) {
            return -1;
          } else if (a[args] > b[args]) {
            return 1;
          } else {
            return 0;
          }
        } else {
          if (a[args].toLowerCase() < b[args].toLowerCase()) {
            return -1;
          } else if (a[args].toLowerCase() > b[args].toLowerCase()) {
            return 1;
          } else {
            return 0;
          }
        }
      });
      return array;
    } else {
      array.sort((a: any, b: any) => {
        if (typeof a[args] === 'number') {
          if (a[args] < b[args]) {
            return 1;
          } else if (a[args] > b[args]) {
            return -1;
          } else {
            return 0;
          }
        } else {
          if (a[args].toLowerCase() < b[args].toLowerCase()) {
            return 1;
          } else if (a[args].toLowerCase() > b[args].toLowerCase()) {
            return -1;
          } else {
            return 0;
          }
        }
      });
      return array;
    }
  }
}
