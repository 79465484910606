import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ProdToStageRoutingModule} from './prod-to-stage-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ProdToStageComponent} from './pages/prod-to-stage.component';
import {BoardComponent} from './components/board/board.component';
import {CategoryComponent} from './components/category/category.component';
import { MessageMappingsStatusComponent } from './components/message-mappings-status-list/message-mappings-status.component';
import {ProdStageService} from '../../core/services/prod-stage-service/prod-stage-service';
import {ClientsService} from '../../core/services/customer/clients.service';
import {MappingsService} from '../../core/services/Mappings/mappings.service';
import {PopulateModalModule} from '../../shared/components/modal/populate-modal.module';
import { TabsViewComponent } from './tabs-view/tabs-view.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MessageMappingsStatusViewDialog } from './components/message-mappings-status-view/message-mappings-status-view/message-mappings-status-view.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ProdToStageRoutingModule,
    PopulateModalModule,
    MatDividerModule,
    MatToolbarModule,
    MatDialogModule,
    MatButtonModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatSortModule
  ],
  declarations: [
    ProdToStageComponent,
    CategoryComponent,
    BoardComponent,
    MessageMappingsStatusComponent,
    TabsViewComponent,
    MessageMappingsStatusViewDialog
  ],
  entryComponents:[
    MessageMappingsStatusViewDialog
  ],
  providers: [
    ProdStageService,
    ClientsService,
    MappingsService
  ]
})
export class ProdToStageModule {
}
