import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import {
  MatSnackBar, MatSnackBarConfig
} from '@angular/material/snack-bar';
import { CommunityService } from '../../../core/services/community/community.service';
import { ClientsService } from '../../../core/services/customer/clients.service';
import { ModalComponent } from '../../../shared/components/modal/pages/modal.component';
import { SearchComponent } from '../../../shared/components/search/pages/search.component';
import { Constants } from '../../../shared/constants/constants';
import { Authenticationprovider } from '../../../shared/models/Authenticationprovider';
import { Community } from '../../../shared/models/community';
import { CommunityTypeDef } from '../../../shared/models/community-type-def';
import { Accessdetails } from '../../../shared/models/community_accessdetails';
import { Customer } from '../../../shared/models/customer';
import { Domain } from '../../../shared/models/domain';
import { EnvironmentType } from '../../../shared/models/environment_type';
import { InteractStyleMappings } from '../../../shared/models/interact-style-mappings';
import { InteractionStyles } from '../../../shared/models/interaction-styles';
import { Role } from '../../../shared/models/roles';
import { User } from '../../../shared/models/user';
import { AccessDetailsComponent } from '../access-details/access-details.component';
import { CommunityInfoComponent } from '../community-info/community-info.component';
import { CommunityListComponent } from '../community-list/community-list.component';
import { InteractionStylesComponent } from '../interaction-styles/interaction-styles.component';

@Component({
  selector: 'app-communities',
  templateUrl: './communities.component.html',
  styleUrls: ['./communities.component.css']
})
/**
 * Community creation component
 * used for create, update & enable or disable community
 */
export class CommunitiesComponent implements OnInit {

  @ViewChild(ModalComponent, { static: true }) modalComponent;
  @ViewChild(SearchComponent, { static: true }) searchComponent;

  @ViewChild(InteractionStylesComponent, { static: true }) interactionStylesComponent;
  @ViewChild(AccessDetailsComponent, { static: true }) accessDetailsComponent;
  @ViewChild(CommunityInfoComponent, { static: true }) communityInfoComponent;
  @ViewChild(CommunityListComponent, { static: true }) communityListComponent;

  @ViewChild(MatExpansionPanel, { static: true }) expansion;

  formValues: Community;
  accessDetails: Accessdetails;
  environmentType: EnvironmentType;
  interactStyleMappings: InteractStyleMappings[];
  interactionStyles: InteractionStyles;
  communityTypeDef: CommunityTypeDef;
  environmentTypeList: EnvironmentType[] = [];
  communityTypeList: CommunityTypeDef[] = [];
  interactionStylesList: InteractionStyles[] = [];
  owners: User[] = [];
  authenticationprovider: Authenticationprovider;
  domain: Domain;
  user: User;
  role: Role;
  customer: Customer;
  communityToEmit: Community;
  showSpinner = false;
  saveOrUpdate = 'Save';
  communityTypeName: string;
  isCommunityUpdate = false;
  isValidated = false;
  panelOpenState = false;
  customers: Customer[];
  loginUserId: string = localStorage.getItem('loginUserId');
  config = new MatSnackBarConfig();
  icstenantid : String='';
  communityEvents: any = [];
  originalList: any = [];
  eventsEnabled: boolean = false;
  eventTypes = [
    { value: 'MessageCreate', name: 'Message Create' },
    { value: 'MessageMove', name: 'Message Move' },
    { value: 'MessageUpdate', name: 'Message Update' },
    { value: 'MessageDelete', name: 'Message Delete' },
  ];

  constructor(private communityService: CommunityService, private clientService: ClientsService, private snackBar: MatSnackBar, private cdr: ChangeDetectorRef) {
  }

  /**
   * calls when loading the component
   */
  ngOnInit() {
    this.config.verticalPosition = 'top';
    this.config.horizontalPosition = 'end';
    this.config.duration = 5000;
    this.config.panelClass = ['blue-snackbar'];
    this.emptyFormValues();
    this.customersList();
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }
  /**
   * method that set default values for community model
   * */
  emptyFormValues() {
    this.accessDetails = {
      password: '', accessId: null, accessToken: '', refreshToken: '', clientSecret: '', clientId: '',
      userName: '', authenticationToken: '', verifyToken: '', accessTokenURL: '', tenantName: '', htAccess: '', bulkDataCommunityId: '', bulkAPIAccessToken: '', bulkDataClientId: '',
      translateAccessToken: '', translateRefreshToken: '', translateClientId: '',
      translateClientSecret: '', googleProjectId: '',microsoftTranslateSubscriptionKey: '', lingotekTranslateAccesskey: '', lingotekCallbackUserName: '', awsAPIKey: '',
      lingotekCallbackPassword: '', azureStorageConnectionUrl: '', azureFileShare: '', azureFolder: '', coveoOrganizationId: '', coveoDatasourceId: '', coveoAPIKey: '', securityIdentityProviderName: '', chamaAPIKey: '', chamaMACKey: '', smartsheetAPIKey: '', communityUserAuthenticationToken: '', zendeskDatabaseUserName: '', zendeskDatabasePassword: '', zendeskDatabaseServer: '', zendeskDatabaseName: '',    smartlingSignature: '',resourceGroup: '', storageType:'', subscriptionId:'', azureauthtype:'',azureAuthenticationType:'',azureStorageAccountName:'',azureStorageType:'',azureResourceGroup: '',
  azureSubscriptionId: ''
    };

    this.authenticationprovider = { authProviderId: null, authProviderName: '' };

    this.environmentType = { environmentTypeId: null, environmentType: '' };
    this.communityTypeDef = { communityTypeId: null, communityTypeName: '' };
    this.interactionStyles = { interactionStylesDefId: null, interactionStyle: '', communityTypeDef: this.communityTypeDef };
    this.interactStyleMappings = [{
      interactionStyleMappingId: null, sourceCommunityId: null, active: false,
      interactionStyle: this.interactionStyles
    }];
    this.role = { roleId: null, roleName: '' };
    this.domain = { domainCustomerId: 0, domainId: 0, domainName: '' };
    this.customer = {
      customerName: '', customerId: null, active: false, contact: null, address: '',
      email: '', clientaccessdetail: this.accessDetails, domains: [this.domain], authenticationProvider: this.authenticationprovider
    };
    this.user = {
      userId: null, customer: this.customer, loginUserId: null, userEmail: '',
      firstName: '', lastName: '', role: this.role, active: false
    };


    this.formValues = {
      deeplGlossaryMapping:[],
      communityUrl: '',
      active: false,
      accessdetail: this.accessDetails,
      communityId: null, apiUrl: '',
      communityName: '',
      interactionStyleMappings: this.interactStyleMappings,
      communityType: this.communityTypeDef,
      environmentType: this.environmentType,
      communityFunction: '',
      registrationUrl: '',
      user: this.user,
      tenantId: '',
      userValidateBy: '',
      nodeId: '',
      nodeType:'',
      smartsheetFolderId: '',
      customer: this.customer,
      syndicateLabels: false,
      syndicateTags: false,
      metaDataUpdated: false,
      message: '',
      translation: false,
      autoSyndicate: false,
      customerName: '',
      customerId: null,
      isCategoryAsCommunity: '',
      autoDetectLanguageForLingotek: false,
      translationLanguageCode: '',
      translationProvider: '',
      googleTranslationAuthType: '',
      icsTenantId: '',
      pageId: '',
      linkedInpageId: '',
      coveoExtensionId: '',
      khorosPullMechanismEnabled: false,
      excludedRoles: '',
      includedRoles: '',
      resourceGroup: '',
      storageType: '',
      subscriptionId: '',
      azureauthtype: '',
      zendeskScope: '',
      resourceType: '',
      grantType: '',
      tenantName: '',
      siteName: '',
      subredditName: '',
      projectId: '',
      bucketName: '',
      datastoreURL: '',
      datastoreId: '',
      authenticationType: '',
      higherlogicCommunityName: '',
      sharepointStorageId: '',
      postAsMachineUser: false,
      canonicalUrlEnabled: false,
      targetMessageUpdatesSyndication: false,
      targetThreadRepliesSyndication: false,
      khorosSleUpdate:false,
      messageHiddenTagSyndication:false,
      hierarchicalLabels:false,
      communityWebhooks: [],
      fieldMappingEnabled: false,
      chamaEndpointUrl: '',
      awsEndpoint: '',
      awsS3Url: '',
      hiddenBoardIds: '',
      azureAuthenticationType: '',
      mTLSKeyCertificateUpload: null,
      mTLSKeyFileUpload: null,
      gcpKeyFileUpload: null
    };
  }

  /**
   * To save or update the community
   * after submitting the form this will called
   * @param communityForm
   */
  submit() {
    const communityObj: Community = this.getObjectOfCommunity(this.formValues,
      this.interactionStylesComponent.interactionStylesForm,
      this.accessDetailsComponent.accessDetailsForm,
      this.communityInfoComponent.communityInfoForm,this.communityInfoComponent 
    );
    if (this.saveOrUpdate === 'Save') {
      // console.log(communityObj , 'communityobj')

      const stylesMappingsList: Array<any> = this.getInteractionStlesMap(this.interactionStylesList);
      const matchedCommunity: Community[] = this.communityListComponent.list.filter(community => {
        // console.log(community.customerName , communityObj.customerName , 'comm and obj customer name')
        return (community.communityName === communityObj.communityName && community.customerName === communityObj.customerName);
      });
      if (this.isCommunityUpdate) {
        const selectedCommunity = this.communityListComponent.list.filter((community: Community) => {
          return this.communityToEmit.communityId === community.communityId;
        });
        communityObj.communityId = selectedCommunity[0].communityId;
      }

      //added for commuity events
      if (this.communityTypeName === 'KHOROS' && this.eventsEnabled) {
        let list = this.communityEvents.filter(
          (obj: any) => obj.eventType !== '' && obj.callBackUrl !== ''
        );
        communityObj.communityWebhooks = list;
      } else {
        communityObj.communityWebhooks = [];
      }

      if (matchedCommunity.length > 0 && !this.isCommunityUpdate) {
        this.modalComponent.populateModal({ response: { message: Constants.COMMUNITY_ALREADY_EXIST } });
        return;
      } else {
        this.showSpinner = true;
        this.communityService.save(communityObj, stylesMappingsList, this.isCommunityUpdate)
          .subscribe(res => {
            this.showSpinner = false;
            this.isValidated = false;
            this.panelOpenState = false;
            this.modalComponent.populateModal(res);
            this.expansion.close();
            this.resetForm();
            this.communityListComponent.communityList();
          },
            error => {
              this.showSpinner = false;
              if (error.status === 401 && error.error.response.status === 'error' && error.error.response.message === 'UnAuthorized Access Token Expired') {
                return this.snackBar.open(Constants.SESSION_EXPIRED, 'Close', this.config);
              } else {
                return this.snackBar.open(error.error.response.message, 'Close', this.config);
              }
            });
      }
    } else {
      const selectedCommunity = this.communityListComponent.list.filter((community: Community) => {
        return this.communityToEmit.communityId === community.communityId;
      });
      if (this.formValues.nodeType !=="" && this.formValues.nodeType !=="community" && (this.formValues.nodeId === undefined || this.formValues.nodeId === '')) {
        return this.snackBar.open(Constants.CATEGORY_ID_REQUIRED, 'Close', this.config);
      } else if (this.formValues.nodeType ==="" || this.formValues.nodeType ==="community") {
        this.formValues.nodeId = '';
      }
      const payLoad = {
        'communityId': selectedCommunity[0].communityId,
        'communityName': this.formValues.communityName,
        'tenantId': this.formValues.tenantId,
        'zendeskScope': this.formValues.zendeskScope,
        'communityUrl': this.formValues.communityUrl,
        'apiUrl': this.formValues.apiUrl,
        'registrationUrl': this.formValues.registrationUrl,
        'userId': this.formValues.user.userId,
        'nodeId': this.formValues.nodeId,
        'nodeType': this.formValues.nodeType,
        'smartsheetFolderId': this.formValues.smartsheetFolderId,
        'coveoExtensionId': this.formValues.coveoExtensionId !== null ? this.formValues.coveoExtensionId : '',
        'khorosPullMechanismEnabled': this.formValues.khorosPullMechanismEnabled !== null ? this.formValues.khorosPullMechanismEnabled : false,
        'excludedRoles': this.formValues.excludedRoles !== null ? this.formValues.excludedRoles : '',
        'includedRoles': this.formValues.includedRoles !== null ? this.formValues.includedRoles : '',
        'authenticationToken': this.formValues.accessdetail.authenticationToken !== null ?
          this.formValues.accessdetail.authenticationToken : '',
        'verifyToken': this.formValues.accessdetail.verifyToken !== null ? this.formValues.accessdetail.verifyToken : '',
        'communityFunction': this.formValues.communityFunction,
        'htAccess': this.formValues.accessdetail.htAccess === null ? '' : this.formValues.accessdetail.htAccess,
        'interactionStyleMappingsList': this.getInteractionStlesMap(this.interactionStylesList),
        'tags': this.formValues.syndicateTags === null ? false : this.formValues.syndicateTags,
        'labels': this.formValues.syndicateLabels === null ? false : this.formValues.syndicateLabels,
        'icsTenantId': this.formValues.icsTenantId === null ? '' : this.formValues.icsTenantId,
        'pageId': this.formValues.pageId === null ? '' : this.formValues.pageId,
        'linkedInpageId': this.formValues.linkedInpageId === null ? '' : this.formValues.linkedInpageId,
        'azureStorageConnectionUrl': this.formValues.accessdetail.azureStorageConnectionUrl === null ? '' : this.formValues.accessdetail.azureStorageConnectionUrl,
        'azureFileShare': this.formValues.accessdetail.azureStorageConnectionUrl === null ? '' : this.formValues.accessdetail.azureStorageConnectionUrl,
        'azureFolder': this.formValues.accessdetail.azureStorageConnectionUrl === null ? '' : this.formValues.accessdetail.azureStorageConnectionUrl,
        'coveoOrganizationId': this.formValues.accessdetail.coveoOrganizationId === null ? '' : this.formValues.accessdetail.coveoOrganizationId,
        'coveoDatasourceId': this.formValues.accessdetail.coveoDatasourceId === null ? '' : this.formValues.accessdetail.coveoDatasourceId,
        'coveoAPIKey': this.formValues.accessdetail.coveoAPIKey === null ? '' : this.formValues.accessdetail.coveoAPIKey,
        'securityIdentityProviderName': this.formValues.accessdetail.securityIdentityProviderName === null ? '' : this.formValues.accessdetail.securityIdentityProviderName,
        'awsAPIKey': this.formValues.accessdetail.awsAPIKey === null ? '' : this.formValues.accessdetail.awsAPIKey,
        'chamaAPIKey': this.formValues.accessdetail.chamaAPIKey === null ? '' : this.formValues.accessdetail.chamaAPIKey,
        'chamaMACKey': this.formValues.accessdetail.chamaMACKey === null ? '' : this.formValues.accessdetail.chamaMACKey,
        'smartsheetAPIKey': this.formValues.accessdetail.smartsheetAPIKey === null ? '' : this.formValues.accessdetail.smartsheetAPIKey,
        'communityUserAuthenticationToken': this.formValues.accessdetail.communityUserAuthenticationToken === null ? '' : this.formValues.accessdetail.communityUserAuthenticationToken,
        'zendeskDatabaseUserName': this.formValues.accessdetail.zendeskDatabaseUserName === null ? '' : this.formValues.accessdetail.zendeskDatabaseUserName,
        'zendeskDatabasePassword': this.formValues.accessdetail.zendeskDatabasePassword === null ? '' : this.formValues.accessdetail.zendeskDatabasePassword,
        'zendeskDatabaseServer': this.formValues.accessdetail.zendeskDatabaseServer === null ? '' : this.formValues.accessdetail.zendeskDatabaseServer,
        'zendeskDatabaseName': this.formValues.accessdetail.zendeskDatabaseName === null ? '' : this.formValues.accessdetail.zendeskDatabaseName,
        'postAsMachineUser': this.formValues.postAsMachineUser !== null ? this.formValues.postAsMachineUser : false,
        'canonicalUrlEnabled': this.formValues.canonicalUrlEnabled !== null ? this.formValues.canonicalUrlEnabled : false,
        'targetMessageUpdatesSyndication': this.formValues.targetMessageUpdatesSyndication !== null ? this.formValues.targetMessageUpdatesSyndication : false,
        'targetThreadRepliesSyndication': this.formValues.targetThreadRepliesSyndication !== null ? this.formValues.targetThreadRepliesSyndication : false,
        'khorosSleUpdate': this.formValues.khorosSleUpdate !== null ? this.formValues.khorosSleUpdate : false,
        'messageHiddenTagSyndication': this.formValues.messageHiddenTagSyndication !== null ? this.formValues.messageHiddenTagSyndication : false,
        'hierarchicalLabels': this.formValues.hierarchicalLabels !== null ? this.formValues.hierarchicalLabels : false,
        'fieldMappingEnabled': this.formValues.fieldMappingEnabled !== null ? this.formValues.fieldMappingEnabled : false
      };
      this.showSpinner = true;
      // this.isValidated = false;
      this.communityService.update(payLoad, false)
        .subscribe(res => {
          this.showSpinner = false;
          this.showSpinner = false;
          this.saveOrUpdate = 'Save';
          this.isValidated = false;
          this.communityListComponent.communityList();
          this.modalComponent.populateModal(res);
          this.expansion.close();
          this.resetForm();
        }, error => {
          this.showSpinner = false;
          this.resetForm();
          if (error.status === 401 && error.error.response.status === 'error' && error.error.response.message === 'UnAuthorized Access Token Expired') {
            return this.snackBar.open(Constants.SESSION_EXPIRED, 'Close', this.config);
          } else {
            return this.snackBar.open(error.error.response.message, 'Close', this.config);
          }
        });
    }
  }

  /**
   * Edit or modify the community and update
   * @param community
   */
  edit(community: Community) {

    this.icstenantid = community.icsTenantId;
    this.isValidated = false;
    this.interactionStylesComponent.interactionStylesForm.enable();
    this.accessDetailsComponent.accessDetailsForm.enable();
    this.communityInfoComponent.communityInfoForm.enable();
    this.communityToEmit = { ...community };
    this.interactionStylesComponent.edit({ ...community });
    this.accessDetailsComponent.edit({ ...community });
    this.communityInfoComponent.edit({ ...community });
    this.expansion.open();
    this.saveOrUpdate = 'Save';
    this.isValidated = false;
    this.isCommunityUpdate = true;
    // this.saveOrUpdate = 'Update';
    // this.isValidated = true;
    if (this.communityTypeName == 'KHOROS' && community.communityWebhooks){
      this.eventsEnabled = community.communityWebhooks.length ? true : false;
      if(this.eventsEnabled){
        for(var i=0;i<community.communityWebhooks.length;i++){
          community.communityWebhooks[i].disabled = true;
        }
      }
      this.communityEvents = community.communityWebhooks;
    }
  }

  /**
   * preapring pay load for save or update community
   * fetch the interaction style selections from template
   * @param interactionStyles
   */
  getInteractionStlesMap(interactionStyles: InteractionStyles[]): Array<any>[] {
    const mappings: Array<any> = [];
    interactionStyles.map(interactionStyle => {
      let isActive = this.interactionStylesComponent.interactionStylesForm.controls[interactionStyle.interactionStyle].value;
      mappings.push({
        'interactionStyle': interactionStyle.interactionStyle,
        'active': (isActive === undefined || isActive === null || isActive === false) ? false : isActive
      });
    });

    return mappings;
  }

  /**
   * validate community by the values while creating community
   * Accesstoken validation
   * Refresh token validation
   * Authentication token validation
   */
  validateCommunity() {
    let message = '';
    const community: Community = this.getObjectOfCommunity(this.formValues,
      this.interactionStylesComponent.interactionStylesForm,
      this.accessDetailsComponent.accessDetailsForm,
      this.communityInfoComponent.communityInfoForm, this.communityInfoComponent
    );
    const stylesMappingsList: Array<any> = this.getInteractionStlesMap(this.interactionStylesList);
    let nodeType = this.communityInfoComponent.communityInfoForm.controls['nodeType'].value;
    if (nodeType ==='' && (community.communityName === Constants.COMMUNITY_TYPE_KHOROS  || community.communityName === Constants.COMMUNITY_TYPE_KHOROS_CLASSIC)) {
      this.modalComponent.populateModal({ response: { message: 'Please Select the node type' } });
      return;
    }
    const isCategoryIdIsEmpty = community.nodeId === '' ? false : true;
    if (nodeType !=='' && nodeType !=='community'&& !isCategoryIdIsEmpty && (community.communityName === Constants.COMMUNITY_TYPE_KHOROS || community.communityName === Constants.COMMUNITY_TYPE_KHOROS_CLASSIC) ) {
      this.modalComponent.populateModal({ response: { message: 'Please Enter node Id' } });
      return;
    }

    if ((community.communityName === Constants.COMMUNITY_TYPE_KHOROS || community.communityName === Constants.COMMUNITY_TYPE_KHOROS_CLASSIC) && community.environmentType.environmentTypeId === 1 && !this.isValidBulkInput()) {
      this.modalComponent.populateModal({ response: { message: Constants.INVALID_BULK_INPUT } });
      return;
    }

    //added for commuity events
    if (this.communityTypeName === 'KHOROS' && this.eventsEnabled) {
      let list = this.communityEvents.filter(
        (obj: any) => obj.eventType !== '' && obj.callBackUrl !== ''
      );
      community.communityWebhooks = list;
    } else {
      community.communityWebhooks = [];
    }


    this.showSpinner = true;
    this.communityService.validateCommunity(community, stylesMappingsList)
      .subscribe(res => {
        this.showSpinner = false;
        if (res.response.code === 200 && (res.response.data.status === 'success' || res.response.data.status === 200)) {
          this.isValidated = true;
          message = res.response.data.message;
          this.interactionStylesComponent.interactionStylesForm.disable();
          this.accessDetailsComponent.accessDetailsForm.disable();
          this.communityInfoComponent.communityInfoForm.disable();
        } else {
          if (res.response.data.code == 10020) { 
            message += res.response.data.message
          } else {
            message += Constants.VALID_INPUT_FOR_COMMUNITY_VALIDATION;
          }
        }
        this.modalComponent.populateModal({ response: { message: message } });
      }, error => {
        this.showSpinner = false;
        if (error.status === 401 && error.error.response.status === 'error' && error.error.response.message === 'UnAuthorized Access Token Expired') {
          return this.snackBar.open(Constants.SESSION_EXPIRED, 'Close', this.config);
        } else {
          return this.snackBar.open(error.error.response.message, 'Close', this.config);
        }
      });
  }

  /*actual chnages after applying the component base applications*/
  /**
   * This will be used to populate the modal from child component
   * reset the form
   * Emitted by the child component
   * way of communicating data from child to parent component
   * @param message
   */
  public showMessage(message: string) {
    this.modalComponent.populateModal({ response: { message: message } });
    this.resetForm();
  }

  public getEmittedData(data: any) {
    this.communityTypeList = data.communityTypes;
    this.owners = data.owners;
  }

  public getEnvironmentList(data: any) {
    this.environmentTypeList = data.environemnts;
  }

  /**
   * @return community object
   * @param community
   * @param interactionStylesForm
   * @param accessDetailsForm
   * @param communityInfoForm
   */
  public getObjectOfCommunity(community: Community, interactionStylesForm: FormGroup,
    accessDetailsForm: FormGroup, communityInfoForm: FormGroup, communityInfo: FormGroup): Community {

    const communityTypeNane = interactionStylesForm.controls['applicationType'].value;
    const communityType = communityTypeNane; //=== Constants.COMMUNITY_TYPE_LITHIUM ? Constants.LITHIUM :
    // interactionStylesForm.controls['applicationType'].value;
    community.tenantId = communityInfoForm.controls['tenantID'] !== undefined ?
      communityInfoForm.controls['tenantID'].value : null;
    community.zendeskScope = communityInfoForm.controls['zendeskScope'] !== undefined ?
      communityInfoForm.controls['zendeskScope'].value : null;
    community.nodeId = communityInfoForm.controls['nodeId'] !== undefined ?
      communityInfoForm.controls['nodeId'].value : null;
      community.nodeType = communityInfoForm.controls['nodeType'] !== undefined ?
      communityInfoForm.controls['nodeType'].value : null;
    community.communityType.communityTypeName = communityType;
    community.user.customer.customerName = interactionStylesForm.controls['customer'].value;
    community.communityName = communityInfoForm.controls['communityName'].value;
    community.communityUrl = communityInfoForm.controls['communityURL'] !== undefined ?
      communityInfoForm.controls['communityURL'].value.trim() : null;
    community.apiUrl = communityInfoForm.controls['apiHostURL'] !== undefined ?
      communityInfoForm.controls['apiHostURL'].value.trim() : null;
    community.smartsheetFolderId = communityInfoForm.controls['smartsheetFolderId'] !== undefined ?
      communityInfoForm.controls['smartsheetFolderId'].value.trim() : null;
    community.accessdetail.clientId = accessDetailsForm.controls['clientID'] !== undefined ?
      accessDetailsForm.controls['clientID'].value : null;
    community.accessdetail.clientSecret = accessDetailsForm.controls['clientSecret'] !== undefined ?
      accessDetailsForm.controls['clientSecret'].value : null;
    community.accessdetail.accessToken = accessDetailsForm.controls['accessToken'] !== undefined ?
      accessDetailsForm.controls['accessToken'].value : null;
    community.accessdetail.smartlingSignature = accessDetailsForm.controls['smartlingSignature'] !== undefined ?
      accessDetailsForm.controls['smartlingSignature'].value : null;
    community.accessdetail.refreshToken = accessDetailsForm.controls['refreshToken'] !== undefined ?
      accessDetailsForm.controls['refreshToken'].value : null;
    // sets the tenant name and access token url
    community.accessdetail.tenantName = accessDetailsForm.controls['tenantName'] !== undefined ?
      accessDetailsForm.controls['tenantName'].value : null;
    community.tenantName = accessDetailsForm.controls['tenantName'] !== undefined ?
      accessDetailsForm.controls['tenantName'].value : null;
    community.accessdetail.accessTokenURL = accessDetailsForm.controls['accessTokenURL'] !== undefined ?
      accessDetailsForm.controls['accessTokenURL'].value : null;
    community.userValidateBy = communityInfoForm.controls['validateCommunityBy'] !== undefined ?
      communityInfoForm.controls['validateCommunityBy'].value : null;
    community.khorosPullMechanismEnabled = communityInfoForm.controls['khorosPullMechanismEnabled'] !== undefined ? communityInfoForm.controls['khorosPullMechanismEnabled'].value : false;
    community.postAsMachineUser = communityInfoForm.controls['postAsMachineUser'] !== undefined ? communityInfoForm.controls['postAsMachineUser'].value : false;
    community.canonicalUrlEnabled = communityInfoForm.controls['canonicalUrlEnabled'] !== undefined ? communityInfoForm.controls['canonicalUrlEnabled'].value : false;
    community.targetMessageUpdatesSyndication = communityInfoForm.controls['targetMessageUpdatesSyndication'] !== undefined ? communityInfoForm.controls['targetMessageUpdatesSyndication'].value : false;
    community.targetThreadRepliesSyndication = communityInfoForm.controls['targetThreadRepliesSyndication'] !== undefined ? communityInfoForm.controls['targetThreadRepliesSyndication'].value : false;
    community.fieldMappingEnabled = communityInfoForm.controls['fieldMappingEnabled'] !== undefined ? communityInfoForm.controls['fieldMappingEnabled'].value : false;
    community.khorosSleUpdate = communityInfoForm.controls['khorosSleUpdate'] !== undefined ? communityInfoForm.controls['khorosSleUpdate'].value : false;
    community.messageHiddenTagSyndication = communityInfoForm.controls['messageHiddenTagSyndication'] !== undefined ? communityInfoForm.controls['messageHiddenTagSyndication'].value : false;
    community.hierarchicalLabels = communityInfoForm.controls['hierarchicalLabels'] !== undefined ? communityInfoForm.controls['hierarchicalLabels'].value : false;
    community.nodeId = communityInfoForm.controls['nodeId'] !== undefined ?
      communityInfoForm.controls['nodeId'].value : null;
    community.nodeType = communityInfoForm.controls['nodeType'] !== undefined ?
      communityInfoForm.controls['nodeType'].value : null;
    community.accessdetail.authenticationToken = accessDetailsForm.controls['autthenicationToken'] !== undefined ?
      accessDetailsForm.controls['autthenicationToken'].value : null;
    community.accessdetail.communityUserAuthenticationToken = accessDetailsForm.controls['communityUserAuthenticationToken'] !== undefined ? accessDetailsForm.controls['communityUserAuthenticationToken'].value : null;
    community.accessdetail.verifyToken = accessDetailsForm.controls['verifyToken'] !== undefined ?
      accessDetailsForm.controls['verifyToken'].value : null;
    community.registrationUrl = communityInfoForm.controls['registrationURL'] !== undefined ?
      communityInfoForm.controls['registrationURL'].value : null;
    community.resourceType = communityInfoForm.controls['resourceType'] !== undefined ? communityInfoForm.controls['resourceType'].value : null;
    community.grantType = communityInfoForm.controls['grantType'] !== undefined ? communityInfoForm.controls['grantType'].value : null;
    community.siteName = communityInfoForm.controls['siteName'] !== undefined ? communityInfoForm.controls['siteName'].value : null;
    community.subredditName = communityInfoForm.controls['subredditName'] !== undefined ? communityInfoForm.controls['subredditName'].value : null;
    community.projectId = communityInfoForm.controls['projectId'] !== undefined ? communityInfoForm.controls['projectId'].value : null;
    community.bucketName = communityInfoForm.controls['bucketName'] !== undefined ? communityInfoForm.controls['bucketName'].value : null;
    community.datastoreURL = communityInfoForm.controls['datastoreURL'] !== undefined ? communityInfoForm.controls['datastoreURL'].value : null;
    community.datastoreId = communityInfoForm.controls['datastoreId'] !== undefined ? communityInfoForm.controls['datastoreId'].value : null;
    community.authenticationType = communityInfoForm.controls['authenticationType'] !== undefined ? communityInfoForm.controls['authenticationType'].value : null;
    community.higherlogicCommunityName = communityInfoForm.controls['higherlogicCommunityName'] !== undefined ? communityInfoForm.controls['higherlogicCommunityName'].value : null;
    community.sharepointStorageId = communityInfoForm.controls['sharepointStorageId'] !== undefined ? communityInfoForm.controls['sharepointStorageId'].value : null;
    community.communityFunction = communityInfoForm.controls['communityActAS'].value;
    community.accessdetail.htAccess = accessDetailsForm.controls['htAccess'].value;
    community.syndicateLabels = communityInfoForm.controls['syndicateLabels'] !== undefined ? communityInfoForm.controls['syndicateLabels'].value : false;
    community.syndicateTags = communityInfoForm.controls['syndicateTags'] !== undefined ? communityInfoForm.controls['syndicateTags'].value : false;
    community.pageId = interactionStylesForm.controls['pageId'] !== undefined ? interactionStylesForm.controls['pageId'].value : null;
    community.linkedInpageId = communityInfoForm.controls['linkedInpageId'] !== undefined ? communityInfoForm.controls['linkedInpageId'].value : null;
    community.hiddenBoardIds = communityInfoForm.controls['hiddenBoardIds'] !== undefined ? communityInfoForm.controls['hiddenBoardIds'].value : null;
    community.icsTenantId = communityInfoForm.controls['icsTenantId'] !== undefined ? communityInfoForm.controls['icsTenantId'].value : null;
    community.resourceType = communityInfoForm.controls['resourceType'] !== undefined ? communityInfoForm.controls['resourceType'].value : null;
    community.grantType = communityInfoForm.controls['grantType'] !== undefined ? communityInfoForm.controls['grantType'].value : null;
    const environmentid = +accessDetailsForm.controls['environment'].value;
    if ((communityType === Constants.COMMUNITY_TYPE_KHOROS  || community.communityName === Constants.COMMUNITY_TYPE_KHOROS_CLASSIC) && environmentid === 1) {
      community.accessdetail.bulkDataCommunityId = accessDetailsForm.controls['bulkDataCommunityId'].value;
      community.accessdetail.bulkDataClientId = accessDetailsForm.controls['bulkDataClientId'].value;
      community.accessdetail.bulkAPIAccessToken = accessDetailsForm.controls['bulkAPIAccessToken'].value;
      community.accessdetail.azureStorageConnectionUrl = accessDetailsForm.controls['azureStorageConnectionUrl'].value;
      community.accessdetail.storageType = accessDetailsForm.controls['storageType'].value;
      community.accessdetail.resourceGroup = accessDetailsForm.controls['resourceGroup'].value;
      community.accessdetail.subscriptionId = accessDetailsForm.controls['subscriptionId'].value;
      community.accessdetail.azureauthtype = accessDetailsForm.controls['azureauthtype'].value;
      community.accessdetail.azureFileShare = accessDetailsForm.controls['azureFileShare'].value;
      community.accessdetail.azureFolder = accessDetailsForm.controls['azureFolder'].value;
    }
    if (communityType === Constants.COMMUNITY_TYPE_KHOROS && environmentid === 2) { 
      community.accessdetail.azureStorageConnectionUrl = accessDetailsForm.controls['azureStorageConnectionUrl'].value;
      community.accessdetail.storageType = accessDetailsForm.controls['storageType'].value;
      community.accessdetail.resourceGroup = accessDetailsForm.controls['resourceGroup'].value;
      community.accessdetail.subscriptionId = accessDetailsForm.controls['subscriptionId'].value;
      community.accessdetail.azureauthtype = accessDetailsForm.controls['azureauthtype'].value;
      community.accessdetail.azureFileShare = accessDetailsForm.controls['azureFileShare'].value;
      community.accessdetail.azureFolder = accessDetailsForm.controls['azureFolder'].value;
    }
    if (communityType === Constants.COMMUNITY_TYPE_AZURE_STORAGE) {
      community.accessdetail.azureStorageConnectionUrl = accessDetailsForm.controls['azureStorageConnectionUrl'].value;
      community.accessdetail.resourceGroup = accessDetailsForm.controls['resourceGroup'].value;
      community.accessdetail.storageType = accessDetailsForm.controls['storageType'].value;
      community.accessdetail.subscriptionId = accessDetailsForm.controls['subscriptionId'].value;
      community.accessdetail.azureauthtype = accessDetailsForm.controls['azureauthtype'].value;
      community.accessdetail.azureFileShare = accessDetailsForm.controls['azureFileShare'].value;
      community.accessdetail.azureFolder = accessDetailsForm.controls['azureFolder'].value;
    }
    if (communityType === Constants.COMMUNITY_TYPE_COVEO) {
      community.accessdetail.coveoOrganizationId = accessDetailsForm.controls['coveoOrganizationId'].value;
      community.accessdetail.coveoDatasourceId = accessDetailsForm.controls['coveoDatasourceId'].value;
      community.accessdetail.coveoAPIKey = accessDetailsForm.controls['coveoAPIKey'].value;
      community.accessdetail.securityIdentityProviderName = accessDetailsForm.controls['securityIdentityProviderName'].value;
      community.coveoExtensionId = communityInfoForm.controls['coveoExtensionId'] !== undefined ? communityInfoForm.controls['coveoExtensionId'].value : '';
      community.excludedRoles = communityInfoForm.controls['excludedRoles'] !== undefined ? communityInfoForm.controls['excludedRoles'].value : '';
      community.includedRoles = communityInfoForm.controls['includedRoles'] !== undefined ? communityInfoForm.controls['includedRoles'].value : '';
    }
    if (communityType === Constants.COMMUNITY_TYPE_WEB_PROXY) {
      community.accessdetail.awsAPIKey = accessDetailsForm.controls['awsAPIKey'].value;
      community.awsEndpoint = communityInfoForm.controls['awsEndpoint'] !== undefined ? communityInfoForm.controls['awsEndpoint'].value : '';
      community.mTLSKeyFileUpload = communityInfo['mTLSKeyFileUpload'] !== undefined ? communityInfo['mTLSKeyFileUpload'] : '';
      community.mTLSKeyCertificateUpload = communityInfo['mTLSKeyCertificateUpload'] !== undefined ? communityInfo['mTLSKeyCertificateUpload'] : '';
      community.awsS3Url = communityInfoForm.controls['awsS3Url'] !== undefined ? communityInfoForm.controls['awsS3Url'].value : '';
    }
    if (communityType === Constants.COMMUNITY_TYPE_GOOGLE_CLOUD_STORAGE) {
      community.projectId = communityInfoForm.controls['projectId'] !== undefined ? communityInfoForm.controls['projectId'].value : '';
      community.bucketName = communityInfoForm.controls['bucketName'] !== undefined ? communityInfoForm.controls['bucketName'].value : '';
      community.datastoreURL = communityInfoForm.controls['datastoreURL'] !== undefined ? communityInfoForm.controls['datastoreURL'].value : '';
      community.datastoreId = communityInfoForm.controls['datastoreId'] !== undefined ? communityInfoForm.controls['datastoreId'].value : '';
      community.gcpKeyFileUpload = communityInfo['gcpKeyFileUpload'] !== undefined ? communityInfo['gcpKeyFileUpload'] : '';
    }
    if (communityType === Constants.COMMUNITY_TYPE_VERTEX_AI) {
      community.projectId = communityInfoForm.controls['projectId'] !== undefined ? communityInfoForm.controls['projectId'].value : '';
      community.bucketName = communityInfoForm.controls['bucketName'] !== undefined ? communityInfoForm.controls['bucketName'].value : '';
      community.datastoreURL = communityInfoForm.controls['datastoreURL'] !== undefined ? communityInfoForm.controls['datastoreURL'].value : '';
      community.datastoreId = communityInfoForm.controls['datastoreId'] !== undefined ? communityInfoForm.controls['datastoreId'].value : '';
      community.gcpKeyFileUpload = communityInfo['gcpKeyFileUpload'] !== undefined ? communityInfo['gcpKeyFileUpload'] : '';
    }
    if (communityType === Constants.COMMUNITY_TYPE_CHAMA) {
      community.accessdetail.chamaAPIKey = accessDetailsForm.controls['chamaAPIKey'].value;
      community.accessdetail.chamaMACKey = accessDetailsForm.controls['chamaMACKey'].value;
      community.chamaEndpointUrl = communityInfoForm.controls['chamaEndpointUrl'] !== undefined ? communityInfoForm.controls['chamaEndpointUrl'].value : '';
    }
    if (communityType === Constants.COMMUNITY_TYPE_SMARTSHEET) {
      community.accessdetail.smartsheetAPIKey = accessDetailsForm.controls['smartsheetAPIKey'].value;
      community.templateSheetId = communityInfoForm.controls['templateSheetId'] !== undefined ? communityInfoForm.controls['templateSheetId'].value : '';
    }
    if (communityType === Constants.COMMUNITY_TYPE_ZENDESK_DB_SERVICE) {
      community.accessdetail.zendeskDatabaseUserName = accessDetailsForm.controls['zendeskDatabaseUserName'].value;
      community.accessdetail.zendeskDatabasePassword = accessDetailsForm.controls['zendeskDatabasePassword'].value;
      community.accessdetail.zendeskDatabaseServer = accessDetailsForm.controls['zendeskDatabaseServer'].value;
      community.accessdetail.zendeskDatabaseName = accessDetailsForm.controls['zendeskDatabaseName'].value;
    }
    this.communityTypeList.map(obj => {
      if (obj.communityTypeName === interactionStylesForm.controls['applicationType'].value) {
        community.interactionStyleMappings.map((interactionStyleObj) => {
          const communityTypeName = obj.communityTypeName; //=== Constants.COMMUNITY_TYPE_LITHIUM ? Constants.LITHIUM : obj.communityTypeName;
          interactionStyleObj.interactionStyle.communityTypeDef.communityTypeName = communityTypeName;
          interactionStyleObj.interactionStyle.communityTypeDef.communityTypeId = obj.communityTypeId;
        });
      }
    });

    const ownerid = +interactionStylesForm.controls['communityOwner'].value;
    community.user.userId = this.owners.filter((owner: User) => ownerid === owner.userId)[0].userId;


    this.environmentTypeList.map(obj => {
      if (obj.environmentTypeId === environmentid) {
        community.environmentType.environmentTypeId = obj.environmentTypeId;
        community.environmentType.environmentType = obj.environmentType;
      }
    });
    return community;
  }

  resetForm() {
    this.isValidated = false;
    this.saveOrUpdate = 'Save';
    this.isCommunityUpdate = false;
    this.interactionStylesComponent.enableFormFields();
    this.accessDetailsComponent.enableFormFields();
    this.communityInfoComponent.enableFormFields();
    this.interactionStylesComponent.showPageId = false;
    this.interactionStylesComponent.interactionStylesForm.enable();
    this.accessDetailsComponent.accessDetailsForm.enable();
    this.communityInfoComponent.communityInfoForm.enable();
  }

  getCommunityTypeName(communityTypeName: string) {
    this.communityTypeName = communityTypeName;
    this.accessDetailsComponent.getJsonObj(communityTypeName);
    this.communityInfoComponent.receiveCommunityTypeName(communityTypeName);
  }

  getStyles(styles: InteractionStyles[]) {
    this.interactionStylesList = styles.slice();
  }

  resetFormData() {
    this.resetForm();
    this.expansion.close();
  }

  isValidBulkInput(): boolean {
    const form = this.accessDetailsComponent.accessDetailsForm;
    return !(form.controls['bulkDataCommunityId'].value === ''
      || form.controls['bulkDataClientId'].value === ''
      || form.controls['bulkAPIAccessToken'].value === '');
  }

  canValidate(isValidate: boolean) {
    this.isValidated = isValidate;
  }

  regenerateIcsTenantId(icstenantid: any) {
    this.showSpinner = true;
    this.communityService.regenrateICSTenantId(icstenantid)
      .subscribe(res => {
        if (res.response.code === 200) {
          this.showSpinner = false;
          this.isValidated = false;
          this.panelOpenState = false;
          this.modalComponent.populateModal(res);
          this.expansion.close();
          this.resetForm();
          this.communityListComponent.communityList();
        } else {
          this.showSpinner = false;
          this.modalComponent.populateModal({ response: { message: Constants.SERVER_ERROR } });
        }
      }, error => {
        this.showSpinner = false;
        return this.snackBar.open(error.error.response.message, 'Close', this.config);
      });
  }

  public customersList() {
    this.showSpinner = true;
    this.clientService.getActiveCustomers(this.loginUserId)
      .subscribe(listOfCustomers => {
        this.showSpinner = false;
        this.customers = listOfCustomers.response.data;
      }, error => {
        this.showSpinner = false;
        if (error.status === 401 && error.error.response.status === 'error' && error.error.response.message === 'UnAuthorized Access Token Expired') {
          return this.snackBar.open(Constants.SESSION_EXPIRED, 'Close', this.config);
        } else {
          return this.snackBar.open(error.error.response.message, 'Close', this.config);
        }
      });
  }

  add = () => {
    this.originalList = this.communityEvents.slice();
    if (this.communityEvents.length === this.eventTypes.length) {
      alert('There are no events to add');
      return;
    }

    if (!this.communityEvents.length) {
      this.pushEmptyObject();
    } else {
      let filledEvents = this.communityEvents.filter(
        (obj: any) => obj.callBackUrl !== ''
      );
      if (filledEvents.length === this.communityEvents.length) {
        this.pushEmptyObject();
      } else {
        let nonFilledEvents = this.communityEvents
          .filter((obj: any) => obj.callBackUrl === '')
          .map((eObj: any) => eObj.eventType)
          .join(',');
        nonFilledEvents =
          nonFilledEvents === ''
            ? 'Select event type'
            : 'Please update the callback URL for' + nonFilledEvents;
        alert(nonFilledEvents);
      }
    }
  };

  changeHandler = (type: string) => {
    console.log(type, this.communityEvents, this.originalList);
    let filteredList = this.originalList.filter(
      (obj: any) => obj.eventType === type
    );
    if (filteredList.length) {
      alert('The event type is aleady selected.');
      this.communityEvents = this.originalList.slice();
      this.pushEmptyObject();
    }
  };

  pushEmptyObject = () => {
    this.communityEvents.push({
      callBackUrl: '',
      eventOwner: '',
      subscriptionId: '',
      eventType: '',
      communityId: '',
      active: false,
    });
  };

  handleDelete = (index: number) => {
    console.log(index);
    this.communityEvents.splice(index, 1);
  };

}
